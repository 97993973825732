import React from 'react';
import { colorMap } from '../../colors';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import ImportModelCore from './ImportModelCore';

function ImportNodeComponent({ id, recipeVersion, recipeId, data, updateNodeData, setNodesTypes, editable }) {
  return (
    <DynamicNode2 id={id} data={data} className="model" handleColor={colorMap.get(data.color)}>
      <ImportModelCore
        id={id}
        recipeVersion={recipeVersion}
        recipeId={recipeId}
        data={data}
        updateNodeData={updateNodeData}
        setNodesTypes={setNodesTypes}
        editable={editable}
      />
    </DynamicNode2>
  );
}

export default ImportNodeComponent;
