import React, { useState, useCallback, useId, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Skeleton,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { DropEvent, DropzoneState, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import type { AxiosProgressEvent } from 'axios';
import axiosInstance from '../../services/axiosConfig';
import { color } from '../../colors';
import I18N_KEYS from '../../language/keys';

export type UploadedFile = {
  duration?: number;
  fps?: number;
  height?: number;
  publicId: string;
  thumbnailUrl: string;
  type: string;
  url: string;
  viewUrl: string;
  visualId: string;
  width?: number;
};

type FileDisplayProps = {
  isHovered: boolean;
  maxHeight?: number;
  openFileUploader: (e: React.MouseEvent<HTMLDivElement>) => void;
  previewImage?: string;
  uploadProgress: number;
  uploadedFileType?: string;
  value?: Partial<UploadedFile> | null;
};

const FileDisplay = ({
  previewImage,
  uploadedFileType,
  value,
  openFileUploader,
  uploadProgress,
  isHovered,
  maxHeight,
}: FileDisplayProps) => {
  const { t: translate } = useTranslation();

  function renderMediaElement(url?: string, type?: string) {
    if (!url) return null;

    switch (type) {
      case 'video':
        return <video draggable={false} crossOrigin="anonymous" src={url} width="100%" style={{ display: 'block' }} />;
      case 'audio':
      case '3D':
      case 'image':
        return (
          <img
            draggable={false}
            src={url}
            alt="Media"
            style={{
              display: 'block',
              height: 'auto',
              maxHeight,
              objectFit: 'contain',
              width: '100%',
            }}
          />
        );
      default:
        return <Typography>{translate(I18N_KEYS.COMMON_COMPONENTS.FILE_UPLOADER.NO_PREVIEW)}</Typography>;
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box className="media-container" sx={{ width: '100%', height: '100%' }} onClick={openFileUploader}>
        {renderMediaElement(previewImage, uploadedFileType)}
        {value?.width && value?.height && (
          <Typography
            variant="caption"
            sx={{ position: 'absolute', top: 5, left: 5, textShadow: '0 0 5px rgba(0, 0, 0, 1)' }}
          >
            {value.width} x {value.height}
          </Typography>
        )}
      </Box>
      {uploadProgress > 0 && uploadProgress < 100 && (
        <Box sx={{ width: '100%', position: 'absolute', top: 0 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
      {isHovered && (
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1000,
          }}
          onClick={openFileUploader}
        >
          <Box
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <i className="fa-light fa-photo-film-music" style={{ fontSize: '24px' }}></i>
            <Typography variant="caption" fontWeight="bold" color={color.Yambo_Text_On_Dark}>
              {translate(I18N_KEYS.COMMON_COMPONENTS.FILE_UPLOADER.UPLOAD_ANOTHER)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

type UploadFormProps = {
  isLoading: boolean;
  error: boolean;
  helperText: string;
  allowUrlInput: boolean;
  getInputProps: DropzoneState['getInputProps'];
  onLinkUpload: (link: string) => void;
};

const UploadForm = ({ isLoading, error, helperText, allowUrlInput, getInputProps, onLinkUpload }: UploadFormProps) => {
  const { t: translate } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [fileLink, setFileLink] = useState('');

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileLink(e.target.value);
  };

  const onUploadClick = useCallback(() => {
    if (allowUrlInput && fileLink) {
      onLinkUpload(fileLink);
    }
  }, [allowUrlInput, fileLink, onLinkUpload]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '200px',
        justifyContent: 'center',
        p: !isLoading ? 2 : 0,
        textAlign: 'center',
      }}
    >
      {isLoading ? (
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
          <i
            className="fa-light fa-photo-film-music"
            style={{
              fontSize: '24px',
              left: '50%',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: allowUrlInput ? 'column' : 'row',
            gap: 1,
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              alignItems: 'center',
              border: !isLoading ? '1px dashed' : 'none',
              borderColor: error ? color.Yambo_Orange : color.Yambo_Text_On_Dark_Transparent,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              gap: 1,
            }}
          >
            <i
              className="fa-light fa-photo-film-music"
              style={{
                color: error ? color.Yambo_Orange : color.Yambo_Text_On_Dark_Transparent,
                fontSize: '24px',
                transition: 'all 0.1s ease',
              }}
            />
            <Typography
              variant="caption"
              fontWeight="bold"
              sx={{ color: error ? `${color.Yambo_Orange} !important` : color.Yambo_Text_On_Dark_Transparent }}
            >
              {error ? helperText : translate(I18N_KEYS.COMMON_COMPONENTS.FILE_UPLOADER.CTA)}
            </Typography>
          </Box>
          {allowUrlInput ? (
            <>
              <Box>
                <Typography variant="caption">{translate(I18N_KEYS.COMMON_COMPONENTS.FILE_UPLOADER.OR)}</Typography>
              </Box>
              <OutlinedInput
                className={isFocused ? 'nowheel nodrag nopan' : ''}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
                type="text"
                placeholder={translate(I18N_KEYS.COMMON_COMPONENTS.FILE_UPLOADER.PASTE_LINK)}
                value={fileLink}
                onChange={handleLinkChange}
                fullWidth
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="upload-from-link" onClick={onUploadClick} edge="end">
                      <UploadIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export type FileUploaderProps = {
  id: string;
  allowRemove?: boolean;
  allowUrlInput?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  initialFile?: File;
  isLoading?: boolean;
  maxHeight?: number;
  onInitialFileUpload?: () => void;
  onUpload: (file: Partial<UploadedFile> | null) => void;
  value?: Partial<UploadedFile> | null;
};

export const FileUploader = ({
  id,
  value,
  onUpload,
  allowRemove = false,
  allowUrlInput = false,
  disabled = false,
  error = false,
  helperText = '',
  initialFile,
  isLoading = false,
  maxHeight = undefined,
  onInitialFileUpload,
}: FileUploaderProps) => {
  const uniqueId = useId(); // to avoid conflicts with multiple upload components
  const { t: translate } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState<string | undefined>(value?.thumbnailUrl);
  const [uploadedFileType, setUploadedFileType] = useState<string | undefined>(value?.type);
  const [hasError, setHasError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const inputId = `file-upload-input-${id}-${uniqueId}`;
  const inProgress = isLoading || (uploadProgress > 0 && uploadProgress < 100);
  const fileIsSet = (previewImage || value?.url) && !hasError;

  useEffect(() => {
    if (value) {
      setPreviewImage(value.thumbnailUrl);
      setUploadedFileType(value.type);
    }
  }, [value, value?.thumbnailUrl, value?.type]);

  const uploadSuccess = useCallback(
    (uploadData: UploadedFile) => {
      setUploadProgress(100);
      setPreviewImage(uploadData.thumbnailUrl);
      setUploadedFileType(uploadData.type);
      onUpload(uploadData);
    },
    [onUpload],
  );

  const removeFile = useCallback(() => {
    if (inProgress) return;
    onUpload({ url: '', thumbnailUrl: '', type: '' });
    setPreviewImage(undefined);
    setUploadedFileType(undefined);
  }, [inProgress, onUpload]);

  const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    const total = progressEvent.total ?? progressEvent.loaded;
    const progress = Math.round((progressEvent.loaded / total) * 95);
    setUploadProgress(progress);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[], _rejectedFiles?: FileRejection[], event?: DropEvent) => {
      if (event) {
        event.preventDefault();
      }
      setHasError(false);
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const fileName = file.name.toLowerCase();

        if (file.type.startsWith('video')) {
          setUploadedFileType('video');
          setPreviewImage(URL.createObjectURL(file));
        } else if (file.type.startsWith('image')) {
          setUploadedFileType('image');
          setPreviewImage(URL.createObjectURL(file));
        } else if (file.type.startsWith('audio')) {
          setUploadedFileType('audio');
          setPreviewImage('/audio.png');
        } else if (fileName.endsWith('.glb')) {
          setUploadedFileType('3D');
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
          setUploadProgress(0);
          const response = await axiosInstance.post(`/v1/nodes/upload`, formData, {
            onUploadProgress,
          });

          uploadSuccess(response.data);
        } catch (err) {
          console.error('Error uploading file:', err);
          setHasError(true);
        } finally {
          setUploadProgress(0);
        }
      }
    },
    [onUploadProgress, uploadSuccess],
  );

  useEffect(() => {
    if (initialFile && onInitialFileUpload) {
      onDrop([initialFile]).then(() => {
        onInitialFileUpload();
      });
    }
  }, [initialFile, onDrop, onInitialFileUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp', '.webm', '.mp4', '.mov'],
      'audio/*': ['.mp3', '.wav', '.ogg'],
      'model/gltf-binary': ['.glb'],
    },
    multiple: false,
    disabled: disabled || isLoading,
  });

  const openFileUploader = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const inputElement = document.getElementById(inputId);
      inputElement?.click();
    },
    [inputId],
  );

  const onLinkUpload = useCallback(
    async (link: string) => {
      setUploadProgress(0);
      setHasError(false);
      setPreviewImage(link);
      setUploadedFileType('image');
      const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
      if (!urlPattern.test(link)) {
        console.error('Invalid URL');
        setHasError(true);
        return;
      }

      try {
        const parsedUrl = new URL(link);
        const filePath = parsedUrl.pathname;
        // const fileUrlPattern = /[/|.|\w|\s|-]*\.(?:jpg|jpeg|png|obj|fbx|glb)/gi;

        if (filePath) {
          const response = await axiosInstance.post(`/v1/nodes/upload`, null, {
            params: {
              file: link,
            },
            onUploadProgress,
          });

          uploadSuccess(response.data);
        }
      } catch (err) {
        console.error(err);
        setHasError(true);
      } finally {
        setUploadProgress(0);
      }
    },
    [onUploadProgress, uploadSuccess],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        {...getRootProps()}
        sx={{
          width: '100%',
          position: 'relative',
          transition: 'all 0.1s ease',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            border: `1px solid ${error ? color.Yambo_Orange : color.Yambo_Text_On_Dark_Transparent}`,
            borderRadius: 1,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {fileIsSet && !isLoading ? (
            <FileDisplay
              isHovered={isHovered}
              openFileUploader={openFileUploader}
              uploadProgress={uploadProgress}
              value={value}
              previewImage={previewImage}
              uploadedFileType={uploadedFileType}
              maxHeight={maxHeight}
            />
          ) : (
            <UploadForm
              isLoading={isLoading}
              error={error}
              helperText={helperText}
              allowUrlInput={allowUrlInput}
              getInputProps={getInputProps}
              onLinkUpload={onLinkUpload}
            />
          )}
        </Box>

        <input id={inputId} type="file" style={{ display: 'none' }} {...getInputProps()} />
        {hasError && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', px: 1 }}>
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <i className="fa-light fa-poo" />
              {translate(I18N_KEYS.GENERAL.UPLOAD_ERROR)}
            </Typography>
          </Box>
        )}
      </Box>
      {allowRemove ? (
        <>
          {inProgress ? <Skeleton variant="rectangular" height={30} width={150} /> : null}
          {fileIsSet && !inProgress ? (
            <Box>
              <Button
                variant="text"
                size="small"
                startIcon={<i className="fa-light fa-trash" />}
                color="weavy_cta_secondary"
                onClick={removeFile}
              >
                {translate(I18N_KEYS.COMMON_COMPONENTS.FILE_UPLOADER.REMOVE_FILE)}
              </Button>
            </Box>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};
