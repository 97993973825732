export const I18N_KEYS = {
  COMMON_COMPONENTS: {
    FILE_UPLOADER: {
      CTA: 'COMMON_COMPONENTS_FILE_UPLOADER_CTA',
      NO_PREVIEW: 'COMMON_COMPONENTS_FILE_UPLOADER_NO_PREVIEW',
      OR: 'COMMON_COMPONENTS_FILE_UPLOADER_OR',
      PASTE_LINK: 'COMMON_COMPONENTS_FILE_UPLOADER_PASTE_LINK',
      REMOVE_FILE: 'COMMON_COMPONENTS_FILE_UPLOADER_REMOVE_FILE',
      UPLOAD_ANOTHER: 'COMMON_COMPONENTS_FILE_UPLOADER_UPLOAD_ANOTHER',
      UPLOAD_FROM_LINK: 'COMMON_COMPONENTS_FILE_UPLOADER_UPLOAD_FROM_LINK',
    },
  },
  DASHBOARD_BANNER: {
    CTA: 'DASHBOARD_BANNER_CTA',
    MESSAGE: 'DASHBOARD_BANNER_MESSAGE',
  },
  FLOW_NAVBAR: {
    SHARED_DESIGN_APP_BUTTON: 'FLOW_NAVBAR_SHARED_DESIGN_APP_BUTTON',
  },
  GENERAL: {
    CANCEL: 'CANCEL',
    CLOSE: 'CLOSE',
    COPIED: 'COPIED',
    COPY_LINK: 'COPY_LINK',
    DONE: 'DONE',
    DOWNLOAD: 'DOWNLOAD',
    FILE: 'FILE',
    IMAGE: 'IMAGE',
    NAME: 'NAME',
    RENAME: 'RENAME',
    SAVE: 'SAVE',
    SAVING: 'SAVING',
    UPLOAD_ERROR: 'UPLOAD_ERROR',
    URL: 'URL',
  },
  MAIN_DASHBOARD: {
    CREATE_NEW_FILE: 'MAIN_DASHBOARD_CREATE_NEW_FILE',
    DELETE_DIALOG: {
      CANCEL: 'MAIN_DASHBOARD_DELETE_DIALOG_CANCEL',
      CONFIRM: 'MAIN_DASHBOARD_DELETE_DIALOG_CONFIRM',
      CONFIRMATION_DELETE_APP: 'MAIN_DASHBOARD_DELETE_DIALOG_CONFIRMATION_DELETE_APP',
      CONFIRMATION_DELETE_FILE: 'MAIN_DASHBOARD_DELETE_DIALOG_CONFIRMATION_DELETE_FILE',
    },
    DESIGN_APPS: {
      TITLE: 'MAIN_DASHBOARD_DESIGN_APPS_TITLE',
    },
    EMPTY_STATE: {
      APPS: 'MAIN_DASHBOARD_EMPTY_STATE_APPS',
      FILES: 'MAIN_DASHBOARD_EMPTY_STATE_FILES',
    },
    SECTION_TITLE: {
      FAVORITES: 'MAIN_DASHBOARD_SECTION_TITLE_FAVORITES',
      RECENT: 'MAIN_DASHBOARD_SECTION_TITLE_RECENT',
      SHARED_RECIPES: 'MAIN_DASHBOARD_SECTION_TITLE_SHARED_RECIPES',
    },
    SHARED_APPS: 'MAIN_DASHBOARD_SHARED_APPS',
    SHARED_WITH_ME: 'MAIN_DASHBOARD_SHARED_WITH_ME',
  },
  MODEL_NODE: {
    ERROR_IMAGE_INPUT: 'RUN_MODEL_CANNOT_GET_IMAGE_DIMENSIONS',
    ERROR_REQUIRED: 'RUN_MODEL_REQUIRED_ERROR',
  },
  NAVIGATION_DRAWER: {
    BUY_MORE: 'NAVIGATION_DRAWER_BUY_MORE',
  },
  NODE_IMAGE_LIST: {
    CONTEXT_MENU: {
      DOWNLOAD: 'NODE_IMAGE_LIST_CONTEXT_DOWNLOAD',
      DOWNLOAD_ALL: 'NODE_IMAGE_LIST_CONTEXT_DOWNLOAD_ALL',
      SET_AS_COVER: 'NODE_IMAGE_LIST_CONTEXT_MENU_SET_AS_COVER',
    },
    DELETE_ALL: 'NODE_IMAGE_LIST_DELETE_ALL',
    DELETE_ALL_OTHERS: 'NODE_IMAGE_LIST_DELETE_ALL_OTHERS',
    DELETE_CURRENT: 'NODE_IMAGE_LIST_DELETE_CURRENT',
  },
  PAYMENTS: {
    SUCCESS_MESSAGE: 'PAYMENTS_SUCCESS_MESSAGE',
  },
  PRODUCT_TOURS: {
    QUIT_TOUR_CONFIRMATION_CANCEL: 'PRODUCT_TOURS_QUIT_TOUR_CONFIRMATION_CANCEL',
    QUIT_TOUR_CONFIRMATION_CTA: 'PRODUCT_TOURS_QUIT_TOUR_CONFIRMATION_CTA',
    QUIT_TOUR_CONFIRMATION_MESSAGE: 'PRODUCT_TOURS_QUIT_TOUR_CONFIRMATION_MESSAGE',
  },
  RECIPE_MAIN: {
    ERROR_SAVE: 'ERROR_SAVING_RECIPE',
    FLOW: {
      FLOW_NAVBAR: {
        DUPLICATE_TO_MY_FILES: 'DUPLICATE_TO_MY_FILES',
        READ_ONLY: 'READ_ONLY',
        RESTORE_VERSION: 'RESTORE_VERSION',
        SAVING: 'SAVING',
      },
      GALLERY: {
        PARAMS: {
          HIDE_INFO: 'HIDE_INFO',
          NEGATIVE_PROMPT: 'NEGATIVE_PROMPT',
          PARAMETERS: 'PARAMETERS',
          PROMPT: 'PROMPT',
          SHOW_INFO: 'SHOW_INFO',
        },
      },
      GENERAL: {
        READ_ONLY_PANEL: {
          CTA_EDITOR: 'READ_ONLY_PANEL_CTA_EDITOR',
          CTA_GUEST: 'READ_ONLY_PANEL_CTA_GUEST',
          TITLE_EDITOR: 'READ_ONLY_PANEL_TITLE_EDITOR',
          TITLE_GUEST: 'READ_ONLY_PANEL_TITLE_GUEST',
        },
      },
      MODEL_PROPERTIES: {
        SET_AS_INPUT: 'SET_AS_INPUT',
        SET_AS_PARAMETER: 'SET_AS_PARAMETER',
      },
    },
    NODES: {
      LOCKED: {
        CLICK_TO_UNLOCK: 'CLICK_TO_UNLOCK',
        THIS_NODE_IS_LOCKED: 'THIS_NODE_IS_LOCKED',
      },
      MULTI_LORA: {
        ADD_LORA: 'ADD_LORA',
        LORAS: 'LORAS',
        SELECT_LORA: 'SELECT_LORA',
      },
      NODE_MENU: {
        DELETE: 'DELETE',
        DUPLICATE: 'DUPLICATE',
        LOCK: 'LOCK',
        RENAME: 'RENAME',
        UNLOCK: 'UNLOCK',
      },
      NODE_MENU_CONFIRMATION_DIALOG: {
        CANCEL_TEXT: 'NODE_MENU_CONFIRMATION_DIALOG_CANCEL_TEXT',
        CONFIRM_TEXT: 'NODE_MENU_CONFIRMATION_DIALOG_CONFIRM_TEXT',
        MESSAGE: 'NODE_MENU_CONFIRMATION_DIALOG_MESSAGE',
      },
    },
  },
  SETTINGS: {
    DELETE_ACCOUNT: 'SETTINGS_DELETE_ACCOUNT',
    LOGOUT: 'SETTINGS_LOGOUT',
    MENU: {
      BILLING: 'SETTINGS_MENU_BILLING',
      PROFILE: 'SETTINGS_MENU_PROFILE',
      TEAM: 'SETTINGS_MENU_TEAM',
      TEAM_WORKSPACE: 'SETTINGS_MENU_TEAM_WORKSPACE',
    },
    SECTIONS_TITLES: {
      BILLING: 'SETTINGS_SECTIONS_TITLES_BILLING',
      PROFILE: 'SETTINGS_SECTIONS_TITLES_PROFILE',
      TEAM: 'SETTINGS_SECTIONS_TITLES_TEAM',
      TEAM_WORKSPACE: 'SETTINGS_SECTIONS_TITLES_TEAM_WORKSPACE',
    },
    SETTINGS: 'SETTINGS_SETTINGS',
    TEAM: {
      ACTIVATION_PAGE: {
        ERROR_SUBTITLE: 'SETTINGS_TEAM_ACTIVATION_PAGE_ERROR_SUBTITLE',
        ERROR_TITLE: 'SETTINGS_TEAM_ACTIVATION_PAGE_ERROR_TITLE',
        SUBTITLE: 'SETTINGS_TEAM_ACTIVATION_PAGE_SUBTITLE',
        TITLE: 'SETTINGS_TEAM_ACTIVATION_PAGE_TITLE',
      },
      COPY_INVITE_LINK: 'SETTINGS_TEAM_COPY_INVITE_LINK',
      EMPTY_STATE: {
        CTA: 'SETTINGS_TEAM_EMPTY_STATE_CTA',
        TITLE: 'SETTINGS_TEAM_EMPTY_STATE_TITLE',
      },
      INVITE_USER_MODAL: {
        SUCCESS_MESSAGE: 'INVITE_USER_MODAL_SUCCESS_MESSAGE',
        TITLE: 'SETTINGS_TEAM_INVITE_USER_MODAL_TITLE',
      },
      JOIN_TEAM_SUCCESS: 'SETTINGS_TEAM_JOIN_TEAM_SUCCESS',
      REMOVE_MEMBER_DIALOG: {
        CONTENT: 'SETTINGS_TEAM_REMOVE_MEMBER_DIALOG_CONTENT',
      },
      TEAM_TABLE: {
        ADD_MEMBERS_BUTTON: 'SETTINGS_TEAM_TABLE_ADD_MEMBERS_BUTTON',
        SEARCH_PLACEHOLDER: 'SETTINGS_TEAM_TABLE_SEARCH_PLACEHOLDER',
        TABLE_TITLE: 'SETTINGS_TEAM_TABLE_TABLE_TITLE',
      },
      USER_STATUS: {
        ACTIVE: 'SETTINGS_TEAM_USER_STATUS_ACTIVE',
        INVITED: 'SETTINGS_TEAM_USER_STATUS_INVITED',
      },
      WORKSPACE_CREDITS: 'SETTINGS_TEAM_WORKSPACE_CREDITS',
      WORKSPACE_CREDITS_CTA: 'SETTINGS_TEAM_WORKSPACE_CREDITS_CTA',
      WORKSPACE_MEMBERS_CAN_SEE_CREDITS: 'SETTINGS_TEAM_WORKSPACE_MEMBERS_CAN_SEE_CREDITS',
      WORKSPACE_NAME: 'SETTINGS_TEAM_WORKSPACE_NAME',
      WORKSPACE_NAME_CHANGE: 'SETTINGS_TEAM_WORKSPACE_NAME_CHANGE',
      WORKSPACE_NAME_PLACEHOLDER: 'SETTINGS_TEAM_WORKSPACE_NAME_PLACEHOLDER',
    },
    TITLE: 'SETTINGS_TITLE',
  },
  SHARED_DESIGN_APP: {
    ACTIVATION_PAGE: {
      DEFAULT_ERROR: 'SHARED_DESIGN_APP_ACTIVATION_PAGE_DEFAULT_ERROR',
      TITLE: 'SHARED_DESIGN_APP_ACTIVATION_PAGE_TITLE',
    },
    EDIT_DESIGN_APP_BUTTON: 'SHARED_DESIGN_APP_EDIT_DESIGN_APP_BUTTON',
    INPUTS: {
      DEFAULTS_COMMENT: 'SHARED_DESIGN_APP_INPUTS_DEFAULTS_COMMENT',
      HEADER: {
        ADD_DESCRIPTION: 'SHARED_DESIGN_APP_INPUTS_HEADER_ADD_DESCRIPTION',
        DESCRIPTION_PLACEHOLDER: 'SHARED_DESIGN_APP_INPUTS_DESCRIPTION_PLACEHOLDER',
        DISABLED: 'SHARED_DESIGN_APP_INPUTS_HEADER_DISABLED',
        ENABLED: 'SHARED_DESIGN_APP_INPUTS_HEADER_ENABLED',
      },
      MULTI_LORA: {
        WEIGHT: 'SHARED_DESIGN_APP_INPUTS_MULTI_LORA_WEIGHT',
      },
      PARAMS_DRAWER: {
        SET_AS_INPUT: 'SHARED_DESIGN_APP_INPUTS_PARAMS_DRAWER_SET_AS_INPUT',
      },
      PROMPT: {
        PLACEHOLDER: 'SHARED_DESIGN_APP_INPUTS_PROMPT_PLACEHOLDER',
      },
      REQUIRED_ERROR: 'SHARED_DESIGN_APP_INPUTS_REQUIRED_ERROR',
      REQUIRED_ERROR_FILE: 'SHARED_DESIGN_APP_INPUTS_REQUIRED_ERROR_FILE',
      REQUIRED_ERROR_LORA: 'SHARED_DESIGN_APP_INPUTS_REQUIRED_ERROR_LORA',
      SET_AS_PARAMETER: 'SHARED_DESIGN_APP_INPUTS_SET_AS_PARAMETER',
    },
    MODAL: {
      BUTTON_CTA: 'SHARED_DESIGN_APP_MODAL_BUTTON_CTA',
      DEFAULT_ERROR: 'SHARED_DESIGN_APP_MODAL_DEFAULT_ERROR',
      LINK_COPIED: 'SHARED_DESIGN_APP_MODAL_LINK_COPIED',
      TITLE: 'SHARED_DESIGN_APP_MODAL_TITLE',
    },
    NO_INPUTS: {
      TIP: 'SHARED_DESIGN_APP_TOOLBAR_NO_INPUTS_TIP',
      TITLE: 'SHARED_DESIGN_APP_TOOLBAR_NO_INPUTS_TITLE',
    },
    PREVIEW_DESIGN_APP_BUTTON: 'SHARED_DESIGN_APP_PREVIEW_DESIGN_APP_BUTTON',
    PUBLISH_DESIGN_APP_BUTTON: 'SHARED_DESIGN_APP_PUBLISH_DESIGN_APP_BUTTON',
    PUBLISH_DESIGN_APP_FAILED: 'SHARED_DESIGN_APP_PUBLISH_DESIGN_APP_FAILED',
    PUBLISH_DESIGN_APP_SUCCESS: 'SHARED_DESIGN_APP_PUBLISH_DESIGN_APP_SUCCESS',
    TOOLBAR: {
      CURRENT_VERSION: 'SHARED_DESIGN_APP_TOOLBAR_CURRENT_VERSION',
      LAST_PUBLISHED_AT: 'SHARED_DESIGN_APP_TOOLBAR_LAST_PUBLISHED_AT',
      LATEST_VERSION: 'SHARED_DESIGN_APP_TOOLBAR_LATEST_VERSION',
      NO_VERSIONS: 'SHARED_DESIGN_APP_TOOLBAR_NO_VERSIONS',
      RESTORE_VERSION: 'SHARED_DESIGN_APP_TOOLBAR_RESTORE_VERSION',
      VIEW_VERSION: 'SHARED_DESIGN_APP_TOOLBAR_VIEW_VERSION',
    },
  },
  SHARE_WORKFLOW_MODAL: {
    COPIED: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_COPIED',
    COPY_LINK: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_COPY_LINK',
    EMAIL_LABEL: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_EMAIL_LABEL',
    EMAIL_PLACEHOLDER: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_EMAIL_PLACEHOLDER',
    INPUTS: {
      PROMPT_PLACEHOLDER: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_INPUTS_PROMPT_PLACEHOLDER',
    },
    LINK: {
      ANYONE: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_LINK_ANYONE',
      ORGANIZATION: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_LINK_ORGANIZATION',
      RESTRICTED: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_LINK_RESTRICTED',
      TEAM: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_LINK_TEAM',
    },
    SHARE_BUTTON: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_SHARE_BUTTON',
    SHARE_BUTTON_LOADING: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_SHARE_BUTTON_LOADING',
    SUCCESS_MESSAGE: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_SUCCESS_MESSAGE',
    TITLE: 'SHARED_DESIGN_APP_SHARE_WORKFLOW_MODAL_TITLE',
  },
  UPGRADE_MODAL: {
    CTA: 'UPGRADE_MODEL_CTA',
    TITLE: 'UPGRADE_MODEL_TITLE',
  },
  UPLOAD_LORA_NODE: {
    DEFAULT_WEIGHT: 'DEFAULT_WEIGHT',
    TRIGGER: 'TRIGGER',
    UPLOADING: 'UPLOAD_LORA_NODE_UPLOADING',
    UPLOAD_ANOTHER_BUTTON_TEXT_FILE: 'UPLOAD_LORA_NODE_UPLOAD_ANOTHER_BUTTON_TEXT_FILE',
    UPLOAD_BUTTON_TEXT: 'UPLOAD_LORA_NODE_UPLOAD_BUTTON_TEXT',
  },
  COMPOSITOR: {
    NO_MEDIA: 'COMPOSITOR_NO_MEDIA',
    MISSING_URL_TOOLTIP: 'COMPOSITOR_MISSING_URL_TOOLTIP',
  },
};

export default I18N_KEYS;
