import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
  Snackbar,
  Portal,
  Alert,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { UserRoles } from '../../../enums/user-roles.enum';
import { color } from '../../../colors';
import I18N_KEYS from '../../../language/keys';
import EmailChipsInput from '../../Recipe/FlowComponents/Share/EmailChipsInput';

const InviteMemberModal = ({ open, onClose, onInviteMembersSuccess, inviteMembers }) => {
  const [emails, setEmails] = useState([]);
  const [currentInput, setCurrentInput] = useState({ value: '', isValid: false });
  const [error, setError] = useState('');
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [intendedRole, setIntendedRole] = useState(UserRoles.MEMBER);

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const { t: translate } = useTranslation();

  const handleClose = () => {
    setEmails([]);
    setCurrentInput({ value: '', isValid: false });
    setIsInviteLoading(false);
    setError('');
    onClose();
  };

  const handleInviteSuccess = (members) => {
    setEmails([]);
    setShowSuccessSnackbar(true);
    onInviteMembersSuccess(members); // todo: replace with actual members
  };

  const handleInviteSubmit = async () => {
    const emailsToInvite = [...emails];
    if (currentInput.value.trim() && currentInput.isValid) {
      emailsToInvite.push(currentInput.value.trim());
    }

    if (emailsToInvite.length === 0) return;

    setIsInviteLoading(true);
    posthog.capture('team_invite_member_by_email', { number_of_users: emailsToInvite.length });

    try {
      const response = await inviteMembers(emailsToInvite, intendedRole);
      handleInviteSuccess(response.data.members);
    } catch (e) {
      console.error('Error sharing file:', e);
      const errorMessage = error.response?.data?.error || translate(I18N_KEYS.SHARED_DESIGN_APP.MODAL.DEFAULT_ERROR);
      setError(errorMessage);
    } finally {
      setIsInviteLoading(false);
      handleClose();
    }
  };

  const handleInputChange = (value, isValid) => {
    setCurrentInput({ value, isValid });
  };

  return (
    <>
      <Modal id="team-invite-modal" open={open} onClose={handleClose} aria-labelledby="team-invite-modal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 540,
            background: color.Dark_Blue,
            border: '1px solid',
            borderColor: color.Dark_Grey,
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="caption" sx={{ mb: 1, fontWeight: 'bold' }}>
            {translate(I18N_KEYS.SETTINGS.TEAM.INVITE_USER_MODAL.TITLE)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <EmailChipsInput emails={emails} setEmails={setEmails} onInputChange={handleInputChange} />
            <Select size="small" value={intendedRole} onChange={(e) => setIntendedRole(e.target.value)}>
              <MenuItem value={UserRoles.ADMIN}>Admin</MenuItem>
              {/* <MenuItem value={ UserRoles.BUILDER }>Builder</MenuItem> */}
              <MenuItem value={UserRoles.MEMBER}>Member</MenuItem>
            </Select>
            <Button
              variant="contained"
              size="small"
              color="weavy_cta"
              onClick={handleInviteSubmit}
              disabled={emails.length === 0 && !currentInput.isValid}
              fullWidth
              endIcon={isInviteLoading ? <CircularProgress color="inherit" size={16} /> : null}
              sx={{ position: 'relative', overflow: 'hidden', width: '140px', height: '40px' }}
            >
              <Typography variant="caption">
                {isInviteLoading
                  ? translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.SHARE_BUTTON_LOADING)
                  : translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.SHARE_BUTTON)}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Portal>
        <Snackbar
          open={showSuccessSnackbar}
          onClose={() => setShowSuccessSnackbar(false)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowSuccessSnackbar(false)}
            severity="success"
            variant="filled"
            icon={<i className="fa-light fa-paper-plane"></i>}
            sx={{ width: '100%', color: 'white' }}
          >
            {translate(I18N_KEYS.SETTINGS.TEAM.INVITE_USER_MODAL.SUCCESS_MESSAGE)}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default InviteMemberModal;
