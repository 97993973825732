import React, { useContext, useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Route, Routes, Navigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box, Snackbar, Alert } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Footer from './components/layout/Footer';
import { color } from './colors.js';
import { Dashboard, Home, Recipe, AdminConsole, FileViewer } from './pages';
import './fonts.css';
import { AuthContext } from './AuthContext';
import { CreditsProvider } from './services/CreditsContext';
import EarlyAccess from './components/Homepage/EarlyAccess.jsx';
import EarlyAccessMebina from './components/Homepage/EarlyAccessMebina.jsx';
import TempSignin from './components/Homepage/TempSignin.jsx';
import PaymentSuccessHandler from './components/SubscriptionsAndPayments/PaymentSuccessHandler';
import DesignAppShareActivationPage from './pages/DesignAppShareActivation.page';
import ProtectedRoutes from './ProtectedRoutes';
import { QueryParamsProvider } from './QueryParamsContext';
import Settings from './pages/Settings';
import TeamInviteActivationPage from './pages/TeamInviteActivationPage';
import I18N_KEYS from './language/keys';
import { TourProvider } from './components/ProductTours/TourContext';

const usePreventZoom = () => {
  useEffect(() => {
    const handleWheel = (event) => {
      // Only prevent browser zoom, not component zoom
      if ((event.ctrlKey || event.metaKey) && event.deltaY) {
        event.preventDefault();
      }
    };

    const handleGesture = (event) => {
      // Only prevent browser-level gestures
      if (event.target.tagName === 'BODY' || event.target.tagName === 'HTML') {
        event.preventDefault();
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: false });
    document.addEventListener('gesturestart', handleGesture, { passive: false });
    document.addEventListener('gesturechange', handleGesture, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheel);
      document.removeEventListener('gesturestart', handleGesture);
      document.removeEventListener('gesturechange', handleGesture);
    };
  }, []);
};

const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          color: ${color.Yambo_Text_On_Dark} !important;
          background:#101012;
        }
        body {
          color: ${color.Yambo_Text_On_Dark} !important;
        }
        .MuiButtonBase-root, .MuiButton-root {
          color: ${color.Yambo_Text_On_Dark} ;
        }
        .MuiTypography-root {
          color: ${color.Yambo_Text_On_Dark} !important;
        }
        .MuiTabs-indicator {
          background-color: ${color.Yambo_Purple} !important;
        }
      `,
    },
  },
  palette: {
    mode: 'dark',
    weavy_cta: {
      main: color.Yambo_Purple,
      light: color.Yambo_Green_Stroke,
      dark: color.Yambo_Purple_Stroke,
      contrastText: color.Yambo_Text_On_Dark,
    },
    weavy_cta_secondary: {
      main: color.Yambo_CTA_BG,
      light: color.Yambo_Text_On_Dark,
      dark: color.Yambo_White_BG,
      contrastText: color.Yambo_Text_On_White,
    },
    weavy_cta_blue: {
      main: color.Yambo_Blue,
      light: color.Yambo_Blue_Stroke,
      dark: color.Yambo_Blue_Stroke,
      contrastText: color.Yambo_Text_On_Dark,
    },
    weavy_green_outline: {
      main: color.Yambo_Green_Stroke,
      light: color.Yambo_Green_Stroke,
      dark: color.Yambo_Green_Stroke,
      contrastText: color.Yambo_Text_On_Dark,
    },
    success: {
      main: color.Yambo_Purple,
    },
    success_secondary: {
      main: color.Yambo_Purple_Dark,
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      'ShantellSans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    caption: { fontWeight: 200 },
    body1: { fontSize: '0.875rem', fontWeight: 400, lineHeight: 1.2 },
    body2: { fontSize: '0.875rem', fontWeight: 700 },
    h2: { fontSize: '1rem', fontWeight: 700 },
    h3: { fontSize: '3rem', fontWeight: 600, marginBottom: '1rem' },
    button: { textTransform: 'none' },
    link: { fontSize: '0.875rem' },
  },
});

function App() {
  // usePreventZoom();

  const posthog = usePostHog();
  const { currentUser, signIn, isSigningIn, teamInviteSuccess } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();

  const [loggedInUserInviteSuccess, setLoggedInUserInviteSuccess] = useState(false);

  /// idetify user for posthog
  useEffect(() => {
    if (currentUser) {
      posthog.identify(currentUser.uid, { email: currentUser.email, name: currentUser.displayName });
    }
  }, [posthog, currentUser]);

  // handle accept team invite for logged in user
  useEffect(() => {
    const acceptInvitation = searchParams.get('acceptInvitation');
    if (acceptInvitation) {
      setLoggedInUserInviteSuccess(true);
      window.history.replaceState({}, '', window.location.pathname);
      setTimeout(() => {
        setLoggedInUserInviteSuccess(false);
      }, 3000);
    }
  }, [searchParams]);

  return (
    <ThemeProvider theme={darkTheme}>
      <TourProvider>
        <CssBaseline />
        <CreditsProvider>
          <QueryParamsProvider>
            {currentUser && <PaymentSuccessHandler />}
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }} id="weavy-main">
              <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
                <Routes>
                  {/* Protected Routes */}
                  <Route element={<ProtectedRoutes user={currentUser} />}>
                    <Route
                      path="/design-app/share/activate"
                      element={<DesignAppShareActivationPage user={currentUser} />}
                    />
                    <Route path="/recipe/:recipeId" element={<Recipe user={currentUser} />} />
                    <Route path="/settings" element={<Settings user={currentUser} />} />
                  </Route>
                  <Route path="/activation" element={<TeamInviteActivationPage />} />
                  <Route path="/early" element={<EarlyAccess />} />
                  <Route path="/mebina" element={<EarlyAccessMebina />} />
                  <Route path="/view/:publicId" element={<FileViewer />} />
                  <Route path="/dlpwQTohM9hc" element={<TempSignin signIn={signIn} />} />
                  <Route path="/signin" element={<TempSignin signIn={signIn} />} />
                  <Route
                    path="/signin/playtika"
                    element={<TempSignin signIn={signIn} microsoftTenantId={'02f22272-3538-4a5f-ae4e-64cd13d9890e'} />}
                  />
                  <Route
                    path="/"
                    element={
                      currentUser && !isSigningIn ? (
                        <Dashboard user={currentUser} />
                      ) : (
                        <Home user={currentUser} signIn={signIn} />
                      )
                    }
                  />
                  {currentUser && currentUser.isAdmin && <Route path="/admin" element={<AdminConsole />} />}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Box>
            </Box>
          </QueryParamsProvider>
        </CreditsProvider>
      </TourProvider>
      {!currentUser && <Footer />}
      <Snackbar
        open={teamInviteSuccess || loggedInUserInviteSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity="success"
          variant="filled"
          icon={<i className="fa-light fa-users"></i>}
          sx={{ width: '100%', color: 'white' }}
        >
          {translate(I18N_KEYS.SETTINGS.TEAM.JOIN_TEAM_SUCCESS)}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
