import { Box, Button } from '@mui/material';
import { useState, useRef, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useContentRect } from '../../../../../hooks/useContentRect';
import LayerPanel from './LayerPanel';
import LayerPropertyPanel from './LayerPropertyPanel';
import Designer from './Designer';
import { CompositorNode } from './Compositor';

export interface UILayer {
  name: string;
  type: string;
  url?: string;
  visible: boolean;
  locked: boolean;
  x: number;
  y: number;
  rotation: number;
}

interface DesignerEditorProps {
  data: CompositorNode;
  onClose: () => void;
}

function DesignerEditor({ data, onClose }: DesignerEditorProps) {
  const [layers, setLayers] = useState<Record<number, UILayer>>();
  const [layerOrder, setLayerOrder] = useState<number[]>();
  const selectedLayerId = 1;
  const selectedLayer = layers?.[selectedLayerId];

  const update = useCallback(
    (newData: CompositorNode) => {
      // map to UI layers for now
      const uiLayers = newData.layers.entries().map(([layer_id, layer]) => {
        return [
          layer_id,
          {
            url: layer.kind.type === 'image' ? layer.kind.resourceKey : null,
            type: layer.kind.type,
            visible: true,
            locked: false,
            name: `Layer ${layer_id}`,
            x: layer.position.x,
            y: layer.position.y,
            rotation: (layer.rotation / Math.PI) * 180,
          },
        ];
      });
      setLayers(Object.fromEntries(uiLayers));
      setLayerOrder(newData.layerOrder);
    },
    [setLayers, setLayerOrder],
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerDimensions = useContentRect(containerRef);

  return (
    <Box component="div" sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <Box
        ref={containerRef}
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {containerDimensions && (
          <Designer data={data} update={update} width={containerDimensions.width} height={containerDimensions.height} />
        )}
      </Box>
      {layers && layerOrder ? (
        <>
          <LayerPanel layers={layers} layerOrder={layerOrder} selectedLayer={selectedLayerId} />
          {selectedLayer ? <LayerPropertyPanel layer={selectedLayer} /> : null}
        </>
      ) : null}
      <Button
        color="weavy_cta"
        onClick={onClose}
        sx={{ p: 1, minWidth: '0', left: '-40px', position: 'absolute', top: 0 }}
        variant="contained"
        size="small"
      >
        <CloseIcon />
      </Button>
    </Box>
  );
}

export default DesignerEditor;
