import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Slider, Input, Select, MenuItem, Typography } from '@mui/material';
import cv from '@techstark/opencv-js';
import { DynamicNode2 } from '../DynamicNode/DynamicNode2';
import { colorMap } from '../../../colors';
import { useUserRole } from '../../Recipe/UserRoleContext';
import { hasEditingPermissions } from '../Utils';

const blurAlgo = {
  Fast: 1,
  Gaussian: 2,
};
const MAX_BLUR = 100;

function BlurNode({ id, data, updateNodeData }) {
  const role = useUserRole();

  const { input, handles } = data;
  const [fileSrc, setFileSrc] = useState();
  const [fileOutput, setFileOutput] = useState();
  const [blurSize, setBlurSize] = useState(data?.blurData?.size || 1);
  const [blurType, setBlurType] = useState(data?.blurData?.type || blurAlgo['Fast']);

  const [isFocused, setIsFocused] = useState(false);

  const blurSelectRef = useRef(null);

  const handleDisconnection = useCallback(() => {
    console.log('Updating node data on new blur');
    setFileOutput();
    updateNodeData(id, {
      result: {},
      output: {
        [data.handles.output[0]]: null,
      },
    });
  }, [fileSrc]);

  const applyBlur = (imageUrl, type, size) => {
    return new Promise((resolve, reject) => {
      // console.log("applying blur", type, size);
      size = parseInt(size);
      const imgElement = document.createElement('img');
      imgElement.src = imageUrl;
      imgElement.crossOrigin = 'Anonymous';
      imgElement.onload = () => {
        try {
          const src = cv.imread(imgElement);
          const dst = new cv.Mat();
          let ksize;
          // const ksize = new cv.Size(size, size);
          switch (type) {
            case 1:
              ksize = new cv.Size(size, size);
              cv.blur(src, dst, ksize, new cv.Point(-1, -1), cv.BORDER_DEFAULT);
              break;
            case 2:
              const isOdd = size % 2 === 1;
              ksize = new cv.Size(isOdd ? size : size + 1, isOdd ? size : size + 1);
              cv.GaussianBlur(src, dst, ksize, 0, 0, cv.BORDER_DEFAULT);
              break;
            case 3:
              cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);
              cv.bilateralFilter(src, dst, 30, 75, 75, cv.BORDER_DEFAULT);
              break;
            default:
              cv.blur(src, dst, ksize, new cv.Point(-1, -1), cv.BORDER_DEFAULT);
              break;
          }
          // Use a temporary canvas to output and convert the image
          const canvas = document.createElement('canvas');
          canvas.width = src.cols;
          canvas.height = src.rows;
          cv.imshow(canvas, dst);
          const dataUrl = canvas.toDataURL('image/png');

          src.delete();
          dst.delete();

          resolve(dataUrl);
        } catch (error) {
          reject(error);
        }
      };
      imgElement.onerror = reject;
    });
  };

  //handle connection / disconnection
  useEffect(() => {
    if (input && input[handles.input[0]]) {
      setFileSrc(input[handles.input[0]]);
    } else {
      setFileSrc();
      handleDisconnection();
    }
  }, [input]);

  useEffect(() => {
    const performBlur = async (url, type, size) => {
      try {
        const blur = await applyBlur(url, type, size);
        setFileOutput((prev) => ({ ...prev, url: blur }));

        updateNodeData(id, {
          result: { ...fileOutput, url: blur },
          blurData: { type: type, size: size },
          output: {
            [handles.output[0]]: {
              ...fileSrc,
              url: blur,
            },
          },
        });
      } catch (error) {
        console.error('Error during blur application:', error);
        // Handle error appropriately, perhaps resetting state or showing an error message
      }
    };

    if (fileSrc && fileSrc.url) {
      performBlur(fileSrc.url, blurType, blurSize);
    }
  }, [fileSrc, blurSize, blurType]);

  // useEffect(() => {
  //   console.log(Object.keys(cv).filter((key) => !key.includes("dynCall")));
  // }, []);

  /// UI
  const handleBlurSizeChange = (e) => {
    setBlurSize(e.target.value);
  };

  const handleBlurSizeInputChange = (e) => {
    setBlurSize(e.target.value);
  };

  const handleTypeSelectChange = (e) => {
    setBlurType(e.target.value);
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  return (
    <DynamicNode2
      id={id}
      data={data}
      className="blur"
      handleColor={colorMap.get(data.color)}
      headerColor={colorMap.get(data.dark_color)}
    >
      {fileSrc && (
        <Box
          id="blur-params-container"
          className={isFocused ? 'nowheel nodrag nopan' : ''}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            p: 1,
            cursor: !hasEditingPermissions(role, data) ? 'default' : '',
            pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography>Size</Typography>
            <Slider
              size="small"
              value={blurSize}
              onChange={handleBlurSizeChange}
              onChangeCommitted={() => setIsFocused(false)}
              aria-labelledby="blur-size-slider"
              max={MAX_BLUR}
              min={1}
              sx={{ ml: 2 }}
            />
            <Input
              value={blurSize}
              size="small"
              onChange={handleBlurSizeInputChange}
              // onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 1,
                max: MAX_BLUR,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
              sx={{ ml: 2, width: '50px' }}
            />
          </Box>
          <Box id="blur-type-container" sx={{ my: 1 }}>
            <Select
              ref={blurSelectRef}
              fullWidth
              size="small"
              labelId="blur-type-label"
              id="blur-type-select"
              value={blurType}
              label="Type"
              onChange={(event) => {
                handleTypeSelectChange(event);
                setIsFocused(false);
              }}
              onClose={() => {
                setTimeout(() => {
                  document.activeElement.blur();
                }, 0);
                setIsFocused(false);
              }}
            >
              {Object.keys(blurAlgo).map((type) => (
                <MenuItem key={type} value={blurAlgo[type]}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
      <img src={fileOutput ? fileOutput.url : ''} draggable="false" width="100%" />
    </DynamicNode2>
  );
}

export default BlurNode;
