import React, { useEffect, useRef } from 'react';
import { useUpdateNodeInternals } from 'reactflow';
import { colorMap } from '../../colors';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import ResizeCore from './ResizeCore';

function ResizeNode(props) {
  const { id, data, updateNodeData } = props;

  const updateNodeInternals = useUpdateNodeInternals();
  const mediaContainerRef = useRef();

  /// handle node html resizes
  useEffect(() => {
    const container = mediaContainerRef?.current;
    if (!container) return;
    console.log('updating internals');
    const resizeObserver = new ResizeObserver((entries) => {
      for (let i = 0; i < entries.length; i++) {
        // todo: @jonathan g.z. needed?
        console.log('updating internals');
        updateNodeInternals(id);
      }
    });
    // Start observing the node
    resizeObserver.observe(container);

    return () => resizeObserver.unobserve(container);
  }, [id]);

  return (
    <DynamicNode2 id={id} data={data} className="resize" handleColor={colorMap.get(data.color)}>
      <ResizeCore id={id} data={data} updateNodeData={updateNodeData} mediaContainerRef={mediaContainerRef} />
    </DynamicNode2>
  );
}

export default ResizeNode;
