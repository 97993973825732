import React from 'react';
import { Handle, Position } from 'reactflow';
import { Box, Typography } from '@mui/material';
import { useUserRole } from '../Recipe/UserRoleContext';
import { getHandleId } from './Utils';

export const OutputOnly = ({ id, data, children, className, handleColor, headerColor }) => {
  const { handles } = data;

  const role = useUserRole();

  return (
    <>
      <Box className={`custom-node output-only ${className}`} sx={{ cursor: role === 'guest' ? 'default' : '' }}>
        <Box
          className="node-header"
          sx={{ width: '100%', background: headerColor, flexDirection: 'row', display: 'flex' }}
        >
          <img src="/icons/dots.svg" width="8px" style={{ opacity: 0.8 }} />
          <Typography variant="body1" sx={{ ml: 1 }}>
            {data.name}
          </Typography>
        </Box>
        <Box className="node-content" sx={{ width: '100%' }}>
          {/* <Box>{id}</Box> */}
          {children}
        </Box>
        <Handle
          type="source"
          style={{
            top: '18px',
            background: handleColor,
            cursor: role === 'guest' ? 'default' : '', // Disable pointer for guests
            pointerEvents: role === 'guest' ? 'none' : 'auto',
          }}
          position={Position.Right}
          id={getHandleId(id, 'output', handles.output[0])} //{`${id}-output-${handles.output[0]}`}
        >
          <Box sx={{ position: 'relative', left: 30, top: -20 }}>{handles.output[0]}</Box>
        </Handle>
      </Box>
    </>
  );
};

export const InputOnly = ({ id, data, children, className, handleColor, headerColor }) => {
  return (
    <Box className={`custom-node input-only ${className}`}>
      <Box
        className="node-header"
        sx={{ width: '100%', background: headerColor, flexDirection: 'row', display: 'flex' }}
      >
        <img src="/icons/dots.svg" width="8px" style={{ opacity: 0.8 }} />
        <Typography variant="body1" sx={{ ml: 1 }}>
          {data.name}
        </Typography>
      </Box>
      <Box className="node-content" sx={{ width: '100%' }}>
        {children}
      </Box>
      <Handle
        type="target"
        style={{ top: '18px', background: handleColor }}
        position={Position.Left}
        id={getHandleId(id, 'input0', '')} //{`${id}-input0`}
      />
    </Box>
  );
};

export const DynamicNode = ({ id, data, children, className, handleColor, hideTitle }) => {
  const { handles } = data;
  const handlesSpace = 50;
  const handleTop = '40px';

  const role = useUserRole();

  return (
    <Box className={`custom-node two-input-output ${className}`} sx={{ cursor: role === 'guest' ? 'default' : '' }}>
      {!hideTitle && (
        <>
          <Box>{data.name}</Box>
          {data.paid && <Typography>paid</Typography>}
        </>
      )}
      {/* <Box><Typography>{id}</Typography></Box> */}
      {children}
      {handles.input.map((inputHandle, index) => (
        <Handle
          key={index}
          type="target"
          position={Position.Left}
          style={{
            top: `calc(${handleTop} + ${index}*${handlesSpace}px)`,
            backgroundColor: handleColor,
          }}
          id={getHandleId(id, 'input', inputHandle)} //{`${id}-input-${inputHandle}`}
        >
          <Box sx={{ position: 'relative', left: -40, top: -20 }}>{inputHandle}</Box>
        </Handle>
      ))}
      {handles.output.map((outputHandle, index) => (
        <Handle
          key={index}
          type="source"
          position={Position.Right}
          style={{
            top: `calc(${handleTop} + ${index}*${handlesSpace}px)`,
            backgroundColor: handleColor,
          }}
          id={getHandleId(id, 'output', outputHandle)} //{`${id}-output-${outputHandle}`}
        >
          <Box sx={{ position: 'relative', left: 30, top: -20 }}>{outputHandle}</Box>
        </Handle>
      ))}
    </Box>
  );
};
