import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePostHog, useFeatureFlagEnabled } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  getNodesBounds,
  getViewportForBounds,
  Panel,
  useOnSelectionChange,
  useReactFlow,
  useStoreApi,
  useViewport,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { Box, Button } from '@mui/material';
import { toPng } from 'html-to-image';
import { useQueryParamsContext } from '../../QueryParamsContext';
import {
  CompNode,
  CropCloudinaryNode,
  ImportModelNode,
  MenuNode,
  NumberNode,
  PaintNode,
  PreviewNode,
  PromptNode,
  ResizeCloudinaryNode,
} from '../Nodes';
import { getNodeTemplates, getHandleId } from '../Nodes/Utils';
import { color } from '../../colors';
import StickyNoteNode from '../Nodes/StickyNoteNode';
import BooleanNode from '../Nodes/BooleanNode';
import MuxNode from '../Nodes/MuxNode';
import SeedNode from '../Nodes/SeedNode';
import ArrayCore from '../Nodes/ArrayCore';
import ImportCloudinaryNode from '../Nodes/ImportCloudinaryNode';
import BlurNode from '../Nodes/Edit/BlurNode';
import ChannelsNode from '../Nodes/Edit/ChannelsNode';
import EditNode from '../Nodes/Edit/EditNode';
import PromptNodeV2 from '../Nodes/PromptNodeV2';
import ExportNode from '../Nodes/ExportNode';
import ModelBaseNode from '../Nodes/ModelBaseNode';
import axiosInstance from '../../services/axiosConfig';
import MasksExtractionNode from '../Nodes/MasksExtractionNode';
import PromptConcat from '../Nodes/PromptConcat';
import PaintNodeV2 from '../Nodes/PaintNodeV2';
import ComfyNode from '../Nodes/ComfyNode';
import StringNode from '../Nodes/Helpers/StringNode';
import MergeAlphaNode from '../Nodes/Edit/MergeAlphaNode';
import RouterNode from '../Nodes/RouterNode';
import OutputNode from '../Nodes/OutputNode';
import UploadLoraNode from '../Nodes/UploadLoraNode';
import MultiLoRANode from '../Nodes/MultiLoRANode';
import ExtractVideoFrameNode from '../Nodes/ExtractVideoFrameNode';
import { RecipeType } from '../../enums/recipe-type.enum';
import CompNodeV2 from '../Nodes/CompNodeV2';
import { NodeActionsProvider } from '../Nodes/NodeActionsContext';
import RenameNodeDialog from '../Nodes/RenameNodeDialog';
import { NodeType } from '../../enums/node-type.enum';
import { FlowMode } from '../../enums/flow-modes.enum';
import { useTour } from '../ProductTours/TourContext';
import { TourKeys, StepKeys, ComponentKeys } from '../ProductTours/tour-keys';
import RunBatchButton from './RunFlow/RunBatchButton';
import LeftToolMenu from './FlowComponents/LeftToolMenu';
import PropertiesDrawer from './PropertiesDrawer';
import Editor from './FlowComponents/Editor/Editor';
import { useEditorContext } from './FlowComponents/Editor/EditorContext';
import LeftPanel from './FlowComponents/LeftPanel';
import FlowNavbar from './FlowComponents/FlowNavbar';
import MediaGallery from './FlowComponents/MediaGallery';
import ReadOnlyPanel from './FlowComponents/ReadOnlyPanel';
import ModelRunConsole from './RunFlow/ModelRunConsole';
import ModelRunContext from './RunFlow/ModelRunContext';
import { getModelsToProcess } from './RunFlow/RenderOrchestration';
import menuItems from './Menu/menu.json';
import FloatMenu from './Menu/FloatMenu';
import { useUserRole } from './UserRoleContext';
import { FlowTour } from './FlowComponents/FlowTourElements';
import { useMediaGalleryContext } from './FlowComponents/MediaGalleryContext';
import CustomEdge from './CustomEdge';
import DesignApp from './FlowComponents/DesignApp';

const posterWidth = 300;
const posterHeight = 300;
const MIN_HANDLE_DISTANCE = 20;
const MIN_NODE_DISTANCE = 800;

const MENU_MAGRINS = 16;
const NAVBAR_HEIGHT = 48;
const TOOLBAR_MENU_WIDTH = 48;
const TOOLBAR_WIDTH = 240;
const READ_ONLY_PANEL_HEIGHT = 48;
const READ_ONLY_PANEL_EDITOR_WIDTH = 400;
const READ_ONLY_PANEL_GUEST_WIDTH = 480;

const BLUR_AMOUNT = 3;

const TOUR_COMPONENT_ID = ComponentKeys.FLOW;

function Flow({
  user,
  recipeData,
  setRecipeData,
  recipeId,
  nodes,
  setNodes,
  edges,
  setEdges,
  selectedNodes,
  setSelectedNodes,
  onConnect,
  updateNodeData,
  nodeTypes,
  setNodeTypes,
  passData,
  duplicateRecipe,
  viewingVersionMode,
}) {
  const isDesignApp = recipeData?.type === RecipeType.DesignApp; // temp

  const {
    registerPrehookFunction,
    activeTour,
    getCurrentStepConfig,
    runTour,
    checkForEligibleTour,
    showStepSuccess,
    currentStep,
    goToNextStep,
    updateCustomTourData,
    customTourData,
    goToStep,
    tourReadiness,
    setTourReadiness,
    stopTour,
  } = useTour();
  const posthog = usePostHog();
  const { queryParamViewingMode, queryParamNewRecipe, cleanQueryParams } = useQueryParamsContext();

  // prefereces
  const [userPrefOnScroll, setUserPrefOnScroll] = useState(true);

  useEffect(() => {
    const storedPref = localStorage.getItem('panOnScroll');
    if (storedPref === null) {
      localStorage.setItem('panOnScroll', JSON.stringify(true));
    } else {
      setUserPrefOnScroll(JSON.parse(storedPref));
    }
    if ('panOnScroll' in (user?.preferences ?? {})) {
      setUserPrefOnScroll(user.preferences.panOnScroll);
    }
  }, [user]);

  // end of preferncees

  const navigate = useNavigate();
  const role = useUserRole();
  const debounceTimeoutRef = useRef();

  /// react flow stuff
  const store = useStoreApi();
  const { screenToFlowPosition, getNodes, setViewport, fitView } = useReactFlow();
  const deletedEdge = useRef(null); // related to onEdgeUpdate (user disconnects edges by drag)
  const [selectOnDrag, setSelectOnDrag] = useState(true);

  const [isLoadingRecipe, setIsLoadingRecipe] = useState(true);
  const [savingBeforeExit, setSavingBeforeExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /// running models
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingProgress, setIsProcessingProgress] = useState(0);
  const numOfRunningModels = useRef(null);
  const totalProgress = useRef(null);
  const [finishedProcessing, setFinishedProcessing] = useState({ finished: false, status: null });
  const [isRunningModelWhileInTour, setIsRunningModelWhileInTour] = useState(false); // used for tour
  const [isRunningModelWhileInTourSuccess, setIsRunningModelWhileInTourSuccess] = useState(false); // used for tour

  const flowRef = useRef(null);

  // Running models from flow.jsx
  const [modelRunTrigger, setModelRunTrigger] = useState([]);
  // const [modelRunHistory, setModelRunHistory] = useState([]);
  const [showConsole, setShowConsole] = useState(false);

  /// UI ELEMENTS -
  /// sidebars
  const [showSidebars] = useState(true);
  /// FLOAT MENU
  const [floatMenu, setFloatMenu] = useState({ mouseX: null, mouseY: null, isOpen: false });
  const [enrichedMenu, setEnrichedMenu] = useState(menuItems);
  /// GALLERY
  const { showGallery } = useMediaGalleryContext();
  /// EDITOR
  const { handleOpenEditor, inEditingMode } = useEditorContext();

  /// main toolbar
  const [selectedMenu, setSelectedMenu] = useState();
  //// POSTER
  const [userSetPoster, setUserSetPoster] = useState(recipeData?.poster);
  /// DIALOG
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  /// MEDIA ARRAY
  const [mediaMap, setMediaMap] = useState([]);

  /// FLOW VIEWING MODE
  const shouldShowDesignAppToggle = useMemo(() => {
    if (!nodes?.some((node) => node.type === NodeType.WorkflowOutput)) return false;
    if (role === 'editor') return true;
    return recipeData.latestPublishedVersion;
  }, [nodes, role, recipeData.latestPublishedVersion]);

  const [flowViewingMode, setFlowViewingMode] = useState(() => {
    const viewParam = queryParamViewingMode;

    if (role === 'editor' && viewParam === FlowMode.App && shouldShowDesignAppToggle) {
      return FlowMode.App;
    }

    if (role !== 'editor' && !recipeData.latestPublishedVersion) {
      // prevnet from guest to see the app mode if no published version
      return FlowMode.Workflow;
    }

    if (viewParam) {
      if (viewParam === FlowMode.App && !shouldShowDesignAppToggle) {
        return FlowMode.Workflow;
      }
      return viewParam;
    }
    return role === 'editor' || !shouldShowDesignAppToggle ? FlowMode.Workflow : FlowMode.App;
  });

  const addNodesAndSelect = useCallback(
    (newNodes) => {
      setNodes((prevNodes) => {
        // Deselect all current nodes
        const updatedNodes = prevNodes.map((node) => ({ ...node, selected: false }));

        // Add the new nodes with the selected flag set to true
        newNodes.forEach((newNode) => {
          updatedNodes.push({ ...newNode, selected: true });
        });

        return updatedNodes;
      });
      setSelectedNodes(newNodes);
    },
    [nodes, setNodes, setSelectedNodes],
  );

  const duplicateNodes = useCallback(() => {
    if (selectedNodes && selectedNodes.length > 0) {
      const newNodes = selectedNodes.map((node) => ({
        ...node,
        id: uuidv4(), // generate a new unique ID for each node
        position: { x: node.position.x + 10, y: node.position.y + 10 }, // adjust the position for each node
      }));

      setNodes((prevNodes) => [...prevNodes, ...newNodes]);
      addNodesAndSelect(newNodes);
      setSelectedNodes(newNodes); // Set the array of new nodes as the selected ones
    }
  }, [selectedNodes, setNodes, setSelectedNodes, addNodesAndSelect]);

  const duplicateNodeById = useCallback(
    (id) => {
      if (!id) return;
      let newNode;
      setNodes((prevNodes) => {
        const currentNode = prevNodes.find((node) => node.id === id);
        if (!currentNode) return prevNodes;

        newNode = {
          ...currentNode,
          id: uuidv4(),
          position: { x: currentNode.position.x + 10, y: currentNode.position.y + 10 },
        };

        return [...prevNodes, newNode];
      });
      addNodesAndSelect([newNode]);
      setSelectedNodes([newNode]);
    },
    [nodes, setNodes, setSelectedNodes, addNodesAndSelect],
  );

  const toggleLockNode = (nodeId) => {
    updateNodeData(nodeId, { isLocked: !nodes.find((node) => node.id === nodeId).data.isLocked });
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            draggable: node.data.isLocked ? false : undefined,
          };
        }

        return node;
      }),
    );
  };

  const getCenterScreenPosition = () => {
    const rect = flowRef.current.getBoundingClientRect();
    const x = rect.right - rect.left - 240;
    const y = rect.bottom - rect.top - 120;

    return screenToFlowPosition({
      x: x / 2,
      y: y / 2,
    });
  };

  const addNewNode = (action, dropX, dropY, absolutePosition = false) => {
    posthog.capture('added_new_node', { type: action.displayName });
    let position;
    if (dropX && dropY && !absolutePosition)
      position = screenToFlowPosition({
        x: dropX - 60,
        y: dropY - 50,
      });
    else if (dropX && dropY && absolutePosition) {
      position = {
        x: dropX,
        y: dropY,
      };
    } else position = getCenterScreenPosition();

    const selectedOption = nodeTypes.find((option) => option.id === action.id);

    if (selectedOption) {
      // Create a new node object with the selected option's data
      const newNode = {
        ...selectedOption,
        id: uuidv4(),
        position: position,
        data: { ...cloneDeep(selectedOption.data), ...action.initialData },
      };
      if (action.initialData) {
        //supports image drag from gallery
        newNode.data.initialData = action.initialData;
      }
      if (action.pastedData) {
        // support for paste from copy image
        newNode.data.pastedData = action.pastedData;
      }
      // Update the nodes state with the new node
      setNodes((prevNodes) => [...prevNodes, newNode]);

      return newNode;
    } else {
      console.error(`Option for action "${action.name}" not found.`);
    }
  };

  /// add new edge
  const addNewEdge = useCallback(
    (sourceNode, targetNode, sourceHandleId, targetHandleId) => {
      const newEdge = {
        id: uuidv4(),
        source: sourceNode.id,
        target: targetNode.id,
        sourceHandle: sourceHandleId,
        targetHandle: targetHandleId,
        data: {
          sourceColor: sourceNode.data.color,
          targetColor: targetNode.data.color,
        },
        type: 'custom',
      };
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    },
    [edges, setEdges],
  );

  const pasteNodesOrImageFromClipboard = (event) => {
    if (role !== 'editor') return;

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text/plain');
    const pastedHtml = clipboardData.getData('text/html');

    if (pastedHtml) {
      // handle paste of image
      for (let item of clipboardData.items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
          if (file) {
            const innerItem = {
              type: 'import',
              pastedData: { imageFile: file },
              id: 'wkKkBSd0yrZGwbStnU6r',
            };
            addNewNode(innerItem, event.clientX, event.clientY);
          }

          return;
        }
      }
    }
    if (!pastedText) return;
    // handle paste nodes and edges
    let parsedJson;
    try {
      parsedJson = JSON.parse(pastedText);
    } catch (error) {
      return;
    }
    if (!parsedJson) return;

    const clipboardNodes = parsedJson.nodes;
    const clipboardEdges = parsedJson.edges;

    if (clipboardNodes && clipboardNodes.length > 0) {
      // Find the top-leftmost node to use as a reference for positioning
      const referenceNode = clipboardNodes.reduce((ref, node) => {
        return !ref || node.position.x < ref.position.x || node.position.y < ref.position.y ? node : ref;
      }, null);

      const centerPosition = getCenterScreenPosition();

      let idMapping = {};

      const newNodes = clipboardNodes.map((node) => {
        // Calculate the relative position to the reference node
        const relativeX = node.position.x - referenceNode.position.x;
        const relativeY = node.position.y - referenceNode.position.y;

        // Apply this relative position to the center position
        const newPosition = {
          x: centerPosition.x + relativeX,
          y: centerPosition.y + relativeY,
        };

        const newNodeId = uuidv4();
        idMapping[node.id] = newNodeId; // Store mapping of old ID to new ID

        return { ...node, id: newNodeId, position: newPosition };
      });

      // Create new edges with updated source and target IDs based on copied edges
      const newEdges = clipboardEdges.reduce((acc, edge) => {
        const newSource = idMapping[edge.source];
        const newTarget = idMapping[edge.target];

        if (newSource && newTarget) {
          // Both source and target are in the selection
          acc.push({
            ...edge,
            id: uuidv4(), // Generate a new ID for the edge
            source: newSource,
            target: newTarget,
            // Handle IDs are based on node IDs, adjust them to the new IDs
            sourceHandle: edge.sourceHandle.replace(edge.source, newSource),
            targetHandle: edge.targetHandle.replace(edge.target, newTarget),
          });
        }
        // Else, you could handle edges connected to nodes outside the selection differently

        return acc;
      }, []);

      setNodes((prevNodes) => [...prevNodes, ...newNodes]);
      setEdges((prevEdges) => [...prevEdges, ...newEdges]);

      addNodesAndSelect(newNodes);
      setSelectedNodes(newNodes);
      // setClipboardNodes([]);
    }
  };

  const copyNodesToClipboard = () => {
    if (selectedNodes && selectedNodes.length > 0) {
      const nodesToCopy = selectedNodes.map((node) => ({ ...node }));

      // Copy edges that connect between selected nodes
      const edgesToCopy = edges.filter(
        (edge) =>
          selectedNodes.some((node) => node.id === edge.source) &&
          selectedNodes.some((node) => node.id === edge.target),
      );

      // setClipboardNodes(nodesToCopy);
      // setClipboardEdges(edgesToCopy); // Store copied edges as is
      const clipboardDataString = JSON.stringify({ nodes: nodesToCopy, edges: edgesToCopy });
      navigator.clipboard.writeText(clipboardDataString);
    }
  };

  function captureVideoFrame(videoElement) {
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext('2d');
    videoElement.crossOrigin = 'anonymous';
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image/png');
  }

  ////  SAVE IMAGE OF CANVAS
  const createPoster = async () => {
    const nodesBounds = getNodesBounds(getNodes());
    const transform = getViewportForBounds(nodesBounds, posterWidth, posterHeight, 0.1, 3);

    const videos = document.querySelectorAll('video');
    const videoReplacements = new Map();

    videos.forEach((video) => {
      const frameDataURL = captureVideoFrame(video);
      const imageElement = new Image();
      imageElement.src = frameDataURL;
      imageElement.style.width = `${video.offsetWidth}px`;
      imageElement.style.height = `${video.offsetHeight}px`;
      video.parentNode.replaceChild(imageElement, video);
      videoReplacements.set(video, imageElement);
    });

    try {
      const dataUrl = await toPng(document.querySelector('.react-flow__viewport'), {
        backgroundColor: color.Dark_BG,
        width: posterWidth,
        height: posterHeight,
        skipFonts: true,
        style: {
          width: posterWidth,
          height: posterHeight,
          transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
        },
        pixelRatio: 1,
      });

      return dataUrl;
    } catch (err) {
      console.error('Failed to create poster image', err);
    } finally {
      videoReplacements.forEach((imageElement, video) => {
        imageElement.parentNode.replaceChild(video, imageElement);
      });
    }
  };

  const mediaArray = Array.from(mediaMap.values());

  const sanitizeNodes = (nodesToSanitize) => {
    // Now you can safely modify sanitizedNode since it's deeply cloned
    return nodesToSanitize.map((node) => {
      if (node.data.output) {
        for (const [name, conf] of Object.entries(node.data.output)) {
          if (
            conf?.type === 'image' &&
            (conf?.url.includes('data:image/png;base64') || conf?.url.includes('data:image/jpeg;base64'))
          ) {
            node.data.output[name].url = '';
          }
        }
      }

      if (node.data.input) {
        for (const [name, conf] of Object.entries(node.data.input)) {
          if (
            conf?.type === 'image' &&
            (conf?.url.includes('data:image/png;base64') || conf?.url.includes('data:image/jpeg;base64'))
          ) {
            node.data.input[name].url = '';
          }
        }
      }
      if (node.data.result && typeof node.data.result === 'object') {
        if (
          node.data.result.url &&
          (node.data.result.url.includes('data:image/png;base64') ||
            node.data.result.url.includes('data:image/jpeg;base64'))
        ) {
          node.data.result.url = '';
        }
      }

      if (Array.isArray(node.data.result)) {
        for (const result of node.data.result) {
          if (result.params && Object.entries(result.params)) {
            for (const [name, conf] of Object.entries(result.params)) {
              if (
                conf?.type === 'image' &&
                (conf?.url.includes('data:image/png;base64') || conf?.url.includes('data:image/jpeg;base64'))
              ) {
                result.params[name].url = '';
              }
            }
          }
          if (result.input && Object.entries(result.input)) {
            for (const [name, conf] of Object.entries(result.input)) {
              if (
                conf?.type === 'image' &&
                (conf?.url.includes('data:image/png;base64') || conf?.url.includes('data:image/jpeg;base64'))
              ) {
                result.input[name].url = '';
              }
            }
          }
        }
      }
      if (node.data.result && node.data.result.params && Object.entries(node.data.result.params)) {
        for (const [name, conf] of Object.entries(node.data.result.params)) {
          if (conf?.type === 'image' && conf?.url.includes('data:image/png;base64')) {
            node.data.result.params[name].url = '';
          }
        }
      }

      if (node.data.layers && Object.entries(node.data.layers)) {
        for (const [name, conf] of Object.entries(node.data.layers)) {
          if (conf?.url.includes('data:image/png;base64')) {
            node.data.layers[name].url = '';
          }
        }
      }
      if (node.data.externalData) {
        delete node.data.externalData;
      }

      return node;
    });
  };

  const sanitizeMediaArray = (mediaArrayToSanitize) => {
    return mediaArrayToSanitize.map((media) => {
      for (const key in media) {
        if (
          typeof media[key] === 'string' &&
          (media[key].includes('data:image/png;base64') || media[key].includes('data:image/jpeg;base64'))
        ) {
          media[key] = '';
        }
        if (media[key] === null) {
          /// error saving null on firestore
          media[key] = undefined;
        }
        if (key === 'input' && media[key]) {
          for (const [name, conf] of Object.entries(media[key])) {
            if (
              conf?.type === 'image' &&
              (conf?.url.includes('data:image/png;base64') || conf?.url.includes('data:image/jpeg;base64'))
            ) {
              media.input[name].url = '';
            }
          }
        }
      }

      return media;
    });
  };

  //// SAVING RECIPE
  const handleSave = useCallback(
    async (posterImageUrl, designAppMetadata) => {
      if (role !== 'editor') {
        return;
      }

      if (isSaving) {
        return;
      }

      if (nodes.length === 0) {
        return;
      } /// protection from saving empty recipe

      setIsSaving(true);

      const clonedNodes = cloneDeep(nodes);

      // Perform node sanitization
      const sanitizedNodes = sanitizeNodes(clonedNodes);
      const sanitizedMediaArray = sanitizeMediaArray(mediaArray);
      const dataToSave = { nodes: sanitizedNodes, edges, mediaArray: sanitizedMediaArray, posterImageUrl };
      if (designAppMetadata !== undefined) {
        dataToSave.designAppMetadata = designAppMetadata;
      }
      // Perform the save operation
      try {
        await axiosInstance.post(`/v1/recipes/${recipeId}/save`, dataToSave);
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        console.log('Network response was not ok', error);
        posthog.capture('save_error');
      }
    },
    [nodes, edges, recipeId],
  );

  const debounceAutoSave = useCallback(() => {
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      handleSave();
    }, 2000);
  }, [handleSave]);

  useEffect(() => {
    debounceAutoSave();
  }, [nodes, edges]);

  // useEffect(() => {
  //   // Refresh selected nodes
  //   if (selectedNodes && selectedNodes.length > 0) {
  //     const currentSelectedNodes = selectedNodes.map((selectedNode) => {
  //       return nodes.find((node) => node.id === selectedNode.id);
  //     }).filter((node) => node !== undefined); // Filter out any undefined entries

  //     setSelectedNodes(currentSelectedNodes);
  //   }
  // }, [nodes]);

  const deselectAllNodes = () => {
    setNodes((prevNodes) => prevNodes.map((node) => ({ ...node, selected: false })));
  };

  // PAINT CANVAS RELATED (Prevent from painter interaction the bubbles to React flow)
  const handleCanvasInteraction = () => {
    deselectAllNodes();
  };

  const getHandles = (node) => {
    const symbolProp = Object.getOwnPropertySymbols(node);

    if (symbolProp.length > 0 && node[symbolProp[0]]) {
      const handleBounds = node[symbolProp[0]].handleBounds;

      return handleBounds ? handleBounds : null;
    }

    return null;
  };

  //// PROXIMITY CONNECT
  const getClosestEdge = useCallback((node) => {
    const { nodeInternals } = store.getState();
    const storeNodes = Array.from(nodeInternals.values());

    const currentNode = storeNodes.find((n) => n.id === node.id);
    const currentNodeHandles = getHandles(currentNode);

    let closestHandleInfo = {
      distance: Number.MAX_VALUE,
      node: null,
      sourceHandleId: null,
      targetHandleId: null,
    };

    storeNodes.forEach((n) => {
      if (n.id !== node.id) {
        const dx = n.positionAbsolute.x - currentNode.positionAbsolute.x;
        const dy = n.positionAbsolute.y - currentNode.positionAbsolute.y;
        const nodeDistance = Math.sqrt(dx * dx + dy * dy);

        if (nodeDistance < MIN_NODE_DISTANCE) {
          const closestNodeHandles = getHandles(n);

          ['source', 'target'].forEach((type) => {
            currentNodeHandles[type]?.forEach((currentHandle) => {
              const oppositeType = type === 'source' ? 'target' : 'source';
              closestNodeHandles[oppositeType]?.forEach((oppositeHandle) => {
                const newDx =
                  n.positionAbsolute.x + oppositeHandle.x - (currentNode.positionAbsolute.x + currentHandle.x);
                const newDy =
                  n.positionAbsolute.y + oppositeHandle.y - (currentNode.positionAbsolute.y + currentHandle.y);
                const distance = Math.sqrt(newDx * newDx + newDy * newDy);

                if (distance < closestHandleInfo.distance && distance < MIN_HANDLE_DISTANCE) {
                  closestHandleInfo = {
                    distance,
                    node: n,
                    sourceHandleId: type === 'source' ? currentHandle.id : oppositeHandle.id,
                    targetHandleId: type === 'target' ? currentHandle.id : oppositeHandle.id,
                  };
                }
              });
            });
          });
        }
      }
    });

    if (!closestHandleInfo.node) {
      return null;
    }

    const closeNodeIsSource = closestHandleInfo.node.positionAbsolute.x < currentNode.positionAbsolute.x;

    return {
      id: uuidv4(),
      source: closeNodeIsSource ? closestHandleInfo.node.id : currentNode.id,
      target: closeNodeIsSource ? currentNode.id : closestHandleInfo.node.id,
      sourceHandle: closestHandleInfo.sourceHandleId,
      targetHandle: closestHandleInfo.targetHandleId,
      data: {
        sourceColor: closeNodeIsSource ? closestHandleInfo.node.data.color : currentNode.data.color,
        targetColor: closeNodeIsSource ? currentNode.data.color : closestHandleInfo.node.data.color,
      },
      type: 'custom',
    };
  }, []);

  const onNodeDrag = useCallback(
    (event, node) => {
      // Check if the SHIFT key is held down
      const isShiftPressed = event.shiftKey;

      if (isShiftPressed) {
        const closeEdge = getClosestEdge(node);

        // Only proceed if a close edge is found
        if (closeEdge) {
          setEdges((es) => {
            const nextEdges = es.filter((e) => e.className !== 'temp');

            if (!nextEdges.find((ne) => ne.source === closeEdge.source && ne.target === closeEdge.target)) {
              // closeEdge.className = 'temp';
              nextEdges.push(closeEdge);

              // Pass data immediately if SHIFT is pressed and handles are close enough
              passData(nodes, closeEdge.source, closeEdge.sourceHandle, closeEdge.target, closeEdge.targetHandle);
            }

            return nextEdges;
          });
        }
      }
    },
    [getClosestEdge, setEdges, nodes],
  );

  const removeDataFromDisconnectedNodes = (localEdges, removedEdges) => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        let updated = false;

        // Create a shallow copy of node.data.input to manipulate
        const newInputData = { ...node.data.input };

        removedEdges.forEach((edge) => {
          const disconnectedEdge = localEdges.find((edg) => edge.id === edg.id);

          if (disconnectedEdge.target === 'temp') return;
          const inputKey = disconnectedEdge.targetHandle.split('-input-')?.pop();
          if (disconnectedEdge.target === node.id && inputKey in newInputData) {
            // Set the specific input to null in the new object
            newInputData[inputKey] = null;
            updated = true;
          }
        });

        // If updated, return a new node object with updated data.input
        if (updated) {
          return { ...node, data: { ...node.data, input: newInputData } };
        }

        // Return the original node if not updated
        return node;
      });
    });
  };

  const onNodesChange = useCallback(
    (changes) =>
      setNodes((nds) => {
        // console.log(nds);
        return applyNodeChanges(changes, nds);
      }),
    [],
  );

  const onNodeDragStop = useCallback(
    // (_, node) => {
    () => {
      // const closeEdge = getClosestEdge(node);
      // setEdges((es) => {
      //   const nextEdges = es.filter((e) => e.className !== 'temp');
      //   if (
      //     closeEdge &&
      //     !nextEdges.find(
      //       (ne) =>
      //         ne.source === closeEdge.source && ne.target === closeEdge.target,
      //     )
      //   ) {
      //     passData(nodes, closeEdge.source, closeEdge.sourceHandle, closeEdge.target, closeEdge.targetHandle);
      //     nextEdges.push(closeEdge);
      //   }
      //   return nextEdges;
      // });
    },
    [getClosestEdge, nodes],
  );

  /// EDGE DISCONNECT BY USER
  const onEdgeUpdateStart = useCallback(
    (_, edge) => {
      deletedEdge.edge = edge;
      setEdges((currentEdges) => currentEdges.filter((e) => e.id !== edge.id));
      removeDataFromDisconnectedNodes(edges, [edge]);
    },
    [edges, removeDataFromDisconnectedNodes, setEdges],
  );

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      setEdges((es) => {
        const newEdge = { ...deletedEdge.edge, ...newConnection };
        passData(
          nodes,
          newConnection.source,
          newConnection.sourceHandle,
          newConnection.target,
          newConnection.targetHandle,
        );

        return [...es, newEdge];
      });
    },
    [nodes, setEdges],
  );

  const deleteEdgeByTargetHandleId = useCallback(
    (targetHandleId) => {
      setEdges((es) => {
        const edgeToDelete = es.find((e) => e.targetHandle === targetHandleId);
        if (edgeToDelete) {
          return es.filter((e) => e.id !== edgeToDelete.id);
        }

        return es;
      });
    },
    [setEdges],
  );

  /// END OF EDGE DISCONNECT BY USER
  //// FLOAT MENU
  const handleFloatMenu = (event) => {
    if (event.target.classList.contains('react-flow__pane')) {
      event.preventDefault();
      setFloatMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        isOpen: true,
      });
    }
  };
  const handleFloatMenuClose = () => {
    setFloatMenu({ ...floatMenu, isOpen: false });
  };
  /// END OF FLOAT MEMU
  //// RUNNING MODELS

  const runSelected = useCallback(() => {
    setModelRunTrigger([]);
    const modelsToRun = getModelsToProcess(edges, nodes, selectedNodes);
    // console.log(modelsToRun);
    setModelRunTrigger(modelsToRun);
    setIsProcessingProgress(0);
    setIsProcessing(true);
    numOfRunningModels.value = modelsToRun.length;
    totalProgress.value = [];
  }, [nodes, edges, selectedNodes]);

  const handleResetTrigger = () => {
    setIsProcessing(false);
    setIsProcessingProgress(0);
    totalProgress.value = [];
    // setFinishedProcessing({finished:false, status:null});
    // setModelRunTrigger([]);
  };

  const updateModelRunTriggerStatus = useCallback((nodeId, status, progress, predictionId) => {
    setModelRunTrigger((currentTriggers) =>
      currentTriggers.map((trigger) =>
        trigger.id === nodeId ? { ...trigger, status, progress, predictionId } : trigger,
      ),
    );
  }, []);

  const deleteNode = (nodeId) => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    setEdges((prevEdges) => prevEdges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
  };

  /// exposing handles and creating new nodes from them

  const createNewNodeFromParamExpose = useCallback(
    (nodeId, newHandle, param, schema) => {
      const originNode = nodes.find((n) => n.id === nodeId);
      const newNodesData = getNodeTemplates(schema.type, param, schema);

      if (newNodesData) {
        let newNode = addNewNode(newNodesData.template, originNode.position.x - 550, originNode.position.y + 50, true);

        const sourceHandleId = getHandleId(newNode.id, 'output', newNodesData.handleName);
        const targetHandleId = getHandleId(nodeId, 'input', Object.keys(newHandle)[0]);
        addNewEdge(newNode, originNode, sourceHandleId, targetHandleId);
      }
    },
    [nodes],
  );

  const staticNodeTypesComponent = React.useMemo(
    () => ({
      import: (nodeData) => <ImportCloudinaryNode {...nodeData} updateNodeData={updateNodeData} />,
      media: (nodeData) => <ImportCloudinaryNode {...nodeData} updateNodeData={updateNodeData} />,
      prompt: (nodeData) => <PromptNode {...nodeData} updateNodeData={updateNodeData} />,
      promptV2: (nodeData) => <PromptNodeV2 {...nodeData} updateNodeData={updateNodeData} />,
      prompt_concat: (nodeData) => <PromptConcat {...nodeData} updateNodeData={updateNodeData} />,
      prompt_enhance: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      controlNet: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      preview: (nodeData) => (
        <PreviewNode
          {...nodeData}
          updateNodeData={updateNodeData}
          recipeId={recipeId}
          setUserSetPoster={setUserSetPoster}
        />
      ),
      painter: (nodeData) => (
        <PaintNode {...nodeData} updateNodeData={updateNodeData} onCanvasInteraction={handleCanvasInteraction} />
      ),
      painterV2: (nodeData) => (
        <PaintNodeV2 {...nodeData} updateNodeData={updateNodeData} onCanvasInteraction={handleCanvasInteraction} />
      ),
      sd_inpaint: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_outpaint: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      image2image: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      text2image: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_text2image: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      br_text2image: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      br_vector: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      dalle3: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      midjourney: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      bgremove: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_bgrmv: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_sketch: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_upscale: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_img2video: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      sd_image23d: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      nim_cc: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      luma_video: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      rw_video: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      kling: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      mochiv1: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      minimax: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      minimax_i2v: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      objectremove: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      menunode: (nodeData) => <MenuNode {...nodeData} />,
      integer: (nodeData) => <NumberNode {...nodeData} updateNodeData={updateNodeData} />,
      stickynote: (nodeData) => <StickyNoteNode {...nodeData} updateNodeData={updateNodeData} />,
      resize: (nodeData) => <ResizeCloudinaryNode {...nodeData} updateNodeData={updateNodeData} />,
      crop: (nodeData) => <CropCloudinaryNode {...nodeData} updateNodeData={updateNodeData} />,
      comp: (nodeData) => (
        <CompNode {...nodeData} updateNodeData={updateNodeData} onCanvasInteraction={handleCanvasInteraction} />
      ),
      ImportLoRA: (nodeData) => <UploadLoraNode {...nodeData} updateNodeData={updateNodeData} />,
      compv2: (nodeData) => (
        <CompNodeV2 {...nodeData} updateNodeData={updateNodeData} openEditWindow={handleOpenEditor} />
      ),
      wildcard: (nodeData) => (
        <ImportModelNode
          {...nodeData}
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          updateNodeData={updateNodeData}
          setNodesTypes={setNodeTypes}
          editable={true}
        />
      ),
      custommodel: (nodeData) => (
        <ImportModelNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
          editable={false}
        />
      ),
      wildcardV2: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
          setNodesTypes={setNodeTypes}
          editable={true}
          createNewNodeFromParamExpose={createNewNodeFromParamExpose}
          deleteEdgeByTargetHandleId={deleteEdgeByTargetHandleId}
        />
      ),
      custommodelV2: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
          editable={false}
          createNewNodeFromParamExpose={createNewNodeFromParamExpose}
          deleteEdgeByTargetHandleId={deleteEdgeByTargetHandleId}
          setIsRunningModelWhileInTour={setIsRunningModelWhileInTour} // used for tour
          setIsRunningModelWhileInTourSuccess={setIsRunningModelWhileInTourSuccess} // used for tour
        />
      ),
      blur: (nodeData) => <BlurNode {...nodeData} updateNodeData={updateNodeData} />,
      channels: (nodeData) => <ChannelsNode {...nodeData} updateNodeData={updateNodeData} />,
      edit: (nodeData) => <EditNode {...nodeData} updateNodeData={updateNodeData} openEditWindow={handleOpenEditor} />,
      export: (nodeData) => <ExportNode {...nodeData} updateNodeData={updateNodeData} />,
      masks: (nodeData) => (
        <MasksExtractionNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      comfy: (nodeData) => (
        <ComfyNode recipeId={recipeId} {...nodeData} updateNodeData={updateNodeData} setNodesTypes={setNodeTypes} />
      ),
      flux_pro: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      flux_fast: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
          setIsRunningModelWhileInTour={setIsRunningModelWhileInTour} // used for tour
          setIsRunningModelWhileInTourSuccess={setIsRunningModelWhileInTourSuccess} // used for tour
        />
      ),
      flux_lora: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      ig_text2image: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      ig_describe: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      meshy_image23d: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      any_llm: (nodeData) => (
        <ModelBaseNode
          recipeVersion={recipeData.recipeVersion}
          recipeId={recipeId}
          {...nodeData}
          updateNodeData={updateNodeData}
        />
      ),
      string: (nodeData) => <StringNode {...nodeData} updateNodeData={updateNodeData} />,
      router: (nodeData) => <RouterNode {...nodeData} updateNodeData={updateNodeData} />,
      merge_alpha: (nodeData) => <MergeAlphaNode {...nodeData} updateNodeData={updateNodeData} />,
      boolean: (nodeData) => <BooleanNode {...nodeData} updateNodeData={updateNodeData} />,
      mux: (nodeData) => <MuxNode {...nodeData} updateNodeData={updateNodeData} />,
      seed: (nodeData) => <SeedNode {...nodeData} updateNodeData={updateNodeData} />,
      array: (nodeData) => <ArrayCore {...nodeData} updateNodeData={updateNodeData} />,
      workflow_output: (nodeData) => <OutputNode {...nodeData} updateNodeData={updateNodeData} />,
      multilora: (nodeData) => <MultiLoRANode {...nodeData} updateNodeData={updateNodeData} />,
      extract_video_frame: (nodeData) => <ExtractVideoFrameNode {...nodeData} updateNodeData={updateNodeData} />,
    }),
    [recipeData],
  );

  useEffect(() => {
    if (user) {
      const userCustomNodes = nodeTypes.filter((node) => node.owner === user.uid && node.isModel);
      if (userCustomNodes.length !== 0) {
        const myModels = {
          displayName: 'My Models',
          icon: 'FavoriteIcon',
          children: {},
        };

        userCustomNodes.map((node) => {
          myModels.children[node.id] = {
            displayName: node.data?.name,
            id: node.id,
          };
        });
        setEnrichedMenu((prevMenu) => ({
          ...prevMenu,
          models: {
            ...prevMenu.models,
            children: {
              ...prevMenu.models.children,
              myModels,
            },
          },
        }));
      }

      const communityModels = nodeTypes.filter((node) => node.visibility === 'public' && node.isModel);
      const communityModelsMenuEntry = {
        displayName: 'Community Models',
        icon: 'FavoriteIcon',
        children: {},
      };

      communityModels.map((node) => {
        communityModelsMenuEntry.children[node.id] = {
          displayName: node.data?.menu.displayName,
          icon: node?.data?.menu.icon,
          id: node.id,
        };
      });

      setEnrichedMenu((prevMenu) => ({
        ...prevMenu,
        models: {
          ...prevMenu.models,
          children: {
            ...prevMenu.models.children,
            communityModelsMenuEntry,
          },
        },
      }));
      setIsLoadingRecipe(false);
    }
  }, [nodeTypes, flowViewingMode]);

  const edgeTypes = React.useMemo(
    () => ({
      custom: (edgeData) => <CustomEdge {...edgeData} />,
    }),
    [],
  );

  useEffect(() => {
    const handleBackButton = async () => {
      if (role === 'editor') await handleSave();
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handleGoToDashboard = async () => {
    setSavingBeforeExit(true);
    let posterImageUrl = userSetPoster;
    if (!recipeData?.userPoster && !userSetPoster) {
      posterImageUrl = await createPoster();
    }
    handleSave(posterImageUrl);
    navigate('/');
  };

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        // Apply edge changes
        const newEdges = applyEdgeChanges(changes, eds);

        // Find which edges were removed
        const removedEdges = changes.filter((change) => change.type === 'remove');

        // If there are removed edges, update nodes' input data
        if (removedEdges.length > 0) {
          removeDataFromDisconnectedNodes(edges, removedEdges);
        }

        return newEdges;
      });
    },
    [setEdges, removeDataFromDisconnectedNodes],
  );

  useOnSelectionChange({
    onChange: ({ nodes: newSelectedNodes }) => {
      if (newSelectedNodes.length > 0) {
        setSelectedNodes(newSelectedNodes);
      } else {
        setSelectedNodes([]);
      }
    },
  });

  const createPromptNode = () => {
    const newPromptTemplate = {
      id: 'OBtdfjfMoqp3PAzVUBHd',
      displayName: 'Prompt',
    };
    addNewNode(newPromptTemplate);
  };

  /// KEYBORAD SHORTCUTS ACTIONS
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return; // Skip handling the event if it's on an input or textarea
      }

      if ((event.metaKey || event.ctrlKey) && event.key === 'd') {
        event.preventDefault();
        duplicateNodes();
      }
      if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
        event.preventDefault();
        copyNodesToClipboard();
      }
      // if ((event.metaKey || event.ctrlKey) && event.key === 'v') {
      //   event.preventDefault();
      //   pasteNodesFromClipboard(event);
      // }
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        handleSave();
      }
      if ((event.metaKey || event.ctrlKey) && event.key === 'p') {
        event.preventDefault();
        createPromptNode();
      }
      // if (event.key === 'Escape') {
      //   event.preventDefault();
      //   setShowGallery(false);
      // }
    };

    const handlePaste = (event) => {
      // event.preventDefault();
      pasteNodesOrImageFromClipboard(event);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('paste', handlePaste);
    };
  }, [selectedNodes, nodes]);

  useEffect(() => {
    // this useEffect is to handles the states of the processing of all running models:
    // 1. Handle progress
    modelRunTrigger.forEach((m, index) => {
      if (m.progress) {
        totalProgress.value[index] = parseInt(m.progress);
      }
    });
    const totalProgressSum = totalProgress?.value?.reduce((acc, value) => {
      return acc + value;
    }, 0);

    setIsProcessingProgress(totalProgressSum / numOfRunningModels.value);

    // 2. Handle all models finished running
    const allModelsReady = modelRunTrigger.every((model) => model.status === 'ready'); // Great success
    const someModelsFailed = modelRunTrigger.some((model) => model.status === 'failed'); // Some have failed
    const allModelsReadyOrFailed = modelRunTrigger.every(
      (model) => model.status === 'ready' || model.status === 'failed' || model.status === 'canceled',
    ); // All finished

    if (allModelsReady && modelRunTrigger.length > 0 && !someModelsFailed) {
      //finished with success
      // console.log("finished successfully");
      setFinishedProcessing({ finished: true, status: 'success' });
      handleResetTrigger();
    }
    if (allModelsReadyOrFailed && someModelsFailed) {
      //finished with fails
      // console.log("finished with errors");
      setFinishedProcessing({ finished: true, status: 'error' });
      handleResetTrigger();
    }
  }, [modelRunTrigger]);

  const stopSequence = useCallback(() => {
    const modelsToCancel = modelRunTrigger.filter(
      (model) => (model.status === 'processing' || model.status === 'starting') && model.predictionId,
    );

    modelsToCancel.forEach((model) => {
      try {
        axiosInstance.post(`/v1/models/predict/cancel`, { predictionId: model.predictionId });
      } catch (error) {
        console.error('Could not cancel prediction', error);
      }
    });

    /// cancel all other models to run
    setModelRunTrigger((prevModels) =>
      prevModels.map((model) =>
        model.status !== 'ready' && model.status !== 'failed' ? { ...model, status: 'canceled', progress: 0 } : model,
      ),
    );

    handleResetTrigger();
  }, [modelRunTrigger, setModelRunTrigger]);

  const handleCloseConsole = () => {
    setShowConsole(false);
  };
  //// END OF RUNNING MODELS

  //// DRAG EVENTS
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      if (role !== 'editor') return;
      event.preventDefault();

      const itemString = event.dataTransfer.getData('menuItem');
      if (itemString) {
        let item;
        try {
          item = JSON.parse(itemString);
        } catch (error) {
          // console.log("irrelevant paste");
          return;
        }

        // check if the dropped element is valid
        if (typeof item === 'undefined' || !item) {
          return;
        }

        // handle drop on menu (probably user wants to cancel)
        if (item && event.clientX >= 240) {
          addNewNode(item, event.clientX, event.clientY);
        }

        return;
      }
      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0]; // Handle the first file
        const item = {
          type: 'import',
          pastedData: { imageFile: file },
          id: 'wkKkBSd0yrZGwbStnU6r',
        };
        addNewNode(item, event.clientX, event.clientY);
      }
    },
    [nodes, nodeTypes],
  );

  //// POPULATE MEDIA ARRAY (TO USE IN THE MEDIA TAB) - SHOULD BE HANDLED IN THE SERVER SIDE IN THE FUTURE.
  const populateMediaArray = () => {
    const newMediaMap = new Map(); // Use a Map to track unique media objects by URL

    nodes.forEach((node) => {
      // Check if node.data.result is present and handle both single object and array cases
      if (node.data.result) {
        const results = Array.isArray(node.data.result) ? node.data.result : [node.data.result];
        results.forEach((result) => {
          if (
            (result.type === 'image' || result.type === 'video' || result.type === 'audio') &&
            result.url &&
            !result.url.includes('data:image/png;base64')
          ) {
            // Only add the object if the URL is not already in the Map
            if (!newMediaMap.has(result.url)) {
              const { ...filteredResult } = result; // added this 4.10.24 to exclude the params and input from the media library to avoid issues with saving (cannot save base64 strings)
              newMediaMap.set(result.url, filteredResult);
            }
          }
        });
      }

      // Handle node.data.file similarly if needed
      if (
        node.data.file &&
        (node.data.file.type === 'image' || node.data.file.type === 'video') &&
        node.data.file.url
      ) {
        if (!newMediaMap.has(node.data.file.url)) {
          newMediaMap.set(node.data.file.url, node.data.file);
        }
      }
    });

    // To convert the map values back to an array if needed
    return Array.from(newMediaMap.values());
  };

  useEffect(() => {
    if (nodes) {
      const updatedMediaMap = populateMediaArray(mediaMap);
      setMediaMap(updatedMediaMap); // Update the state with the merged results
    }
  }, [nodes]);

  const handleDuplicateRecipe = async () => {
    try {
      await duplicateRecipe();
    } catch (error) {
      console.error('Could not duplicate recipe', error);
    }
  };

  useEffect(() => {
    if (userSetPoster) {
      handleSave(userSetPoster);
    }
  }, [userSetPoster]);
  /// OVERLAY DIALOG

  const openOverlayDialog = (type, id, data) => {
    setShowDialog(true);
    setDialogData({ type, id, data });
  };

  const closeOverlayDialog = () => {
    setShowDialog(false);
  };

  /// TOUR RELATED
  const isFirstFileTourEnabled = useFeatureFlagEnabled('first-file-tour');
  const [isNewFile, setIsNewFile] = useState(queryParamNewRecipe);

  const [viewportProps, setViewportProps] = useState({
    x: 0,
    y: 0,
    zoom: 1,
  });

  const [finishedPanTourStep, setFinishedPanTourStep] = useState(false);
  const [finishedZoomTourStep, setFinishedZoomTourStep] = useState(false);
  const [finishedConnectNodesTourStep, setFinishedConnectNodesTourStep] = useState(false);
  const [finishedAddNodeStep, setFinishedAddNodeStep] = useState(false);

  const tourStarted = useRef(false);

  useEffect(() => {
    cleanQueryParams('new');
    return () => {
      // Cleanup function to stop the tour when the component is unmounted
      stopTour();
    };
  }, []);

  // init tour if exists and tour is ready
  useEffect(() => {
    if (tourReadiness.prehook && isFirstFileTourEnabled) {
      // isFirstFileTourEnabled is rollout flag.
      if (tourStarted.current) return;
      const eligibleTour = checkForEligibleTour(TOUR_COMPONENT_ID);
      if (eligibleTour) {
        switch (eligibleTour.tourId) {
          case TourKeys.NavigationTour:
            if (isNewFile) {
              setIsNewFile(false);
              const newModelNode = addNewNode(
                {
                  id: 'yE9WAgeGgEYnZmIspEGm', //promptV2 node id
                },
                250, //position x
                250, //position y
              );
              updateCustomTourData(TourKeys.NavigationTour, { recipeId: recipeId, modelNodeId: newModelNode.id });
              runTour(TourKeys.NavigationTour, eligibleTour.currentStep);
              tourStarted.current = true;
            } else if (customTourData?.recipeId && customTourData?.recipeId === recipeId) {
              runTour(TourKeys.NavigationTour, eligibleTour.currentStep);
              tourStarted.current = true;
            }

            break;
          case TourKeys.GetToKnowTheInterface:
            runTour(TourKeys.GetToKnowTheInterface, eligibleTour.currentStep);
            break;
          case TourKeys.LearnToShare:
            runTour(TourKeys.LearnToShare, eligibleTour.currentStep);
            break;
        }
      }
    }
  }, [tourReadiness.prehook, customTourData?.recipeId]);

  // assign model and prompt node ids to custom tour data
  useEffect(() => {
    if (!activeTour) return;
    if (activeTour === TourKeys.NavigationTour) {
      if (!customTourData?.modelNodeId) {
        const modelNode = nodes.find((node) => node.type === 'flux_fast');
        if (modelNode) {
          updateCustomTourData(TourKeys.NavigationTour, { modelNodeId: modelNode.id });
        }
      }
      if (!customTourData?.promptNodeId) {
        const promptNode = nodes.find((node) => node.type === 'promptV2');
        if (promptNode) {
          updateCustomTourData(TourKeys.NavigationTour, { promptNodeId: promptNode.id });
        }
      }
    }
  }, [nodes, activeTour, customTourData]);

  const isNodeAlignedWithOverlay = useCallback(
    (overlayId, targetNodeId) => {
      const { zoom, x: viewportX, y: viewportY } = viewportProps;
      const overlay = document.getElementById(overlayId);
      if (!overlay) return false;
      // Get DOM coordinates of the overlay
      const overlayRect = overlay.getBoundingClientRect();
      const overlayCenter = {
        x: overlayRect.left + overlayRect.width / 2,
        y: overlayRect.top + overlayRect.height / 2,
      };

      // Get the target node
      const targetNode = nodes.find((node) => node.id === targetNodeId);
      if (!targetNode) return false;

      // Convert React Flow coordinates to DOM coordinates
      const nodeInDOMSpace = {
        x: targetNode.position.x * zoom + viewportX,
        y: targetNode.position.y * zoom + viewportY,
      };

      // Calculate node center in DOM space
      const nodeWidth = 320 * zoom;
      const nodeHeight = 200 * zoom;
      const nodeCenterInDOMSpace = {
        x: nodeInDOMSpace.x + nodeWidth / 2,
        y: nodeInDOMSpace.y + nodeHeight / 2,
      };

      // Check alignment with tolerance
      const tolerance = 20;
      return (
        Math.abs(nodeCenterInDOMSpace.x - overlayCenter.x) < tolerance &&
        Math.abs(nodeCenterInDOMSpace.y - overlayCenter.y) < tolerance
      );
    },
    [nodes, viewportProps],
  );

  // Pan step
  useEffect(() => {
    if (!activeTour) return;
    if (activeTour === TourKeys.NavigationTour && getCurrentStepConfig()?.stepId === StepKeys.NAVIGATION_TOUR.PAN) {
      if (isNodeAlignedWithOverlay('tour-pan-overlay', customTourData?.modelNodeId)) {
        showStepSuccess();
        setFinishedPanTourStep(true);
      }
    }
  }, [viewportProps, activeTour, getCurrentStepConfig, showStepSuccess, customTourData]);

  // // Zoom step
  useEffect(() => {
    if (!activeTour) return;
    if (activeTour === TourKeys.NavigationTour && getCurrentStepConfig()?.stepId === StepKeys.NAVIGATION_TOUR.ZOOM) {
      if (viewportProps.zoom > 1.5) {
        showStepSuccess();
        setFinishedZoomTourStep(true);
      }
    }
  }, [viewportProps, showStepSuccess, setFinishedZoomTourStep, activeTour, getCurrentStepConfig]);

  // Add node step
  useEffect(() => {
    if (!activeTour) return;
    if (
      activeTour === TourKeys.NavigationTour &&
      getCurrentStepConfig()?.stepId === StepKeys.NAVIGATION_TOUR.ADD_NODES
    ) {
      setViewport({ x: 800, y: 150, zoom: 0.7 }, { duration: 1000 });
      const promptNode = customTourData?.promptNodeId || nodes.find((node) => node.type === 'promptV2')?.id;
      if (promptNode) {
        closeDrawer();
        goToNextStep(TourKeys.NavigationTour);
        setFinishedAddNodeStep(true);
        setTimeout(() => {
          setViewport({ x: 640, y: -40, zoom: 1.1 }, { duration: 1000 });
        }, 1000);
      }
    }
  }, [nodes, currentStep, activeTour]);

  useEffect(() => {
    if (!activeTour) return;
    if (
      activeTour === TourKeys.NavigationTour &&
      getCurrentStepConfig()?.stepId === StepKeys.NAVIGATION_TOUR.CONNECT_NODES
    ) {
      setFinishedAddNodeStep(true);
      const hasEdge = edges.some(
        (edge) => edge.source === customTourData.promptNodeId && edge.target === customTourData.modelNodeId,
      );
      if (hasEdge) {
        showStepSuccess();
        setFinishedConnectNodesTourStep(true);
      }
      if (isRunningModelWhileInTour) {
        goToStep(TourKeys.NavigationTour, currentStep + 2);
      }
    }
  }, [edges, customTourData]);

  // tour step run model
  useEffect(() => {
    if (!activeTour) return;
    if (
      activeTour === TourKeys.NavigationTour &&
      getCurrentStepConfig()?.stepId === StepKeys.NAVIGATION_TOUR.RUN_MODEL
    ) {
      if (isRunningModelWhileInTour) {
        setViewport({ x: 640, y: 0, zoom: 0.7 }, { duration: 1000 });
        goToNextStep(TourKeys.NavigationTour);
      }
    }
  }, [isRunningModelWhileInTour, activeTour, getCurrentStepConfig]);

  useEffect(() => {
    if (!activeTour) return;
    if (
      activeTour === TourKeys.NavigationTour &&
      getCurrentStepConfig()?.stepId === StepKeys.NAVIGATION_TOUR.RUN_MODEL_SUCCESS
    ) {
      if (isRunningModelWhileInTourSuccess) {
        goToNextStep(TourKeys.NavigationTour);
      }
    }
  }, [isRunningModelWhileInTourSuccess, activeTour, getCurrentStepConfig]);

  const centerViewportOnNode = useCallback(
    (node) => {
      if (!node) return;
      fitView({ nodes: [node], maxZoom: 1.3, duration: 1000 });
    },
    [nodes],
  );

  // register prehook functions
  const openDrawer = () => {
    return new Promise((resolve) => {
      setSelectedMenu('toolbox');
      setTimeout(resolve, 100);
    });
  };

  const closeDrawer = () => {
    return new Promise((resolve) => {
      setSelectedMenu(null);
      setTimeout(resolve, 100);
    });
  };

  const handleCenterViewportOnNode = () => {
    return new Promise((resolve) => {
      const targetNode =
        nodes.find((node) => node.id === customTourData?.modelNodeId) ||
        nodes.find((node) => node.type === 'flux_fast');
      if (targetNode) {
        centerViewportOnNode(targetNode);
      }
      setTimeout(resolve, 1000);
    });
  };

  useEffect(() => {
    registerPrehookFunction('openDrawer', openDrawer);
    registerPrehookFunction('closeDrawer', closeDrawer);
    registerPrehookFunction('handleCenterViewportOnNode', handleCenterViewportOnNode);
    setTourReadiness((prev) => ({ ...prev, prehook: true }));
  }, [customTourData]);

  /// END OF TOUR RELATED

  return (
    <>
      <ModelRunContext.Provider
        value={{ modelRunTrigger, updateModelRunTriggerStatus, handleResetTrigger, stopSequence }}
      >
        <NodeActionsProvider
          deleteNode={deleteNode}
          duplicateNode={duplicateNodeById}
          toggleLockNode={toggleLockNode}
          openOverlayDialog={openOverlayDialog}
          closeOverlayDialog={closeOverlayDialog}
          setCoverImage={setUserSetPoster}
        >
          <Box id="react-flow-container" sx={{ width: '100%', height: '100%' }} ref={flowRef}>
            {!isLoadingRecipe && (
              <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                // onConnectStart={onConnectStart}
                // onConnectEnd={onConnectEnd}
                // onNodesDelete={onNodesDelete}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                fitView={nodes.length > 1 && !activeTour}
                fitViewOptions={{ padding: 0.2, maxZoom: 1, duration: 1000 }}
                defaultViewport={{ x: 0, y: 0, zoom: nodes.length > 1 ? 0.05 : 0.5 }}
                onConnect={onConnect}
                style={{ background: color.Dark_BG }}
                nodeTypes={staticNodeTypesComponent}
                edgeTypes={edgeTypes}
                snapToGrid={true}
                selectionMode="partial"
                snapGrid={[10, 10]}
                minZoom={0.1}
                maxZoom={3}
                panOnDrag={selectOnDrag ? [1, 2] : [0, 1]}
                panOnScroll={userPrefOnScroll !== undefined ? userPrefOnScroll : true}
                panOnScrollSpeed={1.5}
                zoomOnDoubleClick={false}
                selectionOnDrag={selectOnDrag}
                /// PERMISSIONS
                nodesDraggable={role !== 'guest'}
                nodesConnectable={role !== 'guest'}
                edgesUpdatable={role !== 'guest'}
                deleteKeyCode={role !== 'guest' ? ['Backspace', 'Delete'] : null}
                // elementsSelectable={role !== 'guest'}
                onNodeDrag={onNodeDrag}
                onNodeDragStop={onNodeDragStop}
                onContextMenu={(e) => handleFloatMenu(e)}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onMove={(event, viewport) => {
                  if (activeTour === TourKeys.NavigationTour) {
                    setViewportProps(viewport);
                  }
                }}
              >
                <Background color={color.Yambo_Black_Stroke} variant="dots" />
                <Panel position="top" style={{ width: '100%', background: color.Dark_Blue, margin: '0px' }}>
                  <FlowNavbar
                    gotoDashboard={handleGoToDashboard}
                    recipeData={recipeData}
                    setRecipeData={setRecipeData}
                    isSaving={isSaving}
                    savingBeforeExit={savingBeforeExit}
                    isProcessing={isProcessing}
                    stopSequence={stopSequence}
                    runSelected={runSelected}
                    modelRunTrigger={modelRunTrigger}
                    finishedProcessing={finishedProcessing}
                    processingProgress={processingProgress}
                    user={user}
                    recipeId={recipeId}
                    selectOnDrag={selectOnDrag}
                    setSelectOnDrag={setSelectOnDrag}
                    addNewNode={addNewNode}
                    menuItems={enrichedMenu}
                    duplicateRecipe={handleDuplicateRecipe}
                    flowViewingMode={flowViewingMode}
                    setFlowViewingMode={setFlowViewingMode}
                    shouldShowDesignAppToggle={shouldShowDesignAppToggle}
                  />
                </Panel>
                {/* All panels for editor (not guest) */}
                {!isDesignApp && role === 'editor' && (
                  <>
                    <Panel
                      id="left-panel-panel"
                      position="left"
                      style={{
                        height: `calc(100% - ${NAVBAR_HEIGHT}px - 2*${MENU_MAGRINS}px)`,
                        width: !inEditingMode && showSidebars && selectedMenu ? `${TOOLBAR_WIDTH}px` : '0px',
                        top: `calc(${NAVBAR_HEIGHT}px + ${MENU_MAGRINS}px)`,
                        left: `calc(${MENU_MAGRINS}px + ${TOOLBAR_MENU_WIDTH}px)`,
                        margin: 0,
                        overflowX: 'hidden',
                        // display: !inEditingMode && showSidebars && selectedMenu ? 'block' : 'none'
                        transition: 'width 0.1s',
                      }}
                    >
                      <LeftPanel
                        menuItems={enrichedMenu}
                        selectedMenu={selectedMenu}
                        mediaArray={mediaArray}
                        shouldHidePanel={inEditingMode}
                      />
                    </Panel>
                    {/* { selectedNodes && selectedNodes.filter(n => n.isModel === true).length > 1 &&  */}
                    <Panel
                      position="bottom-center"
                      style={{
                        opacity:
                          !activeTour && selectedNodes && selectedNodes.filter((n) => n.isModel === true).length > 0
                            ? 1
                            : 0,
                        transition: 'opacity .1s ease-in',
                      }}
                    >
                      <RunBatchButton
                        isProcessing={isProcessing}
                        stopSequence={stopSequence}
                        runSelected={runSelected}
                        modelRunTrigger={modelRunTrigger}
                        finishedProcessing={finishedProcessing}
                        processingProgress={processingProgress}
                        disable={!selectedNodes || selectedNodes.filter((n) => n.isModel === true).length === 0}
                      />
                    </Panel>
                    {/* } */}

                    <Panel
                      id="left_tool_menu"
                      position="left"
                      style={{
                        height: `calc(100% - ${NAVBAR_HEIGHT}px - 2*${MENU_MAGRINS}px)`,
                        width: `${TOOLBAR_MENU_WIDTH}px`,
                        left: `${MENU_MAGRINS}px`,
                        top: `calc(${NAVBAR_HEIGHT}px + ${MENU_MAGRINS}px)`,
                        margin: 0,
                      }}
                    >
                      <LeftToolMenu selectedItem={selectedMenu} setSelectedItem={setSelectedMenu} />
                    </Panel>

                    {role === 'editor' && (
                      <Panel>
                        {floatMenu.isOpen && (
                          <FloatMenu
                            mouseX={floatMenu.mouseX}
                            mouseY={floatMenu.mouseY}
                            isOpen={floatMenu.isOpen}
                            onClose={handleFloatMenuClose}
                            addNewNode={addNewNode}
                            menuItems={enrichedMenu}
                          />
                        )}
                      </Panel>
                    )}
                    <Panel position="top-center">
                      {showConsole && (
                        <ModelRunConsole
                          runningModels={modelRunTrigger}
                          nodes={nodes}
                          onCloseConsole={handleCloseConsole}
                        />
                      )}
                    </Panel>

                    {inEditingMode && (
                      <Panel
                        id="editor-panel"
                        position="right"
                        style={{
                          height: 'calc(100% - 48px)',
                          width: '100%',
                          top: '48px',
                          margin: 0,
                          zIndex: 101,
                          background: `${color.Yambo_BG}A0`,
                          opacity: inEditingMode && showSidebars ? 1 : 0,
                          transition: 'opacity 0.5s',
                        }}
                      >
                        <Box
                          className={inEditingMode && showSidebars ? 'slide-right-enter' : 'slide-right-exit'}
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            right: 0,
                            width: '80%',
                          }}
                        >
                          <Editor />
                        </Box>
                      </Panel>
                    )}

                    {/* <Controls style={{
          left: inEditingMode ?'0px' :`calc(${MENU_MAGRINS}px + ${TOOLBAR_MENU_WIDTH}px + ${TOOLBAR_WIDTH}px)`
          }}/> */}
                    {selectedNodes.length > 0 &&
                      selectedNodes.some(
                        (n) =>
                          n.type === 'wildcardV2' ||
                          n.type === 'custommodelV2' ||
                          n.type === 'sd_inpaint' ||
                          n.type === 'sd_outpaint' ||
                          n.type === 'sd_sketch' ||
                          n.type === 'sd_text2image' ||
                          n.type === 'sd_upscale' ||
                          n.type === 'sd_img2video' ||
                          n.type === 'image2image' ||
                          n.type === 'midjourney' ||
                          n.type === 'br_text2image' ||
                          n.type === 'br_vector' ||
                          n.type === 'masks' ||
                          n.type === 'comfy' ||
                          n.type === 'flux_pro' ||
                          n.type === 'flux_fast' ||
                          n.type === 'flux_lora' ||
                          n.type === 'ig_text2image' ||
                          n.type === 'sd_image23d' ||
                          n.type === 'nim_cc' ||
                          n.type === 'luma_video' ||
                          n.type === 'rw_video' ||
                          n.type === 'mochiv1' ||
                          n.type === 'kling' ||
                          // n.type === 'minimax_i2v' || // no params exsposed yet by FAL
                          n.type === 'meshy_image23d' ||
                          n.type === 'any_llm' ||
                          n.type === 'prompt_enhance' ||
                          n.type === 'multilora',
                      ) && (
                        <Panel
                          position="right"
                          style={{
                            height: `calc(100% - ${NAVBAR_HEIGHT}px - 2*${MENU_MAGRINS}px)`,
                            width: `${TOOLBAR_WIDTH}px`,
                            top: `calc(${NAVBAR_HEIGHT}px + ${MENU_MAGRINS}px)`,
                            right: `${MENU_MAGRINS}px`,
                            margin: 0,
                            zIndex: 100,
                          }}
                          className={
                            selectedNodes.length > 0 && showSidebars ? 'slide-right-enter' : 'slide-right-exit'
                          }
                        >
                          <PropertiesDrawer
                            selectedNodes={selectedNodes}
                            updateNodeData={updateNodeData}
                            container="drawer"
                            createNewNodeFromParamExpose={createNewNodeFromParamExpose}
                            deleteEdgeByTargetHandleId={deleteEdgeByTargetHandleId}
                          />
                        </Panel>
                      )}
                  </>
                )}
                {showGallery && (
                  <Panel
                    id="media-gallery-panel"
                    style={{
                      width: '100%',
                      height: '100%',
                      background: `${color.Yambo_BG}99`,
                      backdropFilter: `blur(${BLUR_AMOUNT}px)`,
                      WebkitBackdropFilter: `blur(${BLUR_AMOUNT}px)`,
                      top: 0,
                      margin: 0,
                      zIndex: 1002,
                    }}
                    className="fade-in"
                  >
                    <MediaGallery />
                  </Panel>
                )}
                {showDialog && (
                  <Panel
                    id="dialog-panel"
                    style={{
                      width: '100%',
                      height: '100%',
                      top: 0,
                      margin: 0,
                      zIndex: 1002,
                    }}
                    className="fade-in"
                  >
                    <RenameNodeDialog
                      dialogData={dialogData}
                      updateNodeData={updateNodeData}
                      closeOverlayDialog={closeOverlayDialog}
                    />
                  </Panel>
                )}
                {flowViewingMode === FlowMode.App && (
                  <Panel
                    id="design-app-panel"
                    style={{
                      width: '100%',
                      height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
                      background: `${color.Yambo_BG}99`,
                      backdropFilter: `blur(${BLUR_AMOUNT}px)`,
                      WebkitBackdropFilter: `blur(${BLUR_AMOUNT}px)`,
                      top: `${NAVBAR_HEIGHT}px`,
                      margin: 0,
                      zIndex: 9999,
                    }}
                    className="fade-in"
                  >
                    <DesignApp
                      recipeId={recipeId}
                      recipeData={recipeData}
                      setRecipeData={setRecipeData}
                      nodes={nodes}
                      edges={edges}
                      readOnly={viewingVersionMode}
                      updateNodeData={updateNodeData}
                      saveDesignAppMetadata={handleSave}
                    />
                  </Panel>
                )}
                {flowViewingMode === FlowMode.Workflow && (viewingVersionMode || role !== 'editor') && (
                  <Panel position="top-center" style={{ top: `${NAVBAR_HEIGHT}px`, margin: 0, marginTop: '8px' }}>
                    <ReadOnlyPanel
                      recipeId={recipeId}
                      width={viewingVersionMode ? READ_ONLY_PANEL_EDITOR_WIDTH : READ_ONLY_PANEL_GUEST_WIDTH}
                      viewingVersionMode={viewingVersionMode}
                      height={READ_ONLY_PANEL_HEIGHT}
                      duplicateRecipe={duplicateRecipe}
                    />
                  </Panel>
                )}
                {/* end of panels for editor (not guest) */}

                {/* <MiniMap nodeStrokeWidth={3} maskStrokeColor='#f00' nodeStrokeColor='#F00' maskColor='rgba(10,10,10)' nodeColor={color.Dark_Blue}/> */}
              </ReactFlow>
            )}
          </Box>
          <FlowTour
            activeTour={activeTour}
            currentStep={currentStep}
            finishedPanTourStep={finishedPanTourStep}
            finishedZoomTourStep={finishedZoomTourStep}
            finishedAddNodeStep={finishedAddNodeStep}
          />
        </NodeActionsProvider>
      </ModelRunContext.Provider>
    </>
  );
}

export default Flow;
