import { Box, Typography, Popover, IconButton, Chip, Tooltip } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { color } from '../../../../colors';
import I18N_KEYS from '../../../../language/keys';

const ExpandableText = ({ text, maxChars = 300 }) => {
  const [expanded, setExpanded] = useState(false);

  const displayText = useMemo(() => {
    if (text.length <= maxChars || expanded) {
      return text;
    }

    return text.slice(0, maxChars).trim() + '...';
  }, [text, maxChars, expanded]);

  const handleToggle = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Typography variant="caption" sx={{ fontStyle: 'italic', fontWeight: '400' }}>
        {displayText}
        {text.length > maxChars && !expanded && (
          <React.Fragment>
            {' '}
            <a
              href="#"
              onClick={handleToggle}
              style={{
                color: 'inherit',
                textDecoration: 'underline',
                fontStyle: 'italic',
                fontWeight: '400',
              }}
            >
              Show more
            </a>
          </React.Fragment>
        )}
        {expanded && (
          <React.Fragment>
            {' '}
            <a
              href="#"
              onClick={handleToggle}
              style={{
                color: 'inherit',
                textDecoration: 'underline',
                fontStyle: 'italic',
                fontWeight: '400',
              }}
            >
              Show less
            </a>
          </React.Fragment>
        )}
      </Typography>
    </Box>
  );
};

const CustomChip = ({ param, value }) => {
  const { t: translate } = useTranslation();
  const posthog = usePostHog();
  const [formattedParam, setFormattedParam] = useState();
  const [copied, setCopied] = useState(false);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(value);
      posthog.capture('gallery_params_copied_param', { param: param });
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 500);
    } catch (err) {
      console.error('Error copying text: ', err);
    }
  };

  useEffect(() => {
    setFormattedParam(() => {
      return param
        .split('_') // Split by underscore
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' ');
    });
  }, []);

  return (
    <Tooltip title={translate(I18N_KEYS.GENERAL.COPIED)} open={copied} disableHoverListener placement="top">
      <Chip
        size="small"
        sx={{ mb: 1, mr: 1 }}
        label={
          <>
            <Typography variant="caption">
              {formattedParam}: <b>{value}</b>
            </Typography>
          </>
        }
        onClick={handleClick}
      />
    </Tooltip>
  );
};

function GalleryParams({ inputs, selected }) {
  const posthog = usePostHog();
  const { t: translate } = useTranslation();

  const [sortedParams, setSortedParams] = useState();
  const [prompt, setPrompt] = useState();
  const [negativePrompt, setNegativePrompt] = useState();
  const [seed, setSeed] = useState();
  const [promptCopied, setPromptCopied] = useState(false);
  const [negativePromptCopied, setNegativePromptCopied] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpenParams = (event) => {
    setAnchorEl(event.currentTarget);
    posthog.capture('gallery_params_opened');
  };

  const handleCloseParams = () => {
    posthog.capture('gallery_params_closed');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'params-popover' : undefined;

  useEffect(() => {
    if (inputs) {
      const negative = Object.entries(inputs).find(([key]) => key.includes('prompt') && key.includes('negative'))?.[1];

      // first look for an exact match
      const inputPrompt =
        Object.entries(inputs).find(([key]) => key === 'prompt') ||
        Object.entries(inputs).find(([key]) => key.includes('prompt') && !key.includes('negative'));

      const filtered = Object.fromEntries(
        Object.entries(inputs).filter(([key]) => key !== inputPrompt?.[0] && !key.includes('seed')),
      );
      const sortedEntries = Object.fromEntries(
        Object.entries(filtered).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
      );
      setSortedParams(sortedEntries);

      const seedParam = Object.entries(inputs).find(([key]) => key.includes('seed'))?.[1];
      if (seedParam) {
        setSeed(seedParam);
      }

      setPrompt(inputPrompt?.[1]);
      setNegativePrompt(negative);
    }
  }, [inputs, selected]);

  const copyPromptToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      posthog.capture('gallery_params_copied_prompt');
      setPromptCopied(true);
      setTimeout(() => {
        setPromptCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Error copying text: ', err);
    }
  };
  const copyNegativePromptToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      posthog.capture('gallery_params_copied_negative_prompt');
      setNegativePromptCopied(true);
      setTimeout(() => {
        setNegativePromptCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Error copying text: ', err);
    }
  };

  return (
    <>
      <Box
        onClick={handleClickOpenParams}
        sx={{ width: 'fit-content', fontSize: '12px', p: 1, ml: -1, cursor: 'pointer' }}
      >
        {open ? (
          <>
            <i className="fa-light fa-circle-x fa-lg"></i>&nbsp;&nbsp;
            {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.HIDE_INFO)}
          </>
        ) : (
          <>
            <i className="fa-light fa-circle-plus fa-lg"></i>&nbsp;&nbsp;
            {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.SHOW_INFO)}
          </>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseParams}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ '& .MuiPopover-paper': { width: '400px' } }}
        disableAutoFocus
        disableEnforceFocus
      >
        <Box sx={{ background: color.Yambo_BG, p: 2 }}>
          {prompt && (
            <Box id="gallery-param-prompt" sx={{ position: 'relative' }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.PROMPT)}
              </Typography>
              <ExpandableText text={prompt?.value ? prompt.value : prompt} />{' '}
              {/* support both prompt and prompt.value coming from LLM results */}
              <Tooltip
                title={translate(I18N_KEYS.GENERAL.COPIED)}
                open={promptCopied}
                disableHoverListener
                placement="top"
              >
                <Box id="gallery-params-copy-prompt" style={{ position: 'absolute', top: 2, right: 2 }}>
                  {!promptCopied ? (
                    <i
                      className="fa-light fa-copy fade-in-slow"
                      style={{ cursor: 'pointer' }}
                      onClick={() => copyPromptToClipboard(prompt?.value ? prompt.value : prompt)}
                    ></i>
                  ) : (
                    <i className="fa-light fa-check fade-in-slow"></i>
                  )}
                </Box>
              </Tooltip>
            </Box>
          )}
          {negativePrompt && (
            <Box id="gallery-param-n-prompt" sx={{ position: 'relative' }}>
              <Typography variant="body2" sx={{ my: 1 }}>
                {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.NEGATIVE_PROMPT)}
              </Typography>
              <ExpandableText text={negativePrompt} />
              <Tooltip
                title={translate(I18N_KEYS.GENERAL.COPIED)}
                open={negativePromptCopied}
                disableHoverListener
                placement="top"
              >
                <Box id="gallery-params-copy-n-prompt" style={{ position: 'absolute', top: 2, right: 2 }}>
                  {!negativePromptCopied ? (
                    <i
                      className="fa-light fa-copy fade-in-slow"
                      style={{ cursor: 'pointer' }}
                      onClick={() => copyNegativePromptToClipboard(negativePrompt)}
                    ></i>
                  ) : (
                    <i className="fa-light fa-check fade-in-slow"></i>
                  )}
                </Box>
              </Tooltip>
            </Box>
          )}
          {seed && (
            <Box id="gallery-param-seed" sx={{ mt: 1, mb: -1 }}>
              <CustomChip param={'Seed'} value={seed} />
            </Box>
          )}
          {sortedParams && Object.entries(sortedParams).filter(([param, value]) => param !== 'file').length > 0 && (
            <>
              <Typography variant="body2" sx={{ my: 1 }}>
                {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.PARAMETERS)}
              </Typography>
              {Object.entries(sortedParams).map(([param, value]) => {
                if (typeof value === 'object' || value === '' || value == null) return;

                return (
                  <CustomChip key={param} param={param} value={typeof value === 'boolean' ? value.toString() : value} />
                );
              })}
            </>
          )}
        </Box>
      </Popover>
    </>
  );
}

export default GalleryParams;
