import {
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { color } from '../../colors';
import I18N_KEYS from '../../language/keys';
import { useTranslation } from 'react-i18next';
function SettingsDrawer({ width, settingsList, selectedSection, setSelectedSection }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const backToHome = () => {
    navigate('/');
  };

  const handleListItemClick = (section) => {
    setSelectedSection(section);
  };
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width },
      }}
      open
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box id="settings-drawer-upper-container">
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', p: 2 }}>
            <ButtonBase onClick={backToHome}>
              <Box>
                <i className="fa-light fa-arrow-right fa-flip-horizontal" style={{ marginRight: '8px' }}></i>
                <i className="fa-light fa-house"></i>
              </Box>
            </ButtonBase>
          </Box>
          {/* <Box id="settings-drawer-user" sx={ { height:'56px', display:'flex', justifyContent:"center", alignItems:'center' } }>
          <ButtonBase onClick={ handleOpenUserMenu }>
            <Avatar alt="user.displayName" src={ user.photoURL } sx={ { width:'30px', height:'30px', mr:1 } } />
            <Box sx={ { display:'flex', flexDirection:'column' } }>
              <Typography variant="body2">{user.displayName}</Typography>
              <Typography variant="caption">{user.email}</Typography>
            </Box>
            <ArrowDropDownIcon sx={ { ml:1 } } />
          </ButtonBase>
        </Box> */}
          {/* <Typography sx={ { pt:1, px:2 } }>Settings</Typography> */}
          <Typography sx={{ mb: 1, px: 2, display: 'block' }}>{translate(I18N_KEYS.SETTINGS.TITLE)}</Typography>
          <List sx={{ py: 0 }}>
            {settingsList.map((item) => (
              <ListItem
                key={item.menuLabel}
                disablePadding
                sx={{
                  background: item.section === selectedSection ? color.Super_Light_Transparent : '',
                }}
              >
                <ListItemButton onClick={() => handleListItemClick(item.section)} disabled={item.disable}>
                  <ListItemIcon sx={{ textAlign: 'center' }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.menuLabel}
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontWeight: item.section === selectedSection ? 'bold' : 'normal',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
}

export default SettingsDrawer;
