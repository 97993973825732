import { useCallback, useEffect, useRef, useState } from 'react';
import { WasmAPI, initWasm } from './WasmAPI';
import { CompositorNode } from './Compositor';

interface DesignerProps {
  update: (data: CompositorNode) => void;
  data: any /* FIXME */;
  width: number;
  height: number;
}

export default function Designer({ data, update, width, height }: DesignerProps) {
  const [wasmAPI, setWasmAPI] = useState<WasmAPI>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const onChange = useCallback(
    (newData: CompositorNode | null) => {
      if (newData) {
        update(newData);
      }
    },
    [update],
  );

  useEffect(() => {
    const dispose = () => {
      wasmAPI?.dispose();
      setWasmAPI(undefined);
    };

    if (wasmAPI) return; // WasmAPI already created
    initWasm().then(() => {
      if (!canvasRef.current) return; // No canvas
      try {
        setWasmAPI(new WasmAPI(canvasRef.current, onChange, dispose));
      } catch (e) {
        console.error('Error creating WasmAPI: ' + e);
        dispose();
      }
      return dispose;
    });
  }, [onChange, wasmAPI]);

  useEffect(() => {
    if (!wasmAPI) return;

    wasmAPI.importCompositorNode(data);

    Object.values(data.layers).forEach((layer: unknown) => {
      const typedLayer = layer as { type: string; url: string };
      if (typedLayer.type === 'image' && typedLayer.url != '') {
        fetchImage(typedLayer.url).then((img) => {
          wasmAPI?.addImageResource(typedLayer.url, img);
        });
      }
    });
  }, [data, wasmAPI]);

  useEffect(() => {
    if (wasmAPI) {
      wasmAPI.setCallback(onChange);
    }
  }, [wasmAPI, onChange]);

  return (
    <canvas
      ref={canvasRef}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      width={width}
      height={height}
    />
  );
}

function fetchImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Failed to load image: ' + url));
    img.src = url;
    img.crossOrigin = 'Anonymous';
  });
}
