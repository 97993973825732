import type { SvgComponent } from '../../types/SvgComponent';

const WeavyLogo: SvgComponent = ({ title, ...props }) => {
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title ? <title>{title}</title> : null}
      <path
        id="Layer"
        style={{ fill: 'none', stroke: '#ffffff', strokeWidth: 4 }}
        d="m2 18.8c0-8.8 7.2-16 16-16 8.8 0 16 7.2 16 16 0 8.9-7.2 16-16 16-8.8 0-16-7.1-16-16z"
      />
      <path
        id="Layer"
        fillRule="evenodd"
        style={{ fill: '#ffffff' }}
        d="m19.5 12.5l1.7 7.6 1.5-7.6h3.1l-3 12.7h-3.1l-1.7-7.7-1.6 7.7h-3.2l-3-12.7h3.1l1.6 7.6 1.6-7.6z"
      />
    </svg>
  );
};

export default WeavyLogo;
