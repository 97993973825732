import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_KEYS } from '../../language/keys';
import { color } from '../../colors';

function ConfirmationDialog({ open, onClose, onConfirm, onCancel, title, message, confirmText, cancelText }) {
  const { t: translate } = useTranslation();
  return (
    <Dialog onClose={onClose} open={open} sx={{ zIndex: 1600 }}>
      <Box sx={{ backgroundColor: color.Dark_Blue, p: 1 }}>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="weavy_cta" onClick={onCancel}>
            {cancelText || translate(I18N_KEYS.GENERAL.CANCEL)}
          </Button>
          <Button color="weavy_cta_secondary" onClick={onConfirm}>
            {confirmText || translate(I18N_KEYS.GENERAL.CONFIRM)}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ConfirmationDialog;
