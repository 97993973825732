import React, { useEffect, useState, useRef } from 'react';
import { Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { color } from '../../../../../colors';
import axiosInstance from '../../../../../services/axiosConfig';
import config from './config.json';

function Photopea({ src, psdSrc, result, setResult, onClose, setIsLoading }) {
  const shouldClose = useRef(false);
  const [iframeUrl, setIframeUrl] = useState();
  const [error, setError] = useState(false);
  const saveAsPSD = `app.activeDocument.saveToOE("psd")`;
  const photopeaReady = useRef(false);
  const stage = useRef(0);
  const layerCountScript = `
        function cnt(d) { var r=0; if (d.layers) { for (var i=0; i<d.layers.length; i++)  r+=cnt(d.layers[i])+1; } return r; }
        app.echoToOE("layerCount=" + (app.documents.length == 0 ? 0 : cnt(app.activeDocument)));
    `;

  const openSmartObjectLayer = () => {
    const script = `
        // TODO - add selection of layer.
        app.activeDocument = app.documents[0];
        app.activeDocument.activeLayer = app.activeDocument.artLayers.getByName("Background");
        executeAction(stringIDToTypeID("placedLayerEditContents")); 
        `;
    const iframe = document.getElementById('pp-editor');
    if (iframe) {
      iframe.contentWindow.postMessage(script, '*');
    } else {
      console.log('Iframe not found');
    }
  };

  const uploadSuccess = (data) => {
    setResult(data);
  };

  const uploadFileToServer = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob, 'image.psd');

    try {
      const response = await axiosInstance.post(`/v1/nodes/upload`, formData);
      uploadSuccess(response.data);
    } catch (uploadError) {
      setIsLoading(false);
      console.error(uploadError); // Handle any errors
      setError(uploadError);
    }
  };

  const triggerPhotopeaSave = () => {
    setIsLoading(true);
    setError(false);
    const iframe = document.getElementById('pp-editor');
    const photopeaWindow = iframe.contentWindow;
    photopeaWindow.postMessage(saveAsPSD, '*');
    shouldClose.current = true;
  };

  useEffect(() => {
    const configCopy = { ...config };
    configCopy['files'] = psdSrc ? [psdSrc] : [src];
    const encodedConfig = encodeURIComponent(JSON.stringify(configCopy));
    const fullUrl = `https://www.photopea.com/#${encodedConfig}`;
    setIframeUrl(fullUrl);
    setIsLoading(true);
  }, [src, psdSrc]);

  const convertBackgroundToSmartObject = () => {
    // console.log("converting");
    const script = `
          var doc = app.activeDocument;
          // doc.activeLayer.name = "Image Smart Object";
          var idnewPlacedLayer = stringIDToTypeID( 'newPlacedLayer' );
          executeAction(idnewPlacedLayer, undefined, DialogModes.NO);
      `;
    const iframe = document.getElementById('pp-editor');
    if (iframe) {
      iframe.contentWindow.postMessage(script, '*');
    } else {
      console.log('Iframe not found');
    }
  };

  const loadImage = (imageUrl) => {
    // opens a new doc
    const script = `
        app.open("${imageUrl}");
        `;
    const iframe = document.getElementById('pp-editor');
    if (iframe) {
      iframe.contentWindow.postMessage(script, '*');
    } else {
      console.log('Iframe not found');
    }
  };

  const copyAndPasteIntoSO = () => {
    const script = `
        var srcDoc = app.documents[app.documents.length - 1];
        var smartObjectDoc = app.documents[1];
        var psdDoc = app.documents[0];
        // app.activeDocument = srcDoc;
        srcDoc.selection.selectAll();
        srcDoc.selection.copy();
        srcDoc.close(SaveOptions.DONOTSAVECHANGES);
        smartObjectDoc.paste();
        smartObjectDoc.artLayers.getByName("Background").remove();
        smartObjectDoc.activeLayer.name = "Background";
        smartObjectDoc.save();
        smartObjectDoc.close(SaveOptions.DONOTSAVECHANGES);
      `;
    const iframe = document.getElementById('pp-editor');
    if (iframe) {
      iframe.contentWindow.postMessage(script, '*');
    } else {
      console.log('Iframe not found');
    }
  };

  useEffect(() => {
    let documentCheckInterval;

    const handleMessage = async (event) => {
      if (event.origin === 'https://www.photopea.com') {
        if (event.data instanceof ArrayBuffer) {
          setIsLoading(true);
          const blob = new Blob([event.data], { type: 'application/octet-stream' });
          uploadFileToServer(blob);
        } else if (event.data === 'done') {
          if (!photopeaReady.current) {
            photopeaReady.current = true;
            documentCheckInterval = setInterval(() => {
              const iframe = document.getElementById('pp-editor');
              if (iframe) {
                iframe.contentWindow.postMessage(layerCountScript, '*');
              }
            }, 1000);
          }
        } else if (typeof event.data === 'string') {
          // console.log("Message from Photopea:", event.data);
          if (psdSrc) {
            if (event.data.startsWith('layerCount=')) {
              const layerCount = parseInt(event.data.split('=')[1]);
              if (stage.current === 0 && layerCount > 0) {
                // psdSrc loaded.
                stage.current++;
                // open the smart layer
                openSmartObjectLayer();
              } else if (stage.current === 1 && layerCount > 0) {
                // Smart object layer opened as new file
                stage.current++;
                // open the src file
                loadImage(src);
              } else if (stage.current === 2 && layerCount > 0) {
                // Src file is opened in a new doc
                stage.current++;
                // copy the layer from src and paste back into smart layer doc.
                copyAndPasteIntoSO();
              } else if (stage.current === 3 && layerCount > 0) {
                clearInterval(documentCheckInterval);
                setIsLoading(false);
              }
            }
          } else {
            if (event.data.startsWith('layerCount=')) {
              const layerCount = parseInt(event.data.split('=')[1]);
              if (stage.current === 0 && layerCount > 0) {
                stage.current++;
                convertBackgroundToSmartObject();
              } else {
                setIsLoading(false);
                clearInterval(documentCheckInterval);
              }
            }
          }
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      if (documentCheckInterval) {
        clearInterval(documentCheckInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (shouldClose.current) {
      onClose();
    }
  }, [result]);

  return (
    <Box id="photopea-container" sx={{ width: '100%', height: '100%', position: 'relative' }}>
      {iframeUrl && (
        <>
          <iframe
            id="pp-editor"
            src={iframeUrl}
            width="100%"
            height="100%"
            style={{ borderColor: 'transparent' }}
          ></iframe>
          <Box
            sx={{
              position: 'absolute',
              top: '-2px',
              left: '-45px',
              display: 'flex',
              alignItems: 'center',
              p: 0.5,
            }}
          >
            <Button
              color="weavy_cta"
              onClick={triggerPhotopeaSave}
              sx={{ p: 1, minWidth: '0' }}
              variant="contained"
              size="small"
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 2,
              right: 2,
              width: 'calc(100% - 300px)',
              background: '#474747',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              p: 0.5,
            }}
          >
            {error && (
              <Box sx={{ color: color.Yambo_Orange_Stroke }}>
                ❗ Could not save your file. Pleae decrease the image size and try again... ❗
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default Photopea;

// const removeBackgroundLayer = () => {
//   console.log("removing background layer");
//   const script = `
//       var layer = app.activeDocument.artLayers.getByName("Background");
//       layer.remove();
//       app.activeDocument.activeLayer.name = "Background";
//   `;
//   const iframe = document.getElementById('pp-editor');
//   if (iframe) {
//       iframe.contentWindow.postMessage(script, '*');
//   } else {
//       console.log('Iframe not found');
//   }
// };
// const replaceBackgroundImage = (imageUrl) => {
//   const script = `
//     var doc = app.activeDocument;
//     var layer = doc.activeLayer;
//     if (layer.kind === LayerKind.SMARTOBJECT) {
//         app.echoToOE("start replace ${imageUrl}");

//     } else {
//         app.echoToOE("Active layer is not a smart object");
//     }
//     `;
//   const iframe = document.getElementById('pp-editor');
//   if (iframe) {
//       iframe.contentWindow.postMessage(script, '*');
//   } else {
//       console.log('Iframe not found');
//   }
// };
