import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { fabric } from 'fabric';
import CropFreeIcon from '@mui/icons-material/CropFree';
import BrushIcon from '@mui/icons-material/Brush';
import { color } from '../../../../colors';
import { runModelFromEditor } from '../../../Nodes/RunModel';
import useCanvasPanAndZoom from './usePanAndZoom';
import { enableCustomCursor, disableCustomCursor, setCursorRadius } from './CustomCursor';

function Inpainter({ src, onClose }) {
  const [canvas, setCanvas] = useState();
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const inpainterRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  useCanvasPanAndZoom(canvas, inpainterRef);

  // / make sure the container is the active element
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inpainterRef.current) {
        inpainterRef.current.focus();
      }
    }, 800); // Adjust the delay as needed

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, []);

  const handleResetZoom = useCallback(() => {
    if (canvas) {
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.renderAll();
    }
  }, [canvas]);

  /// init canvas
  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      backgroundColor: 'black',
      width: containerRef.current.offsetWidth,
      height: containerRef.current.offsetHeight,
    });
    setCanvas(fabricCanvas);
  }, []);

  const setCanvasBackgroundImage = useCallback(
    (backgroundImage) => {
      if (canvas && backgroundImage) {
        fabric.Image.fromURL(backgroundImage, (img) => {
          const canvasAspect = canvas.width / canvas.height;
          const imageAspect = img.width / img.height;
          let scale = 1;
          if (canvasAspect >= imageAspect) {
            scale = canvas.height / img.height;
          } else {
            scale = canvas.width / img.width;
          }

          img.scale(scale);
          img.set({
            left: canvas.width / 2,
            top: canvas.height / 2,
            originX: 'center',
            originY: 'center',
          });

          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            crossOrigin: 'Anonymous',
          });
          canvas.renderAll();
        });
      }
    },
    [canvas],
  );

  useEffect(() => {
    setCanvasBackgroundImage(src);
  }, [src, canvas]);

  const toggleDrawingMode = () => {
    if (canvas) {
      const newDrawingMode = !canvas.isDrawingMode;
      canvas.isDrawingMode = newDrawingMode;
      setIsDrawing(newDrawingMode);
      canvas.defaultCursor = newDrawingMode ? 'none' : 'default';

      if (newDrawingMode) {
        enableCustomCursor(containerRef.current);
        setCursorRadius(10); // Example usage: set cursor radius to 10 (adjust as needed)
      } else {
        disableCustomCursor();
      }
    }
  };

  // Configure drawing settings
  useEffect(() => {
    if (canvas) {
      canvas.freeDrawingBrush.color = 'red'; // Set the brush color
      canvas.freeDrawingBrush.width = 5; // Set the brush width
    }
  }, [canvas]);

  const handleRun = async () => {
    const input = {};
    runModelFromEditor(input, 'inpaint');
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box id="editor-navbar" sx={{ height: '40px', display: 'flex', alignItems: 'center' }}>
        <IconButton
          size="small"
          onClick={onClose}
          sx={{ width: '30px', height: '30px', p: 0.2, borderRadius: '4px', ml: 1 }}
        >
          <i className="fa-solid fa-x fa-xs"></i>
        </IconButton>
        <IconButton
          onClick={handleResetZoom}
          sx={{ width: '30px', height: '30px', p: 0.2, borderRadius: '4px' }}
          size="small"
        >
          <CropFreeIcon fontSize="sm" />
        </IconButton>
        <IconButton
          className={isDrawing ? 'selected' : ''}
          onClick={toggleDrawingMode}
          sx={{ width: '30px', height: '30px', p: 0.2, borderRadius: '4px' }}
          size="small"
        >
          <BrushIcon fontSize="sm" />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
        <Box
          sx={{
            width: 'calc(100% - 240px)',
            height: 'calc(100% - 40px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            pt: 0,
            '&:focus': { outline: 'none' },
          }}
          tabIndex="0"
          ref={inpainterRef}
        >
          <Box id="inpainter-canvas-container" ref={containerRef} sx={{ width: '100%', height: '100%' }}>
            <canvas ref={canvasRef} id="comp-node-canvas" />
          </Box>
        </Box>
        <Box id="editor-sidebar" sx={{ width: '240px', borderTop: '1px solid', borderColor: color.Dark_Grey }}>
          <Button onClick={handleRun}>run</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Inpainter;
