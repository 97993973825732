import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, IconButton, Tooltip, Menu, MenuItem, CircularProgress } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { color } from '../../../colors';
import ThreeDeeViewer from '../../Nodes/ThreeDeeViewer';
import I18N_KEYS from '../../../language/keys';
import { useNodeActions } from '../../Nodes/NodeActionsContext';
import { downloadAllToZip, downloadFile, generateViewingUrl } from '../../Nodes/Utils';
import GalleryParams from './Gallery/GalleryParams';

export const NonEditableTextarea = styled(InputBase)({
  // pointerEvents: 'none',
  userSelect: 'text',
  cursor: 'text',
  border: `1px solid ${color.Yambo_Purple_Stroke}`,
  borderRadius: '4px',
  padding: '8px',
});

const MAX_IMAGES_PER_ROW = 20;
function NodeImageList({
  nodeName,
  images,
  selected,
  setSelected,
  container,
  disabled,
  threeDProps,
  deletionFunctions,
}) {
  const { setCoverImage } = useNodeActions();
  const { deleteCurrentResult, deleteAllResults, deleteAllOthersResults } = deletionFunctions || {};

  const {
    cameraPosition = null,
    setCameraPosition = () => {},
    is3DLocked = false,
    setIs3DLocked = () => {},
    setExported3DImage = () => {},
  } = threeDProps || {};

  const { t: translate } = useTranslation();

  const posthog = usePostHog();

  const maxSteps = images.length;
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const [scale, setScale] = useState(1);
  const imgRef = useRef(null);
  const resetTransformRef = useRef(null);
  const [transformKey, setTransformKey] = useState(0);

  const [contextMenu, setContextMenu] = useState(null);
  const [rightClickedIndex, setRightClickedIndex] = useState(null);
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState(null);
  const [deleteMenuAnchorEl, setDeleteMenuAnchorEl] = useState(null);

  const videoRef = useRef(null);
  const [prevVideoRef, setPrevVideoRef] = useState(null);

  const imagesPerRow = Math.min(images.length, MAX_IMAGES_PER_ROW);
  const rows = Math.ceil(images.length / imagesPerRow);
  const isSingleRow = images.length <= imagesPerRow;

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const imagesLengthRef = useRef(images.length);

  const resetZoom = async () => {
    if (resetTransformRef.current) {
      // console.log("reseting zoom");
      await resetTransformRef.current.resetTransform();
      setScale(1);
    }
  };

  useEffect(() => {
    if (selected >= images.length) {
      setSelected(images.length - 1);
    }
  }, [images, selected]);

  // Handle context menu open
  const handleContextMenu = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    setRightClickedIndex(index);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  // Handle context menu close
  const handleContextMenuClose = () => {
    setContextMenu(null);
    setRightClickedIndex(null);
  };

  // Handle set as cover action
  const handleSetAsCover = () => {
    posthog.capture('node_image_set_as_cover_clicked');
    if (rightClickedIndex !== null && setCoverImage) {
      setCoverImage(images[rightClickedIndex]?.url);
    }
    handleContextMenuClose();
  };

  const handleDownload = async (index) => {
    posthog.capture('node_image_download_clicked');
    if (index !== null) {
      await downloadFile(images[index], `weavy-${nodeName || ''}-${index + 1}.${images[index].url.split('.').pop()}`);
    }
    handleContextMenuClose();
  };
  const handleDownloadAll = async () => {
    posthog.capture('node_image_download_all_clicked');
    await downloadAllToZip(images, nodeName);
    handleContextMenuClose();
  };

  const handleCopyLink = (index) => {
    posthog.capture('node_image_copy_link_clicked');

    if (index !== null) {
      const file = images[index];
      const url = generateViewingUrl(file);
      navigator.clipboard.writeText(url);
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (imgRef.current && images.length > 0) {
      const renderedWidth = imgRef.current.offsetWidth;
      const initialZoom = (renderedWidth / images[selected]?.width) * 100;
      setZoomPercentage(Math.round(initialZoom));
    }
  }, []);

  const calculateZoomPercentage = useCallback(
    (newScale) => {
      if (imgRef.current && images.length > 0) {
        const renderedWidth = imgRef.current.offsetWidth * newScale;
        const zoomPercent = (renderedWidth / images[selected]?.width) * 100;
        setZoomPercentage(Math.round(zoomPercent));
      }
    },
    [images, selected],
  );

  const handleNext = useCallback(async () => {
    if (disabled) return;
    if (container === 'gallery' || container === 'design-app') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      const currentLength = imagesLengthRef.current;
      const validPrevStep = Math.min(prevActiveStep, currentLength - 1);

      return (validPrevStep + 1) % currentLength;
    });
  }, [disabled, container]);

  const handleBack = useCallback(async () => {
    if (disabled) return;
    if (container === 'gallery' || container === 'design-app') await resetZoom();
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      const currentLength = imagesLengthRef.current;
      const validPrevStep = Math.min(prevActiveStep, currentLength - 1);

      return (validPrevStep - 1 + currentLength) % currentLength;
    });
  }, [disabled, container]);

  const handleArrowUp = useCallback(async () => {
    if (container === 'gallery' || container === 'design-app') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      const currentLength = imagesLengthRef.current;
      const validPrevStep = Math.min(prevActiveStep, currentLength - 1);

      return validPrevStep - imagesPerRow >= 0 ? validPrevStep - imagesPerRow : validPrevStep;
    });
  }, [disabled, container, imagesPerRow]);

  const handleArrowDown = useCallback(async () => {
    if (container === 'gallery' || container === 'design-app') {
      await resetZoom();
    }
    setTransformKey((prev) => prev + 1);
    setSelected((prevActiveStep) => {
      const currentLength = imagesLengthRef.current;
      const validPrevStep = Math.min(prevActiveStep, currentLength - 1);

      return validPrevStep + imagesPerRow < currentLength ? validPrevStep + imagesPerRow : validPrevStep;
    });
  }, [disabled, container, imagesPerRow]);

  const handleImageLoad = () => {
    // Calculate the zoom percentage when the image is fully loaded
    if (imgRef.current && images.length > 0) {
      const initialZoom = (imgRef.current.offsetWidth / images[selected]?.width) * 100;
      setZoomPercentage(Math.round(initialZoom));
    }
  };

  useEffect(() => {
    // calcualte the zoom percentage on zoom change
    if (scale) {
      calculateZoomPercentage(scale);
    }
  }, [scale]);

  useEffect(() => {
    // Reset the zoom scale when the selected image changes
    if (resetTransformRef.current) {
      resetTransformRef.current.resetTransform();
      setScale(1);
    }
    if (videoRef.current) {
      setPrevVideoRef(videoRef.current);
    }

    // Stop the previous video
    if (prevVideoRef) {
      prevVideoRef.pause();
      prevVideoRef.currentTime = 0;
    }
  }, [selected]);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return; // Exit early if user is typing in an input
      }
      if (event.key === 'ArrowRight') {
        event.stopPropagation();
        if (container === 'gallery' || container === 'design-app') handleNext();
      }
      if (event.key === 'ArrowLeft') {
        event.stopPropagation();
        if (container === 'gallery' || container === 'design-app') handleBack();
      }
      if (event.key === 'ArrowUp') {
        event.stopPropagation();
        if (container === 'gallery' || container === 'design-app') handleArrowUp();
      }
      if (event.key === 'ArrowDown') {
        event.stopPropagation();
        if (container === 'gallery' || container === 'design-app') handleArrowDown();
      }
    };
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  // Add this effect to track image array changes
  useEffect(() => {
    imagesLengthRef.current = images.length;
    if (selected >= images.length) {
      setSelected(images.length - 1);
    }
  }, [images]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        id="image-list-container"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: maxSteps > 1 && (container === 'gallery' || container === 'design-app') ? '85%' : '100%',
          width: '100%',
          mb: container === 'node' && images[selected]?.type !== 'text' ? 1 : 0,
        }}
      >
        <Box
          id="image-list-wrapper"
          ref={imgRef}
          sx={{
            maxWidth: container === 'node' ? 400 : 'none',
            width: container === 'node' ? 'auto' : '90%',
            height: container === 'node' ? 'auto' : '85%',
            margin: 'auto',
          }}
          onClick={(event) => event.stopPropagation()}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              style={{ width: '100%', height: '100%', display: index === selected ? 'block' : 'none' }}
              onContextMenu={(e) => handleContextMenu(e, index)}
            >
              {img.type && img.type.includes('image') && (
                <>
                  {container === 'node' ? (
                    <>
                      <img
                        src={img.url}
                        draggable="false"
                        style={{ width: '100%', height: '100%', display: 'block', position: 'relative' }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '10px',
                          position: 'absolute',
                          top: 5,
                          left: 5,
                          textShadow: '0px 0px 2px black',
                        }}
                      >
                        {img.width} X {img.height}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <TransformWrapper
                        style={{ width: '100%', height: '100%' }}
                        centerZoomedOut={true}
                        limitToBounds={true}
                        minScale={1}
                        pinch={{
                          step: 100, // Increase this value for more sensitivity
                        }}
                        onZoom={(ref) => {
                          setScale(ref.state.scale);
                        }}
                        ref={resetTransformRef}
                        key={transformKey}
                      >
                        <TransformComponent style={{ width: '100%', height: '100%' }}>
                          <img
                            className="media-container"
                            onLoad={handleImageLoad}
                            src={img.url}
                            draggable="false"
                            style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto' }}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </>
                  )}
                </>
              )}
              {img.type && img.type.includes('video') && (
                <>
                  <video
                    key={`video-${index}`}
                    ref={index === selected ? videoRef : null}
                    crossOrigin="anonymous"
                    draggable="false"
                    src={img.url}
                    controls
                    loop
                    style={{ width: '100%', height: '100%', display: 'block', position: 'relative' }}
                  />
                  {container === 'node' && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '10px',
                        position: 'absolute',
                        top: 5,
                        left: 5,
                        textShadow: '0px 0px 2px black',
                      }}
                    >
                      {img.width} X {img.height} {img.duration ? `| ${img.duration.toFixed(2)}s` : ''}{' '}
                      {img.fps ? `| ${img.fps} fps` : ''}
                    </Typography>
                  )}
                </>
              )}
              {index === selected &&
                img.type &&
                img.type.includes('3D') && ( // this is unique case - in order to completely re-render the 3D canvas (to solve canvas size issue)
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {container === 'node' && (
                      <Tooltip title={is3DLocked ? 'Unlock Camera' : 'Lock Camera'}>
                        <IconButton
                          onClick={() => setIs3DLocked(!is3DLocked)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1000,
                            width: '40px',
                            height: '40px',
                          }}
                        >
                          {is3DLocked ? (
                            <i className="fa-kit fa-light-camera-rotate-slash fa-xs"></i>
                          ) : (
                            <i className="fa-light fa-camera-rotate fa-xs"></i>
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                    <ThreeDeeViewer
                      key={img.url}
                      objUrl={img.url}
                      containerSize={container === 'gallery' || container === 'design-app' ? { w: 600, h: 600 } : null}
                      setExportedImage={setExported3DImage || null}
                      lockOrbit={is3DLocked || false}
                      cameraPosition={cameraPosition || { x: -3, y: 4, z: 5 }}
                      setCameraPosition={setCameraPosition || null}
                    />
                  </Box>
                )}
              {img.type && img.type.includes('audio') && (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <audio
                    crossOrigin="anonymous"
                    draggable="false"
                    src={img.url}
                    controls
                    loop
                    style={{ width: '100%', display: 'block' }}
                  />
                </Box>
              )}
              {img.type && img.type.includes('text') && (
                <Box sx={{ background: color.Yambo_Purple_Dark }}>
                  {/* <Typography variant='caption'>Result</Typography> */}
                  <Box
                    sx={{
                      color: 'white',
                      fontSize: '12px',
                      fontStyle: 'italic',
                      width: '300px',
                      mb: maxSteps > 1 ? 0 : 1, // add some margins if no scrolling arrows
                    }}
                  >
                    <NonEditableTextarea fullWidth value={img.value} multiline={true} readOnly={true} />
                  </Box>
                </Box>
              )}
              {img.type === 'rendering' && (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 2,
                    border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
                    backgroundColor: color.Yambo_BG,
                  }}
                >
                  <CircularProgress color="weavy_cta" />
                </Box>
              )}
            </Box>
          ))}
          <Menu
            id="node-gallery-context-menu"
            open={contextMenu !== null}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          >
            <MenuItem onClick={() => handleDownload(rightClickedIndex)}>
              <Typography variant="caption">{translate(I18N_KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.DOWNLOAD)}</Typography>
            </MenuItem>
            <MenuItem onClick={() => handleDownloadAll()}>
              <Typography variant="caption">
                {translate(I18N_KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.DOWNLOAD_ALL)}
              </Typography>
            </MenuItem>
            {setCoverImage && images[selected]?.type === 'image' && (
              <MenuItem onClick={() => handleSetAsCover()}>
                <Typography variant="caption">
                  {translate(I18N_KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.SET_AS_COVER)}
                </Typography>
              </MenuItem>
            )}
          </Menu>
          {(container === 'gallery' || container === 'design-app') &&
            (images[selected]?.type === 'image' || images[selected]?.type === 'video') && (
              <Box
                id="file-info-container"
                sx={{
                  position: 'absolute',
                  top: container === 'design-app' ? 10 : 20,
                  left: 20,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '10px',
                    textShadow: '0px 0px 2px black',
                  }}
                >
                  {images[selected].width} X {images[selected].height}
                  {images[selected].duration ? ` | ${images[selected].duration.toFixed(2)}s` : ''}
                  {images[selected].fps ? ` | ${images[selected].fps} fps` : ''}
                  {(container === 'gallery' || container === 'design-app') && images[selected].type === 'image'
                    ? ` | ${zoomPercentage}%`
                    : ``}
                </Typography>
                {(images[selected].params || images[selected].input) && (
                  <Box sx={{ position: 'relative' }}>
                    <GalleryParams
                      inputs={{ ...(images[selected].params || {}), ...(images[selected].input || {}) }}
                      selected={selected}
                    />
                  </Box>
                )}
              </Box>
            )}
          {container === 'design-app' && (
            <Box
              sx={{
                position: 'absolute',
                top: 4,
                right: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip
                title={isLinkCopied ? translate(I18N_KEYS.GENERAL.COPIED) : translate(I18N_KEYS.GENERAL.COPY_LINK)}
                placement="top"
              >
                <IconButton
                  sx={{ width: '28px', height: '28px' }}
                  onClick={() => handleCopyLink(selected)}
                  disabled={images[selected]?.type === 'rendering'}
                >
                  {isLinkCopied ? (
                    <i className="fa-light fa-check" style={{ fontSize: '14px' }}></i>
                  ) : (
                    <i
                      className="fa-light fa-link-simple fa-rotate-by"
                      style={{ fontSize: '14px', transform: 'rotate(-45deg)' }}
                    ></i>
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={translate(I18N_KEYS.GENERAL.DOWNLOAD)} placement="top">
                <IconButton
                  sx={{ width: '28px', height: '28px' }}
                  onClick={(e) => setDownloadMenuAnchorEl(e.currentTarget)}
                  disabled={images[selected]?.type === 'rendering'}
                >
                  <i className="fa-light fa-download" style={{ fontSize: '14px' }}></i>
                </IconButton>
              </Tooltip>
              <Menu
                id="node-gallery-download-menu"
                open={downloadMenuAnchorEl !== null}
                onClose={() => setDownloadMenuAnchorEl(null)}
                anchorEl={downloadMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDownload(selected);
                    setDownloadMenuAnchorEl(null);
                  }}
                >
                  <Typography variant="caption">{translate(I18N_KEYS.GENERAL.DOWNLOAD)}</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDownloadAll();
                    setDownloadMenuAnchorEl(null);
                  }}
                  disabled={images.some((img) => img.type === 'rendering')}
                >
                  <Typography variant="caption">
                    {translate(I18N_KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.DOWNLOAD_ALL)}
                  </Typography>
                </MenuItem>
              </Menu>
              {/* todo : server support */}
              {/* <IconButton onClick={ (e) => setDeleteMenuAnchorEl(e.currentTarget) }>
                  <i className="fa-light fa-trash" style={ { fontSize:'14px' } } ></i>
                </IconButton>
                <Menu
                  id="node-gallery-delete-menu"
                  open={ deleteMenuAnchorEl !== null }
                  onClose={ () => setDeleteMenuAnchorEl(null) }
                  anchorEl={ deleteMenuAnchorEl }
                >
                  <MenuItem onClick={ () => { deleteCurrentResult(); setDeleteMenuAnchorEl(null); } }>
                    <Typography variant='caption'>{ translate(I18N_KEYS.NODE_IMAGE_LIST.DELETE_CURRENT) }</Typography>
                  </MenuItem>
                  <MenuItem onClick={ () => { deleteAllResults(); setDeleteMenuAnchorEl(null); } }>
                    <Typography variant='caption'>{ translate(I18N_KEYS.NODE_IMAGE_LIST.DELETE_ALL) }</Typography>
                  </MenuItem>
                  <MenuItem onClick={ () => { deleteAllOthersResults(); setDeleteMenuAnchorEl(null); } }>
                    <Typography variant='caption'>{ translate(I18N_KEYS.NODE_IMAGE_LIST.DELETE_ALL_OTHERS) }</Typography>
                  </MenuItem>
                </Menu> */}
            </Box>
          )}
          {/* Node carousel */}
          {container === 'node' && !disabled && maxSteps > 1 && (
            <Box
              sx={{
                position: images[selected]?.type !== 'text' ? 'absolute' : 'relative',
                bottom: images[selected]?.type !== 'text' ? 7 : 0,
                my: images[selected]?.type !== 'text' ? 0 : 0.5,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: images[selected]?.type !== 'text' ? '#0008' : '',
              }}
              onMouseEnter={(e) => e.stopPropagation()}
            >
              <Button size="small" onClick={handleBack}>
                <KeyboardArrowLeft />
              </Button>
              <Typography variant="body2" color="common.white">
                {`${selected + 1}/${maxSteps}`}
              </Typography>
              <Button size="small" onClick={handleNext}>
                <KeyboardArrowRight />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {(container === 'gallery' || container === 'design-app') && maxSteps > 1 && (
        <Box
          sx={{
            width: '100%',
            height: '15%',

            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pb: 4,
          }}
        >
          <Box
            id="media-gallery-carousel"
            sx={{
              width: '80%',
              height: '100%',
              margin: 'auto',
              // position: 'relative',
              // bottom:0,
            }}
            onClick={(event) => event.stopPropagation()}
          >
            {Array.from({ length: rows }).map((_, rowIndex) => (
              <Box
                key={rowIndex}
                sx={{
                  height: `${100 / rows}%`, // Distribute height evenly among rows
                  width: '100%',
                  display: 'flex',
                  justifyContent: isSingleRow ? 'center' : 'flex-start',
                }}
              >
                {images.slice(rowIndex * imagesPerRow, (rowIndex + 1) * imagesPerRow).map((file, index) => {
                  const globalIndex = rowIndex * imagesPerRow + index;

                  return (
                    <Box
                      key={globalIndex}
                      sx={{
                        height: '100%',
                        width: `${100 / imagesPerRow}%`, // Distribute width evenly
                        border: selected === globalIndex ? `1px solid ${color.Yambo_Purple}` : 'none',
                      }}
                      onClick={() => setSelected(globalIndex)}
                      onContextMenu={(e) => handleContextMenu(e, globalIndex)}
                      id={`carousel-item-${globalIndex}`}
                    >
                      {file.thumbnailUrl ? (
                        <img
                          src={file.thumbnailUrl}
                          alt={`Image ${globalIndex}`}
                          style={{
                            display: 'block',
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            filter: selected !== globalIndex ? 'grayscale(100%) brightness(60%)' : 'none',
                            cursor: 'pointer',
                          }}
                        />
                      ) : file.type.includes('audio') ? (
                        <img
                          src="/audio.png"
                          alt={`Image ${globalIndex}`}
                          style={{
                            display: 'block',
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            filter: selected !== globalIndex ? 'grayscale(100%) brightness(60%)' : 'none',
                            cursor: 'pointer',
                          }}
                        />
                      ) : file.type === 'rendering' ? (
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 1,
                            border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
                            backgroundColor: color.Yambo_BG,
                          }}
                        >
                          <CircularProgress size={20} sx={{ color: color.Yambo_Text_On_Dark_Transparent }} />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography>Result {globalIndex + 1}</Typography>
                          <Typography variant="caption" sx={{ textAlign: 'center' }}>
                            No Preview Available
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NodeImageList;
