import { Button, Box, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import axiosInstance from '../../services/axiosConfig';

export const SaveCustomNode = ({ data, setNodesTypes }) => {
  const { currentUser } = useContext(AuthContext);
  const [nodeName, setNodeName] = useState('');
  const [nodeDescription, setNodeDescription] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSaveCustomNode = async () => {
    setDialogOpen(true);
  };

  const handleSaveCustomNodeSuccess = (savedCustomNodeData) => {
    setNodesTypes((prevNodesTypes) => [...prevNodesTypes, savedCustomNodeData]);
    setDialogOpen(false);
  };

  const submitCustomNode = async () => {
    console.log('Saving node');
    const requestBody = {
      data: {
        color: 'Red',
        handles: data.handles,
        name: nodeName,
        description: nodeDescription,
        model: {
          name: data.model.name,
          version: data.model.version,
          label: data.model.label,
        },
        menu: {
          displayName: nodeName,
          icon: 'EmojiObjectsIcon',
          isModel: true,
        },
        schema: data.schema,
        params: data.params,
        version: 2,
        paid: data.paid,
      },
      type: 'custommodelV2',
      isModel: true,
      owner: currentUser.uid,
      dragHandle: '.node-header',
      visibility: 'private',
    };

    try {
      // Sending POST request to the server
      const response = await axiosInstance.post(`/v1/nodes`, requestBody);
      console.log(response.data);
      handleSaveCustomNodeSuccess(response.data.data);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error here (e.g., set an error message)
    }
  };

  const handleDetailsSubmit = (event) => {
    event.preventDefault();
    submitCustomNode();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNodeNameChange = (event) => {
    setNodeName(event.target.value);
  };

  const handleNodDescriptionChange = (event) => {
    setNodeDescription(event.target.value);
  };

  // useEffect(()=>{
  //     console.log("SaveCustomNode data:", data);
  //     console.log("Node name: ", nodeName);
  //     console.log("Node Dewcription: ", nodeDescription);

  // },[data, nodeName, nodeDescription])

  return (
    <>
      <Button onClick={handleSaveCustomNode}>Save to My Library</Button>
      <Dialog onClose={handleDialogClose} open={dialogOpen}>
        <Box
          onSubmit={handleDetailsSubmit}
          component="form"
          id="custom-node-save-details-dialog-content"
          sx={{ width: { xs: '90%', sm: '400px' }, display: 'flex', flexDirection: 'column', p: 2 }}
        >
          <Typography variant="h6">Give your custom node a name and description</Typography>
          <TextField
            value={nodeName}
            onChange={handleNodeNameChange}
            required
            label="Meaningful Name"
            sx={{ mt: 2 }}
            size="small"
          />
          <TextField
            value={nodeDescription}
            onChange={handleNodDescriptionChange}
            required
            multiline
            rows={3}
            label="Clear Description"
            sx={{ mt: 2 }}
            size="small"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            Save Your Custom Model
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export const SaveComfyNode = ({ data, setNodesTypes }) => {
  const { currentUser } = useContext(AuthContext);
  const [nodeName, setNodeName] = useState('');
  const [nodeDescription, setNodeDescription] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSaveCustomNode = async () => {
    setDialogOpen(true);
  };

  const handleSaveCustomNodeSuccess = (customNodeSavedData) => {
    setNodesTypes((prevNodesTypes) => [...prevNodesTypes, customNodeSavedData]);
    setDialogOpen(false);
  };

  const submitCustomNode = async () => {
    // console.log("Saving node");
    const requestBody = {
      data: {
        input: [],
        color: 'Yambo_Purple',
        handles: data.handles,
        name: nodeName,
        label: '',
        comfy: data.comfy,
        description: nodeDescription,
        model: {
          name: data.model.name,
          version: data.model?.version || '',
        },
        editable: false,
        schema: data?.schema || {},
        params: data?.params || {},
        menu: {
          displayName: nodeName,
          icon: 'EmojiObjectsIcon',
          isModel: true,
        },
      },

      type: 'comfy',
      isModel: true,
      owner: currentUser.uid,
      visibility: 'private',
    };

    try {
      // Sending POST request to the server
      const response = await axiosInstance.post(`/v1/nodes`, requestBody);
      console.log(response.data);
      handleSaveCustomNodeSuccess(response.data.data);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error here (e.g., set an error message)
    }
  };

  const handleDetailsSubmit = (event) => {
    event.preventDefault();
    submitCustomNode();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNodeNameChange = (event) => {
    setNodeName(event.target.value);
  };

  const handleNodDescriptionChange = (event) => {
    setNodeDescription(event.target.value);
  };

  // useEffect(()=>{
  //     console.log("SaveCustomNode data:", data);
  //     console.log("Node name: ", nodeName);
  //     console.log("Node Dewcription: ", nodeDescription);

  // },[data, nodeName, nodeDescription])

  return (
    <>
      <Button onClick={handleSaveCustomNode}>Save to My Library</Button>
      <Dialog onClose={handleDialogClose} open={dialogOpen}>
        <Box
          onSubmit={handleDetailsSubmit}
          component="form"
          id="custom-node-save-details-dialog-content"
          sx={{ width: { xs: '90%', sm: '400px' }, display: 'flex', flexDirection: 'column', p: 2 }}
        >
          <Typography variant="h6">Give your custom node a name and description</Typography>
          <TextField
            value={nodeName}
            onChange={handleNodeNameChange}
            required
            label="Meaningful Name"
            sx={{ mt: 2 }}
            size="small"
          />
          <TextField
            value={nodeDescription}
            onChange={handleNodDescriptionChange}
            required
            multiline
            rows={3}
            label="Clear Description"
            sx={{ mt: 2 }}
            size="small"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            Save Your Custom Model
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
// export default SaveCustomNode;
