import { CompositorNode } from './Compositor';
import init, { InitOutput, Web } from './Wasm/designer';

let wasm: InitOutput | undefined | null = undefined;

export async function initWasm(): Promise<void> {
  if (wasm === null) {
    return Promise.reject('Wasm is already being initialized');
  } else if (wasm === undefined) {
    wasm = null;
    wasm = await init();
  } else {
    return Promise.resolve();
  }
}

export class WasmAPI {
  private canvas: HTMLCanvasElement;
  private wasm: Web;
  private requestRef: number;
  private onPanic: (e: WebAssembly.RuntimeError) => void;
  private panic = false;
  private callback: (newData: CompositorNode | null) => void;

  private animate = () => {
    try {
      this.wasm.render();
    } catch (e) {
      if (e instanceof WebAssembly.RuntimeError) {
        this.onPanic(e);
      } else {
        console.error('Error occured: ' + e);
      }
    }

    if (!this.panic) {
      // Stop rendering if panic occured
      this.requestRef = requestAnimationFrame(this.animate);
    }
  };

  constructor(canvas: HTMLCanvasElement, update: (newData: CompositorNode | null) => void, onPanic: () => void) {
    this.callback = update;
    this.wasm = new Web(canvas, (changeState: CompositorNode | null, _ongoing: boolean) => {
      this.callback(changeState);
    });
    this.canvas = canvas;
    this.onPanic = (e: WebAssembly.RuntimeError) => {
      console.error('Panic occured: ' + e);
      this.panic = true;
      onPanic();
    };
    this.requestRef = requestAnimationFrame(this.animate);

    canvas.addEventListener('pointermove', this.onPointerMove);
    canvas.addEventListener('pointerdown', this.onPointerDown);
    canvas.addEventListener('pointerup', this.onPointerUp);
    canvas.addEventListener('pointerout', this.onPointerOut);
    canvas.addEventListener('pointerenter', this.onPointerEnter);
    canvas.addEventListener('wheel', this.onWheel);
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
  }

  setCallback(callback: (newData: CompositorNode | null) => void) {
    this.callback = callback;
  }

  importCompositorNode(node: any) {
    try {
      this.wasm.importCompositorNode(node);
    } catch (e) {
      if (e instanceof WebAssembly.RuntimeError) {
        this.onPanic(e);
      } else {
        console.error('Error occured: ' + e);
      }
    }
  }

  exportCompositorNode(): CompositorNode | undefined {
    try {
      return this.wasm.exportCompositorNode();
    } catch (e) {
      if (e instanceof WebAssembly.RuntimeError) {
        this.onPanic(e);
      } else {
        console.error('Error occured: ' + e);
      }
    }
  }

  addImageResource(url: string, img: HTMLImageElement) {
    try {
      this.wasm.addImageResource(url, img);
    } catch (e) {
      if (e instanceof WebAssembly.RuntimeError) {
        this.onPanic(e);
      } else {
        console.error('Error occured: ' + e);
      }
    }
  }

  onPointerMove = (e: PointerEvent) => {
    this.wasm.onPointerMove(e);
    e.preventDefault();
    e.stopPropagation();
  };

  onPointerDown = (e: PointerEvent) => {
    this.wasm.onPointerDown(e);
    e.preventDefault();
    e.stopPropagation();
  };

  onPointerUp = (e: PointerEvent) => {
    this.wasm.onPointerUp(e);
    e.preventDefault();
    e.stopPropagation();
  };

  onPointerOut = (e: PointerEvent) => {
    this.wasm.onPointerOut(e);
  };

  onPointerEnter = (e: PointerEvent) => {
    this.wasm.onPointerEnter(e);
  };

  onWheel = (e: WheelEvent) => {
    this.wasm.onWheel(e);
    e.preventDefault();
    e.stopPropagation();
  };

  onKeyDown = (e: KeyboardEvent) => {
    this.wasm.onKeyDown(e);
  };

  onKeyUp = (e: KeyboardEvent) => {
    this.wasm.onKeyUp(e);
  };

  dispose() {
    cancelAnimationFrame(this.requestRef);
    this.canvas.removeEventListener('pointermove', this.onPointerMove);
    this.canvas.removeEventListener('pointerdown', this.onPointerDown);
    this.canvas.removeEventListener('pointerup', this.onPointerUp);
    this.canvas.removeEventListener('pointerout', this.onPointerOut);
    this.canvas.removeEventListener('pointerenter', this.onPointerEnter);
    this.canvas.removeEventListener('wheel', this.onWheel);
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
  }
}
