import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { color } from '../../../colors';
import { useMediaGalleryContext } from './MediaGalleryContext';
import NodeImageList from './NodeImageList';
import { usePostHog } from 'posthog-js/react';

function MediaGallery() {
  const { mediaArray, setShowGallery, selectedFile, setSelectedFile } = useMediaGalleryContext();
  const posthog = usePostHog();

  const handleCloseGallery = () => {
    setShowGallery(false);
    posthog.capture('media_gallery_closed');
  };

  useEffect(() => {
    posthog.capture('media_gallery_opened');
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' || event.code === 'Space') {
        event.preventDefault();
        handleCloseGallery();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box
      id="media-gallery-container"
      sx={{
        width: '100%',
        height: '100%',
        background: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflowX: 'hidden',
      }}
      onClick={handleCloseGallery}
    >
      <NodeImageList images={mediaArray} selected={selectedFile} setSelected={setSelectedFile} container={'gallery'} />
      <Box sx={{ height: '100%' }}>
        <IconButton onClick={handleCloseGallery} sx={{ position: 'absolute', right: 10, top: 10 }}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default MediaGallery;
