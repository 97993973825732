import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import GoogleLogo from '../../UI/Icons/GoogleLogo';
import MicrosoftLogo from '../../UI/Icons/MicrosoftLogo';
import { AuthContext } from '../../AuthContext';

// pass token and email and remove form url when enter this one
function TempSignin({
  fullScreen = true,
  teamInviteToken,
  hintEmail,
  microsoftTenantId,
}: {
  fullScreen?: boolean;
  teamInviteToken?: string | null;
  hintEmail?: string | null;
  microsoftTenantId?: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, signIn, isSigningIn } = useContext(AuthContext);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (currentUser && !isSigningIn) {
      // Get the redirect path from location state, default to '/' if not present
      const from = location.state?.from || '/';
      navigate(from);
    } else if (!currentUser) {
      setAuthChecked(true);
    }
  }, [currentUser, isSigningIn, navigate, location.state]);

  if (!authChecked) {
    return null;
  }

  //todo: style file
  const buttonStyles = {
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px 20px',
    borderRadius: '8px',
    gap: '12px',
    textTransform: 'none',
  };

  // Get the redirect path from location state, default to '/' if not present
  const redirectPath = location.state?.from || '/';

  return (
    <Box
      sx={{
        width: fullScreen ? '100%' : 'auto',
        height: fullScreen ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Button
        variant="outlined"
        color="weavy_cta_secondary"
        sx={{ ...buttonStyles }}
        onClick={() => signIn(redirectPath, 'google', teamInviteToken || null, hintEmail)}
      >
        <GoogleLogo width={24} height={24} title="Google logo" />
        <Typography>Sign in with Google</Typography>
      </Button>

      <Button
        variant="outlined"
        color="weavy_cta_secondary"
        sx={{ ...buttonStyles }}
        onClick={() => signIn(redirectPath, 'microsoft', teamInviteToken || null, hintEmail, microsoftTenantId)}
      >
        <MicrosoftLogo width={24} height={24} title="Microsoft logo" />
        <Typography>Sign in with Microsoft</Typography>
      </Button>
    </Box>
  );
}

export default TempSignin;
