import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useUserRole } from '../UserRoleContext';
import { color } from '../../../colors';

const finishedAnimation = (status, size) => {
  switch (status) {
    case 'success':
      return (
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
          style={{ width: size, height: size }}
        >
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      );
    case 'error':
      return (
        <svg
          className="checkmark error"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
          style={{ width: size, height: size }}
        >
          <circle className="checkmark__circle error" cx="26" cy="26" r="25" fill="none" />
          <polygon className="checkmark__line" points="24,12 28,12  27,28 25,28" fill="#FFF" />
          <circle className="checkmark__dot" cx="26" cy="39" r="2" fill="#FFF" />
        </svg>
      );
  }
};

function RunBatchButton({
  isProcessing,
  stopSequence,
  runSelected,
  modelRunTrigger,
  finishedProcessing,
  processingProgress,
  disable,
}) {
  const role = useUserRole();

  return (
    // <Box
    // sx={{
    //   width:'160px',
    //   background:color.Yambo_Purple,
    //   borderRadius:2,
    //   display:'flex',
    //   justifyContent:'center',
    //   '&:hover':{
    //     background:color.Yambo_Purple_Stroke
    //   }
    //   }}>
    <Button
      variant="outlined"
      color="weavy_cta"
      onClick={isProcessing ? stopSequence : runSelected}
      sx={{
        px: 2,
        py: 1,
        width: '180px',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '24px',
        // color:color.Yambo_Text_On_White
      }}
      disabled={role !== 'editor' || disable}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {isProcessing ? (
          <>
            <Box sx={{ width: '80%', textAlign: 'center', mr: 1 }}>
              <Typography sx={{ color: `${color.Yambo_Purple_Stroke} !important` }}>
                {Math.round(processingProgress)}%
              </Typography>
            </Box>
            <Box
              sx={{ width: '20%', textAlign: 'center', position: 'relative', display: 'flex', alignItems: 'center' }}
            >
              <i className="fa-solid fa-stop" style={{ fontSize: '14px' }}></i>
              <Box sx={{ position: 'absolute', top: '-12px', left: '-5px', width: '20px', height: '20px' }}>
                {!modelRunTrigger.some((m) => m.progress > 0) && <CircularProgress color="success" size={20} />}
                <CircularProgress size={20} color="success" variant="determinate" value={processingProgress} />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ width: '80%', textAlign: 'center', mr: 1 }}>
              <Typography sx={{ mr: 1, color: `${color.Yambo_Purple_Stroke} !important` }}>Run Selected</Typography>
            </Box>
            <Box
              sx={{ width: '20%', textAlign: 'center', position: 'relative', display: 'flex', alignItems: 'center' }}
            >
              <i className="fa-solid fa-play" style={{ fontSize: '14px' }}></i>
              <Box sx={{ position: 'absolute', top: '-3px', left: '-6px', width: '16px', height: '16px' }}>
                {finishedProcessing.finished && finishedAnimation(finishedProcessing.status, '20px')}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Button>
    // </Box>
  );
}

export default RunBatchButton;
