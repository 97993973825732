import { colorMap } from '../../colors';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import ExtractVideoFrameCore from './ExtractVideoFrameCore';

function ExtractVideoFrameNode({ id, data, updateNodeData }) {
  return (
    <DynamicNode2
      id={id}
      data={data}
      className="convert"
      handleColor={colorMap.get(data.color)}
      headerColor={colorMap.get(data.dark_color)}
    >
      <ExtractVideoFrameCore id={id} data={data} updateNodeData={updateNodeData} />
    </DynamicNode2>
  );
}

export default ExtractVideoFrameNode;
