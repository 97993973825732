import { Typography, Box, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colorMap } from '../../colors';
import axiosInstance from '../../services/axiosConfig';
import { useUserRole } from '../Recipe/UserRoleContext';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import ThreeDeeViewer from './ThreeDeeViewer';
import { hasEditingPermissions, renderMediaElement } from './Utils';
const menu = [
  // {
  //   name:"Download",
  //   action:"download"
  // },
  {
    name: 'Set as cover',
    action: 'setPoster',
  },
];

function PreviewNode({ id, data, recipeId, setUserSetPoster }) {
  const role = useUserRole();

  const { input, handles } = data;
  const [file, setFile] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  // on Connect / disconnect
  useEffect(() => {
    if (input && input[handles.input[0]]) {
      setFile(input[handles.input[0]]);
    } else {
      setFile();
    }
  }, [input, input[handles.input[0]]]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setAnchorEl({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {};
  const handleSetPoster = async () => {
    try {
      await axiosInstance.post(`/v1/recipes/${recipeId}/poster`, { url: file.url });
      setUserSetPoster(true);
    } catch (error) {
      console.error('error saving as cover', error);
    }
  };

  const handleContextMenuClick = (action) => {
    switch (action) {
      case 'download':
        handleDownload();
        handleClose();
        break;
      case 'setPoster':
        handleSetPoster();
        handleClose();
        break;
    }
  };

  return (
    <>
      <DynamicNode2
        id={id}
        data={data}
        className="preview"
        handleColor={colorMap.get(data.color)}
        headerColor={colorMap.get(data.dark_color)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
          }}
        >
          {data && data.input && (
            <Box className="media-container" onContextMenu={handleContextMenu} sx={{ position: 'relative' }}>
              {renderMediaElement(file?.url, file?.type)}
              {file && file.type && file.url && (file.type === 'image' || file.type === 'video') && (
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '10px',
                    position: 'absolute',
                    top: 5,
                    left: 5,
                    textShadow: '0px 0px 2px black',
                  }}
                >
                  {file.width} X {file.height} {file.duration ? `| ${file.duration.toFixed(2)}s` : ''}{' '}
                  {file.fps ? `| ${file.fps} fps` : ''}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </DynamicNode2>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorEl ? { top: anchorEl.mouseY, left: anchorEl.mouseX } : undefined}
      >
        {menu.map((item, index) => (
          <MenuItem key={index} onClick={() => handleContextMenuClick(item.action)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default PreviewNode;
