import React, { useEffect, useState, useRef } from 'react';
import { Menu, MenuItem, Box, TextField } from '@mui/material';
import { flattenMenuItems } from '../../Nodes/Utils';
import ParentMenuItem from './ParentMenuItem';

function FloatMenu(props) {
  const { mouseX, mouseY, isOpen, onClose, addNewNode, menuItems } = props;
  const [search, setSearch] = useState('');
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const menuItemsRef = useRef([]);
  const searchInputRef = useRef(null);

  const handleClose = () => {
    onClose();
  };

  const handleClick = (item) => {
    addNewNode(item, mouseX, mouseY);
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
      e.preventDefault();
    }

    const items = search ? filteredMenuItems : menuItems;

    switch (e.key) {
      case 'ArrowDown':
        if (highlightedIndex === -1) {
          setHighlightedIndex(0);
        } else {
          setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, items.length - 1));
        }
        break;
      case 'ArrowUp':
        if (highlightedIndex === -1) {
          setHighlightedIndex(items.length - 1);
        } else if (highlightedIndex === 0) {
          setHighlightedIndex(-1);
          searchInputRef.current.focus();
        } else {
          setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        }
        if (highlightedIndex === 0) {
          // focus on search input when the selected item is the first one
          searchInputRef.current?.focus();
        }
        break;
      case 'Enter':
        if (highlightedIndex >= 0 && items[highlightedIndex]) {
          handleClick(items[highlightedIndex]);
        }
        break;
      default:
        break;
    }
  };

  const handleSearchKeyDown = (e) => {
    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') && filteredMenuItems.length !== 0) {
      return;
    }
    e.stopPropagation();
  };

  const handleItemClick = (e, item) => {
    if (e.type === 'click') {
      handleClick(item);
    }
  };

  useEffect(() => {
    if (search) {
      const newFilteredMenuItems = flattenMenuItems(menuItems, search);
      setFilteredMenuItems(newFilteredMenuItems);
    } else {
      setFilteredMenuItems([]);
    }
    setHighlightedIndex(-1);
  }, [search, menuItems]);

  const renderMenu = (items) => {
    const isFlatList = Array.isArray(items);

    if (isFlatList) {
      return items.map((item, index) => (
        <MenuItem
          key={index}
          ref={(el) => (menuItemsRef.current[index] = el)}
          selected={index === highlightedIndex}
          onClick={(e) => handleItemClick(e, item)}
        >
          {item.displayName}
        </MenuItem>
      ));
    } else {
      return Object.keys(items).map((key, index) => {
        const item = items[key];

        return (
          <Box key={index}>
            {!item.children && (
              <MenuItem
                ref={(el) => (menuItemsRef.current[index] = el)}
                selected={index === highlightedIndex}
                onClick={(e) => handleItemClick(e, item)}
              >
                {item.displayName}
              </MenuItem>
            )}
            {item.children && (
              <ParentMenuItem label={item.displayName} onClose={onClose}>
                {renderMenu(item.children)}
              </ParentMenuItem>
            )}
          </Box>
        );
      });
    }
  };

  useEffect(() => {
    // const items = search ? filteredMenuItems : menuItems;
    if (highlightedIndex >= 0 && menuItemsRef.current[highlightedIndex]) {
      menuItemsRef.current[highlightedIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [highlightedIndex, search, filteredMenuItems, menuItems]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  return (
    <Box onKeyDown={handleKeyDown}>
      <Menu
        open={isOpen}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={isOpen ? { top: mouseY, left: mouseX } : undefined}
        className="flow-float-memu"
        sx={{ maxHeight: '580px' }}
      >
        <Box
          onKeyDown={handleSearchKeyDown}
          sx={{
            p: 0,
            pb: 1,
          }}
        >
          <TextField
            inputRef={searchInputRef}
            fullWidth
            value={search}
            onChange={handleSearchChange}
            size="small"
            autoComplete="off"
            sx={{
              px: 1,
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'inherit',
                },
              },
            }}
          />
        </Box>
        {renderMenu(search ? filteredMenuItems : menuItems)}
      </Menu>
    </Box>
  );
}

export default FloatMenu;
