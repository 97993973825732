import { createContext, useContext } from 'react';

type TNodeActionsContext = {
  deleteNode: (nodeId: string) => void;
  duplicateNode: (nodeId: string) => void;
  toggleLockNode: (nodeId: string) => void;
  openOverlayDialog: (type: string, id: string, data: any) => void;
  closeOverlayDialog: () => void;
  setCoverImage: (coverImage: unknown) => void;
};

const NodeActionsContext = createContext<TNodeActionsContext>({
  deleteNode: () => {},
  duplicateNode: () => {},
  toggleLockNode: () => {},
  openOverlayDialog: () => {},
  closeOverlayDialog: () => {},
  setCoverImage: () => {},
});

export const useNodeActions = () => useContext(NodeActionsContext);

export const NodeActionsProvider = ({
  children,
  deleteNode,
  duplicateNode,
  toggleLockNode,
  openOverlayDialog,
  closeOverlayDialog,
  setCoverImage,
}) => (
  <NodeActionsContext.Provider
    value={{ deleteNode, duplicateNode, toggleLockNode, openOverlayDialog, closeOverlayDialog, setCoverImage }}
  >
    {children}
  </NodeActionsContext.Provider>
);
