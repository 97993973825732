import { Typography, Box, LinearProgress } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import JSZip from 'jszip';
import axiosInstance from '../../services/axiosConfig';
import { color } from '../../colors';
import { ModelType } from '../../enums/model-type.enum';
import { CreditsContext } from '../../services/CreditsContext';
import DynamicFields from '../Nodes/ModelComponents/DynamicFields';
import { registerImageUtil } from './Utils';
import { runModel } from './RunModel';

function MaskExtractionCore({ id, recipeId, recipeVersion, data, updateNodeData, container }) {
  const { input, handles, description, schema, params } = data;
  const [fileSrc, setFileSrc] = useState();
  const [masks, setMasks] = useState(data.result?.masks);
  const [hoveredMask, setHoveredMask] = useState(null);
  const [selectedMasks, setSelectedMasks] = useState(data.result?.selectedMasks || []);
  const [, setPanopticImage] = useState(data.result?.panopticImage);
  const [combinedImage, setCombinedImage] = useState(null);
  const [matteImage, setMatteImage] = useState(null);
  const [, setAlphaBlendedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const visualId = useRef();

  const panopticCanvasRef = useRef();
  const combinedCanvasRef = useRef();

  const { setUserCredits } = useContext(CreditsContext);

  const [chokerWorker, setChokerWorker] = useState(null);
  const [blendWorker, setBlendWorker] = useState(null);
  const chokerRequestRef = useRef();
  const lastChokerTime = useRef(0);

  useEffect(() => {
    if (data.result?.visualId) {
      visualId.current = data.result.visualId;
    }
  }, []);

  const drawPanopticImage = (imageSrc) => {
    const panopticCanvas = panopticCanvasRef.current;
    if (!panopticCanvas) return;
    const panopticCtx = panopticCanvas.getContext('2d', { willReadFrequently: true });
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      panopticCanvas.width = img.width;
      panopticCanvas.height = img.height;
      panopticCtx.drawImage(img, 0, 0);
    };
  };

  const blobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const createCombinedImage = () => {
    if (!masks) return;
    const combinedCanvas = combinedCanvasRef.current;
    if (!combinedCanvas) return;
    const combinedCtx = combinedCanvas.getContext('2d');
    const baseMask = masks[Object.keys(masks)[0]];
    const img = new Image();
    img.src = baseMask;
    img.onload = () => {
      combinedCanvas.width = img.width;
      combinedCanvas.height = img.height;
      combinedCtx.clearRect(0, 0, combinedCanvas.width, combinedCanvas.height);

      if (selectedMasks.length === 0) {
        // console.log("drawing black  background");
        combinedCtx.fillStyle = 'black';
        combinedCtx.fillRect(0, 0, combinedCanvas.width, combinedCanvas.height);
        const combinedImageData = combinedCanvas.toDataURL('image/png');
        setCombinedImage(combinedImageData);
      } else {
        selectedMasks.forEach((maskKey) => {
          const maskImg = new Image();
          maskImg.src = masks[maskKey];
          maskImg.onload = () => {
            combinedCtx.globalCompositeOperation = 'screen';
            combinedCtx.drawImage(maskImg, 0, 0);
            setCombinedImage(combinedCanvas.toDataURL('image/png'));
          };
        });
      }

      combinedCtx.globalCompositeOperation = 'source-over';
    };
  };

  const downloadAndExtractZip = async (zipUrl) => {
    try {
      const response = await axiosInstance.get(zipUrl, { responseType: 'arraybuffer' });
      // Extract the zip file
      const zip = await JSZip.loadAsync(response.data);
      const extractedFiles = {};
      let panopticImg = null;

      for (const [relativePath, zipEntry] of Object.entries(zip.files)) {
        if (!zipEntry.dir) {
          const content = await zipEntry.async('blob');
          const dataUrl = await blobToDataURL(content);
          if (relativePath.includes('panoptic')) {
            panopticImg = dataUrl;
          } else {
            extractedFiles[relativePath] = dataUrl;
          }
        }
      }
      updateNodeData(id, {
        result: {
          masks: extractedFiles,
          selectedMasks: [],
          panopticImage: panopticImg,
          visualId: visualId.current,
        },
      });
      setMasks(extractedFiles);
      setPanopticImage(panopticImg);
      drawPanopticImage(panopticImg);
      setIsLoading(false);
      // console.log("creating combined image");
      createCombinedImage();
    } catch (error) {
      console.error('Error downloading or extracting zip file:', error);
    }
  };

  const runAnalyseMasks = async (runVisualId) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await runModel(
        { ...handles, input: ['visualId'] },
        {
          visualId: runVisualId,
        },
        {
          name: ModelType.BRMasks,
          type: ModelType.BRMasks,
        },
        params,
        id,
        recipeId,
        recipeVersion,
        {
          onSuccess: async (results, remainingCredits) => {
            if (results.objects_masks) {
              const zipUrl = results.objects_masks;
              await downloadAndExtractZip(zipUrl);
              if (remainingCredits != null) {
                setUserCredits(remainingCredits);
              }
              setIsLoading(false);
            }
          },
          onError: (error) => {
            console.error('Error downloading or extracting zip file:', error);
            setIsLoading(false);
          },
        },
        data.version,
      );
    } catch (error) {
      console.error('Error running mask extraction:', error);
      setIsLoading(false);
    }
  };

  const registerImage = async (url) => {
    setIsLoading(true);

    return registerImageUtil(url);
  };

  const handleConnection = useCallback(async () => {
    setFileSrc(input[handles.input[0]]);

    // if there are no prior masks, run the mask extraction
    if (!data.result?.masks && input[handles.input[0]]) {
      // console.log("Running Mask Extraction");
      if (!input[handles.input[0]].visualId) {
        visualId.current = await registerImage(input[handles.input[0]].url);
      } else visualId.current = input[handles.input[0]].visualId;

      runAnalyseMasks(visualId.current);
    } else {
      // console.log("Already have masks");
      drawPanopticImage(data.result.panopticImage);
    }
  }, [fileSrc, data, updateNodeData]);

  const handleDisconnection = useCallback(() => {
    // console.log("Handling Disconnection");
    setMasks(null);
    setIsLoading(false);
    setSelectedMasks([]);
    setCombinedImage(null);
    setFileSrc(null);
    updateNodeData(id, {
      selectedMasks: [],
      result: {},
      output: {
        [data.handles.output[0]]: null,
      },
    });
  }, [setMasks, setSelectedMasks, setCombinedImage, updateNodeData]);

  useEffect(() => {
    // if (prevInput && prevInput[handles.input[0]] === input[handles.input[0]]) return;
    if (input && input[handles.input[0]]) {
      // console.log("Handling Connection");

      handleConnection();
    } else {
      // console.log("Handling Disconnection");
      handleDisconnection();
    }
  }, [input]);

  const handleMouseMove = (e) => {
    const panopticCanvas = panopticCanvasRef.current;
    if (!panopticCanvas) return;
    const panopticCtx = panopticCanvas.getContext('2d', { willReadFrequently: true });

    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * panopticCanvas.width;
    const y = ((e.clientY - rect.top) / rect.height) * panopticCanvas.height;

    if (panopticCanvas) {
      const pixel = panopticCtx.getImageData(x, y, 1, 1).data;
      // console.log("Pixel", pixel[0],pixel[1],pixel[2], pixel[3] );
      const maskKey = `${visualId.current}/${visualId.current}_${pixel[0]}.png`;
      // console.log("Mask Key", maskKey);
      if (masks[maskKey]) {
        setHoveredMask(maskKey);
      } else {
        setHoveredMask(null);
      }
    }
  };

  // useEffect(() => {
  //     console.log("visulaId", visualId.current);
  // }, [visualId.current]);

  const handleClickedMask = useCallback(
    (e) => {
      // console.log("Clicked Mask", hoveredMask);
      if (hoveredMask) {
        if (e.shiftKey) {
          setSelectedMasks((prev) => {
            if (!prev.includes(hoveredMask)) {
              return [...prev, hoveredMask];
            }

            return prev;
          });
        } else if (e.altKey || e.metaKey) {
          setSelectedMasks((prev) => prev.filter((mask) => mask !== hoveredMask));
        } else {
          setSelectedMasks([hoveredMask]);
        }
      }
    },
    [hoveredMask],
  );

  useEffect(() => {
    if (!selectedMasks?.length) {
      return;
    }
    createCombinedImage();
    updateNodeData(id, {
      result: {
        ...data.result,
        selectedMasks: selectedMasks,
      },
    });
  }, [selectedMasks]);

  // matte choker
  const handleChange = (key, newValue) => {
    // For numerical fields, ensure that the newValue is correctly parsed
    const isNumeric = ['integer', 'number', 'input'].includes(schema[key]?.type);
    let parsedValue = isNumeric ? parseFloat(newValue) : newValue;
    if (schema[key]?.type === 'seed') {
      parsedValue = {
        isRandom: newValue.isRandom,
        seed: parseFloat(newValue.seed) || 1,
      };
    }

    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNumeric && isNaN(parsedValue)) {
      parsedValue = undefined;
    }
    updateNodeData(id, {
      params: {
        ...params,
        [key]: parsedValue,
      },
    });
  };

  // Worker setup and matte choker application
  useEffect(() => {
    const worker = new Worker('/matteChokerWorker.js');
    setChokerWorker(worker);

    const newBlendWorker = new Worker('/blendWorker.js'); // New worker for blending
    setBlendWorker(newBlendWorker);

    return () => {
      worker.terminate();
      newBlendWorker.terminate();
    };
  }, []);

  const blendOriginalWithAlpha = useCallback(
    (chokedImage) => {
      if (!fileSrc || !chokedImage || !blendWorker) return;

      const originalImage = new Image();
      const alphaImage = new Image();

      originalImage.onload = () => {
        alphaImage.onload = () => {
          const originalCanvas = document.createElement('canvas');
          originalCanvas.width = originalImage.width;
          originalCanvas.height = originalImage.height;
          const originalCtx = originalCanvas.getContext('2d');
          originalCtx.drawImage(originalImage, 0, 0);

          const alphaCanvas = document.createElement('canvas');
          alphaCanvas.width = alphaImage.width;
          alphaCanvas.height = alphaImage.height;
          const alphaCtx = alphaCanvas.getContext('2d');
          alphaCtx.drawImage(alphaImage, 0, 0);

          const originalImageData = originalCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height);
          const alphaImageData = alphaCtx.getImageData(0, 0, alphaCanvas.width, alphaCanvas.height);

          blendWorker.postMessage({ originalImageData, alphaImageData }, [
            originalImageData.data.buffer,
            alphaImageData.data.buffer,
          ]);
        };
        alphaImage.src = matteImage;
      };
      originalImage.src = fileSrc.url;
      originalImage.crossOrigin = 'anonymous';

      blendWorker.onmessage = (e) => {
        const { blendedImageData } = e.data;
        const blendedCanvas = document.createElement('canvas');
        blendedCanvas.width = blendedImageData.width;
        blendedCanvas.height = blendedImageData.height;
        const blendedCtx = blendedCanvas.getContext('2d');
        blendedCtx.putImageData(blendedImageData, 0, 0);

        const blendedImage = blendedCanvas.toDataURL('image/png');
        setAlphaBlendedImage(blendedImage);

        const formattedOutput = {};
        formattedOutput[handles.output[0]] = {
          type: 'image',
          url: blendedImage,
          width: fileSrc?.width,
          height: fileSrc?.height,
        };
        formattedOutput[handles.output[1]] = {
          type: 'image',
          url: chokedImage,
          width: fileSrc?.width,
          height: fileSrc?.height,
        };
        updateNodeData(id, { output: formattedOutput });
      };
    },
    [fileSrc, matteImage, blendWorker, updateNodeData],
  );

  const applyMatteChoker = useCallback(() => {
    if (!combinedImage || !chokerWorker) return;
    const tempSize = 256;
    const now = performance.now();
    if (now - lastChokerTime.current < 16) {
      chokerRequestRef.current = requestAnimationFrame(applyMatteChoker);

      return;
    }
    lastChokerTime.current = now;

    const img = new Image();
    img.onload = async () => {
      // Create a small canvas for downscaling
      const smallCanvas = document.createElement('canvas');
      smallCanvas.width = tempSize;
      smallCanvas.height = tempSize;
      const smallCtx = smallCanvas.getContext('2d');

      // Draw the image at a smaller size
      smallCtx.drawImage(img, 0, 0, tempSize, tempSize);
      const smallImageData = smallCtx.getImageData(0, 0, tempSize, tempSize);

      chokerWorker.postMessage(
        {
          imageData: smallImageData,
          amount: params.matte_choker,
          originalWidth: img.width,
          originalHeight: img.height,
          featherRadius: params.feather || 0, // Add feather radius parameter
        },
        [smallImageData.data.buffer],
      );
    };
    img.src = combinedImage;

    chokerWorker.onmessage = async (e) => {
      const { chokedImageData, originalWidth, originalHeight } = e.data;

      // Create an ImageBitmap from the choked data
      const chokedBitmap = await createImageBitmap(chokedImageData);

      // Create a canvas to scale the image back up
      const finalCanvas = document.createElement('canvas');
      finalCanvas.width = originalWidth;
      finalCanvas.height = originalHeight;
      const finalCtx = finalCanvas.getContext('2d');

      // Draw the choked bitmap at the original size
      finalCtx.imageSmoothingEnabled = false; // Disable smoothing for sharper edges
      finalCtx.drawImage(chokedBitmap, 0, 0, originalWidth, originalHeight);

      const chokedImage = finalCanvas.toDataURL('image/png');
      setMatteImage(chokedImage);

      blendOriginalWithAlpha(chokedImage);
    };
  }, [
    combinedImage,
    params.matte_choker,
    params.feather,
    chokerWorker,
    updateNodeData,
    setMatteImage,
    blendOriginalWithAlpha,
  ]);

  useEffect(() => {
    chokerRequestRef.current = requestAnimationFrame(applyMatteChoker);

    return () => cancelAnimationFrame(chokerRequestRef.current);
  }, [applyMatteChoker]);

  return (
    <>
      {container === 'node' && (
        <Box>
          {isLoading && (
            <Box sx={{ width: '100%', position: 'absolute', top: '34px', left: 0 }}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </Box>
          )}
          <Typography variant="caption">{description}</Typography>
          <canvas ref={panopticCanvasRef} style={{ display: 'none' }} />
          <canvas ref={combinedCanvasRef} style={{ display: 'none' }} />
          <Box className="media-container" sx={{ position: 'relative', mt: 1, cursor: 'pointer' }}>
            <img
              src={fileSrc?.thumbnail || fileSrc?.url || ''}
              draggable="false"
              width="100%"
              style={{ display: 'block' }}
            />
            {masks &&
              Object.entries(masks).map(([key, src]) => {
                return (
                  <Box
                    key={key}
                    sx={{
                      position: 'absolute',
                      opacity: hoveredMask === key || selectedMasks.some((m) => m === key) ? 0.5 : 0,
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${src})`,
                      backgroundSize: 'cover',
                      mixBlendMode: 'screen',
                    }}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseLeave={() => setHoveredMask(null)}
                    onClick={(e) => handleClickedMask(e, key)}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: selectedMasks.some((m) => m === key) ? color.Yambo_Purple : color.Yambo_Orange,
                        mixBlendMode: 'multiply',
                        opacity: 0.9,
                      }}
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
      {schema && Object.keys(schema).length !== 0 && container === 'drawer' && (
        <Box id="advanced-mode-container" sx={{ width: '100%', mt: 1 }}>
          <Box id="advnaced-mode-content-container" className="nowheel nodrag nopan">
            <DynamicFields
              params={params}
              schema={schema}
              handleChange={handleChange}
              // handleExposeProperty={ handleExposePropertyCallback }
              // handleCollapseProperty={ handleCollapsePropertyCallback }
              version={data.version}
              // deleteEdgeByTargetHandleId={ deleteEdgeByTargetHandleId  }
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default MaskExtractionCore;
