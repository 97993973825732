import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  onAuthStateChanged,
  signOut as firebaseSignOut,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  onIdTokenChanged,
} from 'firebase/auth';
import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { auth } from './services/firebase';
import server_url from './globals';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [teamInviteSuccess, setTeamInviteSuccess] = useState(false);

  const navigate = useNavigate();

  const signOut = async () => {
    try {
      setCurrentUser(null);
      await firebaseSignOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const updateCurrentUser = (newData) => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      ...newData,
    }));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`;
      } else {
        setCurrentUser(null);
        delete axios.defaults.headers.common['Authorization'];
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (route, providerType, teamInviteToken, hintEmail, microsoftTenantId) => {
    setIsSigningIn(true);

    // Dynamically create the provider based on providerType
    let provider;
    if (providerType === 'google') {
      provider = new GoogleAuthProvider();
    } else if (providerType === 'microsoft') {
      provider = new OAuthProvider('microsoft.com');

      let customParams = {};
      if (microsoftTenantId) {
        customParams.tenant = microsoftTenantId;
      }

      if (hintEmail) {
        customParams.login_hint = hintEmail;
      }

      provider.setCustomParameters(customParams);
    } else {
      throw new Error('Unsupported provider');
    }

    // if activate page ->
    if (hintEmail) {
      provider.setCustomParameters({
        login_hint: hintEmail,
      });
    }
    try {
      // todo : try and pass the email that he can sign in with
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (!user?.uid) {
        console.error("signIn: uid doesn't exist");
      }

      await axios.post(`${server_url}/v1/users/signin`, user, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });
      axios.defaults.headers.Authorization = `Bearer ${user.accessToken}`;
      setCurrentUser(user);
      setIsSigningIn(false);
      navigate(route);

      if (teamInviteToken) {
        await axios.post(`${server_url}/v1/workspaces/members/invitation/${teamInviteToken}/accept`);
        setTeamInviteSuccess(true);
        setTimeout(() => {
          setTeamInviteSuccess(false);
        }, 5000);
      }
    } catch (error) {
      debugger;
      console.log('error in signing in user ', error);
      if (error.response?.data?.message === 'waiting_list') {
        try {
          // Attempt to delete the user
          const user = auth.currentUser;
          if (user) {
            await user.delete();
          }
        } catch (deleteError) {
          console.error('Failed to delete user: ', deleteError);
        }
        navigate('/early');
      }
      setIsSigningIn(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading || isSigningIn) {
    return (
      <Box
        className="App"
        sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CircularProgress sx={{ position: 'relative' }} />
      </Box>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser, signIn, signOut, updateCurrentUser, isSigningIn, teamInviteSuccess }}>
      {children}
    </AuthContext.Provider>
  );
};
