import { Box, Button, TextField, IconButton } from '@mui/material';
import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axiosInstance from '../../services/axiosConfig';

function CreateModelNode({ handleBack }) {
  const [name, setName] = useState('');
  const [modelLabel, setModelLabel] = useState('');
  const [description, setDescription] = useState('');
  const [modelName, setModelName] = useState('');
  const [modelVersion, setModelVersion] = useState('');
  const [inputHandles, setInputHandles] = useState(['']);
  const [outputHandles, setOutputHandles] = useState(['']);
  const [params, setParams] = useState([{ key: '', value: '' }]);
  const [type, setType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedInputHandles = inputHandles.filter((handle) => handle.trim() !== '');
    const cleanedOutputHandles = outputHandles.filter((handle) => handle.trim() !== '');
    const cleanedParams = params.reduce((obj, item) => {
      if (item.key.trim() !== '' && item.value.trim() !== '') {
        obj[item.key] = item.value;
      }

      return obj;
    }, {});
    const requestBody = {
      data: {
        input: cleanedInputHandles,
        handles: {
          input: cleanedInputHandles,
          output: cleanedOutputHandles,
        },
        name: name,
        label: modelLabel,
        description: description,
        model: {
          name: modelName,
          version: modelVersion,
          label: modelLabel,
        },
        params: cleanedParams,
      },
      type: type,
    };
    try {
      // Sending POST request to the server
      const response = await axiosInstance.post(`/v1/nodes`, requestBody);
      console.log(response.data);
      // Handle response here (e.g., set a success message, clear form, etc.)
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error here (e.g., set an error message)
    }
  };

  const handleAddInputHandle = () => {
    setInputHandles([...inputHandles, '']);
  };

  const handleInputChange = (index, event) => {
    const newInputHandles = [...inputHandles];
    newInputHandles[index] = event.target.value;
    setInputHandles(newInputHandles);
  };

  const handleRemoveInputHandle = (index) => {
    const newInputHandles = [...inputHandles];
    newInputHandles.splice(index, 1);
    setInputHandles(newInputHandles);
  };

  const handleAddOutputHandle = () => {
    setOutputHandles([...outputHandles, '']);
  };

  const handleOutputChange = (index, event) => {
    const newOutputHandles = [...outputHandles];
    newOutputHandles[index] = event.target.value;
    setOutputHandles(newOutputHandles);
  };

  const handleRemoveOutputHandle = (index) => {
    const newOutputHandles = [...outputHandles];
    newOutputHandles.splice(index, 1);
    setOutputHandles(newOutputHandles);
  };

  const handleAddParam = () => {
    setParams([...params, { key: '', value: '' }]);
  };

  const handleParamChange = (index, event, newParamsType) => {
    const newParams = [...params];
    newParams[index][newParamsType] = event.target.value;
    setParams(newParams);
  };

  const handleRemoveParam = (index) => {
    const newParams = [...params];
    newParams.splice(index, 1);
    setParams(newParams);
  };

  return (
    <>
      <IconButton color="primary" onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}
      >
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="type"
          label="Type"
          name="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="description"
          label="Description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="modelName"
          label="Model Name (from Replicate)"
          name="modelName"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
        />
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="modelVersion"
          label="Model Version  (from Replicate)"
          name="modelVersion"
          value={modelVersion}
          onChange={(e) => setModelVersion(e.target.value)}
        />
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="modelLabel"
          label="Model Label"
          name="modelLabel"
          value={modelLabel}
          onChange={(e) => setModelLabel(e.target.value)}
        />

        {inputHandles.map((handle, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              fullWidth
              size="small"
              label={`Input Handle ${index + 1}`}
              value={handle}
              onChange={(e) => handleInputChange(index, e)}
            />
            <IconButton onClick={() => handleRemoveInputHandle(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddInputHandle}>
          Add Input Handle
        </Button>

        {/* Output Handles */}
        {outputHandles.map((handle, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              fullWidth
              size="small"
              label={`Output Handle ${index + 1}`}
              value={handle}
              onChange={(e) => handleOutputChange(index, e)}
            />
            <IconButton onClick={() => handleRemoveOutputHandle(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddOutputHandle}>
          Add Output Handle
        </Button>

        {params.map((param, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              fullWidth
              label={`Param Key ${index + 1}`}
              value={param.key}
              onChange={(e) => handleParamChange(index, e, 'key')}
              sx={{ mr: 2 }}
            />
            <TextField
              fullWidth
              label={`Param Value ${index + 1}`}
              value={param.value}
              onChange={(e) => handleParamChange(index, e, 'value')}
            />
            <IconButton onClick={() => handleRemoveParam(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddParam}>
          Add Param
        </Button>

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Submit
        </Button>
      </Box>
    </>
  );
}

export default CreateModelNode;
