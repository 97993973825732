import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../AuthContext';
import axiosInstance from '../../services/axiosConfig';
import { UserTourStatus } from '../../enums/user-tour-status.enum';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import I18N_KEYS from '../../language/keys';
import Tour from './Tour';
import tourConfigs from './tourConfigs.json';

const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const { t: translate } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [tourReadiness, setTourReadiness] = useState({
    positions: false,
    prehook: false,
  });
  const [activeTour, setActiveTour] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [prehookFunctions, setPrehookFunctions] = useState({});
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [showQuitDialog, setShowQuitDialog] = useState(false);
  const [customTourData, setCustomTourData] = useState(null);
  const [activeComponentId, setActiveComponentId] = useState(null);

  const runPrehook = async (tourId, stepIndex) => {
    const nextStepConfig = tourConfigs[tourId].steps[stepIndex];
    if (nextStepConfig.prehook) {
      if (!prehookFunctions[nextStepConfig.prehook]) {
        console.error(`Prehook function "${nextStepConfig.prehook}" not registered`);
        return; // Don't proceed if prehook function is missing
      }

      try {
        await prehookFunctions[nextStepConfig.prehook]();
      } catch (error) {
        console.error('Error executing prehook:', error);
        return; // Don't proceed if prehook execution fails
      }
    } else return;
  };

  const getEligibleTours = (componentId, userPendingTours) => {
    setActiveComponentId(componentId);

    if (Object.keys(userPendingTours).length === 0) return;

    return Object.entries(tourConfigs)
      .filter(([tourId, config]) => {
        return config.componentId === componentId && userPendingTours[tourId];
      })
      .sort((a, b) => (a[1].priority ?? Infinity) - (b[1].priority ?? Infinity));
  };

  const findActiveTourIndex = (eligibleTours, activeTour) => {
    return eligibleTours.findIndex(([tourId]) => tourId === activeTour);
  };

  const checkForEligibleTour = (componentId) => {
    const userPendingTours = currentUser?.tours || {};
    const eligibleTours = getEligibleTours(componentId, userPendingTours);

    const tourToShow = eligibleTours[0];
    if (!tourToShow) return null;
    const currentStep = userPendingTours[tourToShow[0]]?.currentStep;
    const customData = userPendingTours[tourToShow[0]]?.customData;
    setCustomTourData(customData);

    if (tourToShow && currentStep !== undefined) {
      return {
        tourId: tourToShow[0],
        currentStep: currentStep,
        customData: customData,
      };
    }
    return null;
  };

  const updateCustomTourData = async (tourId, customData) => {
    setCustomTourData((prev) => {
      const newData = { ...prev };
      Object.keys(customData).forEach((key) => {
        newData[key] = customData[key];
      });
      // Send the merged data to the server
      sendCustomDataToServer(tourId, newData);

      return newData;
    });
  };

  const sendCustomDataToServer = async (tourId, customData) => {
    try {
      await axiosInstance.put(`/v1/users/tours/${tourId}`, { customData });
    } catch (error) {
      console.error('Error updating user tour:', error);
    }
  };

  const registerPrehookFunction = (functionName, handler) => {
    setPrehookFunctions((prev) => ({
      ...prev,
      [functionName]: handler,
    }));
  };

  const runTour = async (tourId, step) => {
    if (tourConfigs[tourId]) {
      await runPrehook(tourId, step);
      setActiveTour(tourId);

      setCurrentStep(step);
    }
  };

  const goToStep = async (tourId, stepIndex) => {
    if (tourConfigs[tourId] && stepIndex >= 0 && stepIndex < tourConfigs[tourId].steps.length) {
      setShowSuccessView(false);
      await runPrehook(tourId, stepIndex + 1);
      setActiveTour(tourId);
      setCurrentStep(stepIndex);
      try {
        await axiosInstance.put(`/v1/users/tours/${tourId}`, {
          status: UserTourStatus.IN_PROGRESS,
          currentStep: stepIndex,
        });
      } catch (error) {
        console.error('Error updating user tour:', error);
      }
    }
  };

  const goToNextStep = async (tourId) => {
    if (tourConfigs[tourId] && currentStep < tourConfigs[tourId].steps.length - 1) {
      await runPrehook(tourId, currentStep + 1);

      setShowSuccessView(false);
      setCurrentStep((prev) => prev + 1);
      try {
        await axiosInstance.put(`/v1/users/tours/${tourId}`, {
          status: UserTourStatus.IN_PROGRESS,
          currentStep: currentStep + 1,
        });
      } catch (error) {
        console.error('Error updating user tour:', error);
      }
    } else {
      // End tour if we're at the last step
      goToNextTour();

      // handleQuitTour();
    }
  };

  const goToPreviousStep = async (tourId) => {
    if (tourConfigs[tourId] && currentStep > 0) {
      setShowSuccessView(false);
      setCurrentStep((prev) => prev - 1);
      try {
        await axiosInstance.put(`/v1/users/tours/${tourId}`, {
          status: UserTourStatus.IN_PROGRESS,
          currentStep: currentStep - 1,
        });
      } catch (error) {
        console.error('Error updating user tour:', error);
      }
    }
  };

  const goToNextTour = async () => {
    const activeTourBeforeQuit = activeTour;
    handleQuitTour();
    const userPendingTours = currentUser?.tours || {};
    const eligibleTours = getEligibleTours(activeComponentId, userPendingTours);
    const nextTour = eligibleTours[findActiveTourIndex(eligibleTours, activeTourBeforeQuit) + 1];
    if (nextTour) {
      setActiveTour(nextTour[0]);
      setCurrentStep(0);
      setTourReadiness({
        positions: false,
        prehook: false,
      });
      runTour(nextTour[0], 0);
    }
  };

  const showStepSuccess = () => {
    setShowSuccessView(true);
  };

  const endTour = async () => {
    setShowQuitDialog(true);
  };

  const stopTour = () => {
    setActiveTour(null);
    setCurrentStep(0);
    setShowSuccessView(false);
    setTourReadiness({
      positions: false,
      prehook: false,
    });
  };

  const handleQuitTour = async () => {
    setShowQuitDialog(false);
    updateServerOnTourEndOrQuit();
    setActiveTour(null);
    setCurrentStep(0);
    setTourReadiness({
      positions: false,
      prehook: false,
    });
  };

  const updateServerOnTourEndOrQuit = async () => {
    try {
      await axiosInstance.put(`/v1/users/tours/${activeTour}`, { status: UserTourStatus.COMPLETED });
    } catch (error) {
      console.error('Error updating user tour:', error);
    }
  };

  const replacePlaceholders = (template, data) => {
    return template.replace(/{{(.*?)}}/g, (_, key) => data[key.trim()] || '');
  };

  const value = {
    activeTour,
    tourReadiness,
    setTourReadiness,
    currentStep,
    getCurrentTourConfig: () => (activeTour ? tourConfigs[activeTour] : null),
    getCurrentStepConfig: () =>
      activeTour && currentStep < tourConfigs[activeTour].steps.length
        ? tourConfigs[activeTour].steps[currentStep]
        : null,
    runTour,
    checkForEligibleTour,
    goToStep,
    goToNextStep,
    goToPreviousStep,
    showSuccessView,
    showStepSuccess,
    endTour,
    stopTour,
    registerPrehookFunction,
    updateCustomTourData,
    customTourData,
    replacePlaceholders,
  };

  return (
    <>
      <TourContext.Provider value={value}>
        {activeTour && <Tour />}
        {children}
      </TourContext.Provider>
      <ConfirmationDialog
        open={showQuitDialog}
        onClose={() => setShowQuitDialog(false)}
        onConfirm={handleQuitTour}
        onCancel={() => setShowQuitDialog(false)}
        message={translate(I18N_KEYS.PRODUCT_TOURS.QUIT_TOUR_CONFIRMATION_MESSAGE)}
        confirmText={translate(I18N_KEYS.PRODUCT_TOURS.QUIT_TOUR_CONFIRMATION_CTA)}
        cancelText={translate(I18N_KEYS.PRODUCT_TOURS.QUIT_TOUR_CONFIRMATION_CANCEL)}
      />
    </>
  );
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};

export default TourContext;
