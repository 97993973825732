import React from 'react';
import { FileUploader, type UploadedFile } from '../../Common/FileUploader';

type DesignAppUploadFileProps = {
  error?: boolean;
  helperText?: string;
  id: string;
  isLoading: boolean;
  onUpload: (id: string, file: Partial<UploadedFile> | null) => void;
  setIsUploading: (isUploading: boolean) => void;
  value: { file: Partial<UploadedFile> };
};

function DesignAppUploadFile({
  id,
  value,
  onUpload,
  isLoading,
  setIsUploading,
  error,
  helperText,
}: DesignAppUploadFileProps) {
  const handleUpload = (file: Partial<UploadedFile> | null) => {
    setIsUploading(false);
    onUpload(id, file);
  };

  return (
    <FileUploader
      id={id}
      allowRemove
      value={value?.file}
      onUpload={handleUpload}
      isLoading={isLoading}
      error={error}
      helperText={helperText}
      maxHeight={300}
    />
  );
}

export default DesignAppUploadFile;
