import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Typography, Box, ButtonBase, Link, TextField, InputAdornment, CircularProgress } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import _ from 'lodash';
import { useUpdateNodeInternals } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useUserRole } from '../Recipe/UserRoleContext';
import ModelRunContext from '../Recipe/RunFlow/ModelRunContext';
import { CreditsContext } from '../../services/CreditsContext';
import { useMediaGalleryContext } from '../Recipe/FlowComponents/MediaGalleryContext';
import { color } from '../../colors';
import I18N_KEYS from '../../language/keys';
import NodeImageList from '../Recipe/FlowComponents/NodeImageList';
import axiosInstance from '../../services/axiosConfig';
import { ModelType } from '../../enums/model-type.enum';
import CancellableLoadingButton from '../CancellableLoadingButton/CancellableLoadingButton';
import { SaveCustomNode } from './SaveCustomNode';
import { pollPredictionStatus, runModel } from './RunModel';
import { sizeOptions } from './ModelParams';
import DynamicFields from './ModelComponents/DynamicFields';
import {
  cleanParamsForSaving,
  extractInputSchemaDetails,
  rgbaToRgb,
  getModelPrice,
  getHandleId,
  hasEditingPermissions,
} from './Utils';
import { civitSchema } from './Civit/CivitSchema';

let isRunning = new Set();

const civitModelTypeError = (modelType) => {
  return `The model type ${modelType.toUpperCase()} is not yet supported. We currently support only Civit models (checkpoints)`;
};
function ModelBaseComponent({
  id,
  recipeId,
  recipeVersion,
  data,
  updateNodeData,
  selectedOutput,
  setSelectedOutput,
  setOutput,
  container,
  setValidationError,
  editable,
  setNodesTypes,
  createNewNodeFromParamExpose,
  deleteEdgeByTargetHandleId,
  setIsRunningModelWhileInTour, // used for tour
  setIsRunningModelWhileInTourSuccess, // used for tour
}) {
  const posthog = usePostHog();
  const { t: translate } = useTranslation();
  const updateNodeInternals = useUpdateNodeInternals();

  const { credits, setUserCredits, openUpgradeModal, shouldShowCreditsToMembers } = useContext(CreditsContext);

  const { handles, description, input, schema, latestPrediction } = data;
  const [params, setParams] = useState(data.params || {});

  const { modelRunTrigger, updateModelRunTriggerStatus } = useContext(ModelRunContext);

  const role = useUserRole();

  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [progress, setProgress] = useState(0);
  const [predictionStatus, setPredictionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const predictionCanceled = useRef(null);
  const predictionId = useRef(null);

  const { showGallery, setShowGallery, setMediaArray, setSelectedFile } = useMediaGalleryContext();
  const [showGalleryIcon, setShowGalleryIcon] = useState(false);

  /// storing params and input for latest run model
  const lastRunParamsAndInputs = useRef({ params: null, input: null });

  const hasNewResults = useRef(null);
  const prevSeed = useRef();
  const [model, setModel] = useState(data.model);

  const [cannotFindModel, setCannotFindModel] = useState(false);
  const [civitModelNotSupported, setCivitModelNotSupported] = useState(false);
  const [civitModelNotSupportedMessage, setCivitModelNotSupportedMessage] = useState();
  const [modelLoading, setModelLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // 3D viewer related
  const [exported3DImage, setExported3DImage] = useState(null);
  const [is3DLocked, setIs3DLocked] = useState(data.is3DLocked || false);
  const [cameraPosition, setCameraPosition] = useState(data.cameraPosition || { x: -3, y: 4, z: 10 });

  useEffect(() => {
    setParams(data.params || {});
  }, [data.params]);

  const removeHandle = useCallback(
    (handleId) => {
      const updatedInput = { ...handles.input };
      delete updatedInput[handleId];

      updateNodeData(id, {
        handles: {
          ...handles,
          input: updatedInput,
        },
      });
    },
    [handles.input],
  );

  const addHandles = useCallback(
    (defaultHandles) => {
      const mergedInput = { ...handles.input, ...defaultHandles };

      updateNodeData(id, {
        handles: {
          ...handles,
          input: mergedInput,
        },
      });
    },
    [handles.input],
  );

  useEffect(() => {
    // this is to update the node internals when the input handles are exposed
    updateNodeInternals(id);
  }, [handles.input]);

  const handleExposePropertyCallback = useCallback(
    (key) => {
      // 1. create handle
      const newHandle = {};
      newHandle[key] = {
        required: schema[key].required,
        description: schema[key].description,
        format: schema[key].type || 'text',
        order: schema[key].order,
        type: schema[key].type,
        id: uuidv4(),
      };
      addHandles(newHandle);
      // 2. create new node with params as values
      const oldParams = _.cloneDeep(params);
      const oldSchema = _.cloneDeep(schema);
      createNewNodeFromParamExpose?.(id, newHandle, oldParams[key], oldSchema[key]);
      // 3. disable the property in the dynamic fields
      updateNodeData(id, {
        schema: {
          ...schema,
          [key]: {
            ...schema[key],
            exposed: true,
          },
        },
      });
    },
    [createNewNodeFromParamExpose, id, handles.input, schema, params, updateNodeData],
  );

  const handleCollapsePropertyCallback = useCallback(
    (key) => {
      const updatedInput = { ...handles.input };
      delete updatedInput[key];
      updateNodeData(id, {
        schema: {
          ...schema,
          [key]: {
            ...schema[key],
            exposed: false,
          },
        },
        handles: {
          ...handles,
          input: updatedInput,
        },
      });
      deleteEdgeByTargetHandleId?.(getHandleId(id, 'input', key));
    },
    [id, deleteEdgeByTargetHandleId, handles.input, schema, params, updateNodeData],
  );

  const handleInputChange = useCallback(() => {
    if (input && params) {
      // added params to avoid errors for models without params (Remove Backround for example)
      let newParams = { ...params };
      Object.keys(input).forEach((key) => {
        if (params[key] !== undefined || params[key] !== null) {
          // connect
          newParams[key] = input[key];
        } else {
          // disconnect
          newParams[key] = schema[key].default || undefined;
        }
      });
      updateNodeData(id, {
        params: newParams,
      });
    }
  }, [input]);

  useEffect(() => {
    // recheck validation;
    if (input) {
      const validationResult = validateRequiredInput(handles, data.input);
      setValidationError?.(validationResult.missingKeys);
      setErrorMessage(null);
      /// when a connection is made or present we need to update the params (dynamic fields accordingly)
      handleInputChange();
    }
  }, [input]);

  const handleStartProcessing = () => {
    lastRunParamsAndInputs.current = {
      params: cleanParamsForSaving(params, prevSeed.current), // Sending the current seed (not the one in params, as it is not yet updated)
      input,
    };
    setErrorMessage(null);
    setPredictionStatus('starting');
    setIsProcessing(true);
    setProgress(0);
    isRunning.add(id);
    if (setIsRunningModelWhileInTour) {
      setIsRunningModelWhileInTour(true); // used for tour
    }
  };

  const handleStopProcessing = (status) => {
    lastRunParamsAndInputs.current = { params: null, input: null };
    setIsProcessing(false);
    setProgress(0);
    isRunning.delete(id);
    if (setIsRunningModelWhileInTour) {
      setIsRunningModelWhileInTour(false); // used for tour
    }
    predictionId.current = null;
    if (status) {
      updateModelRunTriggerStatus(id, status);
    }
  };

  const pollingCallbacks = useCallback(
    {
      onProgress: (localProgress) => {
        setProgress(localProgress);
        updateModelRunTriggerStatus(id, 'processing', localProgress);
      },
      onSuccess: (results, remainingCredits) => {
        hasNewResults.current = true;
        const newResults = results.map((result) => ({
          ...result,
          input: _.merge({}, lastRunParamsAndInputs.current.params || {}, lastRunParamsAndInputs.current.input || {}),
        }));

        updateNodeData(id, {
          result: data?.result ? [...data.result, ...newResults] : newResults,
        });

        if (remainingCredits != null) {
          setUserCredits(remainingCredits);
        }

        handleStopProcessing('ready');
        posthog.capture('run_model_end', { model: model.name, type: model.name });
        if (setIsRunningModelWhileInTourSuccess) {
          setIsRunningModelWhileInTourSuccess(true);
        }
      },
      onError: (error) => {
        setErrorMessage(error || 'Something went wrong');
        handleStopProcessing('failed');
        posthog.capture('run_model_error', { model: model.name, type: model.name, error });
      },
      onStatusChange: (status) => {
        setPredictionStatus(status);
        if (status === 'initial_processing') {
          updateModelRunTriggerStatus(id, 'initial_processing');
        }
      },
    },
    [id, data, model, handleStopProcessing, updateModelRunTriggerStatus, updateNodeData, setUserCredits, posthog],
  );

  useEffect(() => {
    if (!latestPrediction) {
      return;
    }

    if (latestPrediction.status === 'succeeded' || latestPrediction.status === 'canceled') {
      return;
    }

    if (latestPrediction.status === 'failed') {
      setErrorMessage(latestPrediction.error || 'Something went wrong');

      return;
    }

    prevSeed.current = params?.seed?.seed;
    handleStartProcessing();
    predictionId.current = latestPrediction.id;
    pollPredictionStatus(latestPrediction.id, pollingCallbacks);
  }, []);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const prevInput = usePrevious(data.input);
  // const runInitiatedRef = useRef(new Set());

  useEffect(() => {
    if (params && sizeOptions) {
      const index = sizeOptions.findIndex((option) => option.width === params.width && option.height === params.height);
      setSelectedSize(sizeOptions[index]);
    }
  }, [sizeOptions]);

  const updateOutputWithNewResults = useCallback(() => {
    if (hasNewResults.current) {
      setSelectedOutput(data.result ? data.result.length - 1 : 0);
      setOutput(data.result.length - 1);
    }
    hasNewResults.current = null;
  }, [data.result, hasNewResults]);

  useEffect(() => {
    if (hasNewResults.current) {
      updateOutputWithNewResults();
    }
  }, [data.result, updateOutputWithNewResults]);

  const getSchemaParams = useCallback(
    (schemaToPopulate) => {
      const isParamsEmpty = !params || Object.keys(params).length === 0;
      if (!isParamsEmpty) {
        return;
      }

      const defaultHandles = {};
      const initialFields = {};
      Object.keys(schemaToPopulate).forEach((key) => {
        const prop = schemaToPopulate[key];
        if (prop.default !== undefined) {
          initialFields[key] = prop.default;
        } else {
          initialFields[key] = ''; // Default for strings
        }
        if (prop.type === 'enum' && !prop.default) {
          initialFields[key] = prop.options[0];
        }

        if (prop.type === 'string') {
          defaultHandles[key] = {
            required: prop.required,
            description: prop.description,
            format: prop.format || 'text',
            order: prop.order,
            id: uuidv4(),
          };
        }

        if (prop.type === 'seed') {
          initialFields[key] = {
            isRandom: true,
            seed: _.random(1, 1000000),
          };
        }
      });

      return {
        params: initialFields,
        handles: {
          ...handles,
          input: { ...handles.input, ...defaultHandles },
        },
      };
    },
    [params, updateNodeData],
  );

  const getModelVersionParams = async (modelToFetch) => {
    try {
      const res = await axiosInstance.get(`/v1/models/${modelToFetch.name}/${modelToFetch.version}`);

      return res.data;
    } catch (error) {
      // console.error("Could not get version params ", error);
    }
  };

  const handleModelVersionChange = async (changedModel) => {
    if (changedModel.version && changedModel.service) {
      let newSchema;
      let schemaParams;
      if (changedModel.service === ModelType.Replicate) {
        if (Object.keys(schema).length === 0) {
          const res = await getModelVersionParams(changedModel);
          newSchema = extractInputSchemaDetails(res);
          schemaParams = getSchemaParams(newSchema);
        } else {
          schemaParams = getSchemaParams(schema);
        }
      } else if (changedModel.service === ModelType.Civit) {
        if (Object.keys(schema).length === 0) {
          newSchema = civitSchema;
          schemaParams = getSchemaParams(civitSchema);
        }
      }

      return {
        ...(schemaParams || {}),
        schema: newSchema,
      };
    }
  };

  ///// schema and params
  const handleChange = (key, newValue) => {
    // For numerical fields, ensure that the newValue is correctly parsed
    const isNumeric = ['integer', 'number', 'input', 'input-integer', 'input-float'].includes(schema[key]?.type);
    let parsedValue = isNumeric ? parseFloat(newValue) : newValue;
    if (schema[key]?.type === 'seed') {
      parsedValue = {
        isRandom: newValue.isRandom,
        seed: parseFloat(newValue.seed) || 1,
      };
    }

    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNumeric && isNaN(parsedValue)) {
      parsedValue = undefined;
    }

    //// quick fix for handling runway and kling pricing dif between 5 and 10 seconds
    if (
      model.name === ModelType.RunwayVideo ||
      model.name === ModelType.Kling ||
      (model.name === ModelType.LumaRay2 && (key === 'duration' || key === 'model' || key === 'resolution'))
    ) {
      let duration, modelVersion, resolution;
      if (key === 'model') {
        modelVersion = newValue;
        duration = params.duration;
        resolution = params?.resolution;
      } else if (key === 'resolution') {
        duration = params?.duration;
        modelVersion = params.model || null;
        resolution = newValue;
      } else if (key === 'duration') {
        duration = newValue;
        modelVersion = params.model || null;
        resolution = params?.resolution;
      }
      const price = getModelPrice(model.name, modelVersion, duration, resolution);
      updateNodeData(id, {
        paid: price,
      });
    }

    const newParams = {
      ...params,
      [key]: parsedValue,
    };
    setParams(newParams);
    updateNodeData(id, {
      params: newParams,
    });
  };

  const validateRequiredInput = (handlesToValidate, inputToValidate) => {
    if (data.version !== 2) return { isValid: true, missingKeys: null }; // backwards compatibility
    let missingKeys = [];
    if (!inputToValidate) {
      missingKeys = Object.entries(handlesToValidate.input)
        .filter(([, handle]) => handle.required)
        .map(([key]) => key);

      return { isValid: false, missingKeys };
    }
    // Iterate over handles to find missing required inputs
    for (const [key, handle] of Object.entries(handlesToValidate.input)) {
      if (handle.required && !inputToValidate[key]) {
        missingKeys.push(key);
      }
    }

    return missingKeys.length > 0 ? { isValid: false, missingKeys } : { isValid: true, missingKeys: null };
  };

  // tha main run model function
  const run = async () => {
    if (setIsRunningModelWhileInTourSuccess) {
      setIsRunningModelWhileInTourSuccess(false);
    }
    setValidationError([]);
    const validationResult = validateRequiredInput(handles, data.input);
    if (!validationResult.isValid) {
      setValidationError(validationResult.missingKeys);
      setErrorMessage(`${translate(I18N_KEYS.MODEL_NODE.ERROR_REQUIRED)} ${validationResult.missingKeys}`);
      posthog.capture('run_model_error', { model: model.name, type: model.name, error: 'missing required input' });
      return;
    }

    posthog.capture('run_model_start', { model: model.name, type: model.name });

    // handle seed management
    if (params?.seed) {
      if (params.seed.isRandom) {
        let maxSeed = 1000000;
        if (model.name === ModelType.Hyper3dRodin) {
          maxSeed = 65535;
        }
        prevSeed.current = _.random(1, maxSeed);
      } else prevSeed.current = params?.seed.seed || undefined;
    }

    if (params?.seed) {
      updateNodeData(id, {
        params: {
          ...params,
          seed: {
            ...params.seed,
            seed: prevSeed.current,
          },
        },
      });
    }

    const cloned_input = _.cloneDeep(input);
    if (model.name === 'gbieler/change-background-and-relight' || model.name === 'zsxkib/ic-light') {
      cloned_input.subject_image.url = await rgbaToRgb(cloned_input.subject_image.url);
    }

    handleStartProcessing();

    try {
      const { predictionId: predictionIdRes } = await runModel(
        handles,
        cloned_input,
        model,
        params,
        id,
        recipeId,
        recipeVersion,
        pollingCallbacks,
        prevSeed.current,
        data.version,
      );
      predictionId.current = predictionIdRes;
    } catch (error) {
      setErrorMessage(error?.response?.data?.error?.detail || 'Something went wrong. Check inputs');
      handleStopProcessing('failed');
      posthog.capture('run_model_error', {
        model: model.name,
        type: model.name,
        error: error?.response?.data?.error?.detail,
      });
    }
  };

  const getModelVersionByName = async (modelNameToFetch) => {
    try {
      const response = await axiosInstance.get(`/v1/models/${modelNameToFetch}`);

      return response.data;
    } catch (error) {
      setCannotFindModel(true);
    }
  };

  /// running the model from the outside (flow.jsx)
  useEffect(() => {
    if (modelRunTrigger.length === 0) return;

    const currentModel = modelRunTrigger.find((m) => m.id === id);

    if (!currentModel) return;

    if (currentModel.status === 'canceled') {
      predictionCanceled.value = true;
      handleStopProcessing();

      return;
    }

    // if(runInitiatedRef.current.has(id)) return;
    if (isRunning.has(id)) return;

    if (currentModel.status !== 'pending') return;

    const inputsChanged = JSON.stringify(input) !== JSON.stringify(prevInput);

    setIsProcessing(true);

    /// prevent from running the model twice in parallel (because of the input and modelRunTriggger dependency)
    if (isProcessing) return;

    const { predecessors } = currentModel;
    if (!predecessors || predecessors.length === 0) {
      run();

      return;
    }

    const predecessorsStatus = predecessors.map(
      (predecessorId) => modelRunTrigger.find((m) => m.id === predecessorId)?.status,
    );

    const allPredecessorsReady = predecessorsStatus.every((status) => status === 'ready');
    const anyPredecessorFailedOrCanceled = predecessorsStatus.some(
      (status) => status === 'failed' || status === 'canceled',
    );

    if (anyPredecessorFailedOrCanceled) {
      predictionCanceled.value = true;
      handleStopProcessing('canceled');

      return;
    }
    if (allPredecessorsReady && inputsChanged) {
      run();
    }
  }, [modelRunTrigger, input]);

  useEffect(() => {
    if (predictionStatus === 'succeeded' && data.result) {
      updateModelRunTriggerStatus(id, 'ready', 100);
      // runInitiatedRef.current.delete(id);
      isRunning.delete(id);
    }
  }, [data.result, predictionStatus]);

  useEffect(() => {
    if (params && selectedSize) {
      updateNodeData(id, {
        params: {
          ...params,
          width: selectedSize.width,
          height: selectedSize.height,
        },
      });
    }
  }, [selectedSize]);

  const handleOpenGalleryClick = useCallback(() => {
    setMediaArray(data.result);
    setSelectedFile(selectedOutput);
    setShowGallery(!showGallery);
  }, [data.result, selectedOutput, showGallery, setMediaArray, setSelectedFile, setShowGallery]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        // [FIX] prevent gallery from opening when typing in input or textarea.
        return;
      }
      if (event.code === 'Space' && showGalleryIcon) {
        event.preventDefault();
        handleOpenGalleryClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showGalleryIcon, handleOpenGalleryClick]);

  const getCivitModelVersionByName = async (civitModelName) => {
    const civitId = civitModelName.split('/').filter((part) => /^\d+$/.test(part))[0];
    try {
      const response = await axiosInstance.get(`/v1/models/c/${civitId}`);

      return response.data;
    } catch (error) {
      setCannotFindModel(true);
    }
  };

  const getCivitModelVersionByID = async (civitId) => {
    try {
      const response = await axiosInstance.get(`/v1/models/c/version/${civitId}`);

      return response.data;
    } catch (error) {
      console.error('Could not get civit version ', error);
    }
  };

  const handleModelNamePaste = async (event) => {
    const namePasted = event.clipboardData.getData('text');
    setCivitModelNotSupported(false);
    setModelLoading(true);

    let updatedDescription;
    const updatedModel = {
      name: namePasted,
      version: null,
      coverImage: null,
      service: null,
    };

    if (namePasted.includes('/civitai.com')) {
      const res1 = await getCivitModelVersionByName(namePasted);
      const res2 = await getCivitModelVersionByID(res1.modelVersions[0].id);

      const regex = /urn:air:[^:]+:([^:]+):[^:]+:[^@]+@[^@]+/;
      const match = res2.air.match(regex);
      if (match[1] !== 'checkpoint') {
        setCivitModelNotSupported(true);
        setCivitModelNotSupportedMessage(civitModelTypeError(match[1]));
        setModelLoading(false);

        return;
      }
      // move to obj
      updatedModel.version = res2.air;
      updatedModel.coverImage = res2.images[0].url;
      updatedModel.name = res1.name;
      updatedDescription = 'Civit.ai Model';
      updatedModel.service = 'civit';
    } else {
      let cleanedName;
      if (namePasted.includes('https://replicate.com/')) {
        cleanedName = namePasted.replace('https://replicate.com/', '');
      } else {
        cleanedName = namePasted.split(':')[0];
      }
      const res = await getModelVersionByName(cleanedName);
      if (!res) {
        setModelLoading(false);

        return;
      }

      updatedModel.coverImage = res.cover_image_url;
      updatedModel.version = res.latest_version.id;
      updatedDescription = res.description;
      updatedModel.service = 'replicate';
      updatedModel.name = cleanedName;
    }

    const paid = getModelPrice(updatedModel.name, updatedModel.version);
    const dataToUpdate = {
      name: updatedModel.name,
      description: updatedDescription,
      model: updatedModel,
      paid,
    };

    const { schema: newSchema, handles: newHandles, params: newParams } = await handleModelVersionChange(updatedModel);

    if (newParams) {
      dataToUpdate.params = newParams;
    }

    if (newHandles) {
      dataToUpdate.handles = newHandles;
    }

    if (newSchema) {
      dataToUpdate.schema = newSchema;
      dataToUpdate.version = 2;
    }

    updateNodeData(id, dataToUpdate);

    setTimeout(() => {
      if (!updatedModel.version) {
        setCannotFindModel(true);
        setModelLoading(false);
      } else {
        setModel(updatedModel);
        setModelLoading(false);
      }
    }, 0);
  };

  const cancelRun = async () => {
    const predictionIdToCancel = predictionId.current;
    if (predictionIdToCancel) {
      handleStopProcessing('canceled');
      try {
        await axiosInstance.post(`/v1/models/predict/cancel`, { predictionId: predictionIdToCancel });
      } catch (e) {
        console.error('Failed to cancel prediction', e);
      }
    }
  };

  const resetModelParams = () => {
    const emptyModel = {
      name: '',
      label: '',
      version: '',
      coverImage: '',
      service: '',
    };

    setModel(emptyModel);
    setCannotFindModel(false);

    updateNodeData(id, {
      handles: {
        ...handles,
        input: [],
      },
      params: {},
      schema: {},
      model: emptyModel,
    });
  };

  const handleModelNameChange = (event) => {
    //todo: better product handling - need to check if the model exists
    setModel({
      ...model,
      name: event.target.value,
    });
    setCannotFindModel(false);
    if (event.target.value === '') {
      resetModelParams();
    }
  };

  // 3D viewer related stuff
  useEffect(() => {
    if (exported3DImage?.url && data.output) {
      updateNodeData(id, {
        output: {
          ...data.output,
          ['image']: exported3DImage,
        },
      });
    }
  }, [exported3DImage]);

  useEffect(() => {
    updateNodeData(id, {
      is3DLocked,
      cameraPosition,
    });
  }, [is3DLocked, cameraPosition]);

  // end of 3D viewer related stuff

  const getButtonTitle = () => {
    if (!isProcessing) {
      return data.result && data.result.length > 0 ? 'Re-run model' : 'Run Model';
    }

    switch (predictionStatus) {
      case 'starting':
        return 'Starting';
      case 'processing':
        return `${progress}%`;
      case 'initial_processing':
        return 'Processing';
      default:
        return 'Waiting';
    }
  };

  return (
    <>
      {container === 'node' && (
        <>
          <Typography variant="caption" dangerouslySetInnerHTML={{ __html: description }} />
          {editable && (
            <Box
              className={isFocused ? 'nowheel nodrag nopan' : ''}
              sx={{ mt: 2, width: '100%', position: 'relative' }}
            >
              <TextField
                autoComplete="new-password" // prevent autocomplete
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
                disabled={!!(!editable || model.version) || !hasEditingPermissions(role, data)}
                sx={{ mb: 1 }}
                size="small"
                label="Model Name"
                fullWidth
                value={model.name}
                onChange={handleModelNameChange}
                onPaste={(event) => handleModelNamePaste(event)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {modelLoading && <CircularProgress size={14} color="weavy_cta_secondary" />}
                    </InputAdornment>
                  ),
                }}
              />
              {cannotFindModel && (
                <Typography
                  color={color.Red}
                  variant="caption"
                  sx={{ mt: -1, fontSize: '9px', position: 'relative', top: '-5px', left: '2px' }}
                >
                  Could not find model
                </Typography>
              )}
            </Box>
          )}
          {model.coverImage && !data.result && (
            <Box sx={{ width: '100%', mb: 1 }}>
              <img src={model.coverImage} width={'100%'} alt={'model cover image'} />
            </Box>
          )}
          <Box sx={{ mt: 1 }}>
            {data.result && (
              <Box
                sx={{ position: 'relative' }}
                onMouseEnter={() => setShowGalleryIcon(true)}
                onMouseLeave={() => setShowGalleryIcon(false)}
              >
                <NodeImageList
                  nodeName={data.name}
                  images={data.result}
                  selected={selectedOutput}
                  setSelected={setSelectedOutput}
                  container={'node'}
                  disabled={data.isLocked}
                  threeDProps={
                    data.result?.[0]?.type?.includes('3D')
                      ? {
                          cameraPosition,
                          setCameraPosition,
                          is3DLocked,
                          setIs3DLocked,
                          setExported3DImage,
                        }
                      : null
                  }
                />
                {data.result.length > 0 && data.result[0].type !== 'text' && (
                  <ButtonBase
                    onClick={handleOpenGalleryClick}
                    sx={{
                      position: 'absolute',
                      bottom: '11px',
                      right: '5px',
                      opacity: showGalleryIcon ? 1 : 0,
                      transition: 'opacity 0.2s',
                    }}
                  >
                    <FullscreenIcon fontSize="medium" />
                  </ButtonBase>
                )}
              </Box>
            )}
          </Box>
          {data.paid && credits < data.paid ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: color.Yambo_Purple,
                borderRadius: 1,
                py: 0.2,
              }}
            >
              <Typography variant="caption">Looks like you&apos;re running low on credits,</Typography>
              <Typography variant="caption">
                <Link sx={{ fontWeight: 'bold' }} onClick={() => openUpgradeModal()}>
                  Buy more credits
                </Link>{' '}
                to run this model!
              </Typography>
            </Box>
          ) : (
            <Box id={`run-model-button-container-${id}`}>
              <CancellableLoadingButton
                run={run}
                onCancel={cancelRun}
                isProcessing={isProcessing}
                title={getButtonTitle()}
                data={data}
                canCancel={!!predictionId.current}
                disabled={!hasEditingPermissions(role, data) || (data.paid && credits < data.paid)}
                shouldShowCreditsToMembers={shouldShowCreditsToMembers}
              />
            </Box>
          )}
          {model.name === 'rw_video' && (
            <Box sx={{ width: '100%', px: 12, mt: 1.5 }}>
              <img src="/powered-by-runway.svg" alt="powered by Runway" width="100%" />
            </Box>
          )}
        </>
      )}
      {schema && Object.keys(schema).length !== 0 && container === 'drawer' && (
        <Box id="advanced-mode-container" sx={{ width: '100%', mt: 1 }}>
          <Box id="advnaced-mode-content-container" className="nowheel nodrag nopan">
            <DynamicFields
              params={params}
              schema={schema}
              handleChange={handleChange}
              handleExposeProperty={handleExposePropertyCallback}
              handleCollapseProperty={handleCollapsePropertyCallback}
              version={data.version}
              deleteEdgeByTargetHandleId={deleteEdgeByTargetHandleId}
            />
          </Box>
        </Box>
      )}
      {(errorMessage || civitModelNotSupported) && (
        <Box id="model-error-container" sx={{ mt: 1 }}>
          {errorMessage && (
            <Typography variant="caption" color="error">
              {errorMessage
                ? typeof errorMessage === 'string'
                  ? errorMessage
                  : JSON.stringify(errorMessage)
                : 'Something went wrong'}
            </Typography>
          )}
          {civitModelNotSupported && (
            <Typography variant="caption" color="error">
              {civitModelNotSupportedMessage}
            </Typography>
          )}
        </Box>
      )}
      {model.name && model.version && editable && role === 'editor' && setNodesTypes && (
        <Box id="save-model-container" sx={{ mt: 1, width: '100%', justifyContent: 'center', display: 'flex' }}>
          <SaveCustomNode id={id} data={data} setNodesTypes={setNodesTypes} />
        </Box>
      )}
    </>
  );
}

export default ModelBaseComponent;
