import { useEffect } from 'react';
import { colorMap } from '../../colors';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';

function RouterNode({ id, data, updateNodeData }) {
  const { input, handles } = data;

  // on Connect / disconnect
  useEffect(() => {
    if (input && input[handles.input[0]]) {
      updateNodeData(id, {
        output: {
          [handles.output[0]]: input[handles.input[0]],
        },
      });
    } else {
      updateNodeData(id, {
        output: {
          [data.handles.output[0]]: null,
        },
      });
    }
  }, [input, input[handles.input[0]]]);

  return (
    <>
      <DynamicNode2
        id={id}
        data={data}
        hideBody={true}
        inputHandleYPos="50%"
        className="router"
        handleColor={colorMap.get(data.color)}
        headerColor={colorMap.get(data.dark_color)}
        icon={<i className="fa-sharp fa-light fa-code-fork fa-rotate-90"></i>}
      ></DynamicNode2>
    </>
  );
}

export default RouterNode;
