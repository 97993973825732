import React from 'react';
import { colorMap } from '../../../colors';
import { DynamicNode2 } from '../DynamicNode/DynamicNode2';
import NumberCore from './NumberCore';

function NumberNode({ id, data, updateNodeData }) {
  return (
    <DynamicNode2
      id={id}
      data={data}
      className="number"
      handleColor={colorMap.get(data.color)}
      headerColor={colorMap.get(data.dark_color)}
    >
      <NumberCore id={id} data={data} updateNodeData={updateNodeData} />
    </DynamicNode2>
  );
}

export default NumberNode;
