import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colorMap } from '../../colors';
import I18N_KEYS from '../../language/keys';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import ModelBaseComponent from './ModelBaseComponent';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import { downloadFile, downloadAllToZip } from './Utils';

function ModelBaseNode({
  id,
  recipeId,
  recipeVersion,
  data,
  updateNodeData,
  editable,
  setNodesTypes,
  createNewNodeFromParamExpose,
  deleteEdgeByTargetHandleId,
  setIsRunningModelWhileInTour,
  setIsRunningModelWhileInTourSuccess,
}) {
  const { handles } = data;
  const { t: translate } = useTranslation();
  const [selectedOutput, setSelectedOutput] = useState(data.selectedOutput || 0);
  const [validationError, setValidationError] = useState([]);

  const [isDeletingAllResults, setIsDeletingAllResults] = useState(false);

  useEffect(() => {
    if (data.selectedOutput !== undefined) {
      setSelectedOutput(data.selectedOutput);
    }
  }, [data.selectedOutput]);

  const openDeleteAllResultsModal = () => {
    setIsDeletingAllResults(true);
  };

  const deleteAllResults = useCallback(() => {
    updateNodeData(id, {
      result: [],
      selectedOutput: 0,
      output: {},
    });
    setIsDeletingAllResults(false);
  }, [id, updateNodeData]);

  const deleteAllOtherResults = useCallback(() => {
    const selected = selectedOutput;
    const newResult = data.result.filter((_, index) => index === selected);
    const formattedOutput = {};
    handles.output.forEach((elementName, index) => {
      if (newResult[0 + index]) {
        const { ...rest } = newResult[0 + index];
        formattedOutput[elementName] = rest;
      } else formattedOutput[elementName] = undefined;
    });

    updateNodeData(id, {
      result: newResult,
      selectedOutput: 0,
      output: formattedOutput,
    });
  }, [id, updateNodeData, selectedOutput, handles.output]);

  const deleteCurrentResult = useCallback(() => {
    const selected = selectedOutput;
    let newSelected = selected;
    const newResult = data.result.filter((_, index) => index !== selected);

    if (newResult.length === 0) {
      newSelected = 0; // No results left
    } else if (selected >= newResult.length) {
      newSelected = newResult.length - 1; // Deleted the last item, select the previous
    }

    const formattedOutput = {};
    handles.output.forEach((elementName, index) => {
      if (newResult[newSelected + index]) {
        const { ...rest } = newResult[newSelected + index];
        formattedOutput[elementName] = rest;
      } else formattedOutput[elementName] = undefined;
    });
    updateNodeData(id, {
      result: newResult,
      selectedOutput: newSelected,
      output: formattedOutput,
    });
  }, [data.result, id, selectedOutput, updateNodeData, handles.output]);

  const goToSource = () => {
    if (data.source) {
      window.open(data.source, '_blank');
    }
  };

  const setOutput = useCallback(
    (selected) => {
      const formattedOutput = {};
      handles.output.forEach((elementName, index) => {
        if (data.result[selected + index]) {
          const { ...rest } = data.result[selected + index];
          formattedOutput[elementName] = rest;
        } else formattedOutput[elementName] = undefined;
      });
      updateNodeData(id, {
        selectedOutput: selected,
        output: formattedOutput,
      });
    },
    [handles.output, data.result, updateNodeData, id],
  );

  useEffect(() => {
    if (data.result) {
      setOutput(selectedOutput);
    }
  }, [selectedOutput]);

  const downloadCurrentResult = async () => {
    await downloadFile(
      data.result[selectedOutput],
      `weavy-${data.name || ''}-${selectedOutput + 1}.${data.result[selectedOutput].url.split('.').pop()}`,
    );
  };

  const downloadAllResults = async () => {
    await downloadAllToZip(data.result, data.name);
  };

  const nodeMenu = [
    {
      type: 'divider',
    },
    {
      name: 'Download current generation',
      action: downloadCurrentResult,
      disabled: !data.result || data.result.length === 0 ? true : false,
    },
    {
      name: 'Download all generations',
      action: downloadAllResults,
      disabled: !data.result || data.result.length === 0 ? true : false,
    },
    {
      type: 'divider',
    },
    {
      name: 'Delete current generation',
      action: deleteCurrentResult,
      disabled: !data.result || data.result.length === 0 || data.isLocked ? true : false,
      shortcut: data.isLocked ? <i className="fa-solid fa-lock fa-sm" style={{ opacity: 0.6 }}></i> : '',
    },
    {
      name: 'Delete all other generations',
      action: deleteAllOtherResults,
      disabled: !data.result || data.result.length === 0 || data.isLocked ? true : false,
      shortcut: data.isLocked ? <i className="fa-solid fa-lock fa-sm" style={{ opacity: 0.6 }}></i> : '',
    },
    {
      type: 'divider',
    },
    {
      name: 'Delete all generations',
      action: openDeleteAllResultsModal,
      disabled: !data.result || data.result.length === 0 || data.isLocked ? true : false,
      shortcut: data.isLocked ? <i className="fa-solid fa-lock fa-sm" style={{ opacity: 0.6 }}></i> : '',
    },
    {
      type: 'divider',
    },
    {
      name: 'Learn more about this model',
      action: goToSource,
      disabled: !data.source ? true : false,
    },
  ];

  return (
    <>
      <DynamicNode2
        id={id}
        recipeId={recipeId}
        data={data}
        className="model"
        handleColor={colorMap.get(data.color)}
        additionalMenu={nodeMenu}
        validationError={validationError}
      >
        <ModelBaseComponent
          id={id}
          data={data}
          updateNodeData={updateNodeData}
          selectedOutput={selectedOutput}
          setSelectedOutput={setSelectedOutput}
          setOutput={setOutput}
          container="node"
          setValidationError={setValidationError}
          editable={editable}
          setNodesTypes={setNodesTypes}
          createNewNodeFromParamExpose={createNewNodeFromParamExpose}
          deleteEdgeByTargetHandleId={deleteEdgeByTargetHandleId}
          recipeId={recipeId}
          recipeVersion={recipeVersion}
          setIsRunningModelWhileInTour={setIsRunningModelWhileInTour}
          setIsRunningModelWhileInTourSuccess={setIsRunningModelWhileInTourSuccess}
        />
      </DynamicNode2>
      <ConfirmationDialog
        message={translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU_CONFIRMATION_DIALOG.MESSAGE)}
        onConfirm={deleteAllResults}
        open={isDeletingAllResults}
        confirmText={translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU_CONFIRMATION_DIALOG.CONFIRM_TEXT)}
        cancelText={translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU_CONFIRMATION_DIALOG.CANCEL_TEXT)}
        onCancel={() => setIsDeletingAllResults(false)}
        onClose={() => setIsDeletingAllResults(false)}
      />
    </>
  );
}

export default ModelBaseNode;
