export const ReplicateModelNameToCredits = new Map([
  ['tencent/hunyuan-video', 50],
  ['haiper-ai/haiper-video-2', 50],
  ['minimax/video-01', 50],
  ['minimax/video-01-director', 50],
  ['luma/ray-2-720p', 80],
  ['luma/ray-2-540p', 40],
  ['kwaivgi/kling-v1.6-standard', 15],
  ['kwaivgi/kling-v1.6-pro', 65],
]);
