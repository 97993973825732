import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Avatar,
  Select,
  MenuItem,
  Button,
  Tooltip,
  CircularProgress,
  Snackbar,
  Portal,
  Alert,
  Skeleton,
} from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { TeamMemberStatus } from '../../../enums/team-member-status.enum';
import { UserRoles } from '../../../enums/user-roles.enum';
import { I18N_KEYS } from '../../../language/keys';
import { color } from '../../../colors';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TeamTable({
  members,
  currentUser,
  isLoading,
  updateMemberRole,
  resendInvite,
  removeMember,
  copyInviteLink,
  copyingInviteId,
  currentUserRole,
}) {
  const { t: translate } = useTranslation();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  useEffect(() => {
    setPage(0);
  }, [members]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = members.map((n) => n.id);
      setSelected(newSelected);

      return;
    }
    setSelected([]);
  };

  // handle click to add to selected list . Keepoing this here if we need
  // const handleClick = (event, id) => { // handle click to add to selected list . Keepoing this here if we need
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty users.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members?.length) : 0;

  const visibleRows = useMemo(
    () =>
      members
        ? [...members].sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [order, orderBy, page, rowsPerPage, members],
  );

  const handleCopyInviteLink = async (id) => {
    const success = await copyInviteLink(id);
    if (success) {
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 2000);
    }
  };

  const handleResendInvitation = async (id) => {
    try {
      await resendInvite(id);
      setShowSuccessSnackbar(true);
    } catch (e) {
      console.error('Failed resending invitation', e);
      //todo: show error to user
    }
  };

  const disableRoleEdit = (member) => {
    return currentUserRole !== UserRoles.ADMIN || member.id === currentUser.uid;
  };

  return (
    <Box id="team-table-container" sx={{ width: '100%', backgroundColor: 'transparent' }}>
      <Paper sx={{ width: '100%', backgroundColor: 'transparent' }}>
        <TableContainer>
          <Table sx={{ width: '100%', minWidth: '700px' }} aria-labelledby="tableTitle" size={'large'}>
            {/* <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={users?.length}
                /> */}
            <TableBody>
              {isLoading && (
                <>
                  <TableRow
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    <TableCell width="280px">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Skeleton variant="circular" width="32px" height="32px" />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Skeleton variant="text" width="200px" height="24px" />
                          <Skeleton variant="text" width="200px" height="12px" />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell width="200px">
                      <Skeleton variant="text" width="100%" height="48px" />
                    </TableCell>
                    <TableCell width="100px">
                      <Skeleton variant="text" width="100%" height="48px" />
                    </TableCell>
                    <TableCell width="100px">
                      <Skeleton variant="text" width="100%" height="48px" />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {!isLoading &&
                visibleRows &&
                visibleRows.length > 0 &&
                visibleRows.map((member, index) => {
                  const isItemSelected = selected.includes(member.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover={member.id !== currentUser.uid && currentUserRole === UserRoles.ADMIN}
                      onMouseEnter={(event) => setHoveredRow(member.id)}
                      onMouseLeave={(event) => setHoveredRow(null)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={member.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'auto', border: 'none', borderTop: `1px solid ${color.Dark_Grey}` }}
                    >
                      {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Avatar sx={{ width: 32, height: 32, mr: 2 }} src={member.avatar} />
                          {member.name ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant="body2">
                                {member.name}
                                {member.id === currentUser.uid ? ' (You)' : ''}
                              </Typography>
                              <Typography variant="caption">{member.email}</Typography>
                            </Box>
                          ) : (
                            <Typography variant="body2">{member.email}</Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ width: 200 }}>
                        <Select
                          size="small"
                          disabled={disableRoleEdit(member)}
                          fullWidth
                          value={member.role}
                          onChange={(e) => updateMemberRole(member.id, e.target.value)}
                        >
                          <MenuItem value={UserRoles.ADMIN}>Admin</MenuItem>
                          {/* <MenuItem value={ UserRoles.BUILDER }>Builder</MenuItem> */}
                          <MenuItem value={UserRoles.MEMBER}>Member</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="center">
                        {(() => {
                          switch (member.status) {
                            case TeamMemberStatus.INVITED:
                              return (
                                <Typography
                                  variant="caption"
                                  fontWeight="bold"
                                  sx={{ color: `${color.Yellow} !important` }}
                                >
                                  {translate(I18N_KEYS.SETTINGS.TEAM.USER_STATUS.INVITED)}
                                </Typography>
                              );
                            case TeamMemberStatus.ACTIVE:
                              return (
                                <Typography
                                  variant="caption"
                                  fontWeight="bold"
                                  sx={{ color: `${color.Yambo_Green_Stroke} !important` }}
                                >
                                  {translate(I18N_KEYS.SETTINGS.TEAM.USER_STATUS.ACTIVE)}
                                </Typography>
                              );
                          }
                        })()}
                      </TableCell>
                      {currentUserRole === UserRoles.ADMIN && (
                        <TableCell align="right" sx={{ width: 120 }}>
                          {member.status === TeamMemberStatus.ACTIVE && member.id !== currentUser.uid && (
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => removeMember(member.id)}
                              sx={{
                                opacity: hoveredRow === member.id ? 1 : 0,
                                transition: 'opacity 0.15s ease-out',
                                boxShadow: 'none',
                                '&:hover': {
                                  boxShadow: 'none',
                                },
                              }}
                            >
                              <i className="fa-light fa-xmark"></i>&nbsp;
                              <Typography variant="caption">Remove</Typography>
                            </Button>
                          )}
                          {member.status === TeamMemberStatus.INVITED && member.id !== currentUser.uid && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                opacity: hoveredRow === member.id || copyingInviteId === member.id ? 1 : 0,
                                transition: 'opacity 0.15s ease-out',
                                gap: 1,
                              }}
                            >
                              <Tooltip
                                title={
                                  isLinkCopied
                                    ? translate(I18N_KEYS.GENERAL.COPIED)
                                    : translate(I18N_KEYS.SETTINGS.TEAM.COPY_INVITE_LINK)
                                }
                                placement="top"
                              >
                                <Button
                                  variant="outlined"
                                  color="weavy_cta_secondary"
                                  size="small"
                                  onClick={() => handleCopyInviteLink(member.id)}
                                  disabled={copyingInviteId === member.id}
                                  sx={{
                                    height: '32px',
                                    width: '32px',
                                    minWidth: 0,
                                    boxShadow: 'none',
                                    '&:hover': { boxShadow: 'none' },
                                  }}
                                >
                                  {copyingInviteId === member.id ? (
                                    <i
                                      className="fa-light fa-spinner-third fa-spin"
                                      style={{ '--fa-animation-duration': '0.4s' }}
                                    ></i>
                                  ) : isLinkCopied ? (
                                    <i className="fa-light fa-check"></i>
                                  ) : (
                                    <i
                                      className="fa-light fa-link-simple fa-rotate-by"
                                      style={{ transform: 'rotate(-45deg)' }}
                                    ></i>
                                  )}
                                </Button>
                              </Tooltip>
                              <Button
                                variant="contained"
                                color="weavy_cta_secondary"
                                size="small"
                                onClick={() => handleResendInvitation(member.id)}
                                sx={{
                                  height: '32px',
                                  boxShadow: 'none',
                                  '&:hover': { boxShadow: 'none' },
                                }}
                              >
                                <i className="fa-light fa-paper-plane"></i>&nbsp;
                                <Typography variant="caption" sx={{ color: `${color.Yambo_Text_On_White} !important` }}>
                                  Resend
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {members && members.length > 10 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={members?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ borderTop: `1px solid ${color.Dark_Grey}` }}
          />
        )}
      </Paper>
      <Portal>
        <Snackbar
          open={showSuccessSnackbar}
          onClose={() => setShowSuccessSnackbar(false)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowSuccessSnackbar(false)}
            severity="success"
            variant="filled"
            icon={<i className="fa-light fa-paper-plane"></i>}
            sx={{ width: '100%', color: 'white' }}
          >
            {translate(I18N_KEYS.SETTINGS.TEAM.INVITE_USER_MODAL.SUCCESS_MESSAGE)}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
}

export default TeamTable;
