import { useState, useEffect } from 'react';

export const AnimatedSuccessCheckmark = ({
  size = 35,
  onAnimationComplete,
  autoHide = false,
  hideDelay = 2000,
  className = '',
  style = {},
}: {
  size?: number;
  onAnimationComplete?: () => void;
  autoHide?: boolean;
  hideDelay?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const [show, setShow] = useState(true);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (autoHide) {
      const timer = setTimeout(() => {
        setShow(false);
        onAnimationComplete?.();
      }, hideDelay);
      return () => clearTimeout(timer);
    }
  }, [autoHide, hideDelay, onAnimationComplete]);

  const replay = () => {
    setShow(false);
    // Force remount to replay animation
    setTimeout(() => {
      setKey((prev) => prev + 1);
      setShow(true);
    }, 100);
  };

  if (!show) return null;

  return (
    <div
      style={{
        opacity: 1, // Override the default fade-out
        ...style,
      }}
    >
      <SuccessCheckmark key={key} size={size} className={className} />
    </div>
  );
};

// Original SuccessCheckmark remains unchanged
export const SuccessCheckmark = ({ size, className = '' }) => (
  <svg
    className={`checkmark ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
    style={{ width: size, height: size }}
  >
    <circle className={`checkmark__circle ${className}`} cx="26" cy="26" r="25" fill="none" />
    <path className={`checkmark__check ${className}`} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
);
