import { useState, useEffect } from 'react';
import { Box, TextField, ButtonBase, Link } from '@mui/material';
import { colorMap } from '../../colors';
import { useUserRole } from '../Recipe/UserRoleContext';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import { hasEditingPermissions } from './Utils';
function ArrayCore({ id, data, updateNodeData }) {
  const role = useUserRole();
  const [array, setArray] = useState(data?.result || ['']);
  const placeholder = data?.placeholder || 'Array item';

  useEffect(() => {
    if (data.externalData !== undefined) {
      setArray(data.externalData);
    }
  }, [data.externalData]);

  const handleItemChange = (index, value) => {
    const newArray = [...array];
    const parsedNumber = Number(value);
    newArray[index] = isNaN(parsedNumber) ? value : parsedNumber;
    setArray(newArray);
  };

  const handleItemDelete = (index) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    setArray(newArray);
  };

  const handleItemAdd = () => {
    setArray([...array, '']);
  };

  useEffect(() => {
    updateNodeData(id, {
      result: array,
      output: {
        type: 'array',
        [data.handles.output[0]]: array,
      },
    });
  }, [array]);

  return (
    <DynamicNode2
      id={id}
      data={data}
      updateNodeData={updateNodeData}
      className="array"
      handleColor={colorMap.get(data.color)}
      headerColor={colorMap.get(data.dark_color)}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        {array.map((item, index) => (
          <Box key={`${index}-arrayItem`} display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              placeholder={placeholder}
              size="small"
              value={item}
              onChange={(e) => handleItemChange(index, e.target.value)}
              disabled={!hasEditingPermissions(role, data)}
            />
            <ButtonBase onClick={() => handleItemDelete(index)} disabled={!hasEditingPermissions(role, data)}>
              <i className="fa-regular fa-circle-xmark" style={{ fontSize: '10px', opacity: 0.8 }}></i>
            </ButtonBase>
          </Box>
        ))}
        {role !== 'guest' && (
          <Box>
            <Link
              onClick={handleItemAdd}
              sx={{
                pointerEvents: role === 'guest' || data.isLocked ? 'none' : '',
              }}
            >
              + Add Another item
            </Link>
          </Box>
        )}
      </Box>
    </DynamicNode2>
  );
}

export default ArrayCore;
