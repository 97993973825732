import { Box, CircularProgress } from '@mui/material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { color } from '../../../../colors';
import Photopea from './photopea/Photopea';
import Inpainter from './Inpainter';
import CompositorV2 from './CompositorV2';
import DesignerEditor from './Designer/DesignerEditor';
import { useEditorContext } from './EditorContext';

function Loader({ offset }) {
  return (
    <Box
      sx={{
        zIndex: 100,
        background: color.Dark_Blue,
        opacity: '.8',
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid`,
        borderColor: color.Dark_Grey,
      }}
    >
      <Box
        sx={{
          left: `${offset / 2}px`,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <Typography variant="caption">Loading</Typography> */}
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );
}

function Editor() {
  const {
    handleCloseEditor,
    editorData: { id, data, type, setResult, updateNodeData },
  } = useEditorContext();
  const { input, handles } = data;

  const useWasm = useFeatureFlagEnabled('compositor-v3');
  const [srcFileUrl] = useState(input[handles.input[0]]?.url || '');
  const [psdFileUrl] = useState(data.result?.url || null);
  const [resultFile, setResultFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (resultFile) {
      setResult(resultFile);
    }
  }, [resultFile]);

  return (
    <>
      <Box
        id="editor-container"
        sx={{
          width: '100%',
          height: '100%',
          borderTop: '1px solid',
          borderColor: color.Dark_Grey,
          backgroundColor: color.Dark_Blue,
        }}
      >
        {type === 'photopea' && (
          <Photopea
            src={srcFileUrl}
            result={resultFile}
            setResult={setResultFile}
            onClose={handleCloseEditor}
            setIsLoading={setIsLoading}
            psdSrc={psdFileUrl}
          />
        )}
        {type === 'inpainter' && (
          <Inpainter
            src={srcFileUrl}
            result={resultFile}
            setResult={setResultFile}
            onClose={handleCloseEditor}
            setIsLoading={setIsLoading}
          />
        )}
        {type === 'compositorV2' &&
          (useWasm ? (
            <DesignerEditor
              id={id}
              data={data}
              result={resultFile}
              updateNodeData={updateNodeData}
              onClose={handleCloseEditor}
            />
          ) : (
            <CompositorV2
              id={id}
              data={data}
              result={resultFile}
              setResult={setResultFile}
              updateNodeData={updateNodeData}
              onClose={handleCloseEditor}
              setIsLoading={setIsLoading}
              container="editor"
            />
          ))}
      </Box>
      {isLoading && <Loader />}
    </>
  );
}

export default Editor;
