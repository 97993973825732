import React, { useCallback, useEffect, useState, useRef } from 'react';
import { colorMap } from '../../../colors';
import { DynamicNode2 } from '../DynamicNode/DynamicNode2';

function MergeAlphaNode({ id, data, updateNodeData }) {
  const [image, setImage] = useState(null);
  const [alphaImage, setAlphaImage] = useState(null);
  const blendWorker = useRef(null);
  const { handles, input } = data;

  useEffect(() => {
    if (input) {
      setImage(input[handles.input[0]]);
      setAlphaImage(input[handles.input[1]]);
    }
  }, [input, handles.input]);

  useEffect(() => {
    blendWorker.current = new Worker('/blendWorker.js');

    return () => {
      blendWorker.current?.terminate();
    };
  }, []);

  useEffect(() => {
    if (!image || !alphaImage || !blendWorker.current) {
      updateNodeData(id, {
        output: {
          [handles.output[0]]: null,
        },
      });
      return;
    }
    blendOriginalWithAlpha(image, alphaImage);
  }, [image, alphaImage, blendWorker.current]);

  const blendOriginalWithAlpha = useCallback(
    (image, alphaImage) => {
      if (!image?.url || !alphaImage?.url) {
        // console.error("Invalid image URLs:", {
        //     imageUrl: image?.url,
        //     alphaImageUrl: alphaImage?.url
        // });
        return;
      }

      // console.log("Starting blend process with URLs:", {
      //     imageUrl: image.url,
      //     alphaImageUrl: alphaImage.url
      // });

      const originalImage = new Image();
      const alphaChannelImage = new Image();

      // Error handling for image loading
      originalImage.onerror = (e) => {
        console.error('Error loading original image:', e);
      };

      alphaChannelImage.onerror = (e) => {
        console.error('Error loading alpha image:', e);
      };

      originalImage.onload = () => {
        // console.log("Original image loaded:", {
        //     width: originalImage.width,
        //     height: originalImage.height
        // });

        alphaChannelImage.onload = () => {
          // console.log("Alpha image loaded:", {
          //     width: alphaChannelImage.width,
          //     height: alphaChannelImage.height
          // });

          try {
            const originalCanvas = document.createElement('canvas');
            originalCanvas.width = originalImage.width;
            originalCanvas.height = originalImage.height;
            const originalCtx = originalCanvas.getContext('2d');
            originalCtx.drawImage(originalImage, 0, 0);

            const alphaCanvas = document.createElement('canvas');
            alphaCanvas.width = alphaChannelImage.width;
            alphaCanvas.height = alphaChannelImage.height;
            const alphaCtx = alphaCanvas.getContext('2d');
            alphaCtx.drawImage(alphaChannelImage, 0, 0);

            const originalImageData = originalCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height);
            const alphaImageData = alphaCtx.getImageData(0, 0, alphaCanvas.width, alphaCanvas.height);

            blendWorker.current.postMessage({ originalImageData, alphaImageData }, [
              originalImageData.data.buffer,
              alphaImageData.data.buffer,
            ]);
          } catch (error) {
            console.error('Error during canvas operations:', error);
          }
        };

        alphaChannelImage.src = alphaImage.url;
      };

      // Set crossOrigin before setting src
      originalImage.crossOrigin = 'anonymous';
      originalImage.src = image.url;

      blendWorker.current.onmessage = (e) => {
        const { blendedImageData } = e.data;

        try {
          const blendedCanvas = document.createElement('canvas');
          blendedCanvas.width = blendedImageData.width;
          blendedCanvas.height = blendedImageData.height;
          const blendedCtx = blendedCanvas.getContext('2d');
          blendedCtx.putImageData(blendedImageData, 0, 0);

          const blendedImage = blendedCanvas.toDataURL('image/png');
          // console.log("Blend complete, updating node data");

          const formattedOutput = {
            [handles.output[0]]: {
              type: 'image',
              url: blendedImage,
              width: image?.width,
              height: image?.height,
            },
          };
          updateNodeData(id, { output: formattedOutput });
        } catch (error) {
          console.error('Error processing worker response:', error);
        }
      };

      blendWorker.current.onerror = (error) => {
        console.error('Worker error:', error);
      };
    },
    [blendWorker.current, updateNodeData, id, handles.output],
  );

  return (
    <DynamicNode2
      id={id}
      data={data}
      hideBody={true}
      className="merge-alpha"
      inputHandleYPos="-25%"
      handleColor={colorMap.get(data.color)}
      headerColor={colorMap.get(data.dark_color)}
      icon={<i className="fa-solid fa-merge fa-lg"></i>}
    />
  );
}

export default MergeAlphaNode;
