import React, { useCallback, useEffect, useState } from 'react';
import { colorMap } from '../../colors';
import { getOS } from '../Nodes/Utils';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import ComfyCore from './ComfyCore';

function ComfyNode({ id, data, updateNodeData, setNodesTypes, deleteNode, duplicateNode }) {
  const { handles } = data;
  const [os, setOs] = React.useState('other');
  const [selectedOutput, setSelectedOutput] = useState(data.selectedOutput || 0);
  const shortcutKey = os === 'Mac' ? 'cmd' : 'ctrl';

  useEffect(() => {
    setOs(getOS());
  }, []);

  const handleDelete = useCallback(() => {
    deleteNode(id);
  }, [deleteNode, id]);

  const handleDuplicate = useCallback(() => {
    duplicateNode(id);
  }, [duplicateNode, id]);

  const deleteAllResults = useCallback(() => {
    updateNodeData(id, {
      result: [],
      selectedOutput: 0,
      output: {},
    });
  }, [id, updateNodeData]);

  const deleteCurrentResult = useCallback(() => {
    const selected = selectedOutput;
    let newSelected = selected;
    const newResult = data.result.filter((_, index) => index !== selected);

    if (newResult.length === 0) {
      newSelected = 0; // No results left
    } else if (selected >= newResult.length) {
      newSelected = newResult.length - 1; // Deleted the last item, select the previous
    }

    const formattedOutput = {};
    handles.output.forEach((elementName, index) => {
      formattedOutput[elementName] = newResult[newSelected + index];
    });
    updateNodeData(id, {
      result: newResult,
      selectedOutput: newSelected,
      output: formattedOutput,
    });
    setSelectedOutput(newSelected);
  }, [data.result, id, selectedOutput, updateNodeData]);

  const setOutput = useCallback(
    (selected) => {
      const formattedOutput = {};
      handles.output.forEach((elementName, index) => {
        formattedOutput[elementName] = data.result[selected + index];
      });
      updateNodeData(id, {
        selectedOutput: selected,
        output: formattedOutput,
      });
    },
    [handles.output, data.result, updateNodeData, id],
  );

  useEffect(() => {
    if (data.result) {
      setOutput(selectedOutput);
    }
  }, [selectedOutput]);

  const nodeMenu = [
    {
      name: 'Duplicate',
      action: handleDuplicate,
      shortcut: `${shortcutKey}+d`,
    },
    {
      name: 'Delete',
      action: handleDelete,
      shortcut: 'delete / backspace',
    },
    {
      type: 'divider',
    },
    {
      name: 'Delete current generation',
      action: deleteCurrentResult,
      disabled: !data.result || data.result.length === 0 ? true : false,
    },
    {
      name: 'Delete all generations',
      action: deleteAllResults,
      disabled: !data.result || data.result.length === 0 ? true : false,
    },
  ];

  return (
    <DynamicNode2 id={id} data={data} className="model" handleColor={colorMap.get(data.color)} menu={nodeMenu}>
      <ComfyCore
        id={id}
        data={data}
        updateNodeData={updateNodeData}
        setNodesTypes={setNodesTypes}
        selectedOutput={selectedOutput}
        setSelectedOutput={setSelectedOutput}
        setOutput={setOutput}
        container="node"
      />
    </DynamicNode2>
  );
}

export default ComfyNode;
