import React, { useEffect, useState, useContext } from 'react';
import { Typography, Box, LinearProgress, ButtonBase, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { Rnd } from '../../Nodes/Crop/rnd';
import ModelRunContext from './ModelRunContext';

function ModelRunConsole({ runningModels, nodes, onCloseConsole }) {
  const { stopSequence } = useContext(ModelRunContext);
  const [history, setHistory] = useState([]);
  // const [shouldCopyRunningModels, setShouldCopyRunningModels] = useState(true);

  useEffect(() => {
    setHistory((prevHistory) => {
      const updatedHistory = prevHistory.map((historyItem) => {
        const triggerUpdate = runningModels.find((trigger) => trigger.id === historyItem.id);

        return triggerUpdate ? { ...historyItem, ...triggerUpdate } : historyItem;
      });

      runningModels.forEach((trigger) => {
        if (!prevHistory.some((historyItem) => historyItem.id === trigger.id)) {
          updatedHistory.push(trigger);
        }
      });

      return updatedHistory;
    });
  }, [runningModels]);

  const handleCancelRunning = (predictionId) => {
    // console.log("cancel", predictionId);
    // setShouldCopyRunningModels(false);
    stopSequence(predictionId);
    /// now set all other models to canceled
    // setHistory(prevHistory => {
    //     return prevHistory.map(item => {
    //         return { ...item, status: "canceled" }
    //     });
    // });
  };

  useEffect(() => {
    console.log('history: ', history);
  }, [history]);

  const statusMap = {
    pending: 'Pending',
    starting: 'Starting',
    processing: 'Processing',
    ready: 'Done',
    failed: 'Failed',
    canceled: 'Canceled',
  };

  return (
    <Rnd enableResizing={false}>
      <Box sx={{ width: '550px', background: '#000A' }}>
        <code>
          <Box
            sx={{
              background: '#333',
              px: 1,
              py: 0.4,
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontFamily: 'inherit', fontSize: '12px' }}>Console</Typography>
            <ButtonBase sx={{ fontFamily: 'inherit' }} onClick={onCloseConsole}>
              <CloseIcon sx={{ fontSize: '12px' }} />
            </ButtonBase>
          </Box>
          <Box sx={{ p: 2 }}>
            {/* <Typography variant="body2" sx={{fontFamily:'inherit'}}>Running models: </Typography> */}
            {history &&
              history.length != 0 &&
              history.map((model) => {
                const node = nodes.find((n) => n.id === model.id);

                return (
                  <Box
                    key={node.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontFamily: 'inherit',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ fontFamily: 'inherit', fontSize: '12px' }}>
                        [{moment(model.time).format('YYYY-MM-DD HH:mm:ss')}]
                      </Typography>
                      <Typography variant="body2" sx={{ fontFamily: 'inherit', fontSize: '12px', ml: 0.5 }}>
                        {node.data.name}: {statusMap[model.status]}
                      </Typography>
                      {(model.status === 'processing' || model.status === 'starting') && (
                        <Link
                          onClick={() => handleCancelRunning(model.predictionId)}
                          sx={{ fontSize: '12px', ml: 0.5 }}
                        >
                          Cancel
                        </Link>
                      )}
                    </Box>
                    <Box sx={{ width: '100px' }}>
                      <LinearProgress
                        sx={{ height: '10px' }}
                        color="success"
                        value={model.progress ? model.progress : 0}
                        variant="determinate"
                      />
                    </Box>
                    {/* {model.status === 'processing' && model.progress} */}
                  </Box>
                );
              })}
          </Box>
        </code>
      </Box>
    </Rnd>
  );
}

export default ModelRunConsole;
