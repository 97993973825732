import { Box, Typography, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { color } from '../../../../../colors';
import { UILayer } from './DesignerEditor';

export const propertiesStructure = {
  position: {
    label: 'Position',
    properties: {
      x: { label: 'x', unit: '', type: 'number' },
      y: { label: 'y', unit: '', type: 'number' },
    },
  },
  rotation: {
    label: 'Rotation',
    properties: {
      rotation: { label: '', unit: '°', type: 'number' },
    },
  },
} as const;

const ExtraSmallOutlinedInput = styled(OutlinedInput)`
  & .MuiInputBase-root,
  & {
    font-size: 0.6rem;
    height: 26px;
  }

  & .MuiInputBase-input {
    padding: 4px 6px;

    /* Remove arrows from number input */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }

  & .MuiInputAdornment-root {
    font-size: 0.65rem;
  }

  // Change selection outline to white
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${color.Yambo_Idle_Icon} !important;
    border-width: 1px;
  }

  // Also ensure the fieldset border is white when focused
  & fieldset {
    &.Mui-focused {
      border-color: ${color.Yambo_Idle_Icon} !important;
    }
  }

  max-width: 70px;
  margin-left: 8px;
`;

const formatDisplayValue = (value?: string | number) => {
  if (value === undefined || value === null || typeof value === 'string') return '';
  return Number(value).toFixed(1);
};

interface LayerPropertiesPanelProps {
  layer: UILayer;
}

function LayerPropertyPanel({ layer }: LayerPropertiesPanelProps) {
  return (
    <Box
      id="compositor-layers-panel"
      sx={{
        background: color.Dark_Blue,
        position: 'absolute',
        top: 30,
        right: 30,
        borderRadius: 1,
        width: '240px',
        overflow: 'hidden',
      }}
    >
      <Box
        id="compositor-layers-panel-header"
        sx={{
          width: '100%',
          background: color.Yambo_Black,
          px: 1,
          py: 0.4,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
          <i className="fa-light fa-image fa-sm"></i>
          &nbsp;&nbsp;{layer.name}
        </Typography>
      </Box>

      <Box id="compositor-layers-panel-layers-container" sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {Object.entries(propertiesStructure).map(([groupKey, group]) => (
            <Box
              key={groupKey}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="caption">{group.label}</Typography>
              <Box>
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  {Object.entries(group.properties).map(([propKey, prop]) => (
                    <React.Fragment key={propKey}>
                      <FormControl variant="outlined">
                        {prop.type !== 'select' && (
                          <ExtraSmallOutlinedInput
                            value={formatDisplayValue(layer[propKey])}
                            disabled={layer.locked}
                            id={`property-${groupKey}-${propKey}`}
                            endAdornment={
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: layer.locked ? 'rgba(255,255,255,0.3)' : color.Yambo_Text_On_Dark,
                                  }}
                                >
                                  {prop.label}
                                  {prop.unit}
                                </span>
                              </InputAdornment>
                            }
                            inputProps={{
                              'aria-label': `${group.label} ${prop.label}`,
                              type: 'number',
                              onFocus: (e) => e.target.select(),
                            }}
                          />
                        )}
                      </FormControl>
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default LayerPropertyPanel;
