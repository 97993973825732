import { Box, Typography, IconButton, Button, Stack, Fade, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AnimatedSuccessCheckmark } from '../Common/AnimatedCheckmark';
import { LottieAnimation } from '../Common/LottieAnimation';
import { color } from '../../colors';
import { TourStepMediaType } from '../../enums/tour-step-media-type.enum';

export const TourContent = ({
  step,
  currentStep,
  totalSteps,
  onClose,
  onPrevStep,
  onNextStep,
  placement,
  showSuccessView,
  bgcolor,
  textColor,
}) => (
  <Box
    sx={{
      width: step.width ? step.width : 320,
      bgcolor: bgcolor,
      borderRadius: 1,
      p: 2,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 0,
        height: 0,
        border: '8px solid transparent',
        borderColor: 'transparent',
        ...(placement?.startsWith('top') && {
          bottom: -16,
          left: placement === 'top-start' ? '90%' : placement === 'top-end' ? '10%' : '50%',
          transform: 'translateX(-50%)',
          borderTopColor: bgcolor,
        }),
        ...(placement?.startsWith('bottom') && {
          top: -16,
          left: placement === 'bottom-start' ? '90%' : placement === 'bottom-end' ? '10%' : '50%',
          transform: 'translateX(-50%)',
          borderBottomColor: bgcolor,
        }),
        ...(placement === 'left' && {
          right: -16,
          top: '50%',
          transform: 'translateY(-50%)',
          borderLeftColor: bgcolor,
        }),
        ...(placement === 'right' && {
          left: -16,
          top: '50%',
          transform: 'translateY(-50%)',
          borderRightColor: bgcolor,
        }),
      },
    }}
    onClick={(e) => e.stopPropagation()}
  >
    <Box sx={{ position: 'relative' }}>
      <IconButton
        size="small"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: -16,
          top: -16,
          color: textColor,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <>
        <Typography variant="h6" gutterBottom color={`${textColor} !important`}>
          {step.title}
        </Typography>
        {showSuccessView ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <AnimatedSuccessCheckmark
              className="tour-success green"
              size={50}
              autoHide={false}
              style={{ margin: '20px auto' }}
            />
          </Box>
        ) : (
          <>
            {step.media && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  border: `1px solid`,
                  borderColor: color.Light_Grey,
                  borderRadius: 1,
                  overflow: 'hidden',
                }}
              >
                {step.mediaType === TourStepMediaType.IMAGE && (
                  <img src={step.media} alt={step.title} style={{ width: '100%', height: 'auto', marginBottom: 10 }} />
                )}
                {step.mediaType === TourStepMediaType.VIDEO && (
                  <video
                    src={step.media}
                    alt={step.title}
                    style={{ width: '100%', height: 'auto', marginBottom: 10 }}
                  />
                )}
              </Box>
            )}
          </>
        )}
        <Typography variant="body2" color={`${textColor} !important`} sx={{ mb: 2, mt: step.media ? 2 : 0 }}>
          {showSuccessView ? step.successView?.content : step.content}
        </Typography>
      </>

      {step.showButtons && !showSuccessView && (
        <Stack direction="row" justifyContent="space-between">
          {step.secondaryCta && (
            <Button onClick={onPrevStep} disabled={currentStep === 0}>
              {step.secondaryCta}
            </Button>
          )}
          <Button fullWidth={!step.secondaryCta} color="weavy_cta" variant="contained" onClick={onNextStep}>
            {currentStep === totalSteps - 1 ? 'Finish' : step.cta || 'Next'}
          </Button>
        </Stack>
      )}
      {showSuccessView && (
        <Stack direction="row" justifyContent="space-between">
          <Button size="small" onClick={onPrevStep} disabled={currentStep === 0}>
            Replay
          </Button>
          <Button size="small" variant="contained" onClick={onNextStep}>
            {step.successView?.cta || 'Continue'}
          </Button>
        </Stack>
      )}
      <Stepper currentStep={currentStep} totalSteps={totalSteps} theme="light" />
    </Box>
  </Box>
);

export const TourOverlay = ({ step, onNextStep, showSuccessView, currentStep, totalSteps, onLottieReady, onClose }) => (
  <Box
    sx={{ width: '750px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
  >
    <Fade in={showSuccessView} timeout={300}>
      <Box sx={{ display: showSuccessView ? 'block' : 'none' }}>
        <Box
          sx={{
            display: !showSuccessView ? 'flex' : 'none',
            textAlign: 'center',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="weavy_cta_secondary"
            onClick={onNextStep}
            sx={{ transform: 'scale(1.5)', fontFamily: 'ShantellSans' }}
          >
            {step.successView?.cta || 'Continue'}
            <Box
              component="i"
              className="fa-light fa-arrow-right"
              sx={{
                marginLeft: 1,
                '@keyframes moveRight': {
                  '0%': { transform: 'translateX(0)' },
                  '50%': { transform: 'translateX(5px)' },
                  '100%': { transform: 'translateX(0)' },
                },
                animation: 'moveRight 1s ease-in-out infinite',
              }}
            />
          </Button>
        </Box>
      </Box>
    </Fade>
    <Fade in={!showSuccessView} timeout={300}>
      <Box
        sx={{
          display: !showSuccessView ? 'flex' : 'none',
          textAlign: 'center',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {step.media && step.mediaType === TourStepMediaType.LOTTIE && (
          <LottieAnimation animationData={step.media} width={180} height={180} onReady={onLottieReady} />
        )}
        {step.media && step.mediaType === TourStepMediaType.IMAGE && (
          <img src={step.media} alt={step.title} style={{ width: '240px', height: 'auto', marginBottom: 10 }} />
        )}
        <Typography variant="h3" gutterBottom fontFamily="ShantellSans" sx={{ mb: 0 }}>
          {step.title}
        </Typography>
        <Typography variant="h5" color="text.secondary" fontFamily="ShantellSans" fontWeight="light">
          {step.content}
        </Typography>
        {step.showButtons && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 4,
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={onNextStep}
              color="weavy_cta_secondary"
              sx={{ fontSize: '1.5rem', fontFamily: 'ShantellSans' }}
            >
              {step.cta || 'Next'}
              <Box
                component="i"
                className="fa-light fa-arrow-right"
                sx={{
                  marginLeft: 1,
                  '@keyframes moveRight': {
                    '0%': { transform: 'translateX(0)' },
                    '50%': { transform: 'translateX(5px)' },
                    '100%': { transform: 'translateX(0)' },
                  },
                  animation: 'moveRight 1s ease-in-out infinite',
                }}
              />
            </Button>
            {step.secondaryCta && (
              <Link sx={{ color: color.Yambo_Text_On_Dark, mt: 2 }} onClick={onClose}>
                {step.secondaryCta}
              </Link>
            )}
          </Box>
        )}
        <Stepper currentStep={currentStep} totalSteps={totalSteps} theme="dark" />
      </Box>
    </Fade>
  </Box>
);

const Stepper = ({ currentStep, totalSteps, theme }) => {
  if (currentStep === totalSteps - 1) return null;
  return (
    <Stack direction="row" spacing={0.5} justifyContent="center" sx={{ mb: 0, mt: 2 }}>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <Box
          key={index}
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            border: `1px solid ${theme === 'light' ? color.Yambo_Text_On_White : color.Yambo_Text_On_Dark}`,
            bgcolor:
              index === currentStep
                ? theme === 'light'
                  ? color.Yambo_Text_On_White
                  : color.Yambo_Text_On_Dark
                : 'none',
          }}
        />
      ))}
    </Stack>
  );
};
