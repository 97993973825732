export const color = {
  Red: '#653FE1',
  Purple: '#a91bb3',
  Dark_Purple: '#901799',
  Deep_Purple: '#2d082f',
  Blue: '#3352bb',
  Azul: '#06a6ed',
  Green: '#05b246',
  Yellow: '#fcbf00',
  Orange: '#ff3905',
  Gray: '#557f8c',
  Dark_BG: '#101012',
  Dark_Blue: '#222126',
  Dark_Blue_Hover: '#0c1017',
  Light_menu_BG: '#253042',
  Light_Green: '#86c28d',
  Dark_Grey: '#302E33',
  Light_Grey: '#666',
  White: '#EAEAEA',
  Super_Light_Transparent: '#DDF2',
  Yambo_Green: '#16745D',
  Yambo_Green_Dark: '#0B4836',
  Yambo_Green_Stroke: '#45A08A',
  Yambo_Purple: '#653FE1',
  Yambo_Purple_Dark: '#352074',
  Yambo_Purple_Stroke: '#8C6DEF',
  Yambo_Blue: '#2C57F0',
  Yambo_Blue_Dark: '#183C9B',
  Yambo_Blue_Stroke: '#5A82E8',
  Yambo_Orange: '#E54A19',
  Yambo_Orange_Dark: '#C53111',
  Yambo_Orange_Stroke: '#EA8362',
  Yambo_Black: '#312f37',
  Yambo_Black_Dark: '#222127',
  Yambo_Black_Stroke: '#65616b',
  Yambo_Text_On_Dark: '#F2F0F8',
  Yambo_CTA_BG: '#F2F0F8',
  Yambo_BG: '#101012',
  Yambo_Idle_Icon: '#4E4B55',
  Yambo_White_BG: '#E5E3EB',
  Yambo_Text_On_Dark_Transparent: '#F2F0F840',
  Yambo_Text_On_White: '#1C1B1F',
  Yambo_Red_Stroke: '#ff8080',
  Icon_Button_Active: '#653FE1',
  Icon_Button_Hover: '#000',
};

export const colorArray = Object.entries(color);
export const colorMap = new Map(Object.entries(color));
