import { RESOURCE_TYPES } from '@cld-apis/utils';
import type { CldOptions, CloudConfig } from '@cld-apis/types';
import axiosInstance from '../../../services/axiosConfig';
import { buildCloudinaryUrl } from './url';

const cloudName = 'dpp2flk93';

let config = {};

export const setConfig = (options: CloudConfig) =>
  (config = {
    ...config,
    ...options,
  });

export const buildUrl = (publicId: string, { cloud = {}, transformations = {} }: CldOptions): string => {
  const cloudConfig = {
    ...config,
    ...cloud,
  };

  return buildCloudinaryUrl(publicId, cloudConfig, transformations);
};

export const buildImageUrl = (publicId: string, { cloud = {}, transformations = {} }: CldOptions): string =>
  buildUrl(publicId, {
    cloud: {
      ...cloud,
      resourceType: RESOURCE_TYPES.IMAGE,
    },
    transformations,
  });

export const buildVideoUrl = (publicId: string, { cloud = {}, transformations = {} }: CldOptions): string =>
  buildUrl(publicId, {
    cloud: {
      ...cloud,
      resourceType: RESOURCE_TYPES.VIDEO,
    },
    transformations,
  });

setConfig({
  cloudName: cloudName,
});

const buildFileUrl = (file, resizeType, width, height, x_pos, y_pos, nodeId) => {
  const transformations = file.transformations ? [...file.transformations] : [];

  const newTransformation = {
    nodeIdentifier: nodeId, // This is a unique identifier for the node
    resize: {
      type: resizeType,
      width: width,
      height: height,
      x_pos: x_pos !== undefined ? x_pos : null,
      y_pos: y_pos !== undefined ? y_pos : null,
    },
  };

  const index = transformations.findIndex((t) => t.nodeIdentifier === nodeId);
  if (index !== -1) {
    // If found, update the existing transformation
    transformations[index] = newTransformation;
  } else {
    // Otherwise, add the new transformation
    transformations.push(newTransformation);
  }

  switch (file.type) {
    case 'image':
      return buildImageUrl(file.publicId, {
        transformations: {
          chaining: transformations,
        },
      });

    case 'video':
      return buildVideoUrl(file.publicId, {
        transformations: {
          chaining: transformations,
        },
      });
  }
};

export const Resize = (file, width, height, nodeId) => {
  return buildFileUrl(file, 'scale', width, height, 0, 0, nodeId);
};

export const Crop = (file, scale, cropBox, nodeId) => {
  return buildFileUrl(
    file,
    'crop',
    Math.ceil(cropBox.width * scale),
    Math.ceil(cropBox.height * scale),
    Math.ceil(cropBox.x * scale),
    Math.ceil(cropBox.y * scale),
    nodeId,
  );
};

const uploadBase64 = async (base64Data, onProgress) => {
  // Assuming base64Data is a complete string like 'data:image/png;base64,iVBORw0K...'
  const contentType = base64Data.substring(base64Data.indexOf(':') + 1, base64Data.indexOf(';'));
  const b64Data = base64Data.substring(base64Data.indexOf(',') + 1);
  const blob = fetch(`data:${contentType};base64,${b64Data}`).then((res) => res.blob());

  const formData = new FormData();
  formData.append('file', await blob, 'upload.png');

  try {
    const response = await axiosInstance.post('/v1/nodes/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = progressEvent.total ? Math.round((progressEvent.loaded / progressEvent.total) * 85) : 0;
        onProgress(progress);
      },
    });

    return response.data;
  } catch (error) {
    return error;
  } finally {
    // setUploadProgress(0); // Reset the progress bar
  }
};

/// handles base64 inputs
export const convertBase64ToUrl = async (url: string, onProgress) => {
  return await uploadBase64(url, onProgress);
};
