import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axiosInstance from '../../services/axiosConfig';

function ModelNodesList() {
  const [nodeTypes, setNodeTypes] = useState(null);

  const getNodeTypes = async () => {
    try {
      const response = await axiosInstance.get(`/v1/nodes/types`);
      console.log('nodes: ', response.data);
      setNodeTypes(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getNodeTypes();
  }, []);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Model</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodeTypes &&
              nodeTypes
                .filter((node) => node.isModel)
                .map((node) => (
                  <TableRow
                    key={node.id}
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0, cursor: 'pointer' } }}
                    onClick={() => console.log(node.id)}
                  >
                    <TableCell component="th" scope="row">
                      {node.data.name}
                    </TableCell>
                    <TableCell align="right">{node.type}</TableCell>
                    {node.data.model && <TableCell align="right">{node.data.model.name}</TableCell>}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ModelNodesList;
