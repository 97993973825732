import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  InputBase,
  TextField,
  Skeleton,
  Link,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { UserRoles } from '../../enums/user-roles.enum';
import { color } from '../../colors';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { I18N_KEYS } from '../../language/keys';
import axiosInstance from '../../services/axiosConfig';
import { CreditsContext } from '../../services/CreditsContext';
import InviteMemberModal from './Team/InviteMemberModal';
import TeamTable from './Team/TeamTable';

function Team({ currentUser }) {
  const { t: translate } = useTranslation();
  const posthog = usePostHog();
  const { openUpgradeModal } = useContext(CreditsContext);
  const [members, setMembers] = useState();
  const [workspaceId, setWorkspaceId] = useState('');
  const [workspaceName, setWorkspaceName] = useState('The A Team');
  const [oldWorkspaceName, setOldWorkspaceName] = useState('The A Team');
  const [isEditingWorkspaceName, setIsEditingWorkspaceName] = useState(false);
  const [workspaceCredits, setWorkspaceCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openInviteMemberModal, setOpenInviteMemberModal] = useState(false);
  const [idToRemove, setIdToRemove] = useState(null);
  const [copyingInviteId, setCopyingInviteId] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);

  const hasEditPermissions = members?.find((member) => member.id === currentUser.uid)?.role === UserRoles.ADMIN;

  const navigate = useNavigate();

  const handleOpenInviteMemberModal = () => {
    posthog.capture('Clicked_open_invite_member_modal');
    setOpenInviteMemberModal(true);
  };

  const handleCloseInviteMemberModal = () => {
    posthog.capture('Clicked_close_invite_member_modal');
    setOpenInviteMemberModal(false);
  };

  const filterMembers = () => {
    return members?.filter(
      (member) =>
        member?.name?.toLowerCase().includes(search.toLowerCase()) ||
        member?.email?.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const getWorkspace = async () => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.get(`/v1/workspaces`);
      setMembers([...res.data?.members]);
      setCurrentUserRole(res.data?.members?.find((m) => m.id === currentUser.uid)?.role);
      setWorkspaceId(res.data?.id);
      setWorkspaceName(res.data?.name);
      setOldWorkspaceName(res.data?.name);
      setWorkspaceCredits(res.data?.credits);
    } catch (error) {
      setIsLoading(false);
      console.log('Error getting workspace', error);
      if (error.response && [404, 403].includes(error.response.status)) {
        navigate('/');
      }
      // todo: show error to user
    } finally {
      setIsLoading(false);
    }
  };

  const updateWorkspaceName = async (name) => {
    try {
      await axiosInstance.put(`/v1/workspaces/${workspaceId}`, { name });
    } catch (error) {
      console.log('Error updating workspace name', error);
      // todo: show error to user
    }
  };

  const updateMember = async (memberId, update) => {
    try {
      await axiosInstance.put(`/v1/workspaces/${workspaceId}/members/${memberId}`, update);
    } catch (error) {
      console.log('Error updating user', error);
      // todo: show error to user
    }
  };

  useEffect(() => {
    getWorkspace();
  }, []);

  const updateMemberRole = (id, role) => {
    setMembers(members.map((member) => (member.id === id ? { ...member, role: role } : member)));
    updateMember(id, { role });
  };

  const resendInvite = async (id) => {
    await axiosInstance.post(`/v1/workspaces/${workspaceId}/members/${id}/invitation/resend`);
  };

  const handleRemoveMember = (id) => {
    setIdToRemove(id);
    setOpenConfirmationDialog(true);
  };

  const removeMember = (id) => {
    axiosInstance.delete(`/v1/workspaces/${workspaceId}/members/${id}`);
    // todo: handle error
    setMembers(members.filter((member) => member.id !== id));
    setOpenConfirmationDialog(false);
  };

  const copyInviteLink = async (id) => {
    try {
      setCopyingInviteId(id);
      const res = await axiosInstance.get(`/v1/workspaces/${workspaceId}/members/${id}/invitation-link`);
      const link = res.data.link;
      navigator.clipboard.writeText(link);
      posthog.capture('team_invite_link_copied');

      return true;
    } catch (error) {
      console.error('Error copying invite link:', error);

      return false;
    } finally {
      setCopyingInviteId(null);
    }
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleInviteMembersSuccess = (members) => {
    setMembers((prevMembers) => [...prevMembers, ...members]);
  };

  const inviteMembers = async (emails, intendedRole) => {
    return axiosInstance.post(`/v1/workspaces/${workspaceId}/members/invite`, { emails, userRole: intendedRole });
  };

  const commitWorkspaceNameChange = () => {
    if (workspaceName === '') {
      setWorkspaceName(oldWorkspaceName);
    } else {
      updateWorkspaceName(workspaceName);
    }
    setIsEditingWorkspaceName(false);
  };

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', py: 2 }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Box
            id="workspace-name-container"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '65%',
              height: '56px',
              my: 2,
              border: `1px solid ${color.Dark_Grey}`,
              borderRadius: 2,
              p: 2,
              overflow: 'hidden',
            }}
          >
            <Typography variant="body1" sx={{ mr: 1 }}>
              {translate(I18N_KEYS.SETTINGS.TEAM.WORKSPACE_NAME)}
            </Typography>
            {isLoading ? (
              <Skeleton variant="text" width="50%" height="28px" />
            ) : isEditingWorkspaceName ? (
              <TextField
                variant="standard"
                autoFocus
                placeholder={translate(I18N_KEYS.SETTINGS.TEAM.WORKSPACE_NAME_PLACEHOLDER)}
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                onBlur={commitWorkspaceNameChange}
                onFocus={(e) => {
                  e.target.select();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    commitWorkspaceNameChange();
                  }
                  if (e.key === 'Escape') {
                    setWorkspaceName(oldWorkspaceName); // Discard changes
                    setIsEditingWorkspaceName(false);
                  }
                }}
                sx={{
                  width: '50%',
                  borderBottom: `1px solid ${color.Dark_Grey}`,
                  '& .MuiInput-input': {
                    p: 0,
                    paddingBlock: 0,
                    paddingInline: 0,
                  },
                }}
              />
            ) : (
              <Box
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                onClick={() => {
                  if (!hasEditPermissions) {
                    return;
                  }
                  setIsEditingWorkspaceName(true);
                  setOldWorkspaceName(workspaceName);
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ cursor: 'text', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  <b>{workspaceName}</b>
                </Typography>
                {hasEditPermissions && (
                  <Link
                    sx={{ cursor: 'pointer', ml: 1 }}
                    onClick={() => {
                      setIsEditingWorkspaceName(true);
                      posthog.capture('Clicked_change_team_workspace_name');
                    }}
                  >
                    <Typography variant="body1" sx={{ fontSize: 12 }}>
                      {translate(I18N_KEYS.SETTINGS.TEAM.WORKSPACE_NAME_CHANGE)}
                    </Typography>
                  </Link>
                )}
              </Box>
            )}
          </Box>
          <Box
            id="workspace-credits-container"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '35%',
              height: '56px',
              my: 2,
              border: `1px solid ${color.Dark_Grey}`,
              borderRadius: 2,
              p: 2,
              overflow: 'hidden',
            }}
          >
            <Typography variant="body1" sx={{ mr: 1 }}>
              {translate(I18N_KEYS.SETTINGS.TEAM.WORKSPACE_CREDITS)}
            </Typography>
            {isLoading ? (
              <Skeleton variant="text" width="50%" height="28px" />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Typography variant="body1" sx={{ mr: 1 }}>
                  <b>{workspaceCredits}</b>
                </Typography>
                {hasEditPermissions && (
                  <>
                    <Link
                      onClick={() => {
                        openUpgradeModal();
                        posthog.capture('Clicked_upgrade_team_workspace_credits');
                      }}
                    >
                      <Typography variant="body1" sx={{ fontSize: 12 }}>
                        {translate(I18N_KEYS.SETTINGS.TEAM.WORKSPACE_CREDITS_CTA)}
                      </Typography>
                    </Link>
                    {/* todo: add option to hide / show credits to members */}
                    {/* <FormControlLabel
                                control={
                                    <Checkbox 
                                        size="small"
                                        checked={shouldShowCreditsToMembers} 
                                        onChange={ (e) => setShouldShowCreditsToMembers(e.target.checked) } 
                                        sx={ { ml: 1 } }
                                    />
                                }
                                label={ 
                                    <Typography variant="body1" sx={ {  fontSize:12 } }>
                                        { translate(I18N_KEYS.SETTINGS.TEAM.WORKSPACE_MEMBERS_CAN_SEE_CREDITS) }
                                    </Typography>
                                }
                            /> */}
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          id="team-table-container"
          sx={{ width: '100%', border: `1px solid ${color.Dark_Grey}`, p: 2, borderRadius: 2 }}
        >
          {/* <Typography variant="body1" sx={ { mb:2 } }>
              { translate(I18N_KEYS.SETTINGS.TEAM.TEAM_TABLE.TABLE_TITLE) }
            </Typography> */}
          <Box
            id="team-table-header"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              mb: 2,
            }}
          >
            <InputBase
              placeholder={translate(I18N_KEYS.SETTINGS.TEAM.TEAM_TABLE.SEARCH_PLACEHOLDER)}
              sx={{ width: '300px', borderBottom: `1px solid ${color.Dark_Grey}` }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              variant="contained"
              color="weavy_cta"
              onClick={handleOpenInviteMemberModal}
              disabled={isLoading || !members || !hasEditPermissions}
            >
              <Typography variant="caption">
                <i className="fa-light fa-user-plus"></i>&nbsp;
                {translate(I18N_KEYS.SETTINGS.TEAM.TEAM_TABLE.ADD_MEMBERS_BUTTON)}
              </Typography>
            </Button>
          </Box>

          <TeamTable
            currentUser={currentUser}
            currentUserRole={currentUserRole}
            members={filterMembers()}
            isLoading={isLoading}
            updateMemberRole={updateMemberRole}
            resendInvite={resendInvite}
            removeMember={handleRemoveMember}
            copyInviteLink={copyInviteLink}
            copyingInviteId={copyingInviteId}
          />
        </Box>
        <InviteMemberModal
          open={openInviteMemberModal}
          onClose={handleCloseInviteMemberModal}
          onInviteMembersSuccess={handleInviteMembersSuccess}
          inviteMembers={inviteMembers}
        />
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        onConfirm={() => removeMember(idToRemove)}
        onCancel={handleCloseConfirmationDialog}
        title="Remove Member"
        message={translate(I18N_KEYS.SETTINGS.TEAM.REMOVE_MEMBER_DIALOG.CONTENT)}
        confirmText="Remove"
        cancelText="Cancel"
      />
    </>
  );
}

export default Team;
