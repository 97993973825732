let wasm;

let WASM_VECTOR_LEN = 0;

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
  if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
    cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
  }
  return cachedUint8ArrayMemory0;
}

const cachedTextEncoder =
  typeof TextEncoder !== 'undefined'
    ? new TextEncoder('utf-8')
    : {
        encode: () => {
          throw Error('TextEncoder not available');
        },
      };

const encodeString =
  typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
        return cachedTextEncoder.encodeInto(arg, view);
      }
    : function (arg, view) {
        const buf = cachedTextEncoder.encode(arg);
        view.set(buf);
        return {
          read: arg.length,
          written: buf.length,
        };
      };

function passStringToWasm0(arg, malloc, realloc) {
  if (realloc === undefined) {
    const buf = cachedTextEncoder.encode(arg);
    const ptr = malloc(buf.length, 1) >>> 0;
    getUint8ArrayMemory0()
      .subarray(ptr, ptr + buf.length)
      .set(buf);
    WASM_VECTOR_LEN = buf.length;
    return ptr;
  }

  let len = arg.length;
  let ptr = malloc(len, 1) >>> 0;

  const mem = getUint8ArrayMemory0();

  let offset = 0;

  for (; offset < len; offset++) {
    const code = arg.charCodeAt(offset);
    if (code > 0x7f) break;
    mem[ptr + offset] = code;
  }

  if (offset !== len) {
    if (offset !== 0) {
      arg = arg.slice(offset);
    }
    ptr = realloc(ptr, len, (len = offset + arg.length * 3), 1) >>> 0;
    const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
    const ret = encodeString(arg, view);

    offset += ret.written;
    ptr = realloc(ptr, len, offset, 1) >>> 0;
  }

  WASM_VECTOR_LEN = offset;
  return ptr;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
  if (
    cachedDataViewMemory0 === null ||
    cachedDataViewMemory0.buffer.detached === true ||
    (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)
  ) {
    cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
  }
  return cachedDataViewMemory0;
}

function addToExternrefTable0(obj) {
  const idx = wasm.__externref_table_alloc();
  wasm.__wbindgen_export_4.set(idx, obj);
  return idx;
}

function handleError(f, args) {
  try {
    return f.apply(this, args);
  } catch (e) {
    const idx = addToExternrefTable0(e);
    wasm.__wbindgen_exn_store(idx);
  }
}

function isLikeNone(x) {
  return x === undefined || x === null;
}

const cachedTextDecoder =
  typeof TextDecoder !== 'undefined'
    ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true })
    : {
        decode: () => {
          throw Error('TextDecoder not available');
        },
      };

if (typeof TextDecoder !== 'undefined') {
  cachedTextDecoder.decode();
}

function getStringFromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

let cachedFloat32ArrayMemory0 = null;

function getFloat32ArrayMemory0() {
  if (cachedFloat32ArrayMemory0 === null || cachedFloat32ArrayMemory0.byteLength === 0) {
    cachedFloat32ArrayMemory0 = new Float32Array(wasm.memory.buffer);
  }
  return cachedFloat32ArrayMemory0;
}

function getArrayF32FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getFloat32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedInt32ArrayMemory0 = null;

function getInt32ArrayMemory0() {
  if (cachedInt32ArrayMemory0 === null || cachedInt32ArrayMemory0.byteLength === 0) {
    cachedInt32ArrayMemory0 = new Int32Array(wasm.memory.buffer);
  }
  return cachedInt32ArrayMemory0;
}

function getArrayI32FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getInt32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedUint32ArrayMemory0 = null;

function getUint32ArrayMemory0() {
  if (cachedUint32ArrayMemory0 === null || cachedUint32ArrayMemory0.byteLength === 0) {
    cachedUint32ArrayMemory0 = new Uint32Array(wasm.memory.buffer);
  }
  return cachedUint32ArrayMemory0;
}

function getArrayU32FromWasm0(ptr, len) {
  ptr = ptr >>> 0;
  return getUint32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}

function debugString(val) {
  // primitive types
  const type = typeof val;
  if (type == 'number' || type == 'boolean' || val == null) {
    return `${val}`;
  }
  if (type == 'string') {
    return `"${val}"`;
  }
  if (type == 'symbol') {
    const description = val.description;
    if (description == null) {
      return 'Symbol';
    } else {
      return `Symbol(${description})`;
    }
  }
  if (type == 'function') {
    const name = val.name;
    if (typeof name == 'string' && name.length > 0) {
      return `Function(${name})`;
    } else {
      return 'Function';
    }
  }
  // objects
  if (Array.isArray(val)) {
    const length = val.length;
    let debug = '[';
    if (length > 0) {
      debug += debugString(val[0]);
    }
    for (let i = 1; i < length; i++) {
      debug += ', ' + debugString(val[i]);
    }
    debug += ']';
    return debug;
  }
  // Test for built-in
  const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
  let className;
  if (builtInMatches && builtInMatches.length > 1) {
    className = builtInMatches[1];
  } else {
    // Failed to match the standard '[object ClassName]'
    return toString.call(val);
  }
  if (className == 'Object') {
    // we're a user defined class or Object
    // JSON.stringify avoids problems with cycles, and is generally much
    // easier than looping through ownProperties of `val`.
    try {
      return 'Object(' + JSON.stringify(val) + ')';
    } catch (_) {
      return 'Object';
    }
  }
  // errors
  if (val instanceof Error) {
    return `${val.name}: ${val.message}\n${val.stack}`;
  }
  // TODO we could test for more things here, like `Set`s and `Map`s.
  return className;
}

export function start() {
  wasm.start();
}

function takeFromExternrefTable0(idx) {
  const value = wasm.__wbindgen_export_4.get(idx);
  wasm.__externref_table_dealloc(idx);
  return value;
}

const WebFinalization =
  typeof FinalizationRegistry === 'undefined'
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry((ptr) => wasm.__wbg_web_free(ptr >>> 0, 1));

export class Web {
  __destroy_into_raw() {
    const ptr = this.__wbg_ptr;
    this.__wbg_ptr = 0;
    WebFinalization.unregister(this);
    return ptr;
  }

  free() {
    const ptr = this.__destroy_into_raw();
    wasm.__wbg_web_free(ptr, 0);
  }
  /**
   * @param {HTMLCanvasElement} canvas
   * @param {Function} notify_change
   */
  constructor(canvas, notify_change) {
    const ret = wasm.web_new(canvas, notify_change);
    if (ret[2]) {
      throw takeFromExternrefTable0(ret[1]);
    }
    this.__wbg_ptr = ret[0] >>> 0;
    WebFinalization.register(this, this.__wbg_ptr, this);
    return this;
  }
  /**
   * @param {any} node
   */
  importCompositorNode(node) {
    const ret = wasm.web_importCompositorNode(this.__wbg_ptr, node);
    if (ret[1]) {
      throw takeFromExternrefTable0(ret[0]);
    }
  }
  /**
   * @returns {any}
   */
  exportCompositorNode() {
    const ret = wasm.web_exportCompositorNode(this.__wbg_ptr);
    if (ret[2]) {
      throw takeFromExternrefTable0(ret[1]);
    }
    return takeFromExternrefTable0(ret[0]);
  }
  /**
   * @param {any} file
   */
  importFile(file) {
    const ret = wasm.web_importFile(this.__wbg_ptr, file);
    if (ret[1]) {
      throw takeFromExternrefTable0(ret[0]);
    }
  }
  /**
   * @param {PointerEvent} event
   */
  onPointerMove(event) {
    wasm.web_onPointerMove(this.__wbg_ptr, event);
  }
  /**
   * @param {PointerEvent} event
   */
  onPointerDown(event) {
    wasm.web_onPointerDown(this.__wbg_ptr, event);
  }
  /**
   * @param {PointerEvent} event
   */
  onPointerUp(event) {
    wasm.web_onPointerUp(this.__wbg_ptr, event);
  }
  /**
   * @param {PointerEvent} event
   */
  onPointerOut(event) {
    wasm.web_onPointerOut(this.__wbg_ptr, event);
  }
  /**
   * @param {PointerEvent} event
   */
  onPointerEnter(event) {
    wasm.web_onPointerEnter(this.__wbg_ptr, event);
  }
  /**
   * @param {WheelEvent} event
   */
  onWheel(event) {
    wasm.web_onWheel(this.__wbg_ptr, event);
  }
  /**
   * @param {KeyboardEvent} event
   */
  onKeyDown(event) {
    wasm.web_onKeyDown(this.__wbg_ptr, event);
  }
  /**
   * @param {KeyboardEvent} event
   */
  onKeyUp(event) {
    wasm.web_onKeyUp(this.__wbg_ptr, event);
  }
  /**
   * @param {string} text
   */
  onTextChange(text) {
    const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    wasm.web_onTextChange(this.__wbg_ptr, ptr0, len0);
  }
  /**
   * @param {string} resource_key
   * @param {HTMLImageElement} image_element
   */
  addImageResource(resource_key, image_element) {
    const ptr0 = passStringToWasm0(resource_key, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    wasm.web_addImageResource(this.__wbg_ptr, ptr0, len0, image_element);
  }
  /**
   * @returns {any}
   */
  selection() {
    const ret = wasm.web_selection(this.__wbg_ptr);
    if (ret[2]) {
      throw takeFromExternrefTable0(ret[1]);
    }
    return takeFromExternrefTable0(ret[0]);
  }
  /**
   * @param {any} selection
   */
  setSelection(selection) {
    const ret = wasm.web_setSelection(this.__wbg_ptr, selection);
    if (ret[1]) {
      throw takeFromExternrefTable0(ret[0]);
    }
  }
  /**
   * @param {number} zoom_level
   */
  setZoomLevel(zoom_level) {
    wasm.web_setZoomLevel(this.__wbg_ptr, zoom_level);
  }
  /**
   * @returns {number}
   */
  zoomLevel() {
    const ret = wasm.web_zoomLevel(this.__wbg_ptr);
    return ret;
  }
  render() {
    const ret = wasm.web_render(this.__wbg_ptr);
    if (ret[1]) {
      throw takeFromExternrefTable0(ret[0]);
    }
  }
}

async function __wbg_load(module, imports) {
  if (typeof Response === 'function' && module instanceof Response) {
    if (typeof WebAssembly.instantiateStreaming === 'function') {
      try {
        return await WebAssembly.instantiateStreaming(module, imports);
      } catch (e) {
        if (module.headers.get('Content-Type') != 'application/wasm') {
          console.warn(
            '`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n',
            e,
          );
        } else {
          throw e;
        }
      }
    }

    const bytes = await module.arrayBuffer();
    return await WebAssembly.instantiate(bytes, imports);
  } else {
    const instance = await WebAssembly.instantiate(module, imports);

    if (instance instanceof WebAssembly.Instance) {
      return { instance, module };
    } else {
      return instance;
    }
  }
}

function __wbg_get_imports() {
  const imports = {};
  imports.wbg = {};
  imports.wbg.__wbg_String_8f0eb39a4a4c2f66 = function (arg0, arg1) {
    const ret = String(arg1);
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_activeTexture_0f19d8acfa0a14c2 = function (arg0, arg1) {
    arg0.activeTexture(arg1 >>> 0);
  };
  imports.wbg.__wbg_activeTexture_460f2e367e813fb0 = function (arg0, arg1) {
    arg0.activeTexture(arg1 >>> 0);
  };
  imports.wbg.__wbg_altKey_c33c03aed82e4275 = function (arg0) {
    const ret = arg0.altKey;
    return ret;
  };
  imports.wbg.__wbg_attachShader_3d4eb6af9e3e7bd1 = function (arg0, arg1, arg2) {
    arg0.attachShader(arg1, arg2);
  };
  imports.wbg.__wbg_attachShader_94e758c8b5283eb2 = function (arg0, arg1, arg2) {
    arg0.attachShader(arg1, arg2);
  };
  imports.wbg.__wbg_bindBuffer_309c9a6c21826cf5 = function (arg0, arg1, arg2) {
    arg0.bindBuffer(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_bindBuffer_f32f587f1c2962a7 = function (arg0, arg1, arg2) {
    arg0.bindBuffer(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_bindFramebuffer_bd02c8cc707d670f = function (arg0, arg1, arg2) {
    arg0.bindFramebuffer(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_bindFramebuffer_e48e83c0f973944d = function (arg0, arg1, arg2) {
    arg0.bindFramebuffer(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_bindTexture_a6e795697f49ebd1 = function (arg0, arg1, arg2) {
    arg0.bindTexture(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_bindTexture_bc8eb316247f739d = function (arg0, arg1, arg2) {
    arg0.bindTexture(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_bindVertexArrayOES_da8e7059b789629e = function (arg0, arg1) {
    arg0.bindVertexArrayOES(arg1);
  };
  imports.wbg.__wbg_bindVertexArray_6b4b88581064b71f = function (arg0, arg1) {
    arg0.bindVertexArray(arg1);
  };
  imports.wbg.__wbg_blendEquationSeparate_c1aa26a9a5c5267e = function (arg0, arg1, arg2) {
    arg0.blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_blendEquationSeparate_f3d422e981d86339 = function (arg0, arg1, arg2) {
    arg0.blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_blendFuncSeparate_483be8d4dd635340 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.blendFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
  };
  imports.wbg.__wbg_blendFuncSeparate_dafeabfc1680b2ee = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.blendFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
  };
  imports.wbg.__wbg_bufferData_3261d3e1dd6fc903 = function (arg0, arg1, arg2, arg3) {
    arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
  };
  imports.wbg.__wbg_bufferData_33c59bf909ea6fd3 = function (arg0, arg1, arg2, arg3) {
    arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
  };
  imports.wbg.__wbg_buffer_609cc3eee51ed158 = function (arg0) {
    const ret = arg0.buffer;
    return ret;
  };
  imports.wbg.__wbg_button_f75c56aec440ea04 = function (arg0) {
    const ret = arg0.button;
    return ret;
  };
  imports.wbg.__wbg_call_672a4d21634d4a24 = function () {
    return handleError(function (arg0, arg1) {
      const ret = arg0.call(arg1);
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_call_833bed5770ea2041 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = arg0.call(arg1, arg2, arg3);
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_clearColor_d39507085c98a678 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.clearColor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_clearColor_f0fa029dfbcc1982 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.clearColor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_clearDepth_17cfee5be8476fae = function (arg0, arg1) {
    arg0.clearDepth(arg1);
  };
  imports.wbg.__wbg_clearDepth_670d19914a501259 = function (arg0, arg1) {
    arg0.clearDepth(arg1);
  };
  imports.wbg.__wbg_clear_62b9037b892f6988 = function (arg0, arg1) {
    arg0.clear(arg1 >>> 0);
  };
  imports.wbg.__wbg_clear_f8d5f3c348d37d95 = function (arg0, arg1) {
    arg0.clear(arg1 >>> 0);
  };
  imports.wbg.__wbg_clientHeight_216178c194000db4 = function (arg0) {
    const ret = arg0.clientHeight;
    return ret;
  };
  imports.wbg.__wbg_clientWidth_ce67a04dc15fce39 = function (arg0) {
    const ret = arg0.clientWidth;
    return ret;
  };
  imports.wbg.__wbg_code_459c120478e1ab6e = function (arg0, arg1) {
    const ret = arg1.code;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_colorMask_5e7c60b9c7a57a2e = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
  };
  imports.wbg.__wbg_colorMask_6dac12039c7145ae = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
  };
  imports.wbg.__wbg_compileShader_0ad770bbdbb9de21 = function (arg0, arg1) {
    arg0.compileShader(arg1);
  };
  imports.wbg.__wbg_compileShader_2307c9d370717dd5 = function (arg0, arg1) {
    arg0.compileShader(arg1);
  };
  imports.wbg.__wbg_createBuffer_7a9ec3d654073660 = function (arg0) {
    const ret = arg0.createBuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createBuffer_9886e84a67b68c89 = function (arg0) {
    const ret = arg0.createBuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createFramebuffer_7824f69bba778885 = function (arg0) {
    const ret = arg0.createFramebuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createFramebuffer_c8d70ebc4858051e = function (arg0) {
    const ret = arg0.createFramebuffer();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createProgram_8ff56c485f3233d0 = function (arg0) {
    const ret = arg0.createProgram();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createProgram_da203074cafb1038 = function (arg0) {
    const ret = arg0.createProgram();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createShader_4a256a8cc9c1ce4f = function (arg0, arg1) {
    const ret = arg0.createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createShader_983150fb1243ee56 = function (arg0, arg1) {
    const ret = arg0.createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createTexture_9c536c79b635fdef = function (arg0) {
    const ret = arg0.createTexture();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createTexture_bfaa54c0cd22e367 = function (arg0) {
    const ret = arg0.createTexture();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createVertexArrayOES_991b44f100f93329 = function (arg0) {
    const ret = arg0.createVertexArrayOES();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_createVertexArray_e435029ae2660efd = function (arg0) {
    const ret = arg0.createVertexArray();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_ctrlKey_1e826e468105ac11 = function (arg0) {
    const ret = arg0.ctrlKey;
    return ret;
  };
  imports.wbg.__wbg_cullFace_187079e6e20a464d = function (arg0, arg1) {
    arg0.cullFace(arg1 >>> 0);
  };
  imports.wbg.__wbg_cullFace_fbae6dd4d5e61ba4 = function (arg0, arg1) {
    arg0.cullFace(arg1 >>> 0);
  };
  imports.wbg.__wbg_debug_3cb59063b29f58c1 = function (arg0) {
    console.debug(arg0);
  };
  imports.wbg.__wbg_deleteBuffer_7ed96e1bf7c02e87 = function (arg0, arg1) {
    arg0.deleteBuffer(arg1);
  };
  imports.wbg.__wbg_deleteBuffer_a7822433fc95dfb8 = function (arg0, arg1) {
    arg0.deleteBuffer(arg1);
  };
  imports.wbg.__wbg_deleteFramebuffer_66853fb7101488cb = function (arg0, arg1) {
    arg0.deleteFramebuffer(arg1);
  };
  imports.wbg.__wbg_deleteFramebuffer_cd3285ee5a702a7a = function (arg0, arg1) {
    arg0.deleteFramebuffer(arg1);
  };
  imports.wbg.__wbg_deleteProgram_3fa626bbc0001eb7 = function (arg0, arg1) {
    arg0.deleteProgram(arg1);
  };
  imports.wbg.__wbg_deleteProgram_71a133c6d053e272 = function (arg0, arg1) {
    arg0.deleteProgram(arg1);
  };
  imports.wbg.__wbg_deleteShader_8d42f169deda58ac = function (arg0, arg1) {
    arg0.deleteShader(arg1);
  };
  imports.wbg.__wbg_deleteShader_c65a44796c5004d8 = function (arg0, arg1) {
    arg0.deleteShader(arg1);
  };
  imports.wbg.__wbg_deleteTexture_a30f5ca0163c4110 = function (arg0, arg1) {
    arg0.deleteTexture(arg1);
  };
  imports.wbg.__wbg_deleteTexture_bb82c9fec34372ba = function (arg0, arg1) {
    arg0.deleteTexture(arg1);
  };
  imports.wbg.__wbg_deltaX_5c1121715746e4b7 = function (arg0) {
    const ret = arg0.deltaX;
    return ret;
  };
  imports.wbg.__wbg_deltaY_f9318542caea0c36 = function (arg0) {
    const ret = arg0.deltaY;
    return ret;
  };
  imports.wbg.__wbg_depthFunc_2906916f4536d5d7 = function (arg0, arg1) {
    arg0.depthFunc(arg1 >>> 0);
  };
  imports.wbg.__wbg_depthFunc_f34449ae87cc4e3e = function (arg0, arg1) {
    arg0.depthFunc(arg1 >>> 0);
  };
  imports.wbg.__wbg_depthMask_5fe84e2801488eda = function (arg0, arg1) {
    arg0.depthMask(arg1 !== 0);
  };
  imports.wbg.__wbg_depthMask_76688a8638b2f321 = function (arg0, arg1) {
    arg0.depthMask(arg1 !== 0);
  };
  imports.wbg.__wbg_detachShader_ab39d8a19811cfa6 = function (arg0, arg1, arg2) {
    arg0.detachShader(arg1, arg2);
  };
  imports.wbg.__wbg_detachShader_cd3ab294e635ff90 = function (arg0, arg1, arg2) {
    arg0.detachShader(arg1, arg2);
  };
  imports.wbg.__wbg_devicePixelRatio_68c391265f05d093 = function (arg0) {
    const ret = arg0.devicePixelRatio;
    return ret;
  };
  imports.wbg.__wbg_disableVertexAttribArray_452cc9815fced7e4 = function (arg0, arg1) {
    arg0.disableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_disableVertexAttribArray_afd097fb465dc100 = function (arg0, arg1) {
    arg0.disableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_disable_2702df5b5da5dd21 = function (arg0, arg1) {
    arg0.disable(arg1 >>> 0);
  };
  imports.wbg.__wbg_disable_8b53998501a7a85b = function (arg0, arg1) {
    arg0.disable(arg1 >>> 0);
  };
  imports.wbg.__wbg_done_769e5ede4b31c67b = function (arg0) {
    const ret = arg0.done;
    return ret;
  };
  imports.wbg.__wbg_drawArrays_6acaa2669c105f3a = function (arg0, arg1, arg2, arg3) {
    arg0.drawArrays(arg1 >>> 0, arg2, arg3);
  };
  imports.wbg.__wbg_drawArrays_6d29ea2ebc0c72a2 = function (arg0, arg1, arg2, arg3) {
    arg0.drawArrays(arg1 >>> 0, arg2, arg3);
  };
  imports.wbg.__wbg_drawBuffersWEBGL_9fdbdf3d4cbd3aae = function (arg0, arg1) {
    arg0.drawBuffersWEBGL(arg1);
  };
  imports.wbg.__wbg_drawBuffers_e729b75c5a50d760 = function (arg0, arg1) {
    arg0.drawBuffers(arg1);
  };
  imports.wbg.__wbg_drawElements_16199ef1cc58b16a = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.drawElements(arg1 >>> 0, arg2, arg3 >>> 0, arg4);
  };
  imports.wbg.__wbg_drawElements_65cb4b099bd7d4ac = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.drawElements(arg1 >>> 0, arg2, arg3 >>> 0, arg4);
  };
  imports.wbg.__wbg_enableVertexAttribArray_607be07574298e5e = function (arg0, arg1) {
    arg0.enableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_enableVertexAttribArray_93c3d406a41ad6c7 = function (arg0, arg1) {
    arg0.enableVertexAttribArray(arg1 >>> 0);
  };
  imports.wbg.__wbg_enable_51114837e05ee280 = function (arg0, arg1) {
    arg0.enable(arg1 >>> 0);
  };
  imports.wbg.__wbg_enable_d183fef39258803f = function (arg0, arg1) {
    arg0.enable(arg1 >>> 0);
  };
  imports.wbg.__wbg_entries_3265d4158b33e5dc = function (arg0) {
    const ret = Object.entries(arg0);
    return ret;
  };
  imports.wbg.__wbg_error_524f506f44df1645 = function (arg0) {
    console.error(arg0);
  };
  imports.wbg.__wbg_error_7534b8e9a36f1ab4 = function (arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
      deferred0_0 = arg0;
      deferred0_1 = arg1;
      console.error(getStringFromWasm0(arg0, arg1));
    } finally {
      wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
    }
  };
  imports.wbg.__wbg_framebufferRenderbuffer_2fdd12e89ad81eb9 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4);
  };
  imports.wbg.__wbg_framebufferRenderbuffer_8b88592753b54715 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4);
  };
  imports.wbg.__wbg_framebufferTexture2D_81a565732bd5d8fe = function (arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.framebufferTexture2D(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4, arg5);
  };
  imports.wbg.__wbg_framebufferTexture2D_ed855d0b097c557a = function (arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.framebufferTexture2D(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4, arg5);
  };
  imports.wbg.__wbg_framebufferTextureLayer_5e6bd1b0cb45d815 = function (arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.framebufferTextureLayer(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
  };
  imports.wbg.__wbg_generateMipmap_13d3d6406de35b14 = function (arg0, arg1) {
    arg0.generateMipmap(arg1 >>> 0);
  };
  imports.wbg.__wbg_generateMipmap_e3b21a330b500089 = function (arg0, arg1) {
    arg0.generateMipmap(arg1 >>> 0);
  };
  imports.wbg.__wbg_getActiveAttrib_62d0edceacb9259f = function (arg0, arg1, arg2) {
    const ret = arg0.getActiveAttrib(arg1, arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_getActiveAttrib_ef9231684274e84a = function (arg0, arg1, arg2) {
    const ret = arg0.getActiveAttrib(arg1, arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_getActiveUniform_3c49cb0b185d71e0 = function (arg0, arg1, arg2) {
    const ret = arg0.getActiveUniform(arg1, arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_getActiveUniform_999b2093d93c3d0e = function (arg0, arg1, arg2) {
    const ret = arg0.getActiveUniform(arg1, arg2 >>> 0);
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_getAttribLocation_959c0150cdd39cac = function (arg0, arg1, arg2, arg3) {
    const ret = arg0.getAttribLocation(arg1, getStringFromWasm0(arg2, arg3));
    return ret;
  };
  imports.wbg.__wbg_getAttribLocation_9db82d01924fa43d = function (arg0, arg1, arg2, arg3) {
    const ret = arg0.getAttribLocation(arg1, getStringFromWasm0(arg2, arg3));
    return ret;
  };
  imports.wbg.__wbg_getContext_fc19859df6331073 = function () {
    return handleError(function (arg0, arg1, arg2, arg3) {
      const ret = arg0.getContext(getStringFromWasm0(arg1, arg2), arg3);
      return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getExtension_ff0fb1398bcf28c3 = function () {
    return handleError(function (arg0, arg1, arg2) {
      const ret = arg0.getExtension(getStringFromWasm0(arg1, arg2));
      return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    }, arguments);
  };
  imports.wbg.__wbg_getParameter_1f0887a2b88e6d19 = function () {
    return handleError(function (arg0, arg1) {
      const ret = arg0.getParameter(arg1 >>> 0);
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_getParameter_e3429f024018310f = function () {
    return handleError(function (arg0, arg1) {
      const ret = arg0.getParameter(arg1 >>> 0);
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_getProgramInfoLog_631c180b1b21c8ed = function (arg0, arg1, arg2) {
    const ret = arg1.getProgramInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_getProgramInfoLog_a998105a680059db = function (arg0, arg1, arg2) {
    const ret = arg1.getProgramInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_getProgramParameter_0c411f0cd4185c5b = function (arg0, arg1, arg2) {
    const ret = arg0.getProgramParameter(arg1, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_getProgramParameter_360f95ff07ac068d = function (arg0, arg1, arg2) {
    const ret = arg0.getProgramParameter(arg1, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_getShaderInfoLog_7e7b38fb910ec534 = function (arg0, arg1, arg2) {
    const ret = arg1.getShaderInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_getShaderInfoLog_f59c3112acc6e039 = function (arg0, arg1, arg2) {
    const ret = arg1.getShaderInfoLog(arg2);
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_getSupportedExtensions_8c007dbb54905635 = function (arg0) {
    const ret = arg0.getSupportedExtensions();
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_getUniformLocation_657a2b6d102bd126 = function (arg0, arg1, arg2, arg3) {
    const ret = arg0.getUniformLocation(arg1, getStringFromWasm0(arg2, arg3));
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_getUniformLocation_838363001c74dc21 = function (arg0, arg1, arg2, arg3) {
    const ret = arg0.getUniformLocation(arg1, getStringFromWasm0(arg2, arg3));
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_get_67b2ba62fc30de12 = function () {
    return handleError(function (arg0, arg1) {
      const ret = Reflect.get(arg0, arg1);
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_get_b9b93047fe3cf45b = function (arg0, arg1) {
    const ret = arg0[arg1 >>> 0];
    return ret;
  };
  imports.wbg.__wbg_getwithrefkey_1dc361bd10053bfe = function (arg0, arg1) {
    const ret = arg0[arg1];
    return ret;
  };
  imports.wbg.__wbg_height_d3f39e12f0f62121 = function (arg0) {
    const ret = arg0.height;
    return ret;
  };
  imports.wbg.__wbg_info_3daf2e093e091b66 = function (arg0) {
    console.info(arg0);
  };
  imports.wbg.__wbg_instanceof_ArrayBuffer_e14585432e3737fc = function (arg0) {
    let result;
    try {
      result = arg0 instanceof ArrayBuffer;
    } catch (_) {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_instanceof_Map_f3469ce2244d2430 = function (arg0) {
    let result;
    try {
      result = arg0 instanceof Map;
    } catch (_) {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_instanceof_Uint8Array_17156bcf118086a9 = function (arg0) {
    let result;
    try {
      result = arg0 instanceof Uint8Array;
    } catch (_) {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_instanceof_WebGl2RenderingContext_2b6045efeb76568d = function (arg0) {
    let result;
    try {
      result = arg0 instanceof WebGL2RenderingContext;
    } catch (_) {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_instanceof_Window_def73ea0955fc569 = function (arg0) {
    let result;
    try {
      result = arg0 instanceof Window;
    } catch (_) {
      result = false;
    }
    const ret = result;
    return ret;
  };
  imports.wbg.__wbg_isArray_a1eab7e0d067391b = function (arg0) {
    const ret = Array.isArray(arg0);
    return ret;
  };
  imports.wbg.__wbg_isSafeInteger_343e2beeeece1bb0 = function (arg0) {
    const ret = Number.isSafeInteger(arg0);
    return ret;
  };
  imports.wbg.__wbg_iterator_9a24c88df860dc65 = function () {
    const ret = Symbol.iterator;
    return ret;
  };
  imports.wbg.__wbg_length_a446193dc22c12f8 = function (arg0) {
    const ret = arg0.length;
    return ret;
  };
  imports.wbg.__wbg_length_e2d2a49132c1b256 = function (arg0) {
    const ret = arg0.length;
    return ret;
  };
  imports.wbg.__wbg_linkProgram_067ee06739bdde81 = function (arg0, arg1) {
    arg0.linkProgram(arg1);
  };
  imports.wbg.__wbg_linkProgram_e002979fe36e5b2a = function (arg0, arg1) {
    arg0.linkProgram(arg1);
  };
  imports.wbg.__wbg_log_c222819a41e063d3 = function (arg0) {
    console.log(arg0);
  };
  imports.wbg.__wbg_metaKey_e1dd47d709a80ce5 = function (arg0) {
    const ret = arg0.metaKey;
    return ret;
  };
  imports.wbg.__wbg_name_f5089a8a6c7a983a = function (arg0, arg1) {
    const ret = arg1.name;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_new_405e22f390576ce2 = function () {
    const ret = new Object();
    return ret;
  };
  imports.wbg.__wbg_new_5e0be73521bc8c17 = function () {
    const ret = new Map();
    return ret;
  };
  imports.wbg.__wbg_new_78feb108b6472713 = function () {
    const ret = new Array();
    return ret;
  };
  imports.wbg.__wbg_new_8a6f238a6ece86ea = function () {
    const ret = new Error();
    return ret;
  };
  imports.wbg.__wbg_new_a12002a7f91c75be = function (arg0) {
    const ret = new Uint8Array(arg0);
    return ret;
  };
  imports.wbg.__wbg_new_f28f5e09e6640a5b = function () {
    const ret = new Object();
    return ret;
  };
  imports.wbg.__wbg_newnoargs_105ed471475aaf50 = function (arg0, arg1) {
    const ret = new Function(getStringFromWasm0(arg0, arg1));
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_840f3c038856d4e9 = function (arg0, arg1, arg2) {
    const ret = new Int8Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_999332a180064b59 = function (arg0, arg1, arg2) {
    const ret = new Int32Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_d4a86622320ea258 = function (arg0, arg1, arg2) {
    const ret = new Uint16Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_d97e637ebe145a9a = function (arg0, arg1, arg2) {
    const ret = new Uint8Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_e6b7e69acd4c7354 = function (arg0, arg1, arg2) {
    const ret = new Float32Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_f1dead44d1fc7212 = function (arg0, arg1, arg2) {
    const ret = new Uint32Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_newwithbyteoffsetandlength_f254047f7e80e7ff = function (arg0, arg1, arg2) {
    const ret = new Int16Array(arg0, arg1 >>> 0, arg2 >>> 0);
    return ret;
  };
  imports.wbg.__wbg_next_25feadfc0913fea9 = function (arg0) {
    const ret = arg0.next;
    return ret;
  };
  imports.wbg.__wbg_next_6574e1a8a62d1055 = function () {
    return handleError(function (arg0) {
      const ret = arg0.next();
      return ret;
    }, arguments);
  };
  imports.wbg.__wbg_of_2eaf5a02d443ef03 = function (arg0) {
    const ret = Array.of(arg0);
    return ret;
  };
  imports.wbg.__wbg_offsetX_adb14e39bdd32e22 = function (arg0) {
    const ret = arg0.offsetX;
    return ret;
  };
  imports.wbg.__wbg_offsetY_e88dc24ec0418dd4 = function (arg0) {
    const ret = arg0.offsetY;
    return ret;
  };
  imports.wbg.__wbg_pixelStorei_6aba5d04cdcaeaf6 = function (arg0, arg1, arg2) {
    arg0.pixelStorei(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_pixelStorei_c8520e4b46f4a973 = function (arg0, arg1, arg2) {
    arg0.pixelStorei(arg1 >>> 0, arg2);
  };
  imports.wbg.__wbg_push_737cfc8c1432c2c6 = function (arg0, arg1) {
    const ret = arg0.push(arg1);
    return ret;
  };
  imports.wbg.__wbg_readPixels_51a0c02cdee207a5 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
      arg0.readPixels(arg1, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
    }, arguments);
  };
  imports.wbg.__wbg_readPixels_a6cbb21794452142 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
      arg0.readPixels(arg1, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
    }, arguments);
  };
  imports.wbg.__wbg_readPixels_cd64c5a7b0343355 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
      arg0.readPixels(arg1, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
    }, arguments);
  };
  imports.wbg.__wbg_scissor_e917a332f67a5d30 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.scissor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_scissor_eb177ca33bf24a44 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.scissor(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_set_37837023f3d740e8 = function (arg0, arg1, arg2) {
    arg0[arg1 >>> 0] = arg2;
  };
  imports.wbg.__wbg_set_38f7584f0ef73bd2 = function (arg0, arg1, arg2) {
    arg0[arg1] = arg2;
  };
  imports.wbg.__wbg_set_3f1d0b984ed272ed = function (arg0, arg1, arg2) {
    arg0[arg1] = arg2;
  };
  imports.wbg.__wbg_set_65595bdd868b3009 = function (arg0, arg1, arg2) {
    arg0.set(arg1, arg2 >>> 0);
  };
  imports.wbg.__wbg_set_8fc6bf8a5b1071d1 = function (arg0, arg1, arg2) {
    const ret = arg0.set(arg1, arg2);
    return ret;
  };
  imports.wbg.__wbg_setheight_da683a33fa99843c = function (arg0, arg1) {
    arg0.height = arg1 >>> 0;
  };
  imports.wbg.__wbg_setwidth_c5fed9f5e7f0b406 = function (arg0, arg1) {
    arg0.width = arg1 >>> 0;
  };
  imports.wbg.__wbg_shaderSource_72d3e8597ef85b67 = function (arg0, arg1, arg2, arg3) {
    arg0.shaderSource(arg1, getStringFromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_shaderSource_ad0087e637a35191 = function (arg0, arg1, arg2, arg3) {
    arg0.shaderSource(arg1, getStringFromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_shiftKey_86e737105bab1a54 = function (arg0) {
    const ret = arg0.shiftKey;
    return ret;
  };
  imports.wbg.__wbg_size_3d0a84da1d4aaea9 = function (arg0) {
    const ret = arg0.size;
    return ret;
  };
  imports.wbg.__wbg_stack_0ed75d68575b0f3c = function (arg0, arg1) {
    const ret = arg1.stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbg_static_accessor_GLOBAL_88a902d13a557d07 = function () {
    const ret = typeof global === 'undefined' ? null : global;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_static_accessor_GLOBAL_THIS_56578be7e9f832b0 = function () {
    const ret = typeof globalThis === 'undefined' ? null : globalThis;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_static_accessor_SELF_37c5d418e4bf5819 = function () {
    const ret = typeof self === 'undefined' ? null : self;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_static_accessor_WINDOW_5de37043a91a9c40 = function () {
    const ret = typeof window === 'undefined' ? null : window;
    return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
  };
  imports.wbg.__wbg_texParameteri_8112b26b3c360b7e = function (arg0, arg1, arg2, arg3) {
    arg0.texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
  };
  imports.wbg.__wbg_texParameteri_ef50743cb94d507e = function (arg0, arg1, arg2, arg3) {
    arg0.texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
  };
  imports.wbg.__wbg_texStorage2D_fbda848497f3674e = function (arg0, arg1, arg2, arg3, arg4, arg5) {
    arg0.texStorage2D(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
  };
  imports.wbg.__wbg_texSubImage2D_331fdc5bbdef5747 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
      arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
    }, arguments);
  };
  imports.wbg.__wbg_texSubImage2D_3b94ca3b3c44902f = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
      arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5 >>> 0, arg6 >>> 0, arg7);
    }, arguments);
  };
  imports.wbg.__wbg_texSubImage2D_c7951ed97252bdff = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
      arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
    }, arguments);
  };
  imports.wbg.__wbg_texSubImage2D_d52d1a0d3654c60b = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
      arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
    }, arguments);
  };
  imports.wbg.__wbg_texSubImage2D_fbdf91268228c757 = function () {
    return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) {
      arg0.texSubImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9);
    }, arguments);
  };
  imports.wbg.__wbg_type_a43080ecd49da621 = function (arg0) {
    const ret = arg0.type;
    return ret;
  };
  imports.wbg.__wbg_uniform1fv_9a1cdaa3386c8081 = function (arg0, arg1, arg2, arg3) {
    arg0.uniform1fv(arg1, getArrayF32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform1fv_b41efa3d9c5df06c = function (arg0, arg1, arg2, arg3) {
    arg0.uniform1fv(arg1, getArrayF32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform1iv_337c4a74e31ddc15 = function (arg0, arg1, arg2, arg3) {
    arg0.uniform1iv(arg1, getArrayI32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform1iv_fe0f24a5cc9acd95 = function (arg0, arg1, arg2, arg3) {
    arg0.uniform1iv(arg1, getArrayI32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform1uiv_03156e0197a3fb3c = function (arg0, arg1, arg2, arg3) {
    arg0.uniform1uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform3fv_7d828b7c4c91138e = function (arg0, arg1, arg2, arg3) {
    arg0.uniform3fv(arg1, getArrayF32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform3fv_8153c834ce667125 = function (arg0, arg1, arg2, arg3) {
    arg0.uniform3fv(arg1, getArrayF32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform4fv_8827081a7585145b = function (arg0, arg1, arg2, arg3) {
    arg0.uniform4fv(arg1, getArrayF32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniform4fv_c01fbc6c022abac3 = function (arg0, arg1, arg2, arg3) {
    arg0.uniform4fv(arg1, getArrayF32FromWasm0(arg2, arg3));
  };
  imports.wbg.__wbg_uniformMatrix3fv_3d6ad3a1e0b0b5b6 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix3fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
  };
  imports.wbg.__wbg_uniformMatrix3fv_3df529aab93cf902 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix3fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
  };
  imports.wbg.__wbg_uniformMatrix4fv_da94083874f202ad = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix4fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
  };
  imports.wbg.__wbg_uniformMatrix4fv_e87383507ae75670 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.uniformMatrix4fv(arg1, arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
  };
  imports.wbg.__wbg_useProgram_473bf913989b6089 = function (arg0, arg1) {
    arg0.useProgram(arg1);
  };
  imports.wbg.__wbg_useProgram_9b2660f7bb210471 = function (arg0, arg1) {
    arg0.useProgram(arg1);
  };
  imports.wbg.__wbg_value_cd1ffa7b1ab794f1 = function (arg0) {
    const ret = arg0.value;
    return ret;
  };
  imports.wbg.__wbg_vertexAttribDivisorANGLE_11e909d332960413 = function (arg0, arg1, arg2) {
    arg0.vertexAttribDivisorANGLE(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_vertexAttribDivisor_4d361d77ffb6d3ff = function (arg0, arg1, arg2) {
    arg0.vertexAttribDivisor(arg1 >>> 0, arg2 >>> 0);
  };
  imports.wbg.__wbg_vertexAttribPointer_550dc34903e3d1ea = function (arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    arg0.vertexAttribPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4 !== 0, arg5, arg6);
  };
  imports.wbg.__wbg_vertexAttribPointer_7a2a506cdbe3aebc = function (arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    arg0.vertexAttribPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4 !== 0, arg5, arg6);
  };
  imports.wbg.__wbg_viewport_a1b4d71297ba89af = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.viewport(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_viewport_e615e98f676f2d39 = function (arg0, arg1, arg2, arg3, arg4) {
    arg0.viewport(arg1, arg2, arg3, arg4);
  };
  imports.wbg.__wbg_warn_4ca3906c248c47c4 = function (arg0) {
    console.warn(arg0);
  };
  imports.wbg.__wbg_width_4f334fc47ef03de1 = function (arg0) {
    const ret = arg0.width;
    return ret;
  };
  imports.wbg.__wbindgen_as_number = function (arg0) {
    const ret = +arg0;
    return ret;
  };
  imports.wbg.__wbindgen_bigint_from_i64 = function (arg0) {
    const ret = arg0;
    return ret;
  };
  imports.wbg.__wbindgen_bigint_from_u64 = function (arg0) {
    const ret = BigInt.asUintN(64, arg0);
    return ret;
  };
  imports.wbg.__wbindgen_bigint_get_as_i64 = function (arg0, arg1) {
    const v = arg1;
    const ret = typeof v === 'bigint' ? v : undefined;
    getDataViewMemory0().setBigInt64(arg0 + 8 * 1, isLikeNone(ret) ? BigInt(0) : ret, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
  };
  imports.wbg.__wbindgen_boolean_get = function (arg0) {
    const v = arg0;
    const ret = typeof v === 'boolean' ? (v ? 1 : 0) : 2;
    return ret;
  };
  imports.wbg.__wbindgen_debug_string = function (arg0, arg1) {
    const ret = debugString(arg1);
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbindgen_error_new = function (arg0, arg1) {
    const ret = new Error(getStringFromWasm0(arg0, arg1));
    return ret;
  };
  imports.wbg.__wbindgen_in = function (arg0, arg1) {
    const ret = arg0 in arg1;
    return ret;
  };
  imports.wbg.__wbindgen_init_externref_table = function () {
    const table = wasm.__wbindgen_export_4;
    const offset = table.grow(4);
    table.set(0, undefined);
    table.set(offset + 0, undefined);
    table.set(offset + 1, null);
    table.set(offset + 2, true);
    table.set(offset + 3, false);
  };
  imports.wbg.__wbindgen_is_bigint = function (arg0) {
    const ret = typeof arg0 === 'bigint';
    return ret;
  };
  imports.wbg.__wbindgen_is_function = function (arg0) {
    const ret = typeof arg0 === 'function';
    return ret;
  };
  imports.wbg.__wbindgen_is_object = function (arg0) {
    const val = arg0;
    const ret = typeof val === 'object' && val !== null;
    return ret;
  };
  imports.wbg.__wbindgen_is_string = function (arg0) {
    const ret = typeof arg0 === 'string';
    return ret;
  };
  imports.wbg.__wbindgen_is_undefined = function (arg0) {
    const ret = arg0 === undefined;
    return ret;
  };
  imports.wbg.__wbindgen_jsval_eq = function (arg0, arg1) {
    const ret = arg0 === arg1;
    return ret;
  };
  imports.wbg.__wbindgen_jsval_loose_eq = function (arg0, arg1) {
    const ret = arg0 == arg1;
    return ret;
  };
  imports.wbg.__wbindgen_memory = function () {
    const ret = wasm.memory;
    return ret;
  };
  imports.wbg.__wbindgen_number_get = function (arg0, arg1) {
    const obj = arg1;
    const ret = typeof obj === 'number' ? obj : undefined;
    getDataViewMemory0().setFloat64(arg0 + 8 * 1, isLikeNone(ret) ? 0 : ret, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
  };
  imports.wbg.__wbindgen_number_new = function (arg0) {
    const ret = arg0;
    return ret;
  };
  imports.wbg.__wbindgen_string_get = function (arg0, arg1) {
    const obj = arg1;
    const ret = typeof obj === 'string' ? obj : undefined;
    var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len1 = WASM_VECTOR_LEN;
    getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
    getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
  };
  imports.wbg.__wbindgen_string_new = function (arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return ret;
  };
  imports.wbg.__wbindgen_throw = function (arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
  };

  return imports;
}

function __wbg_init_memory(imports, memory) {}

function __wbg_finalize_init(instance, module) {
  wasm = instance.exports;
  __wbg_init.__wbindgen_wasm_module = module;
  cachedDataViewMemory0 = null;
  cachedFloat32ArrayMemory0 = null;
  cachedInt32ArrayMemory0 = null;
  cachedUint32ArrayMemory0 = null;
  cachedUint8ArrayMemory0 = null;

  wasm.__wbindgen_start();
  return wasm;
}

function initSync(module) {
  if (wasm !== undefined) return wasm;

  if (typeof module !== 'undefined') {
    if (Object.getPrototypeOf(module) === Object.prototype) {
      ({ module } = module);
    } else {
      console.warn('using deprecated parameters for `initSync()`; pass a single object instead');
    }
  }

  const imports = __wbg_get_imports();

  __wbg_init_memory(imports);

  if (!(module instanceof WebAssembly.Module)) {
    module = new WebAssembly.Module(module);
  }

  const instance = new WebAssembly.Instance(module, imports);

  return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
  if (wasm !== undefined) return wasm;

  if (typeof module_or_path !== 'undefined') {
    if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
      ({ module_or_path } = module_or_path);
    } else {
      console.warn('using deprecated parameters for the initialization function; pass a single object instead');
    }
  }

  if (typeof module_or_path === 'undefined') {
    module_or_path = new URL('designer_bg.wasm', import.meta.url);
  }
  const imports = __wbg_get_imports();

  if (
    typeof module_or_path === 'string' ||
    (typeof Request === 'function' && module_or_path instanceof Request) ||
    (typeof URL === 'function' && module_or_path instanceof URL)
  ) {
    module_or_path = fetch(module_or_path);
  }

  __wbg_init_memory(imports);

  const { instance, module } = await __wbg_load(await module_or_path, imports);

  return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
