export enum ModelType {
  Midjourney = 'mj',
  Dalle = 'Dalle3',
  SDImageGeneration = 'sd3',
  SDInpaint = 'sd_inpaint',
  SDOutpaint = 'sd_outpaint',
  SDRemoveBackground = 'sd_bgrmv',
  SDRemoveObject = 'sd_objectremove',
  SDImg2Img = 'sd_img2img',
  SDUpscale = 'sd_upscale',
  SDImage2Video = 'sd_img2video',
  SDControlnet = 'sd_sketch',
  SDImage23D = 'sd_image23d',
  BRRemoveObject = 'br_objectremove',
  BRInpaint = 'br_inpaint',
  BRPsd = 'br_psd',
  BRTextToImage = 'br_text2image',
  BRMasks = 'br_masks',
  BRRemoveBackground = 'br_bgrmv',
  Civit = 'civit',
  IdeogramText2Image = 'ig_text2image',
  IdeogramDescribe = 'ig_describe',
  Replicate = 'replicate',
  NimCC = 'nim_cc',
  BRVector = 'br_vector',
  Luma = 'luma_video',
  LumaRay2 = 'luma_ray2',
  RunwayVideo = 'rw_video',
  MehsyImage23D = 'meshy_image23d',
  MochiV1 = 'mochiv1',
  Kling = 'kling',
  Minimax = 'minimax',
  AnyLLM = 'any_llm',
  LTXI2V = 'ltx_i2v',
  Hyper3dRodin = 'hyper_3d_rodin',
}
