import Lottie from 'lottie-react';
import { useState } from 'react';

export const LottieAnimation = ({
  animationData,
  loop = true,
  autoplay = true,
  width = 300,
  height = 300,
  onReady,
}: {
  animationData: any;
  loop?: boolean;
  autoplay?: boolean;
  width?: number;
  height?: number;
  onReady?: () => void;
}) => {
  const [showLottie, setShowLottie] = useState(true);
  const onComplete = () => {
    setShowLottie(false);
  };

  if (!animationData || !showLottie) return null;

  return (
    <div className="lottie-animation" style={{ width, height }}>
      <Lottie
        animationData={'default' in animationData ? animationData.default : animationData}
        loop={loop}
        autoplay={autoplay}
        onDOMLoaded={onReady}
        onComplete={onComplete}
      />
    </div>
  );
};
