import { Box } from '@mui/material';
import { useState } from 'react';
import NodeImageList from '../FlowComponents/NodeImageList';

function DesignAppGallery({
  mediaArray,
  recipeName,
  selected,
  setSelected,
  deleteCurrentResult,
  deleteAllResults,
  deleteAllOthersResults,
}) {
  const deletionFunctions = {
    deleteCurrentResult: deleteCurrentResult,
    deleteAllResults: deleteAllResults,
    deleteAllOthersResults: deleteAllOthersResults,
  };
  return (
    <Box
      id="design-app-gallery-container"
      sx={{
        width: '100%',
        height: '100%',
        background: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      <NodeImageList
        nodeName={recipeName}
        images={mediaArray}
        selected={selected}
        setSelected={setSelected}
        container={'design-app'}
        deletionFunctions={deletionFunctions}
      />
    </Box>
  );
}

export default DesignAppGallery;
