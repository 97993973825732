import React, { useEffect, useState } from 'react';
import { usePostHog, useFeatureFlagEnabled } from 'posthog-js/react';
import { Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import NavigationDrawer from '../components/Dashboard/NavigationDrawer';
import DashboardMain from '../components/Dashboard/DashboardMain';
import MyModels from '../components/Dashboard/MyModels';
import CommunityModels from '../components/Dashboard/CommunityModels';
import { color } from '../colors';
import axiosInstance from '../services/axiosConfig';
import { EXTERNAL_LINKS } from '../consts/externalLinks.consts';
import { RecipeType } from '../enums/recipe-type.enum';
import I18N_KEYS from '../language/keys';

function Loader({ offset }) {
  return (
    <Box
      sx={{
        zIndex: 100,
        background: color.Yambo_BG,
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="inherit" sx={{ left: `${offset / 2}px`, position: 'relative' }} />
    </Box>
  );
}

function Dashboard({ user }) {
  const posthog = usePostHog();
  const { t: translate } = useTranslation();

  const [drawerWidth] = useState(240);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [, setIsClosing] = React.useState(false);

  const [recipes, setRecipes] = useState(null);
  const [sharedApps, setSharedApps] = useState(null);
  const [hasSharedApps, setHasSharedApps] = useState(false);
  const [showCaseRecipes, setShowCaseRecipes] = useState([]);
  const [loadingRecipes, setLoadingRecipes] = useState(false);
  const [caughtError, setCaughtError] = useState(false);

  const [currentSelection, setCurrentSelection] = useState(() => {
    return localStorage.getItem('dashboardCurrentSelection') || 'recentRecipes';
  });

  /// This should be a feature flag for any banner
  const showBanner = useFeatureFlagEnabled('luma-banner');

  const getRecipes = async () => {
    // console.log("user is: ", user);
    setLoadingRecipes(true);
    // const start = Date.now();
    try {
      const res1 = await axiosInstance.get('/v1/recipes');
      const res2 = await axiosInstance.get('/v1/recipes/public');
      const res3 = await axiosInstance.get('/v1/recipes/shared');
      // const end = Date.now(); // End time
      // console.log(`Query completed in ${end - start} ms`);
      setRecipes(res1.data);
      setShowCaseRecipes(res2.data);
      setSharedApps(res3.data);
      setHasSharedApps(res3.data?.length > 0);
      setLoadingRecipes(false);
    } catch (error) {
      console.log('Error getting flows', error);
      setCaughtError(true);
      setLoadingRecipes(false);
    }
  };

  const handleGoToRecipe = (id, openInNewTab = false, isNewFile = false) => {
    const url = `/recipe/${id}?new=${isNewFile}`;
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  };

  const handleCreateRecipeSuccess = (recipe) => {
    setRecipes([...recipes, recipe]);
    handleGoToRecipe(recipe.id, false, true);
  };
  const handleCreateRecipe = async () => {
    posthog.capture('created_new_flow');
    setLoadingRecipes(true);
    try {
      const response = await axiosInstance.post(`/v1/recipes/create`, {
        user: user,
        name: user.displayName + ' recipe',
      });
      handleCreateRecipeSuccess(response.data);
    } catch (error) {
      console.log('error creating recipe: ', error);
    }
  };

  const handleDuplicateRecipeSuccess = (recipe) => {
    setRecipes([...recipes, recipe]);
  };

  const handleDuplicateRecipe = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.post(`/v1/recipes/${id}/duplicate`);
        handleDuplicateRecipeSuccess(response.data);
      } catch (error) {
        console.log('error creating recipe: ', error);
      }
    }
  };

  const handleDeleteRecipeSuccess = (response, recipeToDeleteId, recipeType) => {
    if (response.status === 204) {
      if (recipeType === RecipeType.File) {
        setRecipes((prevRecipes) => prevRecipes.filter((recipe) => recipe.id !== recipeToDeleteId));
      } else {
        setSharedApps((prevSharedApps) => prevSharedApps.filter((recipe) => recipe.id !== recipeToDeleteId));
      }
    }
  };

  const handleDeleteRecipe = async (recipeToDeleteId, recipeType) => {
    try {
      let response = null;
      if (recipeType === RecipeType.File) {
        response = await axiosInstance.delete(`/v1/recipes/${recipeToDeleteId}`);
      } else {
        response = await axiosInstance.post(`/v1/recipes/${recipeToDeleteId}/shared/delete`);
      }
      handleDeleteRecipeSuccess(response, recipeToDeleteId, recipeType);
    } catch (error) {
      console.log('error creating recipe: ', error);
    }
  };

  const handleRenameRecipe = async (newName, recipeToRenameId) => {
    let updatedRecipe = null;
    setRecipes((prevRecipes) =>
      prevRecipes.map((recipe) => {
        if (recipe.id === recipeToRenameId) {
          const updated = { ...recipe, name: newName };
          updatedRecipe = updated;

          return updated;
        }

        return recipe;
      }),
    );
    try {
      await axiosInstance.put(`/v1/recipes/${recipeToRenameId}`, updatedRecipe);
      // handlePutRecipeSuccess(response, recipeToDeleteId);
    } catch (error) {
      console.log('error creating recipe: ', error);
    }
  };

  const cancelRenameRecipe = (oldName, recipeToRenameId) => {
    setRecipes((prevRecipes) =>
      prevRecipes.map((recipe) => {
        if (recipe.id === recipeToRenameId) {
          return { ...recipe, name: oldName };
        }

        return recipe;
      }),
    );
  };

  const dashboardSections = {
    recentRecipes: () => (
      <DashboardMain
        user={user}
        error={caughtError}
        showCase={showCaseRecipes}
        recipes={recipes}
        createRecipe={handleCreateRecipe}
        duplicateRecipe={handleDuplicateRecipe}
        deleteRecipe={handleDeleteRecipe}
        renameRecipe={handleRenameRecipe}
        cancelRename={cancelRenameRecipe}
        goToRecipe={handleGoToRecipe}
        title={translate(I18N_KEYS.MAIN_DASHBOARD.SECTION_TITLE.RECENT)}
      />
    ),
    myModels: () => <MyModels user={user} title="My Models" />,
    communityModels: () => <CommunityModels user={user} title="Public Models" />,
    sharedApps: () => (
      <DashboardMain
        user={user}
        error={caughtError}
        showCase={showCaseRecipes}
        recipes={sharedApps}
        createRecipe={handleCreateRecipe}
        duplicateRecipe={handleDuplicateRecipe}
        deleteRecipe={handleDeleteRecipe}
        renameRecipe={handleRenameRecipe}
        cancelRename={cancelRenameRecipe}
        goToRecipe={handleGoToRecipe}
        title={translate(I18N_KEYS.MAIN_DASHBOARD.SHARED_APPS)}
        isSharedApps={true}
      />
    ),
  };

  const handleSectionSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };

  useEffect(() => {
    localStorage.setItem('dashboardCurrentSelection', currentSelection);
  }, [currentSelection]);

  const renderSelectedSection = () => {
    const SectionComponentFunc = dashboardSections[currentSelection];

    return SectionComponentFunc ? SectionComponentFunc() : null;
  };

  useEffect(() => {
    getRecipes();
  }, []);

  const handleBannerCTAClick = () => {
    posthog.capture('dashboard_banner_cta_click');
    window.location.href = EXTERNAL_LINKS.lumaShowCaseWorkflow;
  };

  return (
    <>
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <NavigationDrawer
          createRecipe={handleCreateRecipe}
          drawerWidth={drawerWidth}
          user={user}
          setIsClosing={setIsClosing}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          selectedSection={currentSelection}
          onSectionChange={handleSectionSelectionChange}
          hasSharedApps={hasSharedApps}
        />
        {showBanner && (
          <Box
            sx={{
              zIndex: '100',
              width: `calc(100% - ${drawerWidth}px)`,
              // height:'28px',
              backgroundColor: color.Yambo_Purple,
              position: 'fixed',
              top: 0,
              left: drawerWidth,
              py: 1,
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {translate(I18N_KEYS.DASHBOARD_BANNER.MESSAGE)}&nbsp;
              <Button
                sx={{ height: '24px', ml: 1 }}
                variant="contained"
                color="weavy_cta_secondary"
                size="small"
                onClick={handleBannerCTAClick}
              >
                {translate(I18N_KEYS.DASHBOARD_BANNER.CTA)}
              </Button>
            </Typography>
          </Box>
        )}
        {/* <DashboardMain user={user} recipes={recipes} createRecipe={handleCreateRecipe} duplicateRecipe={handleDuplicateRecipe} deleteRecipe={handleDeleteRecipe} renameRecipe={handleRenameRecipe} cancelRename={cancelRenameRecipe} goToRecipe={handleGoToRecipe} /> */}
        {renderSelectedSection()}
      </Box>
      {loadingRecipes && <Loader offset={drawerWidth} />}
    </>
  );
}

export default Dashboard;
