import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import I18N_KEYS from '../../language/keys';

function PaymentSuccessHandler() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('payment') === 'success') {
      // Clean up the URL immediately after detecting success
      window.history.replaceState({}, '', window.location.pathname);

      // Get the previous page URL from localStorage
      const previousPageUrl = localStorage.getItem('lastPageBeforePayment') || '/';

      try {
        const { pathname, search } = new URL(previousPageUrl);
        const redirectPath = pathname + search;

        // Set flag to show the snackbar after redirecting
        setShowSnackbar(true);

        // Redirect to the previous page
        navigate(redirectPath);
      } catch (error) {
        // Fallback if URL parsing fails
        navigate('/');
        setShowSnackbar(true);
      }
    }
  }, [navigate]);

  // Clean up URL and show the snackbar after redirect
  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => {
        // Clean up any remaining query params from the current URL
        window.history.replaceState({}, '', window.location.pathname);
      }, 500); // Delay before cleaning the URL after showing the snackbar
    }
  }, [showSnackbar]);

  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={5000}
      onClose={() => setShowSnackbar(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={() => setShowSnackbar(false)}
        severity="success"
        variant="filled"
        sx={{ width: '100%', '& .MuiAlert-icon': { display: 'none' } }}
      >
        {translate(I18N_KEYS.PAYMENTS.SUCCESS_MESSAGE)}
      </Alert>
    </Snackbar>
  );
}

export default PaymentSuccessHandler;
