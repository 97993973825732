import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { color } from '../../../colors';

function LeftPanelMenuItem({ item }) {
  return (
    <Tooltip disableHoverListener={!item?.paid} title="This is a paid model" placement="top">
      <Box
        sx={{
          border: '1px solid',
          borderColor: color.Dark_Grey,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
          cursor: 'grab',
          height: '82px',
          position: 'relative',
        }}
      >
        {item.logo && <img src={item.logo} width="28px" />}
        <Typography variant="caption" sx={{ textAlign: 'center' }}>
          {item.displayName}
        </Typography>
        {item.paid && (
          // <Typography variant="caption" sx={ { textAlign:'center', fontSize:'10px' } }>
          //   Pro plan only
          // </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: 5,
              right: 8,
            }}
          >
            <Typography
              sx={{
                fontSize: '8px',
                background: color.Yambo_Purple,
                fontWeight: 'bold',
                py: 0.4,
                px: 0.8,
                borderRadius: '4px',
                lineHeight: '8px',
              }}
            >
              Paid
            </Typography>
            {/* <i className="fa-solid fa-crown fa-xs" style={ { color:color.Yellow } }></i> */}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

function LeftPanelModelMenuItem({ item }) {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: color.Dark_Grey,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent:'space-between',
        alignItems: 'center',
        p: 2,
        cursor: 'grab',
        height: '60px',
        overflow: 'hidden',
      }}
    >
      {/* <Box>{iconMap[item.icon]}</Box> */}
      {item.logo && <img src={item.logo} width="28px" />}
      <Tooltip title={item.displayName} placement="top" enterDelay={500}>
        <Typography
          variant="caption"
          noWrap
          fontWeight="bold"
          sx={{ ml: 1, textAlign: 'left', textOverflow: 'ellipsis', width: '100%' }}
        >
          {item.displayName}
        </Typography>
      </Tooltip>
    </Box>
  );
}

export { LeftPanelMenuItem, LeftPanelModelMenuItem };
