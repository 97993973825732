import { Box, ButtonBase, Tooltip } from '@mui/material';
import { color } from '../../../colors';

const menuItems = [
  {
    displayName: 'Toolbox',
    icon: <i className="fa-solid fa-toolbox fa-lg"></i>,
    name: 'toolbox',
  },
  {
    displayName: 'Image Models',
    icon: <i className="fa-kit fa-image-model fa-xl"></i>,
    name: 'image',
  },
  {
    displayName: 'Video Models',
    icon: <i className="fa-kit fa-video-model fa-xl"></i>,
    name: 'video',
  },
  {
    displayName: '3D Models',
    // icon:<i className="fa-solid fa-cube fa-lg"></i>,
    icon: <i className="fa-kit fa-3d-model fa-xl"></i>,
    name: 'threedee',
  },
  {
    displayName: 'My Models and Community Models',
    icon: <i className="fa-solid fa-bolt fa-lg"></i>,
    name: 'models',
  },
  {
    displayName: 'Media',
    icon: <i className="fa-solid fa-photo-film fa-lg"></i>,
    name: 'files',
  },
];
function LeftToolMenu({ selectedItem, setSelectedItem }) {
  const handleMenuItemClick = (item) => {
    if (item === selectedItem) setSelectedItem(null);
    else setSelectedItem(item);
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/T7BH8rGE4t');
  };

  return (
    <Box
      id="main-toolbar-container"
      sx={{
        width: '100%',
        height: '100%',
        background: color.Dark_Blue,
        border: '1px solid',
        borderColor: color.Dark_Grey,
        borderRadius: selectedItem ? '4px 0 0 4px' : '4px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box id="toolbar-container" sx={{ flexGrow: 1 }}>
        {menuItems.map((item, index) => (
          <Box
            id={`toolbar-menu-item-${item.name}`}
            key={index}
            sx={{
              width: '46px',
              height: '46px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              p: 1,
            }}
          >
            <Tooltip title={item.displayName} placement="right" enterDelay={800} enterNextDelay={100}>
              <ButtonBase
                onClick={() => handleMenuItemClick(item.name)}
                className={item.name === selectedItem ? 'toolbar-menu-item selected' : 'toolbar-menu-item'}
                sx={{ width: '100%', height: '100%', borderRadius: '4px' }}
              >
                {item.icon}
              </ButtonBase>
            </Tooltip>
          </Box>
        ))}
      </Box>
      <Box id="toolbar-extras-container">
        <Box
          sx={{
            width: '46px',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            p: 1,
          }}
        >
          <Tooltip title="Join Our Discord" placement="right" enterDelay={800} enterNextDelay={100}>
            <ButtonBase
              onClick={handleDiscordClick}
              className="toolbar-menu-item discord"
              sx={{ width: '100%', height: '100%', borderRadius: '4px' }}
            >
              <i className="fa-brands fa-discord fa-lg"></i>
            </ButtonBase>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}

export default LeftToolMenu;
