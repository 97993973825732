import { useUserRole } from '../../Recipe/UserRoleContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Link, Tooltip, Menu, MenuItem, Divider, ButtonBase, Typography, Fade } from '@mui/material';
import { getHandleId, getOS } from '../Utils';
import I18N_KEYS from '../../../language/keys';
import { useNodeActions } from '../NodeActionsContext';
import DynamicHandleLabel from './DynamicHandleLabel';
import { Position } from 'reactflow';

type TDividerMenuItem = {
  type: 'divider';
};

type TMenuItem =
  | {
      action: () => void;
      disabled?: boolean;
      name: string;
      shortcut?: string;
    }
  | TDividerMenuItem;

const isDividerMenuItem = (item: TMenuItem): item is TDividerMenuItem => {
  return 'type' in item && item.type === 'divider';
};

export const DynamicNode2 = ({
  id,
  data,
  children,
  className,
  handleColor,
  headerColor,
  hideTitle,
  hideBody,
  icon,
  inputHandleYPos,
  outputHandleYPos,
  additionalMenu = [],
  validationError,
}: {
  id: string;
  data: {
    handles?: { input?: Record<string, any>; output?: string[] };
    version?: number;
    isLocked?: boolean;
    name: string;
  };
  children: React.ReactNode;
  className: string;
  handleColor?: string;
  headerColor?: string;
  hideTitle?: boolean;
  hideBody?: boolean;
  icon?: React.ReactNode;
  inputHandleYPos?: string;
  outputHandleYPos?: string;
  additionalMenu?: TMenuItem[];
  validationError?: string[];
}) => {
  const role = useUserRole();

  const { t: translate } = useTranslation();

  const { deleteNode, duplicateNode, toggleLockNode, openOverlayDialog } = useNodeActions();
  const [os, setOs] = useState('other');
  const shortcutKey = os === 'Mac' ? 'cmd' : 'ctrl';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { handles, version } = data;
  const handlesSpace = 50;
  const outputHandleTop = outputHandleYPos || '18px';
  const inputHandleTop = inputHandleYPos || '18px';

  // node locked indication
  const nodeRef = useRef<HTMLDivElement>(null);
  const [showIsLockedTooltip, setShowIsLockedTooltip] = useState(false);
  const [interactionWhileLockedCount, setInteractionWhileLockedCount] = useState(0);

  useEffect(() => {
    setOs(getOS());
  }, []);

  const handleDelete = useCallback(() => {
    deleteNode(id);
  }, [deleteNode, id]);

  const handleDuplicate = useCallback(() => {
    duplicateNode(id);
  }, [duplicateNode, id]);

  const handleToggleLock = useCallback(() => {
    toggleLockNode(id);
  }, [toggleLockNode, id]);

  const handleRename = useCallback(() => {
    openOverlayDialog('rename', id, data);
  }, [openOverlayDialog]);

  const menu: TMenuItem[] = [
    {
      name: `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.DUPLICATE)}`,
      action: handleDuplicate,
      shortcut: `${shortcutKey}+d`,
    },
    {
      name: `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.DELETE)}`,
      action: handleDelete,
      shortcut: 'delete / backspace',
    },
    {
      name: data.isLocked
        ? `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.UNLOCK)}`
        : `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.LOCK)}`,
      action: handleToggleLock,
    },
    {
      type: 'divider',
    },
    {
      name: `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.RENAME)}`,
      action: handleRename,
    },
    ...additionalMenu,
  ];

  // sort handles by its order
  const sortedInputHandles = Object.entries(handles?.input || {}).sort(
    ([, a], [, b]) => (a.order || 0) - (b.order || 0),
  );

  const handleLockedInteraction = useCallback(
    (e) => {
      if (data.isLocked && role !== 'guest') {
        setInteractionWhileLockedCount((prev) => {
          const newCount = prev + 1;
          if (newCount === 4) {
            setShowIsLockedTooltip(true);
            return 0; // Reset counter after showing tooltip
          }
          return newCount;
        });
      }
    },
    [data.isLocked],
  );

  const handleTooltipClose = useCallback(() => {
    setShowIsLockedTooltip(false);
  }, []);

  useEffect(() => {
    if (!data.isLocked) {
      setShowIsLockedTooltip(false);
    }
  }, [data.isLocked]);

  // Add handlers for both click and mousedown (drag attempt)
  useEffect(() => {
    const nodeElement = nodeRef.current;
    if (nodeElement) {
      nodeElement.addEventListener('mousedown', handleLockedInteraction);

      return () => {
        nodeElement.removeEventListener('mousedown', handleLockedInteraction);
      };
    }
  }, [id, handleLockedInteraction]);

  return (
    <Tooltip
      title={
        showIsLockedTooltip ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {`${translate(I18N_KEYS.RECIPE_MAIN.NODES.LOCKED.THIS_NODE_IS_LOCKED)}`}&nbsp;
            <Link
              onClick={(e) => {
                e.stopPropagation();
                handleToggleLock();
                setShowIsLockedTooltip(false);
              }}
            >
              {`${translate(I18N_KEYS.RECIPE_MAIN.NODES.LOCKED.CLICK_TO_UNLOCK)}`}
            </Link>
          </Box>
        ) : (
          ''
        )
      }
      placement="top"
      open={showIsLockedTooltip}
      onClose={handleTooltipClose}
      leaveDelay={500}
      slots={{
        transition: Fade,
      }}
    >
      <Box
        ref={nodeRef}
        className={`custom-node two-input-output ${className}`}
        sx={{ cursor: role === 'guest' ? 'default' : '' }}
      >
        {!hideTitle && (
          <Box
            className="node-header"
            sx={{
              overflow: 'hidden',
              width: '100%',
              background: headerColor || '',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              {icon ? icon : <img src="/icons/dots.svg" width="8px" style={{ opacity: 0.8 }} />}
              <Typography
                variant="body1"
                sx={{ mx: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {data.name}
              </Typography>
            </Box>
            <Box>
              {(role === 'guest' || data.isLocked) && (
                <i className="fa-solid fa-lock fa-sm" style={{ opacity: 0.6 }}></i>
              )}
              {role === 'editor' && menu && menu.length > 0 && (
                <>
                  <ButtonBase
                    size="small"
                    sx={{ px: 1, py: 1, mr: -1, my: -1 }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <img src="/icons/ellipsis-vertical.svg" width="12px" style={{ opacity: 0.6 }} />
                  </ButtonBase>
                  <Menu
                    id="node-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {menu.map((item, index) => {
                      if (isDividerMenuItem(item)) {
                        return <Divider key={index} />;
                      } else {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              item.action();
                              setAnchorEl(null);
                            }}
                            sx={{
                              justifyContent: 'space-between',
                              width: '240px',
                            }}
                            disabled={item.disabled}
                          >
                            <Typography variant="caption">{item.name}</Typography>
                            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                              {item.shortcut}
                            </Typography>
                          </MenuItem>
                        );
                      }
                    })}
                  </Menu>
                </>
              )}
            </Box>
          </Box>
        )}
        {/* <Box><Typography>{id}</Typography></Box> */}
        {!hideBody && (
          <Box className="node-content" sx={{ width: '100%' }}>
            {children}
          </Box>
        )}
        {version === 2
          ? sortedInputHandles.map(([key, inputHandle], index) => (
              <DynamicHandleLabel
                key={index}
                handleType="target"
                handlePosition={Position.Left}
                handleTop={inputHandleTop}
                handleSpace={handlesSpace}
                handleColor={handleColor}
                handleId={getHandleId(id, 'input', key)} //{`${id}-input-${key}`}
                label={key}
                index={index}
                required={inputHandle.required}
                description={inputHandle.description}
                format={inputHandle.format}
                requiredValidationError={validationError?.includes(key)}
              />
            ))
          : handles?.input?.map((inputHandle, index) => (
              <DynamicHandleLabel
                key={index}
                handleType="target"
                handlePosition={Position.Left}
                handleTop={inputHandleTop}
                handleSpace={handlesSpace}
                handleColor={handleColor}
                handleId={getHandleId(id, 'input', inputHandle)} //{`${id}-input-${inputHandle}`}
                label={inputHandle}
                index={index}
              />
            ))}
        {handles?.output?.map((outputHandle, index) => (
          <DynamicHandleLabel
            key={index}
            handleType="source"
            handlePosition={Position.Right}
            handleTop={outputHandleTop}
            handleSpace={handlesSpace}
            handleColor={handleColor}
            handleId={getHandleId(id, 'output', outputHandle)} //{`${id}-output-${outputHandle}`}
            label={outputHandle}
            index={index}
          />
        ))}
      </Box>
    </Tooltip>
  );
};
