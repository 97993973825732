import {
  Box,
  Typography,
  Tooltip,
  Slider,
  Input,
  TextField,
  Skeleton,
  Divider,
  Chip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { color } from '../../../colors';
import CancellableLoadingButton from '../../CancellableLoadingButton/CancellableLoadingButton';
import { CreditsContext } from '../../../services/CreditsContext';
import { useUserRole } from '../UserRoleContext';
import {
  renderDesignAppParams,
  getWorkflowInputNodes,
  getConnectedOutputNodesCount,
} from '../DesignApp/DesignAppUtils';
import DesignAppUploadFile from '../DesignApp/DesignAppUploadFile';
import DesignAppToolbar from '../DesignApp/DesignAppToolbar';
import DesignAppPrompt from '../DesignApp/DesignAppPrompt';
import DesignAppInputHeader from '../DesignApp/DesignAppInputHeader';
import axiosInstance from '../../../services/axiosConfig';
import I18N_KEYS from '../../../language/keys';
import { NodeType } from '../../../enums/node-type.enum';
import { DesignAppMode } from '../../../enums/design-app-modes.enum';
import Preview from './Preview/Preview';
import ReadOnlyPanel from './ReadOnlyPanel';
import Params from './Params/Params';

const PARAM_TYPES = ['seed', 'array', 'number', 'integer', 'boolean', 'mux'];
const DESIGN_APP_HEADER_HEIGHT = 48;
const DESIGN_APP_HEADER_WIDTH = 400;
const CONTROLS_HEIGHT = 116;
const PARAMS_DRAWER_ID = '[PARAMS_DRAWER]';

const SetAsParameterLabel = () => {
  const { t: translate } = useTranslation();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box>
        <i class="fa-light fa-arrow-right-to-arc"></i>
      </Box>
      <Box>{translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.SET_AS_PARAMETER)}</Box>
    </Box>
  );
};

export const InputPanelSkeleton = () => {
  return (
    <Box
      id="design-app-inputs-container-skeleton"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        mb: 1,
        p: 2,
      }}
    >
      <Divider sx={{ ml: -2 }} textAlign="left">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Chip size="small" label={<Skeleton variant="rounded" width={100} height={16} />} />
        </Box>
      </Divider>
      <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
        <Skeleton animation="wave" variant="rounded" width="90%" height={8} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid ${color.Dark_Grey}`,
          borderRadius: 1,
          p: 1,
          gap: 1,
        }}
      >
        <Skeleton animation="wave" variant="rounded" width="100%" height={8} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={8} />
        <Skeleton animation="wave" variant="rounded" width="80%" height={8} />
        <Skeleton animation="wave" variant="rounded" width="60%" height={8} />
      </Box>
    </Box>
  );
};

function DesignApp({
  recipeId,
  recipeData,
  setRecipeData,
  nodes,
  edges,
  readOnly = false,
  updateNodeData,
  saveDesignAppMetadata,
}) {
  // todo: disable button while input file is being uploaded

  const [designAppData, setDesignAppData] = useState(null);
  const { t: translate } = useTranslation();
  const { setUserCredits } = useContext(CreditsContext);
  const role = useUserRole();
  // designApp object
  const [inputs, setInputs] = useState([]);
  const [results, setResults] = useState([]);
  const [numberOfRuns, setNumberOfRuns] = useState(1);
  const [isLoadingDesignApp, setIsLoadingDesignApp] = useState(true);
  const [isParsingInputs, setIsParsingInputs] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [mode, setMode] = useState(role === 'editor' && !readOnly ? DesignAppMode.Editing : DesignAppMode.Running);
  const canCancel = useRef(false);
  const isLoading = isLoadingDesignApp || isParsingInputs;

  const [leftPanelWidth, setLeftPanelWidth] = useState(`${localStorage.getItem('designAppLeftPanelWidth') || '30'}%`);
  const [isDraggingPanel, setIsDraggingPanel] = useState(false);

  // handle validation
  const [validationErrors, setValidationErrors] = useState({});
  const inputRefs = useRef({});

  const [numberOfLoadingResults, setNumberOfLoadingResults] = useState(0);

  // drag and drop
  const [draggedInputId, setDraggedInputId] = useState(null);
  const [targetInputId, setTargetInputId] = useState(null);
  const [isEditingInputMetadata, setIsEditingInputMetadata] = useState(false); // to prevent dragging input metadata while editing text

  /// run workflow
  const [isProcessing, setIsProcessing] = useState(false);
  // const [progress, setProgress] = useState(0);

  const [errorMessage, setErrorMessage] = useState(null);

  const isMounted = useRef(true);

  const [designAppMetadata, setDesignAppMetadata] = useState(recipeData?.designAppMetadata || {});

  const { exposedInputs, concealedInputs } = useMemo(() => {
    if (!inputs) return { exposedInputs: [], concealedInputs: [] };
    return inputs.reduce(
      (acc, input) => {
        if (input.exposed) {
          acc.exposedInputs.push(input);
        } else {
          acc.concealedInputs.push(input);
        }
        return acc;
      },
      { exposedInputs: [], concealedInputs: [] },
    );
  }, [inputs]);

  const isEditMode = role === 'editor' && mode === DesignAppMode.Editing;

  /// validate inputs
  const validateInputs = () => {
    const errors = {};
    exposedInputs
      .filter((input) => !input.disabled)
      .forEach((input) => {
        console.log('input', input);
        if (
          !input.value ||
          (input.type === NodeType.Prompt && !input.value.prompt) ||
          (input.type === NodeType.String && !input.value.string?.trim())
        ) {
          errors[input.id] = translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR);
        }
        if (input.type === NodeType.Import && !input.value.file?.url && !input.value.url) {
          errors[input.id] = translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR_FILE);
        }
        if (input.type === NodeType.MultiLora && !input.value.selectedLora?.file) {
          errors[input.id] = translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR_LORA);
        }
      });

    setValidationErrors(errors);
    // If there are errors, scroll to the first one
    if (Object.keys(errors).length > 0) {
      const firstErrorId = Object.keys(errors)[0];
      const inputsContainer = document.querySelector('#design-app-inputs-container');
      const errorElement = inputRefs.current[firstErrorId];

      if (inputsContainer && errorElement) {
        inputsContainer.scrollTo({
          top: errorElement.offsetTop - inputsContainer.offsetTop - 16,
          behavior: 'smooth',
        });
      }
    }
    return Object.keys(errors).length === 0;
  };

  /// end validate inputs

  /// parsing workflow inputs

  const getParsedInputValue = (input, existingValue) => {
    // todo: simplify conditions

    if (input.type === NodeType.MultiLora) {
      return mode !== DesignAppMode.Editing && existingValue
        ? existingValue
        : {
            weight: input.data.weight,
            selectedLora: input.data.selectedLora,
          };
    }

    if (input.type === NodeType.Prompt) {
      return mode !== DesignAppMode.Editing && existingValue
        ? existingValue
        : {
            prompt: input.data.result.prompt,
          };
    }
    if (input.type === NodeType.Import) {
      return mode !== DesignAppMode.Editing && existingValue
        ? existingValue
        : {
            file: input.data.result,
          };
    }

    if (input.type === NodeType.String) {
      return existingValue?.string
        ? existingValue
        : {
            string: input.data.result.string,
          };
    }

    return mode !== DesignAppMode.Editing && existingValue ? existingValue : input.data.result;
  };

  const numberOfOutputNodes = useMemo(() => {
    return getConnectedOutputNodesCount(nodes, edges);
  }, [nodes]);

  const parseInputs = (inputNodes, initialDesignAppData) => {
    setIsParsingInputs(true);
    const clonedInput = cloneDeep(inputNodes);
    const filteredPrompts = clonedInput.filter((n) => {
      return n.type === NodeType.Prompt && !n.data.isLocked;
    });
    const filteredImports = clonedInput.filter((n) => {
      return n.type === NodeType.Import && !n.data.isLocked;
    });
    const filteredStrings = clonedInput.filter((n) => {
      return n.type === NodeType.String && !n.data.isLocked;
    });
    const filteredLoRas = clonedInput.filter((n) => {
      return n.type === NodeType.MultiLora && !n.data.isLocked;
    });
    const filteredParamNodes = clonedInput.filter((n) => {
      return PARAM_TYPES.includes(n.type) && !n.data.isLocked;
    });

    const allInputs = [...filteredPrompts, ...filteredStrings, ...filteredImports, ...filteredLoRas];

    const parsedInputs = [];

    for (const [index, input] of allInputs.entries()) {
      const currentInput = initialDesignAppData?.inputs?.find((i) => i.id === input.id);
      const existingValue = currentInput?.value;
      const existingOrder = currentInput?.order;
      const existingExposed = currentInput?.exposed;
      const existingDisabled = currentInput?.disabled;

      let inputMetadata;
      if (designAppMetadata[input.id]) {
        inputMetadata = designAppMetadata[input.id];
      } else {
        inputMetadata = {
          order: index,
          required: false,
          exposed: true,
          disabled: typeof existingDisabled === 'boolean' ? existingDisabled : false,
        };
        setDesignAppMetadata((prevMetadata) => ({
          ...prevMetadata,
          [input.id]: inputMetadata,
        }));
      }

      const parsedInput = {
        id: input.id,
        type: input.type,
        description: input.data.description,
        value: getParsedInputValue(input, existingValue),
        exposed: role === isEditMode ? (existingExposed ?? inputMetadata.exposed) : inputMetadata.exposed,
        order: role === isEditMode ? (existingOrder ?? inputMetadata.order) : inputMetadata.order,
        required: inputMetadata.required,
        disabled: typeof existingDisabled === 'boolean' ? existingDisabled : false,
      };
      parsedInputs.push(parsedInput);
    }

    for (const [index, paramNode] of filteredParamNodes.entries()) {
      const currentInput = initialDesignAppData?.inputs?.find((i) => i.id === paramNode.id);
      const existingValue = currentInput?.value;
      const existingOrder = currentInput?.order;
      const existingExposed = currentInput?.exposed;
      const existingDisabled = currentInput?.disabled;

      let inputMetadata;
      if (designAppMetadata[paramNode.id]) {
        inputMetadata = designAppMetadata[paramNode.id];
      } else {
        inputMetadata = {
          order: existingOrder || index,
          required: false,
          exposed: false,
          disabled: typeof existingDisabled === 'boolean' ? existingDisabled : false,
        };
        setDesignAppMetadata((prevMetadata) => ({
          ...prevMetadata,
          [paramNode.id]: inputMetadata,
        }));
      }

      const parsedParam = {
        id: paramNode.id,
        type: paramNode.type,
        description: paramNode.data.description,
        value: getParsedInputValue(paramNode, existingValue),
        exposed: role === isEditMode ? (existingExposed ?? inputMetadata.exposed) : inputMetadata.exposed,
        order: role === isEditMode ? (existingOrder ?? inputMetadata.order) : inputMetadata.order,
        mode: paramNode.data.mode,
        required: inputMetadata.required,
        disabled: typeof existingDisabled === 'boolean' ? existingDisabled : false,
      };
      parsedInputs.push(parsedParam);
    }

    // Sort inputs by order
    const sortedInputs = parsedInputs.sort((a, b) => {
      return a.order - b.order;
    });
    setInputs(sortedInputs);
    setIsParsingInputs(false);
  };

  /// end parsing workflow inputs

  const handleStopProcessing = () => {
    setIsProcessing(false);
  };

  const pollRunsStatus = async (runIds) => {
    let runIdsToPoll = [...runIds];
    const poll = async () => {
      if (!runIdsToPoll.length) {
        handleStopProcessing();

        return;
      }

      try {
        const response = (
          await axiosInstance.get(`/v1/recipes/${recipeId}/runs/status?runIds=${runIdsToPoll.join(',')}`)
        ).data;

        runIdsToPoll = [];

        for (const runId in response?.runs) {
          const currentRunStatus = response?.runs[runId];
          const status = currentRunStatus.status;
          switch (status) {
            case 'COMPLETED':
              setResults((prevResults) => [...currentRunStatus.results, ...prevResults]);
              setUserCredits(response?.remainingCredits);
              break;

            case 'FAILED':
              // todo: better solution when one out of many fails
              setErrorMessage(currentRunStatus.error);
              break;

            case 'CANCELED':
              break;

            case 'RUNNING':
              runIdsToPoll.push(runId);
              break;
          }
        }

        setNumberOfLoadingResults(runIdsToPoll.length * numberOfOutputNodes);

        if (isMounted.current) {
          // Schedule next poll
          setTimeout(poll, 1000);
        }
      } catch (error) {
        setErrorMessage(error?.message || 'Something went wrong');
        handleStopProcessing();
      }
    };

    // Start polling
    await poll();
  };

  const handleStartProcessing = () => {
    setErrorMessage(null);
    setIsProcessing(true);
  };

  const handleExistingRuns = (runs) => {
    const runningRunIds = [];
    runs?.forEach((run) => {
      if (run.status === 'COMPLETED' || run.status === 'CANCELED') {
        return;
      }

      if (run.status === 'FAILED') {
        setErrorMessage(run.error || 'Something went wrong');

        return;
      }

      runningRunIds.push(run.runId);
    });

    if (runningRunIds.length) {
      canCancel.current = true;
      handleStartProcessing();
      pollRunsStatus(runningRunIds);
    }
  };

  const fetchDesignAppData = async (version) => {
    try {
      const response = await axiosInstance.get(`/v1/recipes/${recipeId}/user-design-app?version=${version}`);
      const fetchedData = response.data;

      setDesignAppData(fetchedData);
      setResults(fetchedData?.results || []);

      // Initialize inputs based on fetched data
      const workflowInputNodes = getWorkflowInputNodes(nodes, edges);
      parseInputs(workflowInputNodes, fetchedData);

      // Check for running tasks
      if (fetchedData?.latestRuns?.length) {
        handleExistingRuns(fetchedData.latestRuns);
      }
    } catch (error) {
      setErrorMessage(error?.message || 'Failed to fetch design app data');
      console.error('Error fetching design app data:', error);
    } finally {
      setTimeout(() => {
        setIsLoadingDesignApp(false);
      }, 1000);
    }
  };

  // init
  useEffect(() => {
    setIsLoadingDesignApp(true);
    fetchDesignAppData(recipeData.recipeVersion);
    return () => {
      isMounted.current = false;
    };
  }, []);

  /// handle input change
  const updateInput = (inputId, field, newValue) => {
    setInputs((prevInputs) => {
      return prevInputs.map((input) => {
        if (input.id === inputId) {
          let updatedInput = cloneDeep(input);
          updatedInput = set(updatedInput, field, newValue);
          return updatedInput;
        }
        return input;
      });
    });
  };

  const updateMetadata = (inputId, field, newValue) => {
    setDesignAppMetadata((prevMetadata) => {
      const updatedMetadata = { ...prevMetadata };
      updatedMetadata[inputId] = { ...updatedMetadata[inputId], [field]: newValue };
      return updatedMetadata;
    });
    setRecipeData((prevRecipeData) => {
      const updatedRecipeData = { ...prevRecipeData };
      updatedRecipeData.designAppMetadata[inputId][field] = newValue;
      return updatedRecipeData;
    });
  };

  //todo: remove externalValue and make it more generic. was added since string node has different value structure
  const handleChange = (paramId, newValue, externalValue) => {
    setErrorMessage(null);
    setValidationErrors((prev) => {
      const updated = { ...prev };
      delete updated[paramId];
      return updated;
    });
    if (isEditMode) {
      // Maybe change not to use externalData? nulls are problematic
      updateNodeData(paramId, { externalData: externalValue ?? newValue });
    }
    updateInput(paramId, 'value', newValue);
  };

  const handleExposeParam = (paramId, exposed) => {
    updateMetadata(paramId, 'exposed', exposed);
    updateInput(paramId, 'exposed', exposed);
  };

  const handleDisableInput = (inputId, disabled) => {
    updateMetadata(inputId, 'disabled', disabled);
    updateInput(inputId, 'disabled', disabled);
  };

  /// end handle input change

  /// debounce save design app
  const debounceDesignAppTimeoutRef = useRef();
  const debounceDesignAppMetadataTimeoutRef = useRef();

  const saveDesignApp = async (updatedInputs) => {
    try {
      await axiosInstance.post(`/v1/recipes/${recipeId}/design-app-params/save`, {
        designApp: updatedInputs,
      });

      // Optionally update local state with the response
      setDesignAppData((prevData) => ({
        ...prevData,
        inputs: updatedInputs,
      }));
    } catch (error) {
      console.error('Failed to save design app', error);
    }
  };

  const debouncedSaveApp = useCallback((currentInputs) => {
    clearTimeout(debounceDesignAppTimeoutRef.current);
    debounceDesignAppTimeoutRef.current = setTimeout(() => {
      saveDesignApp(currentInputs);
    }, 500);
  }, []);

  useEffect(() => {
    setDesignAppData({ inputs, results });
    debouncedSaveApp(inputs);

    return () => {
      clearTimeout(debounceDesignAppTimeoutRef.current);
    };
  }, [inputs, debouncedSaveApp, results]);

  // debounce save design app metadata
  const debouncedSaveDesignAppMetadata = useCallback((currentDesignAppMetadata) => {
    clearTimeout(debounceDesignAppMetadataTimeoutRef.current);
    debounceDesignAppMetadataTimeoutRef.current = setTimeout(() => {
      saveDesignAppMetadata(false, currentDesignAppMetadata);
    }, 500);
  }, []);

  useEffect(() => {
    debouncedSaveDesignAppMetadata(designAppMetadata);

    return () => {
      clearTimeout(debounceDesignAppMetadataTimeoutRef.current);
    };
  }, [designAppMetadata, debouncedSaveDesignAppMetadata]);

  /// end debounce save design app metadata

  const DefaultsComment = () => {
    if (mode === DesignAppMode.Running) return null;

    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        <Typography variant="caption" sx={{ color: 'transparent' }}>
          {translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.DEFAULTS_COMMENT)}
        </Typography>
      </Box>
    );
  };

  const renderInputContent = (input) => {
    const hasError = !!validationErrors[input.id];

    const validationProps = {
      error: hasError,
      helperText: hasError ? validationErrors[input.id] : null,
    };

    const disabledConcealAction = {
      label: <SetAsParameterLabel />,
      disabled: true,
      onClick: () => {},
    };

    const concealAction = {
      label: <SetAsParameterLabel />,
      disabled: false,
      onClick: () => handleExposeParam(input.id, false),
    };

    switch (input.type) {
      case NodeType.Prompt:
        return (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <DesignAppInputHeader
              input={designAppMetadata[input.id]}
              inputId={input.id}
              updateNodeData={updateNodeData}
              role={role}
              inputColor={color.Yambo_Green}
              nodes={nodes}
              setIsEditingInputMetadata={setIsEditingInputMetadata}
              mode={mode}
              error={hasError}
              actions={[disabledConcealAction]}
            />
            <DesignAppPrompt
              id={input.id}
              value={input.value?.prompt}
              onChange={handleChange}
              updateNodeData={updateNodeData}
              setIsEditingInputMetadata={setIsEditingInputMetadata}
              mode={mode}
              role={role}
              isLoading={isLoading}
              {...validationProps}
            />
          </Box>
        );
      case NodeType.Import:
        return (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <DesignAppInputHeader
              input={input}
              inputId={input.id}
              updateNodeData={updateNodeData}
              role={role}
              inputColor={color.Yambo_Blue}
              nodes={nodes}
              setIsEditingInputMetadata={setIsEditingInputMetadata}
              mode={mode}
              error={hasError}
              actions={[disabledConcealAction]}
            />
            <DesignAppUploadFile
              id={input.id}
              value={input.value}
              onUpload={handleChange}
              isLoading={isLoading}
              setIsUploading={setIsUploading}
              {...validationProps}
            />
          </Box>
        );
      case NodeType.String:
        return (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <DesignAppInputHeader
              input={input}
              inputId={input.id}
              updateNodeData={updateNodeData}
              role={role}
              inputColor={color.Yambo_Blue}
              nodes={nodes}
              setIsEditingInputMetadata={setIsEditingInputMetadata}
              mode={mode}
              actions={[disabledConcealAction]}
            />
            <TextField
              fullWidth
              multiline
              onFocus={() => setIsEditingInputMetadata(true)}
              onBlur={() => setIsEditingInputMetadata(false)}
              size="small"
              value={input.value?.string}
              onChange={(e) => handleChange(input.id, { string: e.target.value }, e.target.value)}
              sx={{
                background: `${color.Yambo_BG}`,
              }}
              {...validationProps}
              // todo: add validation
            />
          </Box>
        );
      case NodeType.MultiLora:
        const metadataInput = designAppMetadata[input.id];
        const onDisable = (_e, currentChecked) => {
          handleDisableInput(input.id, !currentChecked);
        };
        return (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <DesignAppInputHeader
              input={metadataInput}
              inputId={input.id}
              updateNodeData={updateNodeData}
              role={role}
              inputColor={color.Yambo_Blue}
              nodes={nodes}
              setIsEditingInputMetadata={setIsEditingInputMetadata}
              mode={mode}
              error={hasError}
              actions={[disabledConcealAction]}
            />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  labelPlacement="top"
                  control={<Switch checked={!metadataInput.disabled} onChange={onDisable} />}
                  label={translate(
                    metadataInput.disabled
                      ? I18N_KEYS.SHARED_DESIGN_APP.INPUTS.HEADER.DISABLED
                      : I18N_KEYS.SHARED_DESIGN_APP.INPUTS.HEADER.ENABLED,
                  )}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                {renderDesignAppParams({
                  node: nodes.find((node) => node.id === input.id),
                  param: input,
                  handleChange,
                  isLoading,
                  validationProps,
                  translate,
                  disabled: metadataInput.disabled,
                })}
              </Box>
            </Box>
          </Box>
        );
      default:
        return (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <DesignAppInputHeader
              input={designAppMetadata[input.id]}
              inputId={input.id}
              updateNodeData={updateNodeData}
              role={role}
              inputColor={color.Yambo_Green}
              nodes={nodes}
              setIsEditingInputMetadata={setIsEditingInputMetadata}
              mode={mode}
              error={hasError}
              actions={[concealAction]}
            />
            {renderDesignAppParams({
              node: nodes.find((node) => node.id === input.id),
              param: input,
              handleChange,
              isLoading,
              validationProps,
              translate,
            })}
          </Box>
        );
    }
  };

  // Run
  const run = async () => {
    if (!validateInputs()) {
      setErrorMessage('Please fill in all required fields');
      return;
    }
    canCancel.current = false;
    handleStartProcessing();
    setNumberOfLoadingResults(numberOfOutputNodes * numberOfRuns);

    const body = {
      inputs:
        inputs?.map((input) => ({
          nodeId: input.id,
          input: input.value,
          disabled: input.disabled,
        })) || [],
      numberOfRuns,
      recipeVersion: recipeData.recipeVersion,
    };

    try {
      const response = await axiosInstance.post(`/v1/recipes/${recipeId}/run`, body, { 'axios-retry': { retries: 0 } });
      const runIds = response.data.runIds;
      canCancel.current = true;

      // should solve the issue of going to the workflow tab and back here. the data needs to be updated
      // todo: replace with actual to get the design app api when entering this page
      setDesignAppData({ ...designAppData, latestRuns: runIds.map((runId) => ({ runId, status: 'RUNNING' })) });

      // Start polling in the background
      pollRunsStatus(runIds);
    } catch (e) {
      console.error('Failed to run recipe', e);
      setErrorMessage(e?.message || 'Something went wrong');
      handleStopProcessing();
    }
  };

  const cancelRun = async () => {
    handleStopProcessing();
    try {
      await axiosInstance.post(`/v1/recipes/${recipeId}/runs/cancel`);
    } catch (e) {
      console.error('Failed to cancel prediction', e);
    }
  };

  /// handle drag and drop for reordering inputs & exposing / concealing inputs
  const draggedInput = useMemo(() => inputs.find((input) => input.id === draggedInputId), [draggedInputId, inputs]);

  const handleDragStart = (e, inputId) => {
    setDraggedInputId(inputId);
    e.dataTransfer.setData('text/plain', inputId);
  };

  const handleDragEnd = (_e) => {
    setDraggedInputId(null);
    setTargetInputId(null);
  };

  const handleDragOver = (e, inputId) => {
    e.preventDefault();
    setTargetInputId(inputId);
  };

  const handleParamsDrawerDragEnter = (e) => {
    e.preventDefault();
    setTargetInputId(PARAMS_DRAWER_ID);
    if (!PARAM_TYPES.includes(draggedInput.type)) {
      e.dataTransfer.dropEffect = 'none';
      e.stopPropagation();
    } else {
      e.dataTransfer.dropEffect = 'move';
    }
  };

  const handleInputContainerDrop = (e) => {
    e.preventDefault();
    if (!draggedInputId || draggedInputId === targetInputId) return;

    // We need to expose the input if it's dropped in the inputs container
    handleExposeParam(draggedInputId, true);

    setDraggedInputId(null);
    setTargetInputId(null);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (!draggedInputId || draggedInputId === targetInputId) return;

    // If the target is the params drawer, we need to conceal the input
    if (targetInputId === PARAMS_DRAWER_ID) {
      if (PARAM_TYPES.includes(draggedInput.type)) {
        handleExposeParam(draggedInputId, false);
        setDraggedInputId(null);
        setTargetInputId(null);
      }
      return;
    }

    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      const draggedIndex = newInputs.findIndex((input) => input.id === draggedInputId);
      const targetIndex = newInputs.findIndex((input) => input.id === targetInputId);

      // Remove dragged item and insert at new position
      const [draggedItem] = newInputs.splice(draggedIndex, 1);
      // Expose the input if it's dropped in the inputs container
      draggedItem.exposed = true;
      newInputs.splice(targetIndex, 0, draggedItem);

      // Update order property for all inputs
      const reorderedInputs = newInputs.map((input, index) => ({
        ...input,
        order: index,
      }));

      // Update metadata if user is editor
      if (role === 'editor') {
        setDesignAppMetadata((prevMetadata) => {
          const updatedMetadata = { ...prevMetadata };
          // Update order for all inputs in metadata
          reorderedInputs.forEach((input) => {
            updatedMetadata[input.id] = {
              ...updatedMetadata[input.id],
              order: input.order,
            };
          });

          return updatedMetadata;
        });
      }
      return reorderedInputs;
    });
  };

  const shouldShowTopIndicator = (hoverTargetInputId) => {
    if (!hoverTargetInputId || !draggedInputId) return false;

    // Get the indices from the inputs array
    const targetIndex = inputs.findIndex((input) => input.id === hoverTargetInputId);
    const draggedIndex = inputs.findIndex((input) => input.id === draggedInputId);

    // Show top indicator if dragged item is coming from below
    return draggedIndex > targetIndex;
  };

  const getInputContainerStyles = (inputId) => {
    const validDragging = targetInputId === inputId && !!draggedInputId;
    const showTopIndicator = shouldShowTopIndicator(inputId);
    return {
      width: '100%',
      p: 2,
      borderTop: validDragging && showTopIndicator ? `2px solid ${color.Yambo_Purple}` : 'none',
      borderBottom: validDragging && !showTopIndicator ? `2px solid ${color.Yambo_Purple}` : 'none',
      opacity: draggedInputId === inputId ? 0.5 : 1,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: '1001',
        left: '0px',
        top: '-4px',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: color.Yambo_Purple,
        display: validDragging && showTopIndicator ? 'block' : 'none',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        zIndex: '1001',
        left: '0px',
        bottom: '-4px',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: color.Yambo_Purple,
        display: validDragging && !showTopIndicator ? 'block' : 'none',
      },
    };
  };

  function getButtonTitle() {
    if (isProcessing) {
      return 'Running';
    }

    return results?.length ? 'Re-run' : 'Run';
  }

  const formatError = (error) => {
    if (!error) return 'Something went wrong';

    return typeof error === 'string' ? error : JSON.stringify(error);
  };

  const formattedMessage = formatError(errorMessage);

  const disableRunButton = () => {
    return isLoadingDesignApp || isUploading;
  };

  const handleMouseDown = (e) => {
    setIsDraggingPanel(true);
    e.preventDefault(); // Prevent text selection while dragging
  };

  useEffect(() => {
    let tempWidth;
    const handleMouseMove = (e) => {
      if (!isDraggingPanel) return;

      const container = document.getElementById('design-app-container');
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const newWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;

      // Limit the width between 20% and 80%
      const limitedWidth = Math.min(Math.max(newWidth, 20), 80);
      setLeftPanelWidth(`${limitedWidth}%`);
      localStorage.setItem('designAppLeftPanelWidth', `${limitedWidth}%`);
      tempWidth = limitedWidth;
    };

    const handleMouseUp = () => {
      setIsDraggingPanel(false);
      localStorage.setItem('designAppLeftPanelWidth', tempWidth);
    };

    if (isDraggingPanel) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDraggingPanel]);

  return (
    <Box
      id="design-app-container"
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        background: `${color.Yambo_BG}66`,
        zIndex: 9999,
        p: 1,
      }}
    >
      {role === 'editor' && (
        <DesignAppToolbar
          recipeId={recipeId}
          recipeData={recipeData}
          setRecipeData={setRecipeData}
          height={DESIGN_APP_HEADER_HEIGHT}
          mode={mode}
          setMode={setMode}
        />
      )}
      {readOnly && (
        <ReadOnlyPanel
          recipeData={recipeData}
          viewingVersionMode={readOnly}
          width={DESIGN_APP_HEADER_WIDTH}
          height={DESIGN_APP_HEADER_HEIGHT}
        />
      )}
      <Box
        sx={{
          width: `100%`,
          height: role === 'editor' ? `calc(100% - ${DESIGN_APP_HEADER_HEIGHT}px)` : '100%',
          flexDirection: 'row',
          display: 'flex',
          overflow: 'hidden',
          gap: 0,
        }}
      >
        <Box
          id="design-app-inputs-controls-container"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: leftPanelWidth,
            overflow: 'auto',
            minWidth: '20%',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          {/* Inputs */}
          <Box
            id="design-app-inputs-container"
            sx={{
              width: '100%',
              background: `${color.Dark_Blue}CC`,
              height: `calc(100% - ${CONTROLS_HEIGHT + 16}px)`,
              overflow: 'auto',
              border: `1px solid ${color.Dark_Grey}`,
              position: 'relative',
              // py:2,
              borderRadius: 3,
            }}
            onDrop={handleInputContainerDrop}
          >
            {isLoading && <InputPanelSkeleton />}
            {!isLoading && exposedInputs.length > 0 && <DefaultsComment />}
            {!isLoading && exposedInputs.length > 0 // empty state
              ? exposedInputs.map((input) => (
                  <Box
                    key={`input-${input.id}`}
                    ref={(el) => (inputRefs.current[input.id] = el)}
                    sx={getInputContainerStyles(input.id)}
                    draggable={isEditMode && !isEditingInputMetadata}
                    onDragStart={(e) => handleDragStart(e, input.id)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => handleDragOver(e, input.id)}
                    onDrop={handleDrop}
                  >
                    {renderInputContent(input)}
                  </Box>
                ))
              : !isLoading && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src="/illustrations/no-inputs.png"
                      alt="No inputs"
                      style={{ width: '60%', filter: 'grayscale(100%)', opacity: 0.7 }}
                    />
                    <Typography variant="body2">{translate(I18N_KEYS.SHARED_DESIGN_APP.NO_INPUTS.TITLE)}</Typography>

                    <Typography variant="caption">{translate(I18N_KEYS.SHARED_DESIGN_APP.NO_INPUTS.TIP)}</Typography>
                  </Box>
                )}
          </Box>
          {/* Run button and settings */}
          <Box
            id="design-app-controls-container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              background: `${color.Dark_Blue}CC`,
              width: '100%',
              height: CONTROLS_HEIGHT,
              zIndex: 9998,
              border: `1px solid ${color.Dark_Grey}`,
              p: 2,
              borderRadius: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mb: 1,
                gap: 1,
              }}
            >
              <Typography
                variant="caption"
                fontWeight="bold"
                sx={{
                  whiteSpace: 'nowrap',
                  minWidth: 'fit-content',
                }}
              >
                Number of Results
              </Typography>
              <Slider
                size="small"
                value={numberOfRuns}
                onChange={(event, newValue) => setNumberOfRuns(newValue)}
                aria-labelledby="number-of-runs-slider"
                max={10}
                min={1}
                sx={{ ml: 2 }}
                step={1}
                valueLabelDisplay="auto"
              />
              <Input
                value={numberOfRuns}
                size="small"
                onChange={(e) => setNumberOfRuns(Number(e.target.value))}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 10,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                  style: {
                    width: '50px',
                    fontSize: '10px',
                  },
                }}
                sx={{ ml: 2 }}
              />
            </Box>

            <Box
              id="design-app-run-and-params-buttons-container"
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}
            >
              <CancellableLoadingButton
                run={run}
                onCancel={cancelRun}
                isProcessing={isProcessing}
                title={getButtonTitle()}
                data={{}}
                canCancel={canCancel.current}
                disabled={disableRunButton()}
              />
              <Box sx={{ display: 'flex', flexGrow: 1 }} onDragOver={handleParamsDrawerDragEnter} onDrop={handleDrop}>
                <Params
                  concealedInputs={concealedInputs}
                  nodes={nodes}
                  isEditMode={isEditMode}
                  leftPanelWidth={leftPanelWidth}
                  handleExposeParam={handleExposeParam}
                  handleChange={handleChange}
                />
              </Box>
            </Box>
            {errorMessage && (
              <Box
                id="model-error-container"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 0.5,
                }}
              >
                <Tooltip title={formattedMessage} placement="top">
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {formattedMessage}
                  </Typography>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          id="design-app-panel-resizer"
          onMouseDown={handleMouseDown}
          sx={{
            width: '12px',
            height: '100%',
            background: 'transparent',
            position: 'relative',
            cursor: 'col-resize',
          }}
        >
          <Box
            sx={{
              width: '2px',
              left: 'calc(50% - 1px)',
              height: '20px',
              background: color.Yambo_Purple,
              position: 'relative',
              top: 'calc(45% - 10px)',
              cursor: 'col-resize',
              borderRadius: '2px',
              outline: `1px solid ${color.Yambo_Purple}`,
            }}
          />
        </Box>
        {/* Preview window */}
        <Preview
          isLoading={isLoading}
          isProcessing={isProcessing}
          results={results}
          setResults={setResults}
          recipeName={recipeData.name}
          numberOfLoadingResults={numberOfLoadingResults}
        />
      </Box>
    </Box>
  );
}

export default DesignApp;
