import { createContext, PropsWithChildren, useContext, useState, useMemo, useEffect, Context } from 'react';

type NodeData = NonNullable<unknown>;
type Node = { id: string; data: NodeData };
interface EditorData {
  id: string;
  data: NodeData;
  type: string;
  setResult: (res: any) => void;
  updateNodeData: (nodes: Node[]) => void;
}

type EditorContextType = {
  handleCloseEditor: () => void;
  handleOpenEditor: (
    id: string,
    data: NodeData,
    type: string,
    setResult: EditorData['setResult'],
    updateNodeData: EditorData['updateNodeData'],
  ) => void;
  editorData: EditorData | null;
  inEditingMode: boolean;
} | null;

const EditorContext = createContext<EditorContextType>(null);
export const useEditorContext = () => useContext(EditorContext);

type EditorProviderProps = PropsWithChildren<{ nodes: Node[] }>;

export const EditorProvider = ({ children, nodes }: EditorProviderProps) => {
  const [editorData, setEditorData] = useState<EditorData | null>(null);
  const inEditingMode = useMemo(() => editorData != null, [editorData]);

  useEffect(() => {
    if (editorData && editorData.id) {
      const currentNodeData = nodes.find((node) => node.id === editorData.id);

      if (currentNodeData && currentNodeData.data !== editorData.data) {
        setEditorData((prev) =>
          prev
            ? {
                ...prev,
                data: currentNodeData.data,
              }
            : null,
        );
      }
    }
  }, [nodes, editorData, setEditorData]);

  const handleOpenEditor = (
    id: string,
    data: NodeData,
    type: string,
    setResult: EditorData['setResult'],
    updateNodeData: EditorData['updateNodeData'],
  ) => {
    setEditorData({ id, data, type, setResult, updateNodeData });
  };

  const handleCloseEditor = () => {
    setEditorData(null);
  };

  return (
    <EditorContext.Provider
      value={{
        handleCloseEditor,
        handleOpenEditor,
        editorData,
        inEditingMode,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
