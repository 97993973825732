export enum NodeType {
  WorkflowOutput = 'workflow_output',
  Prompt = 'promptV2',
  Mux = 'mux',
  Import = 'import',
  // Media = 'media',
  // PromptConcat = 'prompt_concat',
  PromptEnhance = 'prompt_enhance',
  ControlNet = 'controlNet',
  // Preview = 'preview',
  // Painter = 'painter',
  // PainterV2 = 'painterV2',
  SdInpaint = 'sd_inpaint',
  SdOutpaint = 'sd_outpaint',
  Image2Image = 'image2image',
  Text2Image = 'text2image',
  SdText2Image = 'sd_text2image',
  BrText2Image = 'br_text2image',
  BrVector = 'br_vector',
  Dalle3 = 'dalle3',
  // BgRemove = 'bgremove',
  // SdBgrmv = 'sd_bgrmv',
  SdSketch = 'sd_sketch',
  SdUpscale = 'sd_upscale',
  SdImg2Video = 'sd_img2video',
  SdImage23d = 'sd_image23d',
  NimCc = 'nim_cc',
  LumaVideo = 'luma_video',
  RwVideo = 'rw_video',
  Kling = 'kling',
  MochiV1 = 'mochiv1',
  Minimax = 'minimax',
  MinimaxI2v = 'minimax_i2v',
  // ObjectRemove = 'objectremove',
  // MenuNode = 'menunode',
  Integer = 'integer',
  // Resize = 'resize',
  // Crop = 'crop',
  // ImportLoRA = 'ImportLoRA',
  // CompV2 = 'compv2',
  Wildcard = 'wildcard',
  CustomModel = 'custommodel',
  WildcardV2 = 'wildcardV2',
  CustomModelV2 = 'custommodelV2',
  // Blur = 'blur',
  // Channels = 'channels',
  // Masks = 'masks',
  // Comfy = 'comfy',
  FluxPro = 'flux_pro',
  FluxFast = 'flux_fast',
  FluxLora = 'flux_lora',
  IgText2Image = 'ig_text2image',
  IgDescribe = 'ig_describe',
  MeshyImage23d = 'meshy_image23d',
  AnyLlm = 'any_llm',
  String = 'string',
  // Router = 'router',
  // MergeAlpha = 'merge_alpha',
  Boolean = 'boolean',
  // Seed = 'seed',
  Array = 'array',
  MultiLora = 'multilora',
}
