import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { colorMap } from '../../colors';
import { useUserRole } from '../Recipe/UserRoleContext';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';
import { hasEditingPermissions } from './Utils';

function PromptNodeV2({ id, data, updateNodeData }) {
  // const textFieldRef = useRef(null);
  const role = useUserRole();
  const [prompt, setPrompt] = useState(data.result.prompt);
  const [negativePrompt, setNegativePrompt] = useState(data.result.negative_prompt);
  const [isFocused, setIsFocused] = useState(false);

  const onPromptChange = (event) => {
    const updatedPrompt = event.target.value;
    setPrompt(updatedPrompt);
  };
  const onNegativePromptChange = (event) => {
    const updatedNegativePrompt = event.target.value;
    setNegativePrompt(updatedNegativePrompt);
  };

  useEffect(() => {
    if (data.externalData) {
      setPrompt(data.externalData.prompt);
      setNegativePrompt(data.externalData.negative_prompt);
    }
  }, [data.externalData]);

  useEffect(() => {
    updateNodeData(id, {
      result: {
        prompt,
        negative_prompt: negativePrompt,
      },
      output: {
        type: 'text',
        prompt,
        negative_prompt: negativePrompt,
      },
    });
  }, [prompt, negativePrompt]);

  return (
    <DynamicNode2
      id={id}
      data={data}
      className="prompt"
      handleColor={colorMap.get(data.color)}
      backgroundColor={colorMap.get(data.color)}
      headerColor={colorMap.get(data.dark_color)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '100%',
          mt: 1,
          cursor: !hasEditingPermissions(role, data) ? 'default' : '',
          pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
        }}
      >
        <TextField
          id={`prompt1-${id}`}
          className={isFocused ? 'nowheel nodrag nopan' : ''}
          value={prompt}
          onChange={onPromptChange}
          label="Prompt"
          multiline
          rows={3}
          fullWidth
          size="small"
          onMouseDown={(event) => event.stopPropagation()}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          inputProps={{
            style: { resize: 'vertical' },
          }}
        />
        <TextField
          id={`prompt2-${id}`}
          className={isFocused ? 'nowheel nodrag nopan' : ''}
          sx={{ mt: 2 }}
          label="Negative Prompt"
          value={negativePrompt}
          onChange={onNegativePromptChange}
          // disabled={role === 'guest' ? true : false}
          multiline
          rows={3}
          fullWidth
          size="small"
          onMouseDown={(event) => event.stopPropagation()}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          inputProps={{
            style: { resize: 'vertical' },
          }}
        />
      </Box>
    </DynamicNode2>
  );
}

export default PromptNodeV2;
