import { useState } from 'react';
import { Box, Typography, Snackbar, Alert, Slide, Menu, MenuItem, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { color } from '../../../colors';
import I18N_KEYS from '../../../language/keys';
import { DesignAppMode } from '../../../enums/design-app-modes.enum';
import axiosInstance from '../../../services/axiosConfig';
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const HEADER_WIDTH = '400px';

function DesignAppToolbar({ recipeId, recipeData, setRecipeData, height, mode, setMode }) {
  const { latestPublishedVersion, publishedVersions } = recipeData;
  const { t: translate } = useTranslation();
  const [showSnackBar, setShowSncakBar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState('success');
  const [snackBarText, setSnackBarText] = useState(translate(I18N_KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_SUCCESS));
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const [versionsAnchorEl, setVersionsAnchorEl] = useState(null);

  const formattedTime = (time) => {
    return moment(time).format('MMMM D, h:mm A');
  };

  const publishDesignApp = async () => {
    try {
      const res = await axiosInstance.post(`/v1/recipes/${recipeId}/publish`);
      setRecipeData((prev) => {
        return {
          ...prev,
          latestPublishedVersion: res.data.latestPublishedVersion,
          publishedVersions: res.data.publishedVersions,
          recipeVersion: res.data.latestVersion.version,
        };
      });
      //todo: do better
      setSnackBarText(translate(I18N_KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_SUCCESS));
      setSnackBarSeverity('success');
      setShowSncakBar(true);
    } catch (e) {
      console.error('Failed to publish design app', e);
      setSnackBarText(translate(I18N_KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_FAILED));
      setSnackBarSeverity('error');
      setShowSncakBar(true);
    }
  };

  const handlePublishDesignApp = async () => {
    setIsPublishLoading(true);
    await publishDesignApp();
    setIsPublishLoading(false);
  };

  const handleViewVersion = (version) => {
    setVersionsAnchorEl(null);
    window.location.href = `${window.location.pathname}?version=${version.version}`;
  };

  return (
    <>
      <Box
        id="design-app-header"
        sx={{
          width: HEADER_WIDTH,
          height: `${height}px`,
          mb: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: `${color.Dark_Blue}CC`,
            border: `1px solid`,
            borderColor: color.Dark_Grey,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 1,
          }}
        >
          <Box
            id="design-app-toolabr-versions-container"
            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
          >
            {latestPublishedVersion ? (
              <>
                <Typography variant="caption">
                  {translate(I18N_KEYS.SHARED_DESIGN_APP.TOOLBAR.LAST_PUBLISHED_AT)}:
                </Typography>
                {publishedVersions && publishedVersions.length > 0 ? (
                  <Button
                    endIcon={<ExpandMoreIcon fontSize="8px" />}
                    onClick={(event) => setVersionsAnchorEl(event.currentTarget)}
                    sx={{
                      color: color.Yambo_Text_On_Dark,
                      ml: 0.5,
                    }}
                  >
                    <Typography variant="caption" fontWeight="bold">
                      {formattedTime(latestPublishedVersion?.publishedAt)}
                    </Typography>
                  </Button>
                ) : (
                  <Typography variant="caption" sx={{ ml: 0.5 }} fontWeight="bold">
                    {formattedTime(latestPublishedVersion?.publishedAt)}
                  </Typography>
                )}
                <Menu
                  anchorEl={versionsAnchorEl}
                  open={Boolean(versionsAnchorEl)}
                  onClose={() => setVersionsAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '.MuiList-root': {
                      padding: 0,
                    },
                  }}
                >
                  {publishedVersions &&
                    publishedVersions.length > 0 &&
                    publishedVersions
                      .sort((a, b) => b.publishedAt - a.publishedAt)
                      .map((version, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => handleViewVersion(version)}
                            onMouseEnter={(e) => {
                              e.currentTarget.querySelector('.version-number').style.opacity = '1';
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.querySelector('.version-number').style.opacity = '0';
                            }}
                            sx={{
                              px: 1.5,
                              py: 1,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '240px',
                              cursor: 'pointer',
                            }}
                          >
                            <Typography variant="caption">{formattedTime(version.publishedAt)}</Typography>
                            <Typography
                              variant="caption"
                              className="version-number"
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.15s ease-in-out',
                              }}
                              fontWeight="bold"
                            >
                              {translate(I18N_KEYS.SHARED_DESIGN_APP.TOOLBAR.VIEW_VERSION)}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                </Menu>
              </>
            ) : (
              <Typography variant="body2">{translate(I18N_KEYS.SHARED_DESIGN_APP.TOOLBAR.NO_VERSIONS)}</Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {/* {mode === DesignAppMode.Editing ?
              <Button variant="outlined" size="small" color="weavy_cta_secondary" onClick={() => setMode(DesignAppMode.Running)}>
                <i className="fa-light fa-eye"></i>&nbsp;{translate(I18N_KEYS.SHARED_DESIGN_APP.PREVIEW_DESIGN_APP_BUTTON)}
              </Button>
            : 
            <Button variant="outlined" size="small" color="weavy_cta_secondary" onClick={() => setMode(DesignAppMode.Editing)}>
                <i className="fa-light fa-pencil"></i>&nbsp;{translate(I18N_KEYS.SHARED_DESIGN_APP.EDIT_DESIGN_APP_BUTTON)}
              </Button>
            } */}
            <Box sx={{ ml: 1 }}>
              <LoadingButton
                className="share-button"
                variant="contained"
                size="small"
                color="weavy_cta"
                onClick={handlePublishDesignApp}
                loading={isPublishLoading}
              >
                <i className="fa-light fa-paper-plane-top"></i>&nbsp;
                {translate(I18N_KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_BUTTON)}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackBar}
        onClose={() => setShowSncakBar(false)}
        autoHideDuration={1500}
        TransitionComponent={SlideTransition}
      >
        <Alert
          severity={snackBarSeverity}
          variant="filled"
          sx={{ width: '100%', color: color.Yambo_Text_On_Dark, backgroundColor: color.Yambo_Light_Green }}
        >
          {snackBarText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DesignAppToolbar;
