import { Box, Input, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import CheckIcon from '@mui/icons-material/Check';

import { color } from '../../colors';
import RecipeContextMenu from './RecipeContextMenu';

function descendingComparator(a, b, orderBy) {
  let valA = a[orderBy];
  let valB = b[orderBy];

  // Check if the values are strings; if so, compare them in a case-insensitive manner
  if (typeof valA === 'string' && typeof valB === 'string') {
    valA = valA.toLowerCase();
    valB = valB.toLowerCase();
  }

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'updated_at',
    numeric: true,
    disablePadding: false,
    label: 'Last Modified',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {
        numSelected > 0 && (
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        )

        // : (
        // <Typography
        //   sx={{ flex: '1 1 100%' }}
        //   variant="h6"
        //   id="tableTitle"
        //   component="div"
        // >
        //   Nutrition
        // </Typography>
        // )
      }

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function DashboardListView(props) {
  const {
    recipes,
    formatDate,
    contextMenu,
    onContextMenuClose,
    contextMenuItems,
    handleContextMenu,
    goToRecipe,
    handleRenameRecipe,
    editingRecipe,
    setEditingRecipe,
    cancelRename,
  } = props;

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('updated_at');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(5);
  const [oldRecipeName] = useState({ id: null, name: '' });
  // const inputRefListView = useRef(null);
  // const [editingRecipe, setEditingRecipe] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = recipes.map((n) => n.id);
      setSelected(newSelected);

      return;
    }
    setSelected([]);
  };

  /// THIS CODE BELOW WILL HANDLE SELECTION OF RECIPES IF NEEDED IN THE FUTURE (REPLACE handleClick)
  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleClick = (event, id) => {
    if (event.detail == 1) goToRecipe(id, false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  //
  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - recipes.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(recipes || [], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [recipes, order, orderBy, page, rowsPerPage],
  );

  const handleCancelRenameRecipe = (id) => {
    console.log('Esc pressed');
    cancelRename(oldRecipeName.name, id);
    setEditingRecipe({ id: null, name: '' });
  };

  return (
    <>
      {recipes && (
        <Box id="dashboard-table-view-container">
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={recipes.length}
              />
              <TableBody>
                {visibleRows.map((recipe, index) => {
                  const isItemSelected = isSelected(recipe.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, recipe.id)}
                      onContextMenu={(e) => {
                        handleContextMenu(e, recipe.id);
                        handleClick(e, recipe.id);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={recipe.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer', border: 'none' }}
                    >
                      {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }}>
                          <Box sx={{ width: '80px', height: '60px', borderRadius: '10px' }}>
                            <img
                              style={{
                                display: 'block',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '5px',
                              }}
                              src={recipe.poster || '/empty.png'}
                            />
                          </Box>
                          <Box id="dashboard-list-view-recipe-name-container" sx={{ ml: 3 }}>
                            {editingRecipe.id === recipe.id ? (
                              <Input
                                inputRef={(input) => input && input.focus()}
                                variant="standard"
                                value={editingRecipe.name}
                                onChange={(e) => setEditingRecipe((prev) => ({ ...prev, name: e.target.value }))}
                                onFocus={(e) => e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleRenameRecipe(editingRecipe.name, editingRecipe.id);
                                  }
                                  if (e.key === 'Escape') {
                                    e.preventDefault();
                                    handleCancelRenameRecipe(editingRecipe.id);
                                  }
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="rename recipe"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRenameRecipe(editingRecipe.name, editingRecipe.id);
                                      }}
                                    >
                                      <CheckIcon fontSize="small" color={color.Green} />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            ) : (
                              <Typography variant="body2" component="div">
                                {recipe.name}
                              </Typography>
                            )}
                          </Box>
                          {/* <Typography sx={{ml:3}}>{recipe.name}</Typography> */}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {formatDate(recipe.updated_at ? recipe.updated_at : recipe.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">{formatDate(recipe.createdAt)}</Typography>
                      </TableCell>
                      {/* <TableCell align="right">{recipe.carbs}</TableCell>
                    <TableCell align="right">{recipe.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={recipes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <RecipeContextMenu
            mouseX={contextMenu.mouseX}
            mouseY={contextMenu.mouseY}
            isOpen={contextMenu.isOpen}
            onClose={onContextMenuClose}
            menuItems={contextMenuItems}
          />
        </Box>
      )}
    </>
  );
}

export default DashboardListView;
