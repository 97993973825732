import React, { useRef, useState, useEffect, Suspense } from 'react';
import { Box } from '@mui/material';
import { Canvas, useThree } from '@react-three/fiber';
import { Environment, OrbitControls, Html, useProgress, ContactShadows } from '@react-three/drei';
import debounce from 'lodash/debounce';
import { Model } from './ThreedeeUtils.jsx';

const VIEWER_SIZE = { w: 304, h: 304 }; // the reason for 304 is 320 (css custom-node.preview width) minus the 2x*8px padding

function ModelLoader() {
  const { progress } = useProgress();

  return <Html center>{progress} % loaded</Html>;
}

function SetCameraPosition({ cameraPosition }) {
  const { camera } = useThree();

  useEffect(() => {
    // Only set camera position if explicitly provided
    if (cameraPosition) {
      camera.position.x = cameraPosition.x ?? -3;
      camera.position.y = cameraPosition.y ?? 4;
      camera.position.z = cameraPosition.z ?? 5;
    } else {
      // Set default positions only on initial mount
      camera.position.x = -3;
      camera.position.y = 4;
      camera.position.z = 5;
    }

    camera.updateProjectionMatrix();
  }, []);

  return null;
}

function CameraControls({ lockOrbit, setCameraPosition, onOrbitChange }) {
  const { camera } = useThree();
  const prevPosition = useRef({ x: 0, y: 0, z: 0 });
  const frameRef = useRef();
  const isMovingRef = useRef(false);

  useEffect(() => {
    const checkMovement = () => {
      const currentPos = camera.position;
      const velocity = Math.sqrt(
        Math.pow(currentPos.x - prevPosition.current.x, 2) +
          Math.pow(currentPos.y - prevPosition.current.y, 2) +
          Math.pow(currentPos.z - prevPosition.current.z, 2),
      );

      const MOVEMENT_THRESHOLD = 0.05; // Adjust this value as needed

      if (velocity < MOVEMENT_THRESHOLD && isMovingRef.current) {
        isMovingRef.current = false;
        onOrbitChange?.();
      } else if (velocity >= MOVEMENT_THRESHOLD) {
        isMovingRef.current = true;
      }

      prevPosition.current = {
        x: currentPos.x,
        y: currentPos.y,
        z: currentPos.z,
      };

      frameRef.current = requestAnimationFrame(checkMovement);
    };

    frameRef.current = requestAnimationFrame(checkMovement);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [camera, onOrbitChange]);

  return (
    <OrbitControls
      enableRotate={!lockOrbit}
      enableZoom={!lockOrbit}
      enablePan={!lockOrbit}
      target={[0, 1.5, 0]}
      dampingFactor={0.1}
      onChange={() => {
        if (setCameraPosition) {
          setCameraPosition({
            x: camera.position.x,
            y: camera.position.y,
            z: camera.position.z,
          });
        }
      }}
    />
  );
}

function ThreeDeeViewer({ objUrl, containerSize, setExportedImage, lockOrbit, cameraPosition, setCameraPosition }) {
  const [objectUrl, setObjectUrl] = useState(null);
  const containerRef = useRef(null);
  const canvasRef = useRef();
  const viewerSize = containerSize || VIEWER_SIZE;
  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (objUrl) {
      fetch(objUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setObjectUrl(url);
        })
        .catch((error) => console.error('Error fetching OBJ file:', error));
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objUrl]);

  useEffect(() => {
    if (containerRef.current) {
      const canvasElement = containerRef.current;

      // Focus canvas when mouse enters or interaction occurs
      const handleMouseEnter = () => {
        canvasElement.focus();
      };

      const handleWheel = (event) => {
        const containerElement = containerRef.current;
        if (
          containerElement &&
          (containerElement === document.activeElement || containerElement.contains(document.activeElement))
        ) {
          event.stopPropagation(); // Prevent scrolling propagation
        }
      };
      const handleMouseClick = (event) => {
        const containerElement = containerRef.current;
        if (
          containerElement &&
          (containerElement === document.activeElement || containerElement.contains(document.activeElement))
        ) {
          event.stopPropagation(); // Prevent scrolling propagation
        }
      };

      canvasElement.addEventListener('mouseenter', handleMouseEnter);
      canvasElement.addEventListener('click', handleMouseClick);
      canvasElement.addEventListener('wheel', handleWheel);

      return () => {
        canvasElement.removeEventListener('mouseenter', handleMouseEnter);
        canvasElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, [containerRef]);

  const setCanvasSize = () => {
    if (canvasRef && canvasRef.current) {
      canvasRef.current.style.width = `${viewerSize.w}px`;
      canvasRef.current.style.height = `${viewerSize.h}px`;
    }
  };

  const handleCanvasCreated = () => {
    setCanvasSize();
  };

  useEffect(() => {
    setCanvasSize();
  }, [objUrl]);

  const exportImage = () => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;

      try {
        // Convert canvas to data URL
        const dataUrl = canvas.toDataURL('image/png');
        const threeDImage = {
          type: 'image',
          url: dataUrl,
          width: canvas.width,
          height: canvas.height,
        };
        setExportedImage?.(threeDImage);
      } catch (error) {
        console.error('Error exporting canvas:', error);
      }
    }
  };

  const debouncedExport = useRef(
    debounce(() => {
      if (!setExportedImage) return;
      exportImage();
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      debouncedExport.cancel();
    };
  }, [debouncedExport]);

  useEffect(() => {
    if (finishedLoading) {
      setTimeout(() => {
        exportImage();
      }, 1000);
    }
  }, [finishedLoading]);

  return (
    <Box
      ref={containerRef}
      tabIndex={0}
      className="3d_player"
      id="three-d-player-wrapper"
      sx={{
        width: `${viewerSize.w}px`,
        height: `${viewerSize.h}px`,
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      {objectUrl && (
        <>
          <Canvas
            key={objectUrl}
            shadows
            onCreated={({ gl }) => {
              gl.setClearColor('#e0e0e0', 0);
              handleCanvasCreated();
              exportImage();
            }}
            gl={{ preserveDrawingBuffer: true, alpha: true }}
            ref={canvasRef}
          >
            <SetCameraPosition cameraPosition={cameraPosition} setCameraPosition={setCameraPosition} />
            <CameraControls
              lockOrbit={lockOrbit}
              setCameraPosition={setCameraPosition}
              onOrbitChange={debouncedExport}
            />
            <directionalLight
              intensity={1}
              position={[-5, 10, -5]}
              castShadow
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
            />
            <ambientLight color={'#dce7f5'} intensity={0.5} />
            <Suspense fallback={<ModelLoader />}>
              <Model castShadow objUrl={objectUrl} type={4} setFinishedLoading={setFinishedLoading} />
              <ContactShadows
                rotation-x={Math.PI / 2}
                position={[0, 0, 0]}
                opacity={1}
                width={5}
                height={5}
                blur={1}
                far={1}
              />
            </Suspense>
            <Environment preset="city" blur={0.5} />
          </Canvas>
        </>
      )}
    </Box>
  );
}

export default ThreeDeeViewer;
