import React, { useContext } from 'react';

import Hero from '../components/Homepage/Hero';
import { AuthContext } from '../AuthContext';

function Home() {
  const { signIn, signOut } = useContext(AuthContext);

  return (
    <>
      {/* <CustomAppBar user={currentUser} signOut={signOut} /> */}
      <Hero signIn={signIn} signOut={signOut} />
      {/* <Examples/>
            <Features/>
            <HomepageFooter/> */}
    </>
  );
}

export default Home;
