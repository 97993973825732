import { ResizeData } from '../interfaces/resize-data.interface';
import { formatValue } from './expression';

export const resize = ({ type, width, height, aspectRatio, x_pos, y_pos }: ResizeData) => {
  const x = x_pos ? `x_${formatValue(x_pos)}` : 'x_0';
  const y = y_pos ? `y_${formatValue(y_pos)}` : 'y_0';
  const w = width ? `w_${formatValue(width)}` : '';
  const h = height ? `h_${formatValue(height)}` : '';
  const crop = type ? `c_${type}` : '';
  const ar = aspectRatio ? `ar_${formatValue(aspectRatio)}` : '';

  return [crop, w, h, x, y, ar].filter(Boolean).join();
};
