import { Box, Typography } from '@mui/material';
import { color } from '../../../../../colors';
import LayerPanelRow from './LayerPanelRow';
import type { UILayer } from './DesignerEditor';

interface LayerPanelProps {
  layers: Record<number, UILayer>;
  layerOrder: number[];
  selectedLayer: number;
}

function LayerPanel({ layers, layerOrder, selectedLayer }: LayerPanelProps) {
  const reversedLayerOrder = [...layerOrder].reverse();
  return (
    <Box
      id="compositor-layers-panel"
      className="compositor-panel"
      sx={{
        background: color.Dark_Blue,
        position: 'absolute',
        top: 30,
        left: 30,
        borderRadius: 1,
        width: '200px',
        overflow: 'hidden',
      }}
    >
      <Box
        id="compositor-layers-panel-header"
        sx={{
          width: '100%',
          background: color.Yambo_Black,
          px: 1,
          py: 0.4,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption">Layers</Typography>
      </Box>
      <Box id="compositor-layers-panel-layers-container" sx={{ p: 0.1 }}>
        {layers &&
          reversedLayerOrder.map((key) => {
            const layer = layers[key];
            return (
              layer && (
                <Box id={`comp-layer-draggable-wrapper-${key}`} key={key} draggable>
                  <LayerPanelRow layer={layer} isSelected={selectedLayer === key} />
                </Box>
              )
            );
          })}
      </Box>
    </Box>
  );
}

export default LayerPanel;
