import { Box, Typography, Tooltip } from '@mui/material';
import { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { renderDynamicField, ParamExposureComponent } from '../ModelNodesUitls';

function DynamicFields({ params, schema, handleChange, handleExposeProperty, handleCollapseProperty, version }) {
  const [isHovered, setIsHovered] = useState({ key: null, value: false });

  return (
    <>
      {Object.keys(schema)
        .sort((a, b) => schema[a].order - schema[b].order)
        .map((key) => {
          if (schema[key].type === 'string') return null;

          return (
            <Box
              key={`${key}-dynamic-field`}
              sx={{
                mb: 2,
                position: 'relative',
              }}
              onMouseEnter={() => setIsHovered({ key, value: true })}
              onMouseLeave={() => setIsHovered({ key: null, value: false })}
            >
              <>
                <Box
                  id="dynamic-field-header"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                >
                  {schema[key].type !== 'boolean' && (
                    <>
                      <Box sx={{ opacity: schema[key].exposed ? 0.5 : 1 }}>
                        <Typography variant="caption" className="property-title" sx={{ mr: 0.5 }}>
                          {schema[key].title}
                        </Typography>
                        <Tooltip title={schema[key].description} sx={{ fontSize: '10px' }}>
                          <HelpOutlineIcon fontSize="10px" />
                        </Tooltip>
                      </Box>
                      {version === 2 && (
                        <ParamExposureComponent
                          paramKey={key}
                          property={schema[key]}
                          isHovered={isHovered}
                          handleCollapseProperty={handleCollapseProperty}
                          handleExposeProperty={handleExposeProperty}
                        />
                      )}
                    </>
                  )}
                </Box>
              </>

              {renderDynamicField(
                params,
                key,
                schema[key],
                handleChange,
                isHovered,
                handleExposeProperty,
                handleCollapseProperty,
                version,
              )}
            </Box>
          );
        })}
    </>
  );
}

export default DynamicFields;
