import React, { ErrorInfo, ReactNode } from 'react';
import ErrorStackParser from 'error-stack-parser';
import ErrorPage from '../Errors/ErrorPage';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo): Promise<void> {
    const stackFrames = ErrorStackParser.parse(error);
    console.error('Received an uncaught error in React Error Boundary', error, {
      errorMessage: error.message || 'N/A',
      errorInfo,
      stackFrames,
    });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
