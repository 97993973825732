import { Box, ButtonBase, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import I18N_KEYS from '../../language/keys';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1112px',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

function UpgradeModal({ currentUser, open, closeModal }) {
  const { t: translate } = useTranslation();

  const handleClose = () => {
    closeModal(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="h6">{translate(I18N_KEYS.UPGRADE_MODAL.TITLE)}</Typography>
        </Box>
        {currentUser?.activeWorkspace?.workspaceId && (
          <stripe-pricing-table
            pricing-table-id="prctbl_1Q1t1lKB1Jh4IYAtEXcMv1wD"
            publishable-key="pk_live_51PSKvDKB1Jh4IYAtAOvLi9e5vRbb731SYIsmmS2niY5XJdwGYRBcxd4Ha9gqQffOKzWNKs3e305tELKd7oPfyqFQ00nKuh9th4"
            client-reference-id={currentUser?.activeWorkspace?.workspaceId}
          ></stripe-pricing-table>
        )}
        <ButtonBase sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleClose}>
          <CloseIcon />
        </ButtonBase>
      </Box>
    </Modal>
  );
}

export default UpgradeModal;
