import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { FileUploader, type UploadedFile } from '../Common/FileUploader';
import { useUserRole } from '../Recipe/UserRoleContext';
import { colorMap } from '../../colors';
import { hasEditingPermissions } from './Utils';
import { DynamicNode2 } from './DynamicNode/DynamicNode2';

type TNodeData = {
  color?: string;
  dark_color?: string;
  externalData?: UploadedFile;
  initialData?: UploadedFile | null;
  name: string;
  output?: {
    file?: Partial<UploadedFile> | null;
  };
  pastedData?: {
    imageFile?: File;
  };
  result?: Partial<UploadedFile> | null;
};

function ImportCloudinaryNode({
  id,
  data,
  updateNodeData,
}: {
  id: string;
  data: TNodeData;
  updateNodeData: (nodeId: string, data: Partial<TNodeData>) => void;
}) {
  const role = useUserRole();

  const handleUpload = (file: Partial<UploadedFile> | null) => {
    updateNodeData(id, {
      result: file,
      output: { file },
    });
  };

  useEffect(() => {
    if (data.externalData) {
      handleUpload;
    }
  }, [data.externalData, id, updateNodeData]);

  useEffect(() => {
    if (data.initialData) {
      updateNodeData(id, {
        result: data.initialData,
        output: { file: data.initialData },
        initialData: null,
      });
    }
  }, [data.initialData, id, updateNodeData]);

  const onPastedDataUpload = () => {
    updateNodeData(id, {
      pastedData: undefined,
    });
  };

  return (
    <DynamicNode2
      id={id}
      data={data}
      className="import"
      handleColor={colorMap.get(data.color ?? '')}
      headerColor={colorMap.get(data.dark_color ?? '')}
    >
      <Box sx={{ width: '100%', pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '' }}>
        <FileUploader
          id={id}
          value={data.result}
          onUpload={handleUpload}
          allowUrlInput
          disabled={!hasEditingPermissions(role, data)}
          onInitialFileUpload={onPastedDataUpload}
          initialFile={data.pastedData?.imageFile}
        />
      </Box>
    </DynamicNode2>
  );
}

export default ImportCloudinaryNode;
