import React, { useEffect } from 'react';
import './ErrorPage.css';
import { usePostHog } from 'posthog-js/react';
import WeavyLogo from '../../../UI/Icons/WeavyLogo';
const ErrorPage = () => {
  const posthog = usePostHog();
  useEffect(() => {
    posthog?.capture('error_page_viewed');
  }, [posthog]);

  return (
    <div className="error-page">
      <a href="/">
        <WeavyLogo className="error-logo" />
      </a>
      <div className="robot-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 300">
          <rect width="400" height="300" fill="#1a1a1a" />
          <g id="robot">
            <rect x="160" y="100" width="80" height="100" rx="10" fill="#2a2a2a" stroke="#6b7280" strokeWidth="2" />
            <rect x="170" y="60" width="60" height="50" rx="5" fill="#2a2a2a" stroke="#6b7280" strokeWidth="2" />
            <circle cx="190" cy="85" r="8" fill="#60a5fa">
              <animate attributeName="r" values="8;6;8" dur="2s" repeatCount="indefinite" />
            </circle>
            <circle cx="210" cy="85" r="8" fill="#60a5fa">
              <animate attributeName="r" values="8;6;8" dur="2s" repeatCount="indefinite" />
            </circle>
            <line x1="200" y1="60" x2="200" y2="40" stroke="#6b7280" strokeWidth="2" />
            <circle cx="200" cy="35" r="5" fill="#f87171">
              <animate attributeName="opacity" values="1;0.5;1" dur="1s" repeatCount="indefinite" />
            </circle>

            <g id="left-legs">
              <path d="M160,115 L130,110 L100,125" stroke="#6b7280" strokeWidth="4" strokeLinecap="round" fill="none" />
              <path d="M160,150 L130,150 L100,150" stroke="#6b7280" strokeWidth="4" strokeLinecap="round" fill="none" />
              <path d="M160,185 L130,190 L100,175" stroke="#6b7280" strokeWidth="4" strokeLinecap="round" fill="none" />
            </g>

            <g id="right-legs">
              <path d="M240,115 L270,110 L300,125" stroke="#6b7280" strokeWidth="4" strokeLinecap="round" fill="none" />
              <path d="M240,150 L270,150 L300,150" stroke="#6b7280" strokeWidth="4" strokeLinecap="round" fill="none" />
              <path d="M240,185 L270,190 L300,175" stroke="#6b7280" strokeWidth="4" strokeLinecap="round" fill="none" />
            </g>
          </g>

          <g id="gears" fill="#4b5563">
            <path d="M100,200 L110,190 L120,200 L110,210 Z">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 110 200"
                to="360 110 200"
                dur="4s"
                repeatCount="indefinite"
              />
            </path>
            <path d="M280,180 L290,170 L300,180 L290,190 Z">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 290 180"
                to="-360 290 180"
                dur="3s"
                repeatCount="indefinite"
              />
            </path>
          </g>

          <text x="200" y="240" textAnchor="middle" fontFamily="Arial" fontSize="16" fontWeight="bold" fill="#e5e7eb">
            Oops! Something went wrong
          </text>
          <text x="200" y="265" textAnchor="middle" fontFamily="Arial" fontSize="10" fill="#e5e7eb">
            Try to refresh or contact us at support@weavy.ai if this happens again
          </text>

          <g fontFamily="monospace" fontSize="14" fill="#60a5fa" opacity="0.6">
            <text>
              <animateMotion dur="7s" repeatCount="indefinite" path="M120,100 Q140,80 130,120 T120,100" />
              01
            </text>
            <text>
              <animateMotion dur="8s" repeatCount="indefinite" path="M260,90 Q280,110 250,100 T260,90" />
              10
            </text>
            <text>
              <animateMotion dur="6s" repeatCount="indefinite" path="M150,180 Q170,160 140,170 T150,180" />
              00
            </text>
            <text>
              <animateMotion dur="9s" repeatCount="indefinite" path="M240,160 Q220,180 250,170 T240,160" />
              11
            </text>
            <text>
              <animateMotion dur="7.5s" repeatCount="indefinite" path="M180,70 Q200,50 170,60 T180,70" />
              01
            </text>
            <text>
              <animateMotion dur="8.5s" repeatCount="indefinite" path="M280,200 Q300,180 270,190 T280,200" />
              10
            </text>
          </g>

          <g stroke="#4b5563" strokeWidth="1">
            <path d="M50,50 L100,50 L100,80" fill="none" />
            <path d="M300,50 L350,50 L350,80" fill="none" />
            <path d="M50,250 L100,250 L100,220" fill="none" />
            <path d="M300,250 L350,250 L350,220" fill="none" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default ErrorPage;
