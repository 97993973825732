import { Box, Typography } from '@mui/material';

function Billing() {
  return (
    <Box>
      <Typography variant="h2">Billing</Typography>
    </Box>
  );
}

export default Billing;
