import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import { color } from '../../colors';

const gradientId = 'line-gradient'; // This ID is used to link the gradient to the path
const startColor = color.Orange;
const endColor = color.Green;

const useIsMobile = () => {
  // Initial check
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

function Hero() {
  const navigate = useNavigate();
  const nodesContainer = useRef(null);
  const [isReady, setIsReady] = useState(false);

  const isMobile = useIsMobile();

  const [positions, setPositions] = useState({
    design: { x: 0, y: 0 },
    ai: { x: 0, y: 0 },
  });

  useLayoutEffect(() => {
    if (nodesContainer.current) {
      if (isMobile) {
        const designX = nodesContainer.current.clientWidth / 2 - 220;
        const designY = nodesContainer.current.clientHeight / 2 - 350;
        const aiX = designX + 100; // for example, 250px to the right from design
        const aiY = designY + 250;

        setPositions({
          design: { x: designX, y: designY },
          ai: { x: aiX, y: aiY },
        });
      } else {
        const designX = nodesContainer.current.clientWidth / 2 - 350;
        const designY = nodesContainer.current.clientHeight / 2 - 250;
        const aiX = designX + 400; // for example, 250px to the right from design
        const aiY = designY + 150;

        setPositions({
          design: { x: designX, y: designY },
          ai: { x: aiX, y: aiY },
        });
      }
      setIsReady(true);
    }
  }, [nodesContainer]);

  const handleDrag = (node, data) => {
    setPositions((prev) => ({
      ...prev,
      [node]: { x: data.x, y: data.y },
    }));
  };

  const linePath = () => {
    if (isMobile) {
      const startX = positions.design.x + 120 + 48; // Adjusted for handle dimensions
      const startY = positions.design.y + 48 + 66;
      const endX = positions.ai.x + 120 + 48;
      const endY = positions.ai.y + 48;

      // Control points for the curve, adjust as needed for the desired curvature
      const controlX1 = (startX + endX) / 2;
      const controlY1 = startY;
      const controlX2 = (startX + endX) / 2;
      const controlY2 = endY;

      return `M ${startX},${startY} C ${controlX1},${controlY1} ${controlX2},${controlY2} ${endX},${endY}`;
    } else {
      const startX = positions.design.x + 240 + 48; // Adjusted for handle dimensions
      const startY = positions.design.y + 48 + 33;
      const endX = positions.ai.x + 48;
      const endY = positions.ai.y + 48 + 33;

      // Control points for the curve, adjust as needed for the desired curvature
      const controlX1 = (startX + endX) / 2;
      const controlY1 = startY;
      const controlX2 = (startX + endX) / 2;
      const controlY2 = endY;

      return `M ${startX},${startY} C ${controlX1},${controlY1} ${controlX2},${controlY2} ${endX},${endY}`;
    }
  };

  const navigateToEarlyAccess = () => {
    navigate('/early');
  };

  const navigateToLogin = () => {
    navigate('/signin');
  };

  return (
    <>
      <Box
        id="hero-header"
        sx={{
          width: '100%',
          p: 2,
          position: 'fixed',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src="/logo.svg" alt="" width="30px" />
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ mr: 1 }} variant="caption">
            Already have an account?
          </Typography>
          <Button variant="outlined" size="small" color="weavy_cta_secondary" onClick={navigateToLogin}>
            <Typography variant="caption">Login</Typography>
          </Button>
        </Box>
      </Box>
      <Box id="hero-container" ref={nodesContainer}>
        <Box id="hero-nodes-wrapper" sx={{ width: '100%', height: '100%' }}>
          {isReady && (
            <Box id="hero-title" sx={{ width: '100%', height: '100%', position: 'relative' }}>
              <Draggable
                onDrag={(e, data) => handleDrag('design', data)}
                defaultPosition={{ x: positions.design.x, y: positions.design.y }}
              >
                <Box className="node design" sx={{ borderColor: color.Orange, color: color.Orange }}>
                  <Typography variant="h5">Design</Typography>
                  <Box
                    className={isMobile ? 'handle mobile' : 'handle'}
                    sx={{ right: '-5px', background: color.Orange }}
                  ></Box>
                </Box>
              </Draggable>
              <Draggable
                onDrag={(e, data) => handleDrag('ai', data)}
                defaultPosition={{ x: positions.ai.x, y: positions.ai.y }}
              >
                <Box className="node ai" sx={{ borderColor: color.Green, color: color.Green }}>
                  <Typography variant="h5">AI</Typography>
                  <Box
                    className={isMobile ? 'handle mobile ai' : 'handle ai'}
                    sx={{ left: '-5px', background: color.Green }}
                  ></Box>
                </Box>
              </Draggable>
            </Box>
          )}
        </Box>
        <Box
          id="hero-cta-container"
          sx={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        >
          <Box
            id="hero-cta-content"
            sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Button
              variant="outlined"
              id="signin-button"
              onClick={navigateToEarlyAccess}
              sx={{
                color: 'white',
                mt: isMobile ? 56 : 18,
                fontSize: isMobile ? '1rem' : '12px',
                pointerEvents: 'auto',
              }}
            >
              Apply for Early Access
            </Button>
          </Box>
        </Box>
        {isReady && (
          <svg style={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <defs>
              <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                ` <stop offset="0%" stopColor={startColor} />
                <stop offset="100%" stopColor={endColor} />
              </linearGradient>
              `
            </defs>
            <path d={linePath()} stroke={`url(#${gradientId})`} strokeWidth="2" fill="none" />
          </svg>
        )}
      </Box>
    </>
  );
}

export default Hero;
