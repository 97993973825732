const DEFAULT_MARGIN = 20;

export const calculateElementPosition = (element, boxWidth, boxHeight, margin = 100) => {
  const rect = element.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // Calculate element's edges and center
  const elementLeft = rect.left;
  const elementRight = rect.right;
  const elementTop = rect.top;
  const elementBottom = rect.bottom;
  const elementCenterX = elementLeft + rect.width / 2;
  const elementCenterY = elementTop + rect.height / 2;

  // Check if element spans across vertical or horizontal center lines
  const spansHorizontally = elementLeft < windowWidth / 2 && elementRight > windowWidth / 2;
  const spansVertically = elementTop < windowHeight / 2 && elementBottom > windowHeight / 2;

  const isOnRightHalf = elementCenterX > windowWidth / 2;
  const isOnTopHalf = elementCenterY < windowHeight / 2;

  let top, left, placement;

  if (spansHorizontally) {
    // Element crosses left and right halves - center horizontally
    left = (windowWidth - boxWidth) / 2;
    if (isOnTopHalf) {
      // Place at bottom
      top = windowHeight - boxHeight - margin;
      placement = 'bottom';
    } else {
      // Place at top
      top = margin;
      placement = 'top';
    }
  } else if (spansVertically) {
    // Element crosses top and bottom halves - center vertically
    top = (windowHeight - boxHeight) / 2;
    if (isOnRightHalf) {
      // Place at left
      left = margin;
      placement = 'left';
    } else {
      // Place at right
      left = windowWidth - boxWidth - margin;
      placement = 'right';
    }
  } else {
    // Standard quadrant-based positioning
    if (isOnTopHalf && isOnRightHalf) {
      left = margin;
      top = windowHeight - boxHeight - margin;
      placement = 'bottom-start';
    } else if (isOnTopHalf && !isOnRightHalf) {
      left = windowWidth - boxWidth - margin;
      top = windowHeight - boxHeight - margin;
      placement = 'bottom-end';
    } else if (!isOnTopHalf && isOnRightHalf) {
      left = margin;
      top = margin;
      placement = 'top-start';
    } else {
      left = windowWidth - boxWidth - margin;
      top = margin;
      placement = 'top-end';
    }
  }

  // Ensure the box stays within viewport bounds
  left = Math.max(margin, Math.min(left, windowWidth - boxWidth - margin));
  top = Math.max(margin, Math.min(top, windowHeight - boxHeight - margin));

  return {
    top: top + window.scrollY,
    left: left + window.scrollX,
    placement,
  };
};

export const calculatePosition = (step, tourBox) => {
  if (!tourBox) return { top: 0, left: 0 };

  const boxWidth = tourBox.offsetWidth;
  const boxHeight = tourBox.offsetHeight;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // For element-based positioning
  if (step.element) {
    const element = document.getElementById(step.element);
    if (element) {
      return calculateElementPosition(element, boxWidth, boxHeight);
    }
  }

  // For predefined positions
  const positions = {
    center: {
      top: (windowHeight - boxHeight) / 2,
      left: (windowWidth - boxWidth) / 2,
    },
    'top-left': {
      top: DEFAULT_MARGIN,
      left: DEFAULT_MARGIN,
    },
    top: {
      top: DEFAULT_MARGIN,
      left: (windowWidth - boxWidth) / 2,
    },
    'top-right': {
      top: DEFAULT_MARGIN,
      left: windowWidth - boxWidth - DEFAULT_MARGIN,
    },
    right: {
      top: (windowHeight - boxHeight) / 2,
      left: windowWidth - boxWidth - DEFAULT_MARGIN,
    },
    left: {
      top: (windowHeight - boxHeight) / 2,
      left: DEFAULT_MARGIN,
    },
    'bottom-right': {
      top: windowHeight - boxHeight - DEFAULT_MARGIN,
      left: windowWidth - boxWidth - DEFAULT_MARGIN,
    },
    'bottom-left': {
      top: windowHeight - boxHeight - DEFAULT_MARGIN,
      left: DEFAULT_MARGIN,
    },
    bottom: {
      top: windowHeight - boxHeight - DEFAULT_MARGIN,
      left: (windowWidth - boxWidth) / 2,
    },
  };

  return positions[step.position] || positions.center;
};
