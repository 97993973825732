import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';
import { color } from '../../../../colors';
import DesignAppGallery from '../../DesignApp/DesignAppGallery';

const Preview = ({
  isLoading,
  isProcessing,
  results,
  setResults,
  recipeName,
  numberOfLoadingResults,
}: {
  isLoading: boolean;
  isProcessing: boolean;
  results: unknown[];
  setResults: (results: unknown[]) => void;
  recipeName: string;
  numberOfLoadingResults: number;
}) => {
  const [selected, setSelected] = useState(0);

  const deleteCurrentResult = () => {
    console.log('deleteCurrentResult');
    const newResult = results.filter((_, index) => index !== selected);
    setSelected(0);
    setResults(newResult);
  };

  const deleteAllResults = () => {
    console.log('deleteAllResults');
    setResults([]);
    setSelected(0);
  };

  const deleteAllOthersResults = () => {
    console.log('deleteAllOthersResults');
    const newResult = results.filter((_, index) => index === selected);
    setResults(newResult);
    setSelected(0);
  };

  return (
    <Box
      id="design-app-preview-container"
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `${color.Dark_Blue}CC`,
        border: `1px solid ${color.Dark_Grey}`,
        p: 2,
        borderRadius: 3,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Box
              sx={{
                flex: 1,
                width: '80%',
                position: 'relative',
              }}
            >
              <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
              <i
                className="fa-light fa-photo-film-music fa-xl"
                style={{
                  fontSize: '50px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: color.Yambo_Text_On_Dark_Transparent,
                }}
              ></i>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                height: '20%',
                gap: 2,
                position: 'relative',
              }}
            >
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} variant="rounded" animation="wave" width="100%" height="100%" />
              ))}
            </Box>
          </Box>
        ) : (
          <>
            {results.length > 0 || isProcessing ? (
              <DesignAppGallery
                recipeName={recipeName}
                mediaArray={
                  isProcessing ? [...Array(numberOfLoadingResults).fill({ type: 'rendering' }), ...results] : results
                }
                selected={selected}
                setSelected={setSelected}
                deleteCurrentResult={deleteCurrentResult}
                deleteAllResults={deleteAllResults}
                deleteAllOthersResults={deleteAllOthersResults}
              />
            ) : (
              <Box
                sx={{
                  border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <i className="fa-thin fa-image fa-xl" style={{ fontSize: '50px' }}></i>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Preview;
