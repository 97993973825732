import { Box, Typography } from '@mui/material';
import { AnimatedSuccessCheckmark } from '../../Common/AnimatedCheckmark';
import { StepKeys } from '../../ProductTours/tour-keys';
import { useTour } from '../../ProductTours/TourContext';
import { color } from '../../../colors';

const ANIMATION_DURATION = 2;
export function ZoomArrows({ width, height, position, finishedStep }) {
  return (
    <Box
      id="tour-arrows-overlay"
      sx={{
        width: width,
        height: height,
        // outline: `1px dashed ${color.Light_Grey}`,
        pointerEvents: 'none',
        borderRadius: 4,
        position: 'fixed',
        top: position.top,
        left: position.left,
        transform: `translate(-50%, -50%) scale(${finishedStep ? 1.5 : 1})`,
        opacity: finishedStep ? 0 : 1,
        '@keyframes arrowUpLeft': {
          '0%': { transform: 'translate(0, 0) scale(2.5)' },
          '50%': { transform: 'translate(-20px, -20px) scale(2.5)' },
          '100%': { transform: 'translate(0, 0) scale(2.5)' },
        },
        '@keyframes arrowUpRight': {
          '0%': { transform: 'translate(0, 0) scale(2.5)' },
          '50%': { transform: 'translate(20px, -20px) scale(2.5)' },
          '100%': { transform: 'translate(0, 0) scale(2.5)' },
        },
        '@keyframes arrowDownLeft': {
          '0%': { transform: 'translate(0, 0) scale(2.5)' },
          '50%': { transform: 'translate(-20px, 20px) scale(2.5)' },
          '100%': { transform: 'translate(0, 0) scale(2.5)' },
        },
        '@keyframes arrowDownRight': {
          '0%': { transform: 'translate(0, 0) scale(2.5)' },
          '50%': { transform: 'translate(20px, 20px) scale(2.5)' },
          '100%': { transform: 'translate(0, 0) scale(2.5)' },
        },
        '@keyframes boxGlow': {
          '0%': { boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)' },
          '70%': { boxShadow: '0 0 0 20px rgba(255, 255, 255, 0)' },
          '100%': { boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)' },
        },
        // animation: 'boxGlow 2s infinite',
        transition: 'opacity 1s ease-in 1s, transform 1.5s ease-in .5s',
      }}
    >
      <Box
        component="i"
        className="fa-light fa-arrow-up-left"
        sx={{
          position: 'absolute',
          top: 40,
          left: 40,
          animation: `arrowUpLeft ${ANIMATION_DURATION}s infinite`,
        }}
      />
      <Box
        component="i"
        className="fa-light fa-arrow-up-right"
        sx={{
          position: 'absolute',
          top: 40,
          right: 40,
          animation: `arrowUpRight ${ANIMATION_DURATION}s infinite`,
        }}
      />
      <Box
        component="i"
        className="fa-light fa-arrow-down-left"
        sx={{
          position: 'absolute',
          bottom: 40,
          left: 40,
          animation: `arrowDownLeft ${ANIMATION_DURATION}s infinite`,
        }}
      />
      <Box
        component="i"
        className="fa-light fa-arrow-down-right"
        sx={{
          position: 'absolute',
          bottom: 40,
          right: 40,
          animation: `arrowDownRight ${ANIMATION_DURATION}s infinite`,
        }}
      />
    </Box>
  );
}

export function PanTourCrosshair({ finishedPanTourStep, position }) {
  return (
    <Box
      id="tour-pan-overlay"
      sx={{
        width: '40px',
        height: '40px',
        position: 'fixed',
        top: position.top,
        left: position.left,
        transform: `translate(-50%, -50%) scale(${finishedPanTourStep ? 2 : 1})`,
        opacity: finishedPanTourStep ? 0 : 1,
        pointerEvents: 'none',
        transition: 'opacity 1s ease-in 1s, transform 1.5s ease-in .5s',
        '@keyframes glow': {
          '0%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)',
          },
          '70%': {
            boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
          },
          '100%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
          },
        },
      }}
    >
      {/* Circle */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          border: `2px solid ${color.Light_Grey}`,
          position: 'relative',
          animation: 'glow 2s infinite',
          '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            background: 'transparent',
            animation: 'glow 2s infinite',
          },
        }}
      >
        {/* Cross */}
        <Box
          sx={{
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              backgroundColor: color.Light_Grey,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            },
            '&::before': {
              width: '2px',
              height: '16px',
            },
            '&::after': {
              width: '16px',
              height: '2px',
            },
          }}
        />
      </Box>
      {/* {finishedPanTourStep && <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <AnimatedSuccessCheckmark />
        </Box>
        } */}
    </Box>
  );
}

export function NodePlaceholderTourBox({ finishedStep, width, height, position, label }) {
  return (
    <Box
      id="tour-box-overlay"
      sx={{
        width: width,
        height: height,
        outline: `1px dashed ${color.Light_Grey}`,
        pointerEvents: 'none',
        borderRadius: 4,
        position: 'fixed',
        top: position.top,
        left: position.left,
        transform: `translate(-50%, -50%) scale(${finishedStep ? 1.5 : 1})`,
        opacity: finishedStep ? 0 : 1,
        '@keyframes boxGlow': {
          '0%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)',
          },
          '70%': {
            boxShadow: '0 0 0 20px rgba(255, 255, 255, 0)',
          },
          '100%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
          },
        },
        animation: 'boxGlow 2s infinite',
        transition: 'opacity 1s ease-in 1s, transform 1.5s ease-in .5s',
      }}
    >
      {label && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) scale(2)',
          }}
        >
          <Typography
            variant="caption"
            fontFamily="ShantellSans"
            fontWeight="light"
            color={`${color.Light_Grey} !important`}
          >
            {label}
          </Typography>
        </Box>
      )}
      {finishedStep && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) scale(2)',
          }}
        >
          <AnimatedSuccessCheckmark size={24} />
        </Box>
      )}
    </Box>
  );
}

export function SuccessTourStepElement({ trigger }) {
  return (
    <>
      {trigger && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <AnimatedSuccessCheckmark />
        </Box>
      )}
    </>
  );
}

export function FlowTour({ activeTour, currentStep, finishedPanTourStep, finishedZoomTourStep, finishedAddNodeStep }) {
  const { getCurrentStepConfig } = useTour();
  if (!activeTour) return null;
  return (
    <>
      {activeTour && getCurrentStepConfig(currentStep).stepId === StepKeys.NAVIGATION_TOUR.PAN && (
        <>
          <PanTourCrosshair finishedPanTourStep={finishedPanTourStep} position={{ top: '40%', left: '50%' }} />
          <NodePlaceholderTourBox
            finishedStep={finishedPanTourStep}
            width={200}
            height={100}
            position={{ top: '40%', left: '50%' }}
          />
        </>
      )}
      {activeTour && getCurrentStepConfig(currentStep).stepId === StepKeys.NAVIGATION_TOUR.ZOOM && (
        <>
          <ZoomArrows
            finishedStep={finishedZoomTourStep}
            width={560}
            height={320}
            position={{ top: '40%', left: '50%' }}
          />
          <NodePlaceholderTourBox
            finishedStep={finishedZoomTourStep}
            width={560}
            height={320}
            position={{ top: '40%', left: '50%' }}
          />
        </>
      )}
      {activeTour &&
        (getCurrentStepConfig(currentStep).stepId === StepKeys.NAVIGATION_TOUR.ADD_NODES ||
          getCurrentStepConfig(currentStep).stepId === StepKeys.NAVIGATION_TOUR.CONNECT_NODES) && (
          <NodePlaceholderTourBox
            finishedStep={finishedAddNodeStep}
            width={280}
            height={200}
            position={{ top: '40%', left: '40%' }}
            label={'Drop Prompt here...'}
          />
        )}
      {activeTour && getCurrentStepConfig(currentStep).stepId === StepKeys.NAVIGATION_TOUR.ADD_NODES && (
        <SuccessTourStepElement trigger={finishedAddNodeStep} />
      )}
    </>
  );
}
