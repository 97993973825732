import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Dialog, Button, CircularProgress } from '@mui/material';
import { color } from '../../colors';
import axiosInstance from '../../services/axiosConfig';
import NodeContextMenu from './NodeContextMenu';
import EditModelDialog from './EditModelDialog';
import ModelCard from './ModelCard';

function Loader({ offset }) {
  return (
    <Box
      sx={{
        zIndex: 100,
        background: color.Yambo_BG,
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color="inherit" sx={{ left: `${offset / 2}px`, position: 'relative' }} />
    </Box>
  );
}

function MyModels({ user, title }) {
  const [myNodes, setMyNodes] = useState([]);
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [loadingEnrichment, setLoadingEnrichment] = useState(true);
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState({ show: false, id: null });
  const [isShowingEditDialog, setIsShowingEditDialog] = useState({ show: false, id: null });
  const [selectedNode, setSelectedNode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [contextMenu, setContextMenu] = useState({ mouseX: null, mouseY: null, isOpen: false, nodeId: null });

  const getModelForUser = async () => {
    try {
      const response = await axiosInstance.get(`/v1/nodes/`);
      // console.log(response.data);
      setMyNodes(response.data);
    } catch (error) {
      console.error('Error loading user nodes: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPoster = async (node) => {
    try {
      const response = await axiosInstance.get(`/v1/models/${node.data.model.name}`);

      return response.data;
    } catch (error) {
      console.error('Error loading model attributes: ', error);
      setLoadingEnrichment(false);
    }
  };

  const handleFinishLoading = () => {
    setTimeout(() => setLoadingEnrichment(false), 2500);
  };

  useEffect(() => {
    const enrichNodes = async () => {
      if (myNodes) {
        const nodesWithPosters = await Promise.all(
          myNodes.map(async (node) => {
            const enrichment = await getPoster(node).catch(() => '/empty.png'); // Fallback to '/empty.png' in case of error

            return {
              ...node,
              poster: enrichment?.cover_image_url,
              creator: enrichment?.owner,
              license: enrichment?.license_url,
              github: enrichment?.github_url,
            };
          }),
        );
        setMyNodes(nodesWithPosters);
        handleFinishLoading();
      }
    };
    enrichNodes();
  }, [myNodes.length]);

  useEffect(() => {
    getModelForUser();
    // console.log("getting nodes");
  }, []);

  const handleMouseEnterCard = (id) => {
    setHoveredNodeId(id);
  };

  const handleMouseLeaveCard = () => {
    setHoveredNodeId(null);
  };

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      isOpen: true,
      nodeId: id,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu({ ...contextMenu, isOpen: false });
  };

  const handleDeleteNodeSuccess = (response, nodeId) => {
    if (response.status === 204) {
      setMyNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    }
  };

  const deleteNode = async (id) => {
    // console.log("delete node");
    try {
      const response = await axiosInstance.delete(`/v1/nodes/${id}`);
      handleDeleteNodeSuccess(response, id);
    } catch (error) {
      console.error('Error deleting node: ', error);
    }
  };

  const menuItems = [
    {
      label: 'Delete',
      onClick: (e) => {
        e.preventDefault();
        deleteNode(contextMenu.nodeId, false);
        handleContextMenuClose();
      },
    },
    {
      label: 'Edit',
      onClick: (e) => {
        e.preventDefault();
        const nodeToEdit = myNodes.find((node) => node.id === contextMenu.nodeId);
        setSelectedNode(nodeToEdit);
        setIsShowingEditDialog({ show: true, id: contextMenu.nodeId });
        handleContextMenuClose();
      },
    },
  ];
  const handleDeleteDialogClose = () => {
    setIsShowingDeleteDialog({ show: false, id: null });
  };
  const handleConfirmDeleteNode = () => {
    deleteNode(isShowingDeleteDialog.id);
    setIsShowingDeleteDialog({ show: false, id: null });
  };

  const handleCancelDeleteNode = () => {
    setIsShowingDeleteDialog({ show: false, id: null });
  };

  const editModel = async (nodeId, nodeName, nodeDescription, nodeVisibility) => {
    try {
      await axiosInstance.put(
        `/v1/nodes/${nodeId}`,
        {
          name: nodeName,
          description: nodeDescription,
          visibility: nodeVisibility,
        },
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        },
      );
      /// update state with new values:
      setMyNodes((currentNodes) =>
        currentNodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                menu: {
                  ...node.data.menu,
                  displayName: nodeName,
                },
                description: nodeDescription,
              },
              visibility: nodeVisibility,
            };
          }

          return node;
        }),
      );
    } catch (error) {
      console.error('Error loading model attributes: ', error);
      setLoadingEnrichment(false);
    }
  };

  const handleEditDialogClose = () => {
    setIsShowingEditDialog({ show: false, id: null });
  };

  const handleSaveEdit = (nodeId, nodeName, nodeDescription, nodeVisibility) => {
    editModel(nodeId, nodeName, nodeDescription, nodeVisibility);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          p: 8,
        }}
        id="dashboard-nodes-container"
      >
        <Typography variant="h2">{title}</Typography>
        <Typography variant="caption">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Library of your favorite models. These models will appear under "My Models" menu in your files. Right-click to
          edit or delete
        </Typography>
        <Box id="my-nodes-content-container" sx={{ mt: 2, height: '100%' }}>
          {myNodes && myNodes.length > 0 && (
            <Grid container spacing={2}>
              {myNodes &&
                myNodes.map((node) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={node.id}
                      onContextMenu={(e) => handleContextMenu(e, node.id)}
                    >
                      <ModelCard
                        handleMouseLeaveCard={handleMouseLeaveCard}
                        handleMouseEnterCard={handleMouseEnterCard}
                        loadingEnrichment={loadingEnrichment}
                        model={node}
                        hoveredModelId={hoveredNodeId}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          )}
          {/* /// empty state */}
          {myNodes && myNodes.length === 0 && !isLoading && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="/illustrations/mymodels_empty.png"
                alt="empty"
                style={{ width: '200px', height: '200px', filter: 'grayscale(1)' }}
              />
              <Typography>No models saved yet</Typography>
            </Box>
          )}
          {contextMenu.isOpen && (
            <NodeContextMenu
              mouseX={contextMenu.mouseX}
              mouseY={contextMenu.mouseY}
              isOpen={contextMenu.isOpen}
              onClose={handleContextMenuClose}
              menuItems={menuItems}
            />
          )}
        </Box>
        <Dialog onClose={handleDeleteDialogClose} open={isShowingDeleteDialog.show}>
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
            <Typography>Are you sure you want to delete this model?</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2, justifyContent: 'flex-end' }}>
              <Button onClick={handleCancelDeleteNode} variant="contained" color="secondary" sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button onClick={handleConfirmDeleteNode}>Yes, Delete</Button>
            </Box>
          </Box>
        </Dialog>
        <EditModelDialog
          node={selectedNode}
          isOpen={isShowingEditDialog.show}
          onClose={handleEditDialogClose}
          onSave={handleSaveEdit}
        />
        {isLoading && <Loader offset={0} />}
      </Box>
    </>
  );
}

export default MyModels;
