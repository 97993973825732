import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, MenuItem, Button, Typography, Avatar, ButtonBase, Link, Skeleton } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AuthContext } from '../../AuthContext';
import { CreditsContext } from '../../services/CreditsContext';
import { color } from '../../colors';
import { useTranslation } from 'react-i18next';
import I18N_KEYS from '../../language/keys';

const modelsList = [
  {
    label: 'My Models',
    icon: <i className="fa-regular fa-lightbulb menu-icon"></i>,
    section: 'myModels',
  },
  {
    label: 'Community',
    icon: <i className="fa-solid fa-people-group menu-icon"></i>,
    section: 'communityModels',
  },
];

function NavigationDrawer({
  createRecipe,
  drawerWidth,
  user,
  setIsClosing,
  mobileOpen,
  setMobileOpen,
  selectedSection,
  onSectionChange,
  hasSharedApps,
  hasSharedRecipes = false,
}) {
  const { t: translate } = useTranslation();
  const { credits, openUpgradeModal, shouldShowCreditsToMembers } = React.useContext(CreditsContext);
  const navigate = useNavigate();

  const foldersList = [
    {
      label: 'Files',
      icon: <i className="fa-regular fa-clock  menu-icon"></i>,
      section: 'recentRecipes',
    },
    {
      label: 'Favorites',
      icon: <i className="fa-regular fa-star menu-icon"></i>,
      section: 'favoriteRecipes',
    },
    {
      label: translate(I18N_KEYS.MAIN_DASHBOARD.SHARED_WITH_ME),
      icon: <i className="fa-solid fa-user-group menu-icon"></i>,
      section: 'sharedRecipes',
      disable: !hasSharedRecipes,
    },
    // {
    //     label:'Community',
    //     icon: <Diversity1Icon/>,
    //     section:'communityRecipes'
    // }
  ];

  const settings = [
    {
      name: translate(I18N_KEYS.SETTINGS.SETTINGS),
      icon: <i className="fa-light fa-sliders"></i>,
    },
    {
      name: translate(I18N_KEYS.SETTINGS.LOGOUT),
      icon: <i className="fa-solid fa-arrow-right-from-bracket menu-icon"></i>,
    },
  ].filter(Boolean);

  const designAppsList = [
    {
      label: translate(I18N_KEYS.MAIN_DASHBOARD.SHARED_WITH_ME),
      icon: <i className="fa-solid fa-user-group menu-icon"></i>,
      section: 'sharedApps',
      disable: !hasSharedApps,
    },
  ];

  // const auth = getAuth();
  const { signOut } = useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleListItemClick = (section) => {
    onSectionChange(section);
  };
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const signOutUser = () => {
    signOut();
  };

  const goToSettings = () => {
    navigate('/settings');
  };

  const handleSettingClick = (setting) => {
    switch (setting) {
      case 'Settings':
        goToSettings();
        break;
      case 'Logout':
        // Logic for logout
        signOutUser();
        break;
      // Add more cases here for other settings
      default:
        console.log(`Unknown setting: ${setting}`);
    }
    setAnchorElUser(null);
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/T7BH8rGE4t');
  };

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      <Box id="dashboard-drawer-upper-container">
        <Box
          id="dashboard-drawer-user"
          sx={{ height: '56px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <ButtonBase onClick={handleOpenUserMenu}>
            <Avatar alt="user.displayName" src={user.photoURL} sx={{ width: '30px', height: '30px', mr: 1 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2">{user.displayName}</Typography>
              <Typography variant="caption">{user.email}</Typography>
            </Box>
            <ArrowDropDownIcon sx={{ ml: 1 }} />
          </ButtonBase>
        </Box>
        <Divider />
        {shouldShowCreditsToMembers && (
          <>
            <Box
              id="dashboard-credits-container"
              sx={{
                px: 2,
                py: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {credits !== null && credits !== undefined ? (
                  <Typography variant="caption">
                    <b>
                      {credits} {credits > 1 ? `Credits` : `Credit`}
                    </b>
                  </Typography>
                ) : (
                  <Skeleton variant="text" width={40} />
                )}
              </Box>
              {credits !== null && credits !== undefined ? (
                <Typography variant="caption">
                  {credits !== 'unlimited' && (
                    <Link
                      onClick={() => openUpgradeModal()}
                      sx={{
                        pointerEvents: credits === 'unlimited' ? 'none' : 'auto',
                        cursor: credits === 'unlimited' ? 'not-allowed' : 'pointer',
                        textDecoration: credits === 'unlimited' ? 'none' : 'underline',
                        // color: credits === 'unlimited' ? 'gray' : 'inherit',
                      }}
                    >
                      {translate(I18N_KEYS.NAVIGATION_DRAWER.BUY_MORE)}
                    </Link>
                  )}
                </Typography>
              ) : (
                <Skeleton variant="text" width={40} />
              )}
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{ width: '100%', p: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Button variant="contained" fullWidth size="small" color="weavy_cta" onClick={createRecipe}>
            <i className="fa-solid fa-plus" style={{ margintop: '-2px' }}></i>&nbsp;&nbsp;Create new File
          </Button>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Typography sx={{ pt: 1, px: 2 }}>My Files</Typography>
        <List>
          {foldersList.map((item) => (
            <ListItem
              key={item.label}
              disablePadding
              sx={{ background: item.section === selectedSection ? color.Super_Light_Transparent : '' }}
            >
              <ListItemButton onClick={() => handleListItemClick(item.section)} disabled={item.disable}>
                <ListItemIcon sx={{ textAlign: 'center' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ my: 1 }} />
        <Typography sx={{ pt: 1, px: 2 }}>Apps</Typography>
        <List>
          {designAppsList.map((item) => (
            <ListItem
              key={item.label}
              disablePadding
              sx={{ background: item.section === selectedSection ? color.Super_Light_Transparent : '' }}
            >
              <ListItemButton onClick={() => handleListItemClick(item.section)} disabled={item.disable}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ my: 1 }} />
        <Typography sx={{ pt: 1, px: 2 }}>Models</Typography>
        <List>
          {modelsList.map((item) => (
            <ListItem
              key={item.label}
              disablePadding
              sx={{ background: item.section === selectedSection ? color.Super_Light_Transparent : '' }}
            >
              <ListItemButton onClick={() => handleListItemClick(item.section)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box id="dashboard-drawer-bottom-container" sx={{}}>
        <Box id="discord-link-container" sx={{ p: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Join our Discord!
          </Typography>
          <Typography variant="caption" sx={{ lineHeight: '8px' }}>
            Chat with other users and the Weavy team to share your ideas, models, workflows, and help us improve.
          </Typography>
          <Button
            onClick={handleDiscordClick}
            sx={{ mt: 2 }}
            variant="contained"
            fullWidth
            size="small"
            color="weavy_cta_secondary"
          >
            Jump in
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const userMenu = (
    <Menu
      sx={{ mt: '45px', p: 0 }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {settings.map((setting, index) => {
        if (setting.name === 'divider') {
          return <Divider key={index} />;
        } else {
          return (
            <MenuItem sx={{ width: '220px', py: 1 }} key={index} onClick={() => handleSettingClick(setting.name)}>
              {setting.icon}
              <Typography textAlign="right" sx={{ ml: 1 }} variant="body1">
                {setting.name}
              </Typography>
            </MenuItem>
          );
        }
      })}
    </Menu>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="dashboard navigation"
      id="dashboard-drawer-wrapper"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, mt: '64px' },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
      {userMenu}
    </Box>
  );
}

export default NavigationDrawer;
