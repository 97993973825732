import { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { color } from '../../../../../colors';
import I18N_KEYS from '../../../../../language/keys';
import { UILayer } from './DesignerEditor';

interface LayerPanelRowProps {
  layer: UILayer;
  isSelected: boolean;
}
function LayerPanelRow({ layer, isSelected }: LayerPanelRowProps) {
  const { t: translate } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      id={`comp-layer-container-${layer.name}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 0.2,
        px: 1,
        cursor: 'pointer',
        boxShadow: isHovered ? `inset 0 0 0 1px ${color.Yambo_Black_Stroke}` : 'none',
        borderRadius: '2px',
        background: isSelected ? color.Yambo_Purple : color.Dark_Blue,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ ml: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: layer.visible && layer.url ? '1' : '.7',
          }}
        >
          {layer.url ? (
            <i className="fa-thin fa-image fa-sm"></i>
          ) : (
            <i
              className="fa-kit fa-light-image-slash fa-sm"
              style={{ position: 'relative', left: '-2px', marginRight: '-2px' }}
            ></i>
          )}

          <Typography
            variant="caption"
            sx={{
              ml: 1,
              fontWeight: isSelected ? '' : '500',
            }}
          >
            {layer.name}
          </Typography>
        </Box>
        {!layer.url && (
          <Tooltip title={translate(I18N_KEYS.COMPOSITOR.MISSING_URL_TOOLTIP)}>
            <Typography variant="caption" sx={{ fontSize: '0.7rem', opacity: '.7' }}>
              {translate(I18N_KEYS.COMPOSITOR.NO_MEDIA)}
            </Typography>
          </Tooltip>
        )}
        {isHovered && layer.url && (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box>
              {layer.locked && layer.locked === true ? (
                <i className="fa-solid fa-lock fa-2xs"></i>
              ) : (
                <i className="fa-light fa-unlock fa-2xs"></i>
              )}
            </Box>
            <Box sx={{ ml: 1 }}>
              {layer.visible && layer.visible === true ? (
                <i className="fa-light fa-eye fa-2xs"></i>
              ) : (
                <i className="fa-light fa-eye-slash fa-2xs"></i>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default LayerPanelRow;
