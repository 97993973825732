import React, { ReactNode, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import UpgradeModal from '../components/SubscriptionsAndPayments/UpgradeModal';
import axiosInstance from './axiosConfig';

export const CreditsContext = React.createContext({
  credits: null,
  setUserCredits: (credits) => {},
  openUpgradeModal: () => {},
  shouldShowCreditsToMembers: true,
  setShouldShowCreditsToMembers: (shouldShowCreditsToMembers: boolean) => {},
});

type CreditsProviderProps = {
  children: ReactNode;
};

export const CreditsProvider = ({ children }: CreditsProviderProps) => {
  const { currentUser, updateCurrentUser } = React.useContext(AuthContext);
  const [credits, setCredits] = React.useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);
  const [shouldShowCreditsToMembers, setShouldShowCreditsToMembers] = React.useState(true);

  useEffect(() => {
    if (currentUser) {
      const fetchCredits = async () => {
        try {
          const response = await axiosInstance.get('/v1/users');
          setCredits(response.data.activeWorkspace?.credits || response.data.credits);
          updateCurrentUser(response.data);
          setShouldShowCreditsToMembers(response.data?.activeWorkspace?.preferences?.showCreditsToMembers ?? true); // default to true if not set
        } catch (error) {
          console.error('Error fetching credits:', error);
        }
      };
      fetchCredits();
    }
  }, []);

  const openUpgradeModal = () => {
    localStorage.setItem('lastPageBeforePayment', window.location.href);
    setShowUpgradeModal(true);
  };

  return (
    <CreditsContext.Provider
      value={{
        credits,
        setUserCredits: setCredits,
        openUpgradeModal,
        shouldShowCreditsToMembers,
        setShouldShowCreditsToMembers,
      }}
    >
      {children}
      {currentUser && (
        <UpgradeModal currentUser={currentUser} open={showUpgradeModal} closeModal={() => setShowUpgradeModal(false)} />
      )}
    </CreditsContext.Provider>
  );
};
