const getPredecessorIds = (nodeId, edges, selectedNodes) => {
  // Convert selectedNodes to a map for efficient lookup
  const selectedNodesMap = new Map(selectedNodes.map((node) => [node.id, node.isModel]));

  // Recursive function to find model predecessors within selectedNodes
  const findModelPredecessors = (currentId, visited = new Set()) => {
    if (visited.has(currentId) || !selectedNodesMap.has(currentId)) return [];
    visited.add(currentId);

    const directPredecessors = edges.filter((edge) => edge.target === currentId).map((edge) => edge.source);
    let modelPredecessors = [];

    for (const predId of directPredecessors) {
      // Check if the predecessor is in selectedNodes and is a model
      if (selectedNodesMap.has(predId) && selectedNodesMap.get(predId)) {
        modelPredecessors.push(predId);
      } else {
        // Recursively look for model predecessors of the predecessor
        modelPredecessors = [...modelPredecessors, ...findModelPredecessors(predId, visited)];
      }
    }

    return modelPredecessors;
  };

  return findModelPredecessors(nodeId);
};

export const processingOrder = (edges, subsetNodes) => {
  const subsetNodeIds = subsetNodes.map((n) => n.id);

  const relevantEdges = edges.filter(
    (edge) => subsetNodeIds.includes(edge.source) && subsetNodeIds.includes(edge.target),
  );

  const graph = {};
  const inDegrees = {};

  subsetNodes.forEach((node) => {
    graph[node.id] = [];
    inDegrees[node.id] = 0; // Initialize in-degrees to 0
  });

  // Step 2: Build the graph and update in-degrees based on edges
  relevantEdges.forEach((edge) => {
    if (graph[edge.source]) {
      // Ensure edge is relevant to the subset
      graph[edge.source].push(edge.target);
      if (inDegrees[edge.target] !== undefined) {
        // Increment in-degree if target is within the subset
        inDegrees[edge.target]++;
      }
    }
  });

  // Step 3: Find nodes with in-degree of 0 to start processing
  let queue = subsetNodes.filter((node) => inDegrees[node.id] === 0).map((node) => node.id);

  const sorted = []; // This will store the ordered node IDs

  // // Step 4: Perform topological sort using Kahn's Algorithm
  while (queue.length) {
    let currentLevel = []; // Nodes to be processed at the current level
    let nextQueue = []; // To keep track of nodes for the next level

    // Process each node in the queue
    queue.forEach((current) => {
      currentLevel.push(current); // Add current node to the current level

      // Update inDegrees and prepare nextQueue
      graph[current].forEach((next) => {
        inDegrees[next]--;
        if (inDegrees[next] === 0) {
          nextQueue.push(next);
        }
      });
    });

    sorted.push(currentLevel); // Add current level to the sorted output
    queue = nextQueue; // Move to the next level
  }

  // Check for cycles in the graph
  if (sorted.flat().length !== subsetNodes.length) {
    throw new Error('The graph has at least one cycle, making topological sorting impossible.');
  }

  return sorted;
};

export const getModelsToProcess = (edges, nodes, selectedNodes) => {
  // const orderedGroups = processingOrder(edges, selectedNodes.filter(n => n.isModel));
  const orderedGroups = processingOrder(edges, selectedNodes);

  const nodeStatusMap = new Map();

  orderedGroups.forEach((group, index) => {
    group.forEach((nodeId) => {
      const currentNode = nodes.find((n) => n.id === nodeId);

      if (!currentNode.isModel) return;

      const modelPredecessorIds = index === 0 ? undefined : getPredecessorIds(nodeId, edges, selectedNodes);

      const isModel = true; // todo: replace with currentNode.isModel
      if (isModel) {
        nodeStatusMap.set(nodeId, {
          id: nodeId,
          status: 'pending', // Initial status
          predecessors: modelPredecessorIds && modelPredecessorIds.length > 0 ? modelPredecessorIds : undefined,
          type: currentNode.type,
          process: 0,
        });
      }
    });
  });

  // Logic to update status based on predecessors being "ready" could be handled externally, as nodes listen for changes.

  return Array.from(nodeStatusMap.values());
};
