import { Box, Select, FormControl, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useUserRole } from '../Recipe/UserRoleContext';
import { hasEditingPermissions } from './Utils';
const fileTypes = [
  {
    value: 'png',
    label: 'PNG',
  },
  {
    value: 'jpeg',
    label: 'JPEG',
  },
];

const mimeToExtension = {
  'image/png': 'png',
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'image/svg+xml': 'svg',
  'video/mp4': 'mp4',
  'video/webm': 'webm',
  'video/ogg': 'ogg',
  'audio/mpeg': 'mp3',
  'audio/wav': 'wav',
  'audio/ogg': 'ogg',
  'audio/webm': 'webm',
  'audio/aac': 'aac',
  'audio/flac': 'flac',
  'audio/x-m4a': 'm4a',
  'model/gltf-binary': 'glb',
  // Add more MIME types and extensions as needed
};
function ExportCore({ data, container }) {
  const role = useUserRole();

  const { input, handles } = data;
  const [file, setFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState('png');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (input && input[handles.input[0]]) {
      setFile(input[handles.input[0]]);
    } else {
      // console.log("Setting file to undefined")
      setFile();
    }
  }, [input]);

  const handleSelectChange = (value) => {
    setSelectedFileType(value);
  };

  const handleExport = async () => {
    if (file && file.url) {
      setIsProcessing(true);
      try {
        const response = await fetch(file.url);
        const blob = await response.blob();
        const mimeType = blob.type;
        const extension = mimeToExtension[mimeType] || 'file';

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `export.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error fetching the file:', error);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <>
      {container === 'node' && (
        <Box>
          <LoadingButton
            size="small"
            variant="contained"
            fullWidth
            disabled={isProcessing || !file || !hasEditingPermissions(role, data)}
            color="weavy_cta_blue"
            loading={isProcessing}
            endIcon={<FileDownloadIcon />}
            loadingPosition="end"
            onClick={handleExport}
          >
            Export
          </LoadingButton>
        </Box>
      )}
      {container === 'drawer' && (
        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <Select
              labelId={`file-type-label`}
              value={selectedFileType}
              onChange={(e) => handleSelectChange(e.target.value)}
              size="small"
            >
              {fileTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
}

export default ExportCore;
