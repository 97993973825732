import {
  Box,
  FormControl,
  Input,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  Autocomplete,
  TextField,
  Slider,
  Skeleton,
  FormControlLabel,
} from '@mui/material';
import { NumberInputField } from '../../Nodes/input-fields/number-input-field/number-input-field.component';
import { ExtraSmallFontTextField } from '../../Nodes/Utils';
import LoRA from '../../Nodes/MultiLoRA/LoRA';
import { color } from '../../../colors';
import { NodeType } from '../../../enums/node-type.enum';
import I18N_KEYS from '../../../language/keys';
import { TextFieldWithCursor } from '../../Nodes/ModelNodesUitls';
import UploadFile from './DesignAppUploadFile';

const getSafeNumber = (value) => {
  const parsedValue = Number.parseFloat(value);
  if (isNaN(parsedValue)) {
    return undefined;
  }
  return parsedValue;
};

export const renderDesignAppParams = ({
  node,
  param,
  handleChange,
  isLoading,
  validationProps,
  translate,
  disabled,
}) => {
  switch (param.type) {
    case 'integer':
    case 'number':
      return (
        <NumberInputField
          key={`${param.id}-numberInputField`}
          inputKey={param.id}
          title={node.data.name}
          description={false}
          value={getSafeNumber(param.value)}
          min={getSafeNumber(node.data.min)}
          max={getSafeNumber(node.data.max)}
          type={param.mode || node.type}
          onChange={handleChange}
          disabled={disabled}
        />
      );
    case 'input': // backward compatibility 24.10 (adding separation between input of integer and floats)
      return (
        <Input
          key={`${param.id}-textfield`}
          fullWidth
          // label={key}
          value={param.value}
          size="small"
          inputProps={{ type: 'number' }}
          onChange={(e) => handleChange(param.id, parseInt(e.target.value))}
          disabled={disabled}
        />
      );
    case 'input-integer':
      return (
        <Input
          key={`${param.id}-input-integer`}
          fullWidth
          value={param.value}
          size="small"
          inputProps={{ type: 'number' }}
          onChange={(e) => handleChange(param.id, parseInt(e.target.value))}
          disabled={disabled}
        />
      );
    case 'input-number':
      return (
        <Input
          key={`${param.id}-input-float`}
          fullWidth
          value={param.value}
          size="small"
          inputProps={{ type: 'number', step: '0.1' }}
          onChange={(e) => handleChange(param.id, parseFloat(e.target.value))}
          disabled={disabled}
        />
      );
    case 'mux':
      return (
        <FormControl fullWidth sx={{ mt: 1 }}>
          {/* <InputLabel id={`${key}-label`}>{property.title}</InputLabel> */}
          <Select
            labelId={`${param.id}-label`}
            id={param.id}
            value={param.value}
            onChange={(e) => handleChange(param.id, e.target.value)}
            size="small"
            disabled={disabled}
          >
            {node.data.options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case 'boolean':
      return (
        <Box
          key={`${param.id}-boolean`}
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            ml: -1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl>
              <Checkbox
                inputProps={{ 'aria-label': 'Checkbox' }}
                checked={param.value}
                onChange={(e) => handleChange(param.id, e.target.checked)}
                disabled={disabled}
              />
            </FormControl>
          </Box>
        </Box>
      );
    case 'seed':
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: -1 }}>
          <FormControl>
            <Checkbox
              inputProps={{ 'aria-label': 'Checkbox' }}
              checked={param.value?.isRandom}
              onChange={(e) =>
                handleChange(param.id, {
                  isRandom: e.target.checked,
                  seed: param.value?.seed,
                })
              }
              disabled={disabled}
            />
          </FormControl>
          <Typography variant="caption" className="property-title" sx={{ mr: 0.5 }}>
            Random
          </Typography>

          <Input
            value={param.value?.seed}
            inputProps={{ type: 'number' }}
            size="small"
            onChange={(e) => handleChange(param.id, { isRandom: param.value?.isRandom, seed: e.target.value })}
            disabled={param.value?.isRandom}
            sx={{ ml: 1 }}
          />
        </Box>
      );
    case 'array':
      return (
        <>
          <ExtraSmallFontTextField
            sx={{ mt: 1 }}
            fullWidth
            id={param.id}
            multiline
            value={Array.isArray(param.value) ? param.value.join(', ') : param.value || ''}
            rows={3}
            onChange={(e) => {
              // Just pass the raw text value
              handleChange(param.id, e.target.value);
            }}
            onBlur={(e) => {
              // Only split into array when the field loses focus
              const newValue = e.target.value
                .split(',')
                .map((item) => item.trim())
                .filter(Boolean);
              handleChange(param.id, newValue);
            }}
            size="small"
            disabled={disabled}
          />
          <Typography variant="caption">Use comma for multiple entries </Typography>
        </>
      );
    case 'text':
      return (
        <TextFieldWithCursor
          id={param.id}
          value={param.value}
          property={param}
          handleChange={handleChange}
          disabled={disabled}
        />
      );

    case 'multilora':
      const { loras } = node.data;

      return (
        <>
          {isLoading ? (
            <>
              <Skeleton width="100%" height={48} />
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Skeleton width="20%" height={18} />
                <Skeleton width="60%" height={18} />
                <Skeleton width="20%" height={18} />
              </Box>
            </>
          ) : (
            <>
              <Autocomplete
                id="lora-select"
                sx={{ width: '100%', mt: 1, backgroundColor: color.Yambo_BG }}
                options={loras && loras.length > 0 ? loras.filter((l) => l.file) : []}
                autoHighlight
                value={param.value.selectedLora}
                onChange={(event, newValue) => {
                  handleChange(node.id, { selectedLora: newValue, weight: param.value.weight });
                }}
                size="small"
                autoComplete={false}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => {
                  const { id: _, ...optionProps } = props;

                  return (
                    <Box
                      key={option.id}
                      component="li"
                      {...optionProps}
                      sx={{
                        width: '100%',
                        backgroundColor: color.Yambo_Blue,
                        '&:hover': {
                          backgroundColor: color.Yambo_Blue_Stroke,
                        },
                      }}
                    >
                      <LoRA lora={option} container="node" />
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    error={validationProps.error}
                    helperText={validationProps.helperText}
                  />
                )}
                disabled={disabled}
              />
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }} gap={2}>
                <FormControlLabel
                  sx={{ flexGrow: 1, ml: 0 }}
                  label={translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.MULTI_LORA.WEIGHT)}
                  labelPlacement="start"
                  disabled={disabled}
                  control={
                    <Slider
                      size="small"
                      value={param.value.weight}
                      onChange={(event, newValue) => {
                        handleChange(node.id, { weight: newValue, selectedLora: param.value.selectedLora });
                      }}
                      aria-labelledby="weight-slider"
                      max={node.data.maxWeight}
                      min={node.data.minWeight}
                      sx={{ ml: 2 }}
                      step={node.data.stepWeight}
                      valueLabelDisplay="auto"
                      disabled={disabled}
                    />
                  }
                />
                <Input
                  value={param.value.weight}
                  size="small"
                  onChange={(e) =>
                    handleChange(node.id, { weight: e.target.value, selectedLora: param.value.selectedLora })
                  }
                  inputProps={{
                    step: node.data.stepWeight,
                    min: node.data.minWeight,
                    max: node.data.maxWeight,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                    style: {
                      width: '50px',
                      fontSize: '10px',
                    },
                  }}
                  sx={{ ml: 2 }}
                  disabled={disabled}
                />
              </Box>
            </>
          )}
        </>
      );
    case 'import':
      return <UploadFile id={param.id} value={param.value} onUpload={handleChange} />;
    case NodeType.Prompt:
      return (
        <TextField
          placeholder={translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.INPUTS.PROMPT_PLACEHOLDER)}
          fullWidth
          multiline
          value={param.value?.prompt}
          onChange={(e) =>
            handleChange(param.id, {
              prompt: e.target.value,
            })
          }
          rows={4}
          inputProps={{
            style: { resize: 'vertical' },
          }}
          sx={{
            background: `${color.Yambo_BG}`,
          }}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export function getWorkflowInputNodes(nodes, edges) {
  const outputNodes = nodes?.filter((node) => node.type === NodeType.WorkflowOutput);

  const nodeMap = new Map(nodes.map((node) => [node.id, node]));
  // for each node - all the nodes that connect to it
  const reverseAdjMap = new Map();

  edges.forEach((edge) => {
    const targetNodes = reverseAdjMap.get(edge.target) || [];
    targetNodes.push(edge.source);
    reverseAdjMap.set(edge.target, targetNodes);
  });

  const inputNodeIds = new Set();

  // For each output node, traverse up to find all input nodes
  function findInputsForNode(nodeId) {
    const sourceNodes = reverseAdjMap.get(nodeId) || [];

    if (sourceNodes.length === 0) {
      // If no incoming edges, this is an input node
      inputNodeIds.add(nodeId);
    } else {
      sourceNodes.forEach((sourceId) => {
        findInputsForNode(sourceId);
      });
    }
  }

  outputNodes.forEach((outputNode) => {
    findInputsForNode(outputNode.id);
  });

  return Array.from(inputNodeIds).map((id) => nodeMap.get(id));
}

export function getConnectedOutputNodesCount(nodes, edges) {
  const outputNodes = nodes?.filter((node) => node.type === NodeType.WorkflowOutput);
  const connectedOutputNodes = outputNodes.filter((node) => edges.some((edge) => edge.target === node.id));

  return connectedOutputNodes.length;
}
