import { Box, Typography, Avatar, ButtonBase, Button } from '@mui/material';
import { useState } from 'react';
import { color } from '../../../../colors';

function ShareModalUser({ user, currentUserId, onDelete }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleRemoveUser = () => {
    onDelete(user.uid);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        justifyContent: 'space-between',
        py: 1,
        px: 2,
        '&:hover': {
          backgroundColor: '#FFF1',
        },
        overflow: 'hidden',
        minHeight: 48,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setShowDelete(false);
      }}
    >
      {user && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            {user.photoURL ? (
              <Avatar src={user.photoURL} sx={{ width: 26, height: 26 }} />
            ) : (
              <Avatar sx={{ width: 26, height: 26 }}>{user.displayName?.charAt(0)}</Avatar>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {user.displayName && (
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  {user.displayName}&nbsp;{user.uid === currentUserId ? '(You)' : ''}
                </Typography>
              )}
              <Typography variant="caption" sx={{ fontSize: '0.6rem', marginTop: -0.5 }}>
                {user.email}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            {user.uid === currentUserId ? (
              <Typography variant="caption" sx={{ color: color.Yambo_Text_On_Dark }}>
                Owner
              </Typography>
            ) : (
              <>
                {isHovered && !showDelete && (
                  <ButtonBase sx={{ p: 1, mr: -1 }} onClick={() => setShowDelete(true)}>
                    <i className="fa-light fa-xmark"></i>
                  </ButtonBase>
                )}
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    transform: showDelete ? 'translateX(0)' : 'translateX(100%)',
                    opacity: showDelete ? 1 : 0,
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <Button
                    onClick={handleRemoveUser}
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      fontSize: '0.75rem',
                    }}
                  >
                    Remove
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default ShareModalUser;
