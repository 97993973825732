import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Slider, Input, Select, MenuItem, Typography } from '@mui/material';
import { useUserRole } from '../Recipe/UserRoleContext';
import { hasEditingPermissions } from './Utils';
import { ExtraSmallOutlinedInput } from './Utils';

function ExtractVideoFrameCore({ id, data, updateNodeData }) {
  const role = useUserRole();

  const { input, handles } = data;
  const [fileSrc, setFileSrc] = useState();
  const [frameNumber, setFrameNumber] = useState(data.frameNumber || 0);
  const [tempFrameNumber, setTempFrameNumber] = useState(frameNumber);
  const [timecode, setTimecode] = useState('00:00:00');
  const [isEditing, setIsEditing] = useState(false);
  const videoRef = useRef(null);

  const handleDisconnection = useCallback(() => {
    setFrameNumber(0);
    setTempFrameNumber(0);
    setTimecode('00:00:00');
    updateNodeData(id, {
      result: {},
      output: {
        [data.handles.output[0]]: null,
      },
    });
  }, [fileSrc]);

  //handle connection / disconnection
  useEffect(() => {
    if (input && input[handles.input[0]]) {
      setFileSrc(input[handles.input[0]]);
    } else {
      setFileSrc(null);
      handleDisconnection();
    }
  }, [input]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleTimeUpdate = () => {
      if (isEditing) return;

      const currentTime = videoElement.currentTime;
      const fps = fileSrc?.fps || 30;
      const frameNum = Math.round(currentTime * fps);

      // Calculate timecode based on frame number instead of time
      const totalFrames = frameNum;
      const framesPerMinute = fps * 60;

      const minutes = Math.floor(totalFrames / framesPerMinute);
      const remainingFrames = totalFrames % framesPerMinute;
      const seconds = Math.floor(remainingFrames / fps);
      const frames = remainingFrames % fps;

      const timecodeStr = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;

      setFrameNumber(frameNum);
      setTempFrameNumber(frameNum);
      setTimecode(timecodeStr);
    };

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [fileSrc, isEditing]);

  useEffect(() => {
    if (data.frameNumber !== undefined && fileSrc?.fps) {
      const fps = fileSrc.fps;
      const timeInSeconds = data.frameNumber / fps;

      // Calculate timecode components
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      const frames = Math.floor((timeInSeconds % 1) * fps);

      // Set initial timecode
      const timecodeStr = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;

      // Set states
      setFrameNumber(data.frameNumber);
      setTempFrameNumber(data.frameNumber);
      setTimecode(timecodeStr);

      // Set video time
      if (videoRef.current) {
        videoRef.current.currentTime = timeInSeconds;
      }
    }
  }, [data.frameNumber, fileSrc]);

  useEffect(() => {
    if (fileSrc && fileSrc.url && videoRef.current?.paused) {
      const timeInSeconds = frameNumber / (fileSrc?.fps || 30);
      updateNodeData(id, {
        frameNumber: frameNumber, // Use the exact frame number
        result: {
          url: getHighResImageUrl(fileSrc.url, timeInSeconds),
          type: 'image',
        },
        output: {
          [handles.output[0]]: {
            url: getHighResImageUrl(fileSrc.url, timeInSeconds),
            type: 'image',
            width: fileSrc?.width,
            height: fileSrc?.height,
          },
        },
      });
    }
  }, [fileSrc, frameNumber]);

  const frameToTime = (frameNumber) => {
    return frameNumber / (fileSrc?.fps || 30);
  };

  const getHighResImageUrl = (videoUrl, timeInSeconds) => {
    // Parse the video URL
    const urlParts = videoUrl.match(/https:\/\/res\.cloudinary\.com\/([^/]+)\/video\/upload\/(?:v\d+\/)?(.+?)\.mp4$/);

    if (!urlParts) {
      return 'Invalid video URL format';
    }

    const [_, cloudName, path] = urlParts;
    const cleanPath = path.replace(/^v\d+\//, '');

    // Construct the image URL
    return `https://res.cloudinary.com/${cloudName}/video/upload/so_${timeInSeconds}/${cleanPath}.jpg`;
  };

  const handleFrameNumberUpdate = (value) => {
    const newFrameNumber = parseInt(value) || 0;

    if (newFrameNumber === frameNumber || isNaN(newFrameNumber)) {
      setTempFrameNumber(frameNumber);
      return;
    }

    const fps = fileSrc?.fps || 30;
    const maxFrames = Math.round((fileSrc?.duration || 0) * fps);

    const clampedFrameNumber = Math.min(Math.max(newFrameNumber, 0), maxFrames);

    // Calculate exact frame-based time
    const timeInSeconds = (clampedFrameNumber - 0.5) / fps; // Subtract 0.5 frame duration to align with video

    setFrameNumber(clampedFrameNumber);
    setTempFrameNumber(clampedFrameNumber);
    videoRef.current.currentTime = timeInSeconds;
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          pb: fileSrc?.url ? 1 : 0,
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="caption" sx={{}}>
            Timecode
          </Typography>
          <ExtraSmallOutlinedInput value={timecode} onChange={(e) => setTimecode(e.target.value)} disabled={true} />
        </Box>
        <Box>
          <Typography variant="caption" sx={{}}>
            Frame
          </Typography>
          <ExtraSmallOutlinedInput
            value={tempFrameNumber}
            type="number"
            onFocus={(e) => {
              setIsEditing(true);
              e.target.select();
            }}
            onChange={(e) => setTempFrameNumber(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleFrameNumberUpdate(tempFrameNumber);
                e.target.blur();
              }
            }}
            onBlur={() => {
              setIsEditing(false);
              handleFrameNumberUpdate(tempFrameNumber);
            }}
          />
        </Box>
      </Box>
      {fileSrc && fileSrc.url && (
        <video
          draggable="false"
          crossOrigin="anonymous"
          src={fileSrc?.url}
          width="100%"
          controls
          loop
          style={{ display: 'block' }}
          ref={videoRef}
        />
      )}
    </>
  );
}

export default ExtractVideoFrameCore;
