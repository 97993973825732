import React, { useState, useEffect, useRef } from 'react';
import { Box, Fade, Tooltip, styled } from '@mui/material';
import { TourStepMediaType } from '../../enums/tour-step-media-type.enum';
import { color } from '../../colors';
import { useTour } from './TourContext';
import tourConfigs from './tourConfigs.json';
import { TourContent, TourOverlay } from './TourComponents';
import { calculatePosition, calculateElementPosition } from './TourUtils';

const FADE_DURATION = 300;

const CustomTourTooltip = styled(({ className, animate, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  @keyframes horizontalBounce {
    0%,
    100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
  }
  @keyframes verticalBounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }
  & .MuiTooltip-tooltip {
    color: inherit;
    max-width: none;
    padding: 0;
    box-shadow: ${(props) => props.theme.shadows[24]};
    animation: ${(props) => (props.animate ? 'horizontalBounce .75s ease-in-out infinite' : 'none')};
  }
  &[data-popper-placement*='top'] .MuiTooltip-tooltip,
  &[data-popper-placement*='bottom'] .MuiTooltip-tooltip {
    animation: ${(props) => (props.animate ? 'verticalBounce .75s ease-in-out infinite' : 'none')};
  }
`;

const Tour = () => {
  const {
    activeTour,
    currentStep,
    endTour,
    goToNextStep,
    goToPreviousStep,
    showSuccessView,
    customTourData,
    tourReadiness,
    setTourReadiness,
    replacePlaceholders,
  } = useTour();
  const steps = activeTour ? tourConfigs[activeTour]?.steps : [];

  return (
    <TourComponent
      isOpen={!!activeTour}
      onClose={endTour}
      steps={steps}
      currentStep={currentStep}
      onNextStep={() => {
        goToNextStep(activeTour);
      }}
      onPrevStep={() => goToPreviousStep(activeTour)}
      showSuccessView={showSuccessView}
      customTourData={customTourData}
      tourReadiness={tourReadiness}
      setTourReadiness={setTourReadiness}
      replacePlaceholders={replacePlaceholders}
    />
  );
};

const TourComponent = ({
  isOpen,
  onClose,
  steps,
  currentStep,
  onNextStep,
  onPrevStep,
  showSuccessView,
  customTourData,
  tourReadiness,
  setTourReadiness,
  replacePlaceholders,
}) => {
  const [positions, setPositions] = useState([]);
  const tourBoxRef = useRef(null);

  const calculatePositions = () => {
    if (!tourBoxRef.current) return;
    // Remove all previous highlights
    document.querySelectorAll('[data-tour-highlight]').forEach((element) => {
      element.style.outline = '';
      element.removeAttribute('data-tour-highlight');
    });

    // Calculate positions for all steps
    const boxWidth = tourBoxRef.current.offsetWidth;
    const boxHeight = tourBoxRef.current.offsetHeight;

    const newPositions = steps.map((step, index) => {
      let targetId = step?.element;
      if (targetId) {
        targetId = replacePlaceholders(targetId, customTourData);
      }
      const targetElement = step.element ? document.getElementById(targetId) : null;
      if (targetElement && index === currentStep) {
        targetElement.style.outline = '4px solid #4CAF50';
        targetElement.style.borderRadius = '4px';
        targetElement.style.outlineRadius = '6px';
        targetElement.style.transition = 'outline 0.3s ease-in-out';
        targetElement.setAttribute('data-tour-highlight', 'true');
      }

      if (targetElement) {
        const position = calculateElementPosition(targetElement, boxWidth, boxHeight);
        return {
          ...position,
          element: targetElement,
          hasTarget: true,
        };
      } else {
        const position = calculatePosition(step, tourBoxRef.current);
        return {
          ...position,
          hasTarget: false,
        };
      }
    });
    setPositions(newPositions);
    setTourReadiness((prev) => ({ ...prev, positions: true })); // update tourcontext tourreadiness
  };

  /// handle media loading before calculating positions
  useEffect(() => {
    if (!steps.length) return;
    const currentStepData = steps[currentStep];
    if (currentStepData.media) {
      switch (currentStepData.mediaType) {
        case TourStepMediaType.IMAGE:
          const img = new Image();
          img.onload = () => {
            calculatePositions(); // calculate after image loads
          };
          img.src = currentStepData.media;
          break;
        case TourStepMediaType.LOTTIE:
          const loadLottieData = async () => {
            try {
              steps[currentStep].media = await import(`../../Lottie-animations/${currentStepData.media}`);
              // calculatePositions();
            } catch (error) {
              console.error('Error loading Lottie animation:', error);
              calculatePositions();
            }
          };

          loadLottieData();
          break;
        case TourStepMediaType.VIDEO:
          break;
      }
    } else {
      calculatePositions(); // calculate for non-image steps
    }

    const observer = new MutationObserver((mutations) => {
      const currentStepData = steps[currentStep];
      if (currentStepData?.element) {
        const targetElement = document.getElementById(currentStepData.element);
        if (targetElement) {
          calculatePositions();
          setTourReadiness((prev) => ({ ...prev, positions: true }));
        }
      }
    });

    // Start observing the document with configured parameters
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
      document.querySelectorAll('[data-tour-highlight]').forEach((element) => {
        element.style.outline = '';
        element.style.transition = '';
        element.removeAttribute('data-tour-highlight');
      });
    };
  }, [isOpen, steps, currentStep]);

  const onLottieReady = () => {
    calculatePositions();
  };

  if (!isOpen || !steps.length) return null;

  const renderStepContent = () => {
    switch (steps[currentStep].type) {
      case 'tooltip':
        return (
          <CustomTourTooltip
            open={true}
            sx={{
              opacity: tourReadiness.positions ? 1 : 0,
              transition: 'opacity 0.4s ease-in-out',
            }}
            placement={steps[currentStep].placement || positions[currentStep]?.placement}
            title={
              <div ref={tourBoxRef}>
                <TourContent
                  bgcolor={color.Yambo_CTA_BG}
                  textColor={color.Yambo_Text_On_White}
                  step={steps[currentStep]}
                  currentStep={currentStep}
                  totalSteps={steps.length}
                  onClose={onClose}
                  onPrevStep={onPrevStep}
                  onNextStep={onNextStep}
                  placement={steps[currentStep].placement || positions[currentStep]?.placement}
                  showSuccessView={showSuccessView}
                  onLottieReady={onLottieReady}
                />
              </div>
            }
            PopperProps={{
              anchorEl: positions[currentStep]?.element,
            }}
            animate={steps[currentStep].animate}
          >
            <div style={{ display: 'none' }} />
          </CustomTourTooltip>
        );
      case 'modal':
        return (
          <Box
            ref={tourBoxRef}
            sx={{
              position: 'fixed',
              top: positions[currentStep]?.top,
              left: positions[currentStep]?.left,
              opacity: tourReadiness.positions ? 1 : 0,
              transform: tourReadiness.positions ? 'scale(1)' : 'scale(0.95)',
              transition: 'opacity 0.4s ease-in-out, transform 0.3s ease-out',
              pointerEvents: tourReadiness.positions ? 'auto' : 'none',
            }}
          >
            <TourContent
              bgcolor={color.Yambo_CTA_BG}
              textColor={color.Yambo_Text_On_White}
              step={steps[currentStep]}
              currentStep={currentStep}
              totalSteps={steps.length}
              onClose={onClose}
              onPrevStep={onPrevStep}
              onNextStep={onNextStep}
              showSuccessView={showSuccessView}
              onLottieReady={onLottieReady}
            />
          </Box>
        );

      case 'overlay':
        return (
          <Box
            ref={tourBoxRef}
            sx={{
              position: 'fixed',
              top: positions[currentStep]?.top,
              left: positions[currentStep]?.left,
              pointerEvents: showSuccessView || steps[currentStep].showButtons ? 'auto' : 'none',
              opacity: tourReadiness.positions ? 1 : 0,
              transition: 'opacity 0.4s ease-in-out',
            }}
          >
            <TourOverlay
              bgcolor={color.Dark_Blue}
              step={steps[currentStep]}
              currentStep={currentStep}
              totalSteps={steps.length}
              onClose={onClose}
              onPrevStep={onPrevStep}
              onNextStep={onNextStep}
              showSuccessView={showSuccessView}
              backdrop={steps[currentStep].backdrop}
              onLottieReady={onLottieReady}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Fade key={currentStep} in={true} timeout={FADE_DURATION}>
        {steps[currentStep].backdrop ? (
          <Box
            id="tour-backdrop"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              width: '100%',
              height: '100%',
              position: 'fixed',
              zIndex: 1200,
            }}
          >
            <Box id="tour-box" sx={{ position: 'fixed', zIndex: 1300 }}>
              {renderStepContent()}
            </Box>
          </Box>
        ) : (
          <Box id="tour-box" sx={{ position: 'fixed', zIndex: 1300 }}>
            {renderStepContent()}
          </Box>
        )}
      </Fade>
    </>
  );
};

export default Tour;
