import { useEffect, useRef, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { Handle, Position } from 'reactflow';
import { useUserRole } from '../../Recipe/UserRoleContext';
import { useTour } from '../../ProductTours/TourContext';
import connectionSuccessAnimation from '../../../Lottie-animations/connection-success.json';
import { StepKeys, TourKeys } from '../../ProductTours/tour-keys';
import { LottieAnimation } from '../../Common/LottieAnimation';
import { AnimatedSuccessCheckmark } from '../../Common/AnimatedCheckmark';
import { color } from '../../../colors';

const DynamicHandleLabel = ({
  description,
  format,
  handleColor,
  handleId,
  handlePosition,
  handleSpace,
  handleTop,
  handleType,
  index,
  label,
  required = false,
  requiredValidationError,
}: {
  description?: string;
  format?: string;
  handleColor?: string;
  handleId: string;
  handlePosition: Position;
  handleSpace: number;
  handleTop: string;
  handleType: 'source' | 'target';
  index: number;
  label: string;
  required?: boolean;
  requiredValidationError?: boolean;
}) => {
  const textRef = useRef<HTMLElement>(null);
  const [labelLeft, setLabelLeft] = useState(-40);

  const role = useUserRole();

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.offsetWidth;
      setLabelLeft(-width);
    }
  }, [label]); // Recalculate if label changes

  const stylesHandleLabel = (labelToHandle) => {
    return labelToHandle.replaceAll('_', ' ');
  };

  // navigation tour related
  const { activeTour, getCurrentStepConfig, showSuccessView, customTourData } = useTour();
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (activeTour === TourKeys.NavigationTour) {
      if (
        (getCurrentStepConfig().stepId === StepKeys.NAVIGATION_TOUR.CONNECT_NODES ||
          getCurrentStepConfig().stepId === StepKeys.NAVIGATION_TOUR.RUN_MODEL) &&
        showSuccessView &&
        index === 0 &&
        handleId.includes(customTourData.modelNodeId) &&
        handleId.includes('prompt')
      ) {
        setShowAnimation(true);
      }
    }
  }, [activeTour, showSuccessView, index, handlePosition, handleId]);

  const showGlow =
    index === 0 &&
    activeTour === TourKeys.NavigationTour &&
    getCurrentStepConfig().stepId === StepKeys.NAVIGATION_TOUR.CONNECT_NODES &&
    ((handleId.includes(customTourData.promptNodeId) && handleId.includes('prompt')) ||
      (handleId.includes(customTourData.modelNodeId) && handleId.includes('prompt'))) &&
    !showSuccessView;

  const lottieAnimation = (
    <LottieAnimation loop={false} animationData={connectionSuccessAnimation} width={200} height={200} />
  );
  // end of navigation tour related

  return (
    <Handle
      type={handleType}
      position={handlePosition}
      style={{
        top: `calc(${handleTop} + ${index * handleSpace}px)`,
        backgroundColor: handleColor,
        cursor: role === 'guest' ? 'default' : '', // Disable pointer for guests
        pointerEvents: role === 'guest' ? 'none' : 'auto',
      }}
      id={handleId}
      className={showGlow ? 'active-tour' : ''}
    >
      {showAnimation && (
        <>
          <Box className="lottie-animation" sx={{ top: -100, left: -100, position: 'absolute' }}>
            {lottieAnimation}
          </Box>
          <Box sx={{ position: 'absolute', top: -7, left: -7 }}>
            <AnimatedSuccessCheckmark size={24} />
          </Box>
        </>
      )}
      {handlePosition === 'left' ? (
        <Tooltip title={format && '[' + format + '] ' + description} placement="right-start" enterDelay={800}>
          <Box sx={{ position: 'relative', left: labelLeft, top: -20, width: 'fit-content' }} ref={textRef}>
            <span
              style={{
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                color: requiredValidationError ? color.Yambo_Orange : '',
              }}
            >
              {stylesHandleLabel(label)}
            </span>
            {required && <span style={{ fontStyle: 'italic' }}>&nbsp;[required]</span>}
          </Box>
        </Tooltip>
      ) : (
        <Box sx={{ position: 'relative', left: 15, top: -20, width: 'fit-content' }} ref={textRef}>
          <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>{stylesHandleLabel(label)}</span>
        </Box>
      )}
    </Handle>
  );
};

export default DynamicHandleLabel;
