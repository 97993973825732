import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Skeleton, Link, Tooltip } from '@mui/material';
import { color } from '../../colors';

function ModelCard({ handleMouseLeaveCard, handleMouseEnterCard, loadingEnrichment, hoveredModelId, model }) {
  return (
    <Card
      sx={{
        transition: 'border-color 0.2s ease-out',
        maxWidth: 345,
        border: '1px solid',
        borderRadius: 2,
        borderColor: hoveredModelId === model.id ? color.Dark_Grey : color.Yambo_Black_Dark,
      }}
      onMouseEnter={() => handleMouseEnterCard(model.id)}
      onMouseLeave={handleMouseLeaveCard}
    >
      {loadingEnrichment ? (
        <Skeleton sx={{ height: 140 }} animation="wave" variant="rectangular" />
      ) : (
        <>
          <CardMedia
            component="img"
            height="140"
            image={model?.poster || '/empty.png'}
            alt="model poster"
            sx={{
              transition: 'transform 0.2s ease-out',
              transform: hoveredModelId === model.id ? 'scale(1.02)' : 'scale(1)',
              objectFit: 'cover',
            }}
          />
        </>
      )}
      <CardContent sx={{ py: 1, pl: 1, backgroundColor: 'transparent', boxShadow: 'none', backgroundImage: 'initial' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={model?.data.menu.displayName} placement="top" enterDelay={800}>
            <Typography noWrap sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} variant="body1" component="div">
              {loadingEnrichment ? <Skeleton /> : `${model?.data.menu.displayName}`}
            </Typography>
          </Tooltip>
          <Tooltip title={model?.data.description} placement="top" enterDelay={800}>
            <Typography variant="caption" noWrap sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {loadingEnrichment ? <Skeleton /> : `${model?.data.description}`}
            </Typography>
          </Tooltip>
          <Tooltip title={model?.data.model.name} placement="top" enterDelay={800}>
            <Typography noWrap variant="caption" sx={{ mt: 2, textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {loadingEnrichment ? <Skeleton /> : `Based on: ${model?.data.model.name}`}
            </Typography>
          </Tooltip>
          <Typography variant="caption">
            {loadingEnrichment ? <Skeleton /> : `Created by: ${model?.creator}`}
          </Typography>
          {model?.github && (
            <Typography variant="caption">
              {loadingEnrichment ? (
                <Skeleton />
              ) : (
                <>
                  <Link href={model?.github} target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-github fa-xs"></i>
                    &nbsp;&nbsp;Github
                  </Link>
                </>
              )}
            </Typography>
          )}
          {/* <Typography variant="caption">License: {model.license}</Typography> */}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ModelCard;
