// Canvas navigation utils
import { fabric } from 'fabric';

// zoom
export const handleZoom = (canvas, event, pinch) => {
  event.preventDefault();
  const delta = pinch ? event.deltaY * 15 : event.deltaY;
  let zoom = canvas.getZoom();

  // Exponential zoom factor change
  zoom *= 0.999 ** delta;
  zoom = Math.max(0.1, Math.min(50, zoom));

  const pointer = canvas.getPointer(event);
  const image = canvas.backgroundImage;

  if (image) {
    const bounds = image.getBoundingRect(true);

    const pointerTransformed = fabric.util.transformPoint(pointer, canvas.viewportTransform);
    const boundTopLeftTransformed = fabric.util.transformPoint(
      new fabric.Point(bounds.left, bounds.top),
      canvas.viewportTransform,
    );
    const boundBottomRightTransformed = fabric.util.transformPoint(
      new fabric.Point(bounds.left + bounds.width, bounds.top + bounds.height),
      canvas.viewportTransform,
    );

    const imgBounds = {
      left: boundTopLeftTransformed.x,
      top: boundTopLeftTransformed.y,
      right: boundBottomRightTransformed.x,
      bottom: boundBottomRightTransformed.y,
    };

    let zoomAnchor = pointerTransformed;
    // if pointer is out of bounds, zoom from the bounds edge
    if (pointerTransformed.x < imgBounds.left) {
      zoomAnchor.x = imgBounds.left;
    } else if (pointerTransformed.x > imgBounds.right) {
      zoomAnchor.x = imgBounds.right;
    }

    if (pointerTransformed.y < imgBounds.top) {
      zoomAnchor.y = imgBounds.top;
    } else if (pointerTransformed.y > imgBounds.bottom) {
      zoomAnchor.y = imgBounds.bottom;
    }

    canvas.zoomToPoint(zoomAnchor, zoom);
  }
};
export const handlePinchZoomGesture = (canvas, event) => {
  event.preventDefault();
  handleZoom(canvas, event, true);
};

// pan
