export enum TourKeys {
  NavigationTour = 'navigation-tour',
  GetToKnowTheInterface = 'get-to-know-the-interface',
  LearnToShare = 'share-tour',
}

export const StepKeys = {
  NAVIGATION_TOUR: {
    WELCOME: 'welcome',
    PAN: 'pan',
    ZOOM: 'zoom',
    ADD_NODES: 'add-nodes',
    CONNECT_NODES: 'connect-nodes',
    RUN_MODEL: 'run-model',
    RUN_MODEL_SUCCESS: 'run-model-success',
    FINISH: 'finish',
  },
};

export const ComponentKeys = {
  FLOW: 'flow',
};
